import { Modal, Button, Row, Col, Form} from "react-bootstrap";

export const DeleteShopTimesheetModal = ({ showDeleteModal, timeSheetToDelete, deleteTimeSheet, setTimesheetToDelete, setShowDeleteModal }) => {
  return (
    <Modal show={showDeleteModal} onHide={() => { }} size="lg">
      <Modal.Header>
        <Modal.Title>Delete Timesheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you would like to delete the following timesheet?
        <p style={{fontWeight: 'bold'}}>{timeSheetToDelete.shopTimesheetName || timeSheetToDelete.timesheetName}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => { setShowDeleteModal(false); setTimesheetToDelete({}) }}>
          Close
        </Button>
        <Button variant="outline-danger" onClick={deleteTimeSheet}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const DeleteResultModal = ({showDeleteResultModal, deleteResult, setShowDeleteResultModal, setDeleteResult}) => {
  return (
    <Modal show={showDeleteResultModal} onHide={() => { setShowDeleteResultModal(false); setDeleteResult(''); }}>
      <Modal.Header>
        <Modal.Title>Deletion Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteResult}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => { setShowDeleteResultModal(false); setDeleteResult(''); }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const BulkShopTimesheetDownloadModal = ({
  showBulkdDownloadModal,
  setShowBulkDownloadModal,
  bulkDownloadTimesheet,
  startDateRef,
  endDateRef,
  emailList,
  setSelectedEmail
}) => {
  return (
    <Modal show={showBulkdDownloadModal} onHide={() => { setShowBulkDownloadModal(false) }} size="xl" style={{ zoom: '90%' }}>
        <Modal.Header>
          <Modal.Title>Timesheet Bulk Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={bulkDownloadTimesheet}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    ref={startDateRef}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    ref={endDateRef}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
              <Col>
                <Form.Group>
                  <Form.Label>User Email</Form.Label>
                  <Form.Control as="select" required onChange={(e) => setSelectedEmail(e.target.value)}>
                    <option key="userEmailSelect">Select User Email</option>
                    <option key="all">All Users</option>
                    {emailList.map((email) => <option key={email}>{email}</option>)}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row >
            <Row style={{ marginTop: '2%' }}>
              <Col>
                <Button variant="outline-dark" type="submit">Download</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => { setShowBulkDownloadModal(false); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
};