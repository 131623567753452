import React from 'react';
import { MarkerType, Position } from 'reactflow';

import { Row, Col } from 'react-bootstrap';

import Barn from '../../../img/pfd/Barn.png';
import Liquids from '../../../img/pfd/Liquids.png';
import PolymerTank from '../../../img/pfd/Polymer tank.png'
import Solids from '../../../img/pfd/Solids.png';
import WaterDroplet from '../../../img/pfd/Water Droplet.png';
import InletVolume from '../../../img/inlet_volume.png';
import System from '../../../img/pfd/system.png';
import SecondWave from '../../../img/pfd/second_wave.png';

const KG_TO_LBS = 2.20462;
const unitConversion = (value, unit) => {
  return unit === 'Imperial' ? value * KG_TO_LBS : value;
};

export const createNodes = (inputData, units, systemType, outputLiquidsPerMinute, outputSolidsPerMinute, outputWaterPerMinute, name, polymerSystem) => {return [
  {
    id: '1',
    type: 'input',
    data: {
      label: (<>
      <div style={{fontWeight: 'bold', fontSize: '13px'}}>
        <Row>
          <Col md={4}>
          <img src={InletVolume} style={{ width: '100%' }} />
          </Col>
          <Col md={7}>
           Inlet Flow <br />
           {unitConversion(Number(inputData["Total Mass Flow"]), units).toFixed(2)} {units == "Imperial" ? "lbs/min" : "kg/min"}
          </Col>
        </Row>
      </div>
      </>),
    },
    position: { x: -200, y: 50 },
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
    draggable: false,
    selectable: false,
  },
  {
    id: '2',
    type: 'input',
    data: {
      label: (<>
        <div style={{ fontWeight: 'bold', fontSize: '13px' }}>
          <Row>
            <Col md={4}>
              <img src={PolymerTank} style={{ width: '100%' }} />
            </Col>
            <Col md={7}>
              Polymer Solution Flow <br />
              {unitConversion(Number(polymerSystem['Polymer Total Mass Flow']), units).toFixed(2)} {units == "Imperial" ? "lbs/min" : "kg/min"}
            </Col>
          </Row>
        </div>
        </>),
    },
    position: { x: -200, y: 150 },
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
    draggable: false,
    selectable: false,
  },
  {
    id: '3',
    data: {
      label: (<>
        <div style={{fontWeight: 'bold', fontSize: '12px'}}>
        <img src={Barn} style={{ width: '90%' }} />
        {name}
        </div>
        <div>
        </div>
        </>),
    },
    position: { x: 100, y: 80 },
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    draggable: false,
    selectable: false,
  },
  {
    id: '4',
    data: {
      label: (<>
        <div style={{fontWeight: 'bold', fontSize: '12px'}}>
        <img src={systemType == "First Wave" ? System : SecondWave} style={{ height: '40px' }} />
        <br />
        {systemType}
        </div>
        <div>
        </div>
        </>),
    },
    position: { x: 350, y: 91 },
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    draggable: false,
    selectable: false,
  },
  {
    id: '5',
    data: {
      label: (<>
      <div style={{ fontWeight: 'bold', fontSize: '13px' }}>
          <Row>
            <Col md={3}>
              <img src={Liquids} style={{ width: '230%', height: '50px' }} />
            </Col>
            <Col md={8}>
              Liq. Nutrient <br />
              {unitConversion(Number(outputLiquidsPerMinute), units).toFixed(2)} {units == "Imperial" ? "lbs/min" : "kg/min"}
            </Col>
          </Row>
        </div>
      </>)
    },
    position: { x: 700, y: 25 },
    targetPosition: Position.Left,
    draggable: false,
    selectable: false,
  },
  {
    id: '6',
    data: {
      label: (<>
        <div style={{ fontWeight: 'bold', fontSize: '13px' }}>
            <Row>
              <Col md={4}>
                <img src={Solids} style={{ width: '130%' }} />
              </Col>
              <Col md={7}>
                Sol. Nutrient <br />
                {unitConversion(parseFloat(outputSolidsPerMinute), units).toFixed(2)} {units == "Imperial" ? "lbs/min" : "kg/min"}
              </Col>
            </Row>
          </div>
        </>)
    },
    position: { x: 700, y: 100 },
    targetPosition: Position.Left,
    draggable: false,
    selectable: false,
  },
  {
    id: '7',
    data: {
      label: (<>
        <div style={{ fontWeight: 'bold', fontSize: '13px' }}>
            <Row>
              <Col md={4}>
                <img src={WaterDroplet} style={{ width: '130%' }} />
              </Col>
              <Col md={7}>
                Clean Water <br />
                {unitConversion(parseFloat(outputWaterPerMinute), units).toFixed(2)} {units == "Imperial" ? "lbs/min" : "kg/min"}
              </Col>
            </Row>
          </div>
        </>)
    },
    position: { x: 700, y: 175 },
    targetPosition: Position.Left,
    draggable: false,
    selectable: false,
  },
];}

export const edges = [
  { id: 'e1-2', source: '1', target: '3' },
  { id: 'e2-3', source: '2', target: '3' },
  { id: 'e3-4', source: '3', target: '4' },
  { id: 'e4-5', source: '4', target: '5' },
  { id: 'e4-6', source: '4', target: '6' },
  { id: 'e4-7', source: '4', target: '7' },
];
