import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { NewFieldReport } from "./TimeReporting/NewFieldReport";

const CreateFieldTimesheetModal = ({ onFinish }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-success" onClick={handleShow}>
        New Field Timesheet
      </Button>

      <Modal show={show} size="xl" style={{zoom: '90%'}}>
        <Modal.Header>
          <Modal.Title>New Field Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewFieldReport />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            handleClose();
            onFinish();
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFieldTimesheetModal;