/**
 * api/workOrders.js
 * ------------------
 * Module encapsulating functions related to the workorders collection in mongodb
 * Interfaces with the LWR API.
 */

import http from "./http";

const createWorkOrder = (workOrder) => {
  return http.post('/work-orders/create', workOrder)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const updateWorkOrder = (workOrder, _id) => {
  return http.put('/work-orders/update', { ...workOrder, _id })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
}

const getAllWorkOrders = () => {
  return http.get('/work-orders/all')
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
}

const getWorkOrder = (id) => {
  return http.get(`/work-orders/${id}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const emailWorkOrder = (body) => {
  return http.post('/work-orders/email',
    body
    ).then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const getAllJobNumbers = () => {
  return http.get('/work-orders/jobs')
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getMetadataForJob = (jobNumber) => {
  return http.post(`/work-orders/metadata-from-job`, { jobNumber })
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const getNextJobNumber = () => {
  return http.get('/work-orders/next-job-number')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const deleteWorkOrder = (id) => {
  return http.delete(`/work-orders/delete/${id}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
}

const downloadWorkOrder = (id, jobNumber, date) => {
  return http({
    method: 'post',
    url: '/work-orders/pdf-download',
    data: { id },
    responseType: 'blob'
  })
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `WO-${jobNumber}-${date.split('T')[0]}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
  .catch((err) => {
    console.log(err);
  });
}

const getWorkOrderPDF = (id) => {
  return http({
    method: 'post',
    url: '/work-orders/pdf-download',
    data: { id },
    responseType: 'blob'
  }).then((res) => {
    return res;
  }).catch((err) => {
    console.log(err);
  });
}

const getAssetClassess = async () => {
  return http.get('/work-orders/asset-classes')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getNextAssetCode = async (baseAssetClass) => {
  return http.post(
    '/work-orders/next-asset-code', 
    { baseAssetClass})
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const getAllAssetIds = async () => {
  return http.get('/work-orders/asset-ids')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAvailableFilters = () => {
  return http.get('/work-orders/filters')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
}

const getFilteredWorkOrders = (body) => {
  return http.post('/work-orders/filtered', body)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return [];
    });
}

const getNextWONumber = () => {
  return http.get('/work-orders/next-wo-id')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return [];
    });
}

export {
  createWorkOrder,
  updateWorkOrder,
  getAllWorkOrders,
  getWorkOrder,
  emailWorkOrder,
  getAllJobNumbers,
  getMetadataForJob,
  deleteWorkOrder,
  getNextJobNumber,
  downloadWorkOrder,
  getAssetClassess,
  getNextAssetCode,
  getAllAssetIds,
  getAvailableFilters,
  getFilteredWorkOrders,
  getNextWONumber,
  getWorkOrderPDF
}