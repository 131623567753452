import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthContext";
import { downloadPDF } from "../../HRFunctions";
import { HRPDFRenderer } from "../../HRPDFRenderer";
import { ExistingHRCSVTimesheet } from "./ExistingHRCSVTimesheet";
import { getTimesheetByID } from "../../../../api/hrTimeSheet.js";
export const ExistingHRTimesheetLearnMore = ({
  learnMoreTimeSheet,
  setLearnMoreTimesheet,
}) => {
  const { email } = useAuth().currentUser;
  const [blobData, setBlobData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    // gets the timesheet data based on the id of timesheet
    getTimesheetByID(learnMoreTimeSheet._id, email).then((result) => {
      // display the buffer from result to a blob csv file
      if (learnMoreTimeSheet.fileType == "pdf") {
        let base64String = Buffer.from(result[0]).toString("base64");
        setBlobData("data:application/pdf;base64," + base64String);
      } else {
        let buffer = Buffer.from(result[0]);
        let arraybuffer = Uint8Array.from(buffer).buffer;
        setBlobData(new Blob([arraybuffer], { type: "octet/stream" }));
      }
    });
  }, [learnMoreTimeSheet]);

  return (
    <div className="">
      {blobData != null && (
        <>
          <div className="d-flex justify-content-between mb-2 pe-5">
            <Button onClick={() => setLearnMoreTimesheet(null)}>Back</Button>
            <div>
              {learnMoreTimeSheet.fileType == "pdf" ? (
                <Button
                  onClick={() =>
                    downloadPDF(
                      blobData,
                      `${learnMoreTimeSheet.timesheetName}.pdf`
                    )
                  }
                >
                  Download Timesheet
                </Button>
              ) : (
                <Button id="timesheetDownloadButton"></Button>
              )}
            </div>
          </div>
          <div className="">
            {learnMoreTimeSheet.fileType == "pdf" ? (
              <HRPDFRenderer
                blobData={blobData}
                pageNumber={pageNumber}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
              />
            ) : (
              <ExistingHRCSVTimesheet
                blobData={blobData}
                timesheetName={learnMoreTimeSheet.timesheetName}
                payPeriod={learnMoreTimeSheet.payPeriod}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
