import React, { useState, useEffect } from 'react';
import { Form, Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';

export const AssessmentReportingInputForm = (props) => {
  const [unit, setUnit] = useState("ppm")
  const [value, setValue] = useState(0)

  useEffect(() => {
    props.changeFormVal(props.formValName, value, unit)
  }, [unit, value])

  const blueBorderStyle = {
    border: '0.5px dotted #005E7D',
  };

  return (
    <div className="col-md-2">
      <label style={{ fontWeight: '550' }}>{props.title}</label>

      <InputGroup className="mb-3">
        <Form.Control type="number" style={blueBorderStyle} defaultValue={0} required ref={props.referenceTo} onChange={(e) => setValue(e.target.value)} />

        <DropdownButton
          variant="outline-secondary"
          title={unit}
          id="input-group-dropdown-2"
          align="end"
        >
          <Dropdown.Item href="#" onClick={() => { setUnit("ppm") }}>ppm</Dropdown.Item>
        </DropdownButton>
      </InputGroup>
    </div>
  )
}
