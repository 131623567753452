import { useState, useRef } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

// hooks
import useQuery from "../../hooks/useQuery";

// api
import { bulkFieldTimePDF } from "../../api/fieldTimeReport";
import { getAllUserEmail } from "../../api/admin";

const FieldTimeBulkDownload = () => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const { data: emailList } = useQuery(
    'email-list',
    getAllUserEmail,
    null
  );

  const bulkDownloadTimesheet = async (e) => {
    e.preventDefault();
    await bulkFieldTimePDF(selectedEmail, startDateRef.current.value, endDateRef.current.value);
  };

  return (
    <>
      <Button variant="outline-dark" onClick={handleShow}>Bulk Timesheet Download</Button>
      <Modal show={show} onHide={handleClose} size="xl" style={{ zoom: '90%' }}>
        <Modal.Header>
          <Modal.Title>Timesheet Bulk Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={bulkDownloadTimesheet}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    ref={startDateRef}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    ref={endDateRef}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
              <Col>
                <Form.Group>
                  <Form.Label>User Email</Form.Label>
                  <Form.Control as="select" required onChange={(e) => setSelectedEmail(e.target.value)}>
                    <option key="userEmailSelect">Select User Email</option>
                    {emailList && emailList.map((email) => <option key={email}>{email}</option>)}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row >
            <Row style={{ marginTop: '2%' }}>
              <Col>
                <Button variant="outline-dark" type="submit">Download</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FieldTimeBulkDownload;