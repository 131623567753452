import React from 'react';
import { Modal, Spinner, Row, Col } from 'react-bootstrap';

export default function LoadingIndicatorModal(props) {
    const handleClose = () => {
        props.setShow(false);
    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose} backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Generating Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
