/**
 * api/apikeys.js
 * ------------------
 * Module encapsulating functions related to the apikeys collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";

const insertApiKey = async (keyNumber, apiKey, targetAPI) => {
  return http.post(`keys/insert/?keyNumber=${keyNumber}&apiKey=${apiKey}&target=${targetAPI}`)
    .then((res) => {
        if(res.status === 200){ return 1; }
        return -1;
    })
    .catch((error) => {
      console.log(error);
      return -1;
    });
};

const deleteApiKey = async (key) => {
  return http.delete(`keys/delete/?apiKey=${key}`)
    .then((res) => {
      if (res.status === 200) {
        return 1;
      }
      return -1;
    }).catch((error) => {
      console.log(error);
      return -1;
    });
};

const getAllApiKeys = async () => {
  return http.get('keys/all')
    .then((res) => {
      if(res.status === 200) {
        return res.data;
      }
    }).catch((error) => {
      console.log(error);
      return;
    });

};

export {
  insertApiKey,
  deleteApiKey,
  getAllApiKeys
};