import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { sendPermissionEmail } from "../../../api/permissionToken";
import { FormGroupCreator } from "../../field-tools/TimeReporting/FormGroupCreator";
import { UserPermissionSelect } from "./UserPermissionSelect";

export const UserPermissionAddModal = ({ allPermission }) => {
  const [show, setShow] = useState(false);
  const exampleNewUserPermission = {
    email: "",
    permissions: "employee",
    morePermissions: [],
  };
  const [newUserPermission, setNewUserPermission] = useState(
    exampleNewUserPermission
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeValue = (value, valueType) => {
    newUserPermission[valueType] = value;
    setNewUserPermission({ ...newUserPermission });
  };

  const isSubmitButtonDisabled = () => {
    return newUserPermission.email.length == 0 ? { disabled: true } : {};
  };

  const createPermissionEmail = () => {
    let permissionList = newUserPermission.permissions
      .replaceAll(" ", "")
      .split(",");
    permissionList = permissionList.concat(
      newUserPermission.morePermissions.map((permission) => permission.value)
    );
    sendPermissionEmail({
      email: newUserPermission.email,
      permissions: permissionList,
    }).then((res) =>
      alert(
        res.status == 200
          ? `Successfully sent signup key to email: ${newUserPermission.email}.`
          : "Could not send signup key to the email provided, please try again."
      )
    );
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add User
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroupCreator
            label="Email"
            valueDict={newUserPermission}
            valueType="email"
            changeValue={changeValue}
          />
          <UserPermissionSelect
            newUser={newUserPermission}
            setNewUser={setNewUserPermission}
            allPermission={allPermission}
          />
          <br />
          {newUserPermission.email == "" ? (
            <div className="text-danger text-center">
              Email cannot be empty!
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            {...isSubmitButtonDisabled()}
            onClick={() => {
              createPermissionEmail();
              handleClose();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
