/**
 * api/iot.js
 * ------------------
 * Module encapsulating functions related to the iotdevices collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";
import { shouldReturnCachedData } from "./cache";

let totalIotCount = null;
let lastPolled    = null;

const getTotalIotCount = async () => {
  // Return cached totalIotCount
  if(shouldReturnCachedData(totalIotCount, lastPolled)) {
    return totalIotCount;
  }
  
  return http.get('iot/count/')
    .then((res) => {
      totalIotCount = res.data;
      lastPolled = Date.now();
      return totalIotCount;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const getAvailableDevices = async () => {
  return http.get('iot/available-devices')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.error(err);
      return err;
    })
};

const deleteIotDevice = async (linkingId) => {
  return http.delete(`iot/delete/?linkingID=${linkingId}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const updateIoTDeviceKey = async (linkingId, key) => {
  return http.put(`iot/update/?linkingID=${linkingId}&newDeviceKey=${key}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const createIotDevice = async (linkingId, iotKey) => {
  return http.post(`iot/insert/?iotKey=${iotKey}&linkingID=${linkingId}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAllIotDevices = async () => {
  return http.get('iot/all-devices')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

export {
  getTotalIotCount,
  deleteIotDevice,
  updateIoTDeviceKey,
  createIotDevice,
  getAllIotDevices,
  getAvailableDevices
};