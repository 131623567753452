import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FieldExpensesModal } from "../FieldExpensesModal";
import { useAuth } from "../../../../contexts/AuthContext";
import { FieldImagesDisplay } from "./FieldImagesDisplay";
import { UploadNewFiles } from "../UploadNewFiles";
import { newExpenseExample, showFileExample } from "../FieldExpenseValues";
import { FormGroupCreator } from "../../TimeReporting/FormGroupCreator";
import { FormCheckCreator } from "../../TimeReporting/FormCheckCreator";
import { convertKeyNameToDisplayName } from "../../TimeReporting/TimeReportFunctions";
import { useEffect } from "react";
import { getProperTime } from "../../fieldToolsFunctions";
import { getReportData } from "../../../../api/fieldTimeReport";
import { createNewExpense } from "../../../../api/fieldExpenseReport";

export const NewFieldExpenses = () => {
  const { email } = useAuth().currentUser;
  useEffect(() => {
    getReportData(email).then((result) =>
      setTimesheetList(() => [
        { _id: null, reportName: null, createdAt: null },
        ...result,
      ])
    );
  }, []);
  const [imgDatas, setImgDatas] = useState([]);
  const [show, setShow] = useState(false);
  const [showFile, setShowFile] = useState(showFileExample);
  const [newExpense, setNewExpense] = useState(newExpenseExample);
  const [timesheetList, setTimesheetList] = useState([]);

  const handleClose = () => {
    setShow(false);
    setShowFile(showFileExample);
  };
  const handleShow = (fileUrl, name) => {
    setShow(true);
    setShowFile({ url: fileUrl, name });
  };
  const updateNewExpenseValue = (value, valueType) => {
    let _newExpense = { ...newExpense };
    _newExpense[`${valueType}`] = value;
    setNewExpense(_newExpense);
  };

  const submitNewFieldExpenseReport = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < imgDatas.length; i++) {
      formData.append("imgData", imgDatas[i]);
    }
    for (var key in newExpense) {
      formData.append(key, newExpense[key]);
    }
    formData.append("email", email);
    createNewExpense(formData).then(async (res) =>
      alert(
        res.status == 200
          ? "Expense report has been successfully created"
          : "Expense report could not be created. Try again later!"
      )
    );
  };

  const onChange = (fileList) => {
    setImgDatas(fileList);
  };

  const disableSubmitButton = () => {
    return !newExpense.expenseTimesheet ? { disabled: true } : null;
  };

  return (
    <div className="pt-2">
      <br />
      <div className="mb-3">
        <br />
        <UploadNewFiles onChange={onChange} />
        <br />
        <FieldImagesDisplay imgDatas={imgDatas} handleShow={handleShow} />
      </div>
      <Form onSubmit={(e) => submitNewFieldExpenseReport(e)}>
        <Form.Group>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={2} className="text-center">
              <Form.Label>Expense Type</Form.Label>
            </Col>
            <Col md={10}>
              <Form.Control
                as="select"
                required
                onChange={(e) => updateNewExpenseValue(e.target.value, 'expenseType')}
              >
                <option value=''>Select Expense Type</option>
                <option value='Meals'>Meals</option>
                <option value='Lodging'>Lodging</option>
                <option value='Fuel'>Fuel</option>
                <option value='Materials'>Materials</option>
                <option value='Subcontractors'>Subcontractors</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
        {Object.keys(newExpense).map((key) => {
          if (key === 'expenseType') { return; }
          return (key != "amount" &&
            key != "currencyType" &&
            key != "expenseTimesheet" ? (
            typeof newExpense[key] == "boolean" ? (
              <FormCheckCreator
                label={convertKeyNameToDisplayName(key)}
                changeValue={updateNewExpenseValue}
                valueDict={newExpense}
                valueType={key}
              />
            ) : (
              <>
                <FormGroupCreator
                  label={convertKeyNameToDisplayName(key)}
                  type={typeof newExpense[key]}
                  changeValue={updateNewExpenseValue}
                  valueDict={newExpense}
                  valueType={key}
                  isRequired={{
                    required: key != "description",
                  }}
                />
              </>
            )
          ) : null)
        }
        )}
        <Row>
          <Col md={4}>
            <Row>
              <Col md={6} className="text-center">
                Amount
              </Col>
              <Col md={6}>
                <FormGroupCreator
                  type="number"
                  changeValue={updateNewExpenseValue}
                  valueDict={newExpense}
                  valueType={"amount"}
                />
              </Col>
            </Row>
          </Col>
          <Col md={1} className="pt-1">
            <select
              class="form-select"
              value={newExpense["currencyType"]}
              size="md"
              onChange={(e) =>
                setNewExpense((newExpense) => ({
                  ...newExpense,
                  currencyType: e.target.value,
                }))
              }
            >
              <option value="USD">USD</option>
              <option value="CAD">CAD</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={2} className="text-center">
            Timesheet
          </Col>
          <Col>
            <select
              class="form-select"
              value={newExpense.expenseTimesheet}
              size="md"
              onChange={(e) =>
                setNewExpense((newExpense) => ({
                  ...newExpense,
                  expenseTimesheet: e.target.value,
                }))
              }
              required
            >
              {timesheetList.map((timesheet) => (
                <option value={timesheet._id}>
                  {timesheet.reportName}

                  {timesheet.reportName
                    ? " created at " + getProperTime(timesheet.createdAt)
                    : null}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <br />
        <div
          className="text-danger text-center"
          style={{
            display: `${!newExpense.expenseTimesheet ? "block" : "none"}`,
          }}
        >
          Please reference a timesheet to expense before submitting
        </div>
        <br />
        <div className="d-flex w-100 justify-content-center">
          <Button {...disableSubmitButton()} type="submit">
            Submit Expense
          </Button>
        </div>
      </Form>
      <FieldExpensesModal
        handleClose={handleClose}
        show={show}
        showFile={showFile}
      />
    </div>
  );
};
