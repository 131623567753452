import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

// api
import { getAllSystemSites } from "../../api/systemSites";
import { deleteByDateAndLinkingId, insertSensorData } from "../../api/sensordata";

export default function ConfirmModal(props) {
  const [show, setShow] = useState(false);
  const [linkingIdElements, setLinkingIdElements] = useState([]);
  const [linkingId, setLinkingId] = useState("skyridge_farms");
  const [logs, setLogs] = useState([]);
  const [logElements, setLogElements] = useState([]);

  const handleClose = () => {
    setShow(false);
    setLogs([]);
    setLogElements([]);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    console.log(props.files);
    fetchAndLoadLinkingIdOptions();
  }, [props.files]);

  const fetchAndLoadLinkingIdOptions = async () => {
    const systemSites = await getAllSystemSites();
    setLinkingIdElements(
      systemSites.map((entry) => {
        return <option value={entry.linking_id}>{entry.linking_id}</option>;
      })
    );
  };

  const isNumeric = (str) => {
    if (typeof str != "string") {
      return false;
    } else {
      // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    }
  };

  const makeLog = (msg) => {
    let currLogs = logs;
    currLogs.push(msg);
    setLogs(currLogs);

    setLogElements(
      logs.map((log) => {
        return <li>{log}</li>;
      })
    );
  };

  const convertFilesToStringContents = async (e) => {
    e.preventDefault();
    await props.files.forEach(async (file) => {
      let allSuccess = true;
      let reader = new FileReader();
      reader.onload = async function (e) {
        try {
          let data = e.target.result;
          data = data.replace(/\r/g, "");
          data = data.replace(/"/g, "");
          let arr = data.split("\n");
          let fieldNames = arr[0].split(",");
          arr.shift();

          // Generate proper document field names
          let properDocumentNames = [];
          fieldNames.forEach((name) => {
            name = name.replace(/-/g, "_");
            name = name.replace(/#/g, "");
            name = name.replace(/ /g, "_");
            name = name.split(".").join();
            name = name.split("/").join("_");
            name = name.split(",").join();
            name = name.replace(/,/g, "");
            name = name.toLowerCase();

            properDocumentNames.push(name);
          });

          // Parse the data in arr
          let parsedData = [];
          arr.forEach((row) => {
            data = row.split(",");
            let finalData = [];
            for (let i = 0; i < data.length; i++) {
              if (isNumeric(data[i])) {
                finalData.push(parseInt(data[i]));
              } else {
                finalData.push(data[i]);
              }
            }
            parsedData.push(finalData);
          });


          // Before inserting data, delete all previous data from that date
          const deleteResult = await deleteByDateAndLinkingId(parsedData[0][0], linkingId);
          if (deleteResult.status != 200) {
            makeLog(
              `Error deleting previously inserted data for ${parsedData[0][0]}`
            );
            return;
          } else {
          }

          await parsedData.forEach(async (row) => {
            let document = {};
            for (let i = 0; i < row.length; i++) {
              document[properDocumentNames[i]] = row[i];
            }
            document["linkingId"] = linkingId;
            const insertResult = await insertSensorData(document);
            if (insertResult.status == 200) {
              allSuccess = true;
            } else {
              makeLog(
                `Failure inserting ${file.path} contents into the databse`
              );
            }
          });

          if (allSuccess) {
            makeLog(
              `Succesfully inserted ${file.path} contents into the database`
            );
          } else {
            makeLog(`Failure inserting ${file.path} contents into the databse`);
          }
        } catch (e) {
          makeLog(`Failure inserting ${file.path} contents into the databse`);
        }
      };
      await reader.readAsText(file);
    });
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow}>
        Upload Files to Database
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Sensor Data CSV Insert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ul>{logElements}</ul>
          </div>
          Choose the corresponding linking ID for the supplied sensor data.
          <Form onSubmit={convertFilesToStringContents}>
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setLinkingId(e.target.value);
                }}
                required
              >
                {linkingIdElements}
              </Form.Control>
            </Form.Group>
            <br />
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
