export function parseDate(date) {
    let year = `${date.getFullYear()}`;
    let month = date.getMonth();
    let day = date.getDate();
    month += 1;

    const properMonth = month < 10 ? `0${month}` : month;
    const properDay = day < 10 ? `0${day}` : day;
    return `${year}-` + properMonth + "-" + properDay;
}

export function getStartDate(stringDate) {
    let date = new Date(stringDate);
    date.setDate(date.getDate() - 12);
    while (date.getDay() % 6 == 0) {
        date.setDate(date.getDate() + 1);
    }
    return parseDate(date);
}

export function getCurrentDate() {
    const getDate = new Date();
    return parseDate(getDate);
}

export const downloadPDF = (blobData, fileName) => {
    const link = document.createElement("a");
    link.href = blobData;
    link.download = fileName;
    link.click();
};