/**
 * WorkOrderListPage.jsx
 * ---------------------
 * Main component for the Work Orders management page.
 * 
 * Author: Oscar Jaimes
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Table, Badge, Button } from 'react-bootstrap';
import { useAuth } from "../../contexts/AuthContext";
import { isPermissionWOAdmin, getUserPermissionFromCache } from '../../utility/permissionFunctions';
import { deleteWorkOrder, downloadWorkOrder } from '../../api/workOrders';
import FilterCard from './FilterCard';
import { FaTrashAlt } from "react-icons/fa";
import { DeleteModal, ResultModal } from './WorkOrderModals';
import { CreateWO, EditWO, ViewWO } from './WorkOrderViews';

const WorkOrdersListPage = ({ }) => {
  const { email } = useAuth().currentUser;
  const [userAccountType, setUserAccountType] = useState([]);

  const [loading, setLoading] = useState(true);
  const [workOrders, setWorkOrders] = useState([]);
  const [workOrderToDelete, setWorkOrderToDelete] = useState("");
  const [_, setConfirmDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [viewWO, setViewWO] = useState(false);
  const [selectedWO, setSelectedWO] = useState(null);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [filter, setFilter] = useState('');
  const [availableFilters, setAvailableFilters] = useState({
    jobNumbers: [],
    orderedBy: []
  });

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredWorkOrders = workOrders.filter((workOrder) => {
    return (
      ("" + workOrder.jobNumber).toLowerCase().includes(filter.toLowerCase()) ||
      workOrder.dateOfOrder.toLowerCase().includes(filter.toLowerCase()) ||
      workOrder.orderedBy.toLowerCase().includes(filter.toLowerCase())
    );
  });

  useEffect(() => {
    const setAccount = async () => {
      setUserAccountType(await getUserPermissionFromCache(email));
    };
    setAccount();
  }, []);

  const handleDelete = async (workOrderId) => {
    setShowDeleteModal(true);
    setWorkOrderToDelete(workOrderId);
  };

  const deleteWO = async () => {
    const res = await deleteWorkOrder(workOrderToDelete);
    if (res.status == 200) {
      setShowResultModal(true);
    } else {
      alert('error deleting work order');
    }
    setConfirmDelete(false);
  };

  const showWO = (wo) => {
    setViewWO(true);
    setSelectedWO(wo);
  };

  const getName = (email) => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const name = email.split('@')[0]
      .split('.')
      .map(capitalize)
      .join(' ');

    return name;
  };

  return (
    <div style={{ margin: '10px' }}>
      {/* Result Modals */}
      <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} setConfirmDelete={setConfirmDelete} deleteWO={deleteWO} />
      <ResultModal showResultModal={showResultModal} setShowResultModal={setShowResultModal} />

      {/* Off Canvas Work Order Views */}
      <EditWO edit={edit} setEdit={setEdit} setViewWO={setViewWO} selectedWO={selectedWO} />
      <CreateWO create={create} setCreate={setCreate} setViewWO={setViewWO}
        setSelectedWO={setSelectedWO} />
      <ViewWO
        viewWO={viewWO}
        setViewWO={setViewWO}
        setSelectedWO={setSelectedWO}
        downloadWorkOrder={downloadWorkOrder}
        selectedWO={selectedWO}
        setEdit={setEdit}
      />

      {/* Page Header */}
      <Row className='mb-3'>
        <Col md={10} style={{ textAlign: 'left' }}>
          <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Work Orders
            {(isPermissionWOAdmin(userAccountType)) && " - WO Admin View"}
          </h5>
        </Col>
        <Col md={2}>
          <Button className='w-100' variant='outline-dark' onClick={() => { setCreate(true) }}>New Work Order</Button>
        </Col>
      </Row>

      {/* Filter Component */}
      <FilterCard
        reset={create}
        filter={filter}
        handleFilterChange={handleFilterChange}
        setWorkOrders={setWorkOrders}
        setLoading={setLoading}
        setFilter={setFilter}
        availableFilters={availableFilters}
        setAvailableFilters={setAvailableFilters}
      />

      {loading && <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" role="status" style={{ width: '100px', height: '100px', marginTop: '10%' }} />
      </div>
      }

      {/* Work Orders */}
      {!loading && <div style={{ maxHeight: '80vh', overflowY: 'auto', marginTop: '1%' }}>
        <Table striped border hover className='mt-3'>
          <thead style={{ position: 'sticky', top: '0', zIndex: '1000', backgroundColor: 'white' }}>
            <tr>
              <th>🧾 WO ID</th>
              <th>Job Number</th>
              <th style={{ width: '30%' }}>Order Info</th>
              <th>Date</th>
              <th>Ordered By</th>
              {isPermissionWOAdmin(userAccountType) && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {filteredWorkOrders.map((wo, i) => (
              <tr>
                <td onClick={() => { showWO(wo); }} style={{ fontWeight: 'bold', color: '#005E7D' }}>{wo.uniqueId}</td>
                <td onClick={() => { showWO(wo); }}>{wo.jobNumber}</td>
                <td onClick={() => { showWO(wo); }}><Badge bg='' style={{ backgroundColor: '#005E7D' }}>{wo.orders[0].description.substring(0, 45) + '...'}</Badge></td>
                <td onClick={() => { showWO(wo); }}>{wo.dateOfOrder.split('T')[0]}</td>
                <td onClick={() => { showWO(wo); }}>{getName(wo.orderedBy)}</td>
                {isPermissionWOAdmin(userAccountType) && <td><FaTrashAlt size={22} onClick={() => { handleDelete(wo._id) }} /></td>}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>}
    </div>
  );
};

export default WorkOrdersListPage;