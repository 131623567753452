/**
 * App.jsx
 * -------
 * Main component of the application.
 * Handles private routing (i.e User cannot access aplication without being logged in)
 *
 * Author: Oscar Jaimes
 * Last Updated: 26/05/2021
 */

import React, { Component } from 'react';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import "react-datepicker/dist/react-datepicker.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import SignUp from './pages/Signup';
import Login from './pages/Login';
import NavBar from './components/NavBar/NavBar';
import PasswordResetRequest from './pages/PasswordResetRequest';
import PasswordReset from './pages/PasswordReset';
import PrivateRoute from './components/PrivateRoute';

class App extends Component {
  render() {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Router>
          <AuthProvider>
            <Switch>
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/password-reset-request" component={PasswordResetRequest} />
              <Route exact path="/password-reset" component={PasswordReset} />
              <PrivateRoute path="/" component={NavBar} />
              <Redirect to="/login" />
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

export default App;
