/**
 * api/chemicals.js
 * ------------------
 * Module encapsulating functions related to the chemicals collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";
import { shouldReturnCachedData } from "./cache";

let chemicalCount = null;
let lastPolled     = null;

const getTotalChemicalCount = async () => {
  if(shouldReturnCachedData(chemicalCount, lastPolled)) {
    return chemicalCount;
  }

  return http.get('chemicalCosts/count/')
    .then((res) => {
      chemicalCount = res.data;
      lastPolled = Date.now();
      return chemicalCount;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const deleteChemical = async (chemicalName) => {
  return http.delete(`chemicalCosts/delete/?chemical=${chemicalName}`)
    .then((res) => {
      if(res.status === 200) {
        return 1;
      }
      return -1;
    }).catch((error) => {
      console.log(error);
      return -1;
    });
};

const updateChemical = async (chemicalName, newCost) => {
  return http.put(`chemicalCosts/update/?chemical=${chemicalName}&newCost=${newCost}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

const createChemical = async (chemicalName, cost) => {
  return http.post(`chemicalCosts/insert/?chemical=${chemicalName}&cost=${cost}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

const getAllChemicals = async () => {
  return http.get('chemicalCosts/all')
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log(error);
      return error;
    })
}

export {
  getTotalChemicalCount,
  deleteChemical,
  updateChemical,
  createChemical,
  getAllChemicals
};