import React, { Component } from "react";
import "../styles/Login.css";
import LoginForm from "../components/auth/LoginForm";
import { checkIfWindowIsMobile } from "../utility/mobileFunctions";
import LoginScreenBoiler from "./LoginScreenBoiler";

export default class Login extends Component {
  constructor(props) {
    super(props);
    document.title = "Login";
  }

  render() {
    return (
      <LoginScreenBoiler>
        <LoginForm />
      </LoginScreenBoiler>
    );
  }
}
