import { Modal, Button } from "react-bootstrap";

const DeleteModal = ({ showDeleteModal, setShowDeleteModal, setConfirmDelete, deleteWO }) => {
  return (<Modal show={showDeleteModal} size='lg' style={{ zoom: '90%' }}>
    <Modal.Header>
      <Modal.Title>Delete Work Order</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you would like to delete this work order?</Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={() => setShowDeleteModal(false)}>
        Cancel
      </Button>
      <Button variant="outline-danger" onClick={() => { setConfirmDelete(true); setShowDeleteModal(false); deleteWO() }}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>)
}

const ResultModal = ({showResultModal, setShowResultModal}) => {
  return (
    <Modal show={showResultModal}>
      <Modal.Header>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>Work Order Succesfully Deleted.</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShowResultModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export {
  DeleteModal,
  ResultModal
}