import http from "./http";

export const getAllShopTimesheet = async (email) => {
  return http.get(`shop-timesheets/all/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getShopTimesheet = async (_id, email) => {
  return http.get(`shop-timesheets/find/?_id=${_id}&email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const createNewShopTimesheet = async (body) => {
  return http.post(`shop-timesheets/create/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateShopTimesheet = async (body) => {
  return http.put(`shop-timesheets/update/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteShopTimesheet = async (_id, email) => {
  return http.delete(`shop-timesheets/delete/?_id=${_id}&email=${email}`,)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const bulkDownload = async (targetUserEmail, startDate, endDate) => {
  return http({
    method: 'post',
    url: '/shop-timesheets/bulk-download',
    data:  {
      targetUserEmail,
      startDate,
      endDate
    },
    responseType: 'blob'

  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${targetUserEmail} Shop Timesheet ${startDate} - ${endDate}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const bulkAggregatedDownload = async(startDate, endDate) => {
  return http({
    method: 'post',
    url: '/shop-timesheets/aggregated-bulk-download',
    data:  {
      startDate,
      endDate
    },
    responseType: 'blob'

  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `All Users Aggregated Shop Timesheet ${startDate} - ${endDate}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const singleDownload = async (_id) => {
  return http({
    method: 'post',
    url: '/shop-timesheets/single-download',
    data: { _id },
    responseType: 'blob'

  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `shopTimesheet.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const approveTimesheet = async (_id, approvedBy) => {
  return http.put('/shop-timesheets/approve', {
    _id,
    approvedBy
  })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};