import React, { useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { deleteHRPolicy, getAllPolicy } from "../../../../api/hrPolicy";
import { useAuth } from "../../../../contexts/AuthContext";
import { DeleteModal } from "../../../DeleteModal";
import { ExistingHRReportCard } from "./ExistingHRReportCard";

// Component shows all existing HR policies
export const ExistingHRPolicies = () => {
  const history = useHistory();
  const [policiesList, setPoliciesList] = useState([]);
  const { email } = useAuth().currentUser;
  const [policyToBeDeleted, setPolicyToBeDeleted] = useState({});
  const [show, setShow] = useState(false);
  const compareFunction = (a, b) => {
    if (a.favorite && a.favorite == true) {
      return -1;
    }
    if (b.favorite && b.favorite == true) {
      return 1;
    }
    return 1;
  };
  // get all polocy that is assigned to the user
  useEffect(async () => {
    getAllPolicy(email).then((result) => {
      result.sort(compareFunction);
      setPoliciesList(result);
    });
  }, []);

  // sets the policy to be displayed
  const learnMoreFunction = (policy) => {
    // setLearnMorePolicyId(policy._id);
    history.push(`/hr-tools/hr-policies/existingHRPolicies/${policy._id}`);
  };

  // sets the policy to be deleted
  const deleteFunction = (policy) => {
    setPolicyToBeDeleted(policy);
    setShow(true);
  };

  // deletes the policy from database
  const deleteFunctionModal = () => {
    deleteHRPolicy(policyToBeDeleted._id).then((res) => {
      if (res.status == 200) {
        let _policiesList = [...policiesList];
        _policiesList = _policiesList.filter(
          (policy) => policy._id != policyToBeDeleted._id
        );
        setPoliciesList(_policiesList);
        alert(`Policy ${policyToBeDeleted.policyName} was successfuly deleted`);
      } else {
        alert("Could not delete policy, please try again later.");
      }
    });
  };
  return (
    <div>
      <ListGroup>
        <div className="">
          <Row className="py-2">
            <Col md={7}>
              <div className="d-flex justify-content-between pe-5 ps-2">
                <div>Policy Name</div>
                <div>Created At</div>
              </div>
            </Col>
          </Row>
          {policiesList.length > 0 ? (
            <div className="">
              <ExistingHRReportCard
                reportList={policiesList}
                learnMoreFunction={learnMoreFunction}
                deleteFunction={deleteFunction}
                setPoliciesList={setPoliciesList}
              />
              <DeleteModal
                show={show}
                setShow={setShow}
                deleteFunction={deleteFunctionModal}
                titleName={policyToBeDeleted.policyName}
                report={false}
                policy={true}
              />
            </div>
          ) : (
            <h5 className="text-center border-top pt-2">
              No Policy to be display
            </h5>
          )}
        </div>
      </ListGroup>
    </div>
  );
};
