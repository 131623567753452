
// Librariers & Components
import React, { Component } from 'react';
import { Card, Button, ListGroupItem, ListGroup, Form, Nav } from 'react-bootstrap';


// Custom Components
import AdminNav from './Admin/AdminNav';

// Styling
import "../styles/AdminTools.css"
export default class AdminToolsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ backgroundColor: "white", overflow: 'hidden' }}>
        <AdminNav account={this.props.account} accountType={this.props.accountType} />
      </div>
    );
  }
}