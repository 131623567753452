/**
 * Validates assessment reporting parameters
 * 
 * @param {Object} assessmentInputs 
 * @returns {Boolean}
 */
export const validateAssessmentInputs = (assessmentInputs) => {
  if (Number(assessmentInputs.tflow) <= 0) {
    alert("Error: Annual Flow Must Be Greater Than 0");
    return false;
  }
  if (!(Number(assessmentInputs.ts) >= 1000 && Number(assessmentInputs.ts) <= 150000)) {
    alert("Error: Total Solids Must Be In Range: 1,000 - 150,000 ppm");
    return false;
  }
  if (!(Number(assessmentInputs.ss) >= 1150 && Number(assessmentInputs.ss) <= 75000)) {
    alert("Error: Suspended Solids Must Be In Range: 1,150 - 75,000 ppm");
    return false;
  }
  if (Number(assessmentInputs.ts) <= Number(assessmentInputs.ss)) {
    alert("Error: Total Solids Must Be Greater Than Suspended Solids");
    return false;
  }
  if (!(Number(assessmentInputs.aNitrogen) > 0)) {
    alert("Error: Ammonium Nitrogen Must Be Greater Than 0");
    return false;
  }
  if (!(Number(assessmentInputs.tkn) > 0)) {
    alert("Error: TKN Must Be Greater Than 0");
    return false;
  }
  if (Number(assessmentInputs.tkn) <= Number(assessmentInputs.aNitrogen)) {
    alert("Error: TKN Must Be Greater Than Ammonium Nitrogen");
    return false;
  }
  if (!(Number(assessmentInputs.potassium) > 0)) {
    alert("Error: Potassium Must Be Greater Than 0");
    return false;
  }
  if (!(Number(assessmentInputs.phosphorus) > 0)) {
    alert("Error: Phosphorus Must Be Greater Than 0");
    return false;
  }
  if (!(Number(assessmentInputs.vs) > 0)) {
    alert("Error: Volatile Solids Must Be Greater Than 0");
    return false;
  }

  if (assessmentInputs.sysType == "Full System" && assessmentInputs.hours > 22) {
    alert("Error: Hours must be less than or equal to 22 for full system");
    return false;
  }

  if (
    Number(assessmentInputs.roRecovery < 40) ||
    Number(assessmentInputs.roRecovery > 73)
  ) {
    alert("Error: Ro recovery must be between 50-73%");
    return false;
  }

  if (
    assessmentInputs.secondRO && (
      Number(assessmentInputs.ro2Recovery < 50) ||
      Number(assessmentInputs.ro2Recovery > 95))
  ) {
    alert("Error: Ro #2 recovery must be between 40-95%");
    return false;
  }

  return true;
};