/**
 * client-report-translations/en.js
 * ---------------------------------
 * 
 * Chineese strings for the client assessment report.
 * 
 * Author: Oscar Jaimes
 * Date: February 5, 2023
 */

const translations = {
  language :'ch',
  treatment_assessment_report: '污水处理评估报告',
  client_assessment: '面向客户的评估报告',
  metric_units: '公制单位s',
  imperial_units: '英制单位',
  annual_flow: '年度总流量',
  total_solids_ppm: '固化物总含量',
  input_data_ppm: '输入参数 (ppm)',
  total_suspended_solids: '悬浮物总含量',
  ammonium_nitrogen: '氨态氮',
  organic_nitrogen: '有机氮',
  potassium: '钾',
  phosphorus: '磷',
  calcium: '钙',
  magnesium: '鎂',
  sodium: '钠',
  iron: '鐵',
  manganese: '錳',
  sulfur: '硫',
  copper: '銅',
  zinc: '鋅',
  other_dissolved_solids: '其他溶解固体',
  other: '其他',
  operating_information: '系统运行信息',
  operating_days_per_year: '每年运行天数',
  operating_hours_per_day: '每天运行小时数',
  nutrient_value: '营养价值',
  process_flow: '工艺流程',
  system_type: '系统类型',
  full_system: 'Full System',
  first_wave: 'First Wave',
  dollar_per_kg: '$/kg',
  kg_per_min: 'kg/min',
  lb_per_min: 'lb/min',
  nutrient_concentration: '营养素浓度',
  inputs: '投入物料',
  system_outputs: '系统产出物料',
  nutrient: '营养素',
  digestate: '沼渣',
  liquid_nutrient: '液态营养素',
  nutrient_solids: '固态营养素',
  clean_water: '清水',
  kg_per_m3: 'kg/m^3',
  lb_per_1000_gal: 'lb/1000gallons',
  kg_per_ton: 'kg/ton',
  kg_per_tonne: 'kg/tonne',
  ppm: 'ppm',
  output_data: '输出参数',
  yearly_outputs: '年度产出量',
  annual_liquid_nutrient: '年度液态营养素产出',
  annual_nutrient_solids: '年度固态营养素产出',
  annual_clean_water: '年度清水产出',
  volatile_solids: '挥发性固体'
};

export default translations;