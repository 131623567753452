import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { deleteTimesheet, getAllTimesheet } from "../../../../api/hrTimeSheet";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  getUserPermissionFromCache,
  isPermissionTypeHRAdmin,
} from "../../../../utility/permissionFunctions";
import { DeleteModal } from "../../../DeleteModal";
import { HRTimesheetFilter } from "../../HRTimesheetFilter";
import { ExistingHRReportCard } from "./ExistingHRReportCard";
import { ExistingHRTimesheetLearnMore } from "./ExistingHRTimesheetLearnMore";

// Displays HR Timesheet
export const ExistingHRTimeSheet = () => {
  const { email } = useAuth().currentUser;
  // contains original timereport list
  const [timesheetReportList, setTimesheetReportList] = useState([]);
  // constains filtered timesheet list
  const [filteredTimesheetReportList, setFilteredTimesheetReportList] =
    useState([]);
  const [learnMoreTimesheet, setLearnMoreTimesheet] = useState(null);
  const [show, setShow] = useState(false);
  const [reportToBeDeleted, setReportToBeDeleted] = useState(false);
  const [userPermission, setUserPermission] = useState([]);
  // gets all timesheet of the user and the user account type
  useEffect(async () => {
    getAllTimesheet(email).then((result) => {
      const selectedValueResult = result.map((val) => ({
        ...val,
        selected: false,
      }));
      setTimesheetReportList(selectedValueResult);
      setFilteredTimesheetReportList(selectedValueResult);
    });
    setUserPermission(await getUserPermissionFromCache(email));
  }, []);

  const learnMoreFunction = (report) => {
    setLearnMoreTimesheet(report);
  };

  // function is called on delete button click
  const deleteFunction = (report) => {
    setReportToBeDeleted(report);
    setShow(true);
  };

  // delete function for the modal
  const deleteFunctionModal = () => {
    deleteTimesheet(reportToBeDeleted._id, email).then((res) => {
      if (res.status == 200) {
        let _timesheetReportList = [...timesheetReportList];
        _timesheetReportList = _timesheetReportList.filter(
          (timesheet) => timesheet._id != reportToBeDeleted._id
        );
        setTimesheetReportList(_timesheetReportList);
        let _filteredTimesheetReportList = [...filteredTimesheetReportList];
        _filteredTimesheetReportList = _filteredTimesheetReportList.filter(
          (timesheet) => timesheet._id != reportToBeDeleted._id
        );
        setFilteredTimesheetReportList(_filteredTimesheetReportList);
        alert("Timesheet has been successfully deleted!");
      } else {
        alert("Timesheet could not be deleted, please try again later!");
      }
    });
  };

  return (
    <>
      {!learnMoreTimesheet ? (
        <>
          {isPermissionTypeHRAdmin(userPermission) ? (
            <HRTimesheetFilter
              reportList={timesheetReportList}
              setFilteredReportList={setFilteredTimesheetReportList}
            />
          ) : null}

          <Row>
            <Col md={4} className="text-center">
              Timesheet Name
            </Col>
            <Col md={4} className="text-center">
              Created At
            </Col>
          </Row>
          {filteredTimesheetReportList.length > 0 ? (
            <>
              <ExistingHRReportCard
                reportList={filteredTimesheetReportList}
                setReportList={setFilteredTimesheetReportList}
                learnMoreFunction={learnMoreFunction}
                deleteFunction={deleteFunction}
              />
              <DeleteModal
                titleName={reportToBeDeleted.timesheetName}
                show={show}
                setShow={setShow}
                deleteFunction={deleteFunctionModal}
              />
            </>
          ) : (
            <h5 className="text-center border-top pt-2">
              No Timesheet to be display
            </h5>
          )}
        </>
      ) : (
        <ExistingHRTimesheetLearnMore
          learnMoreTimeSheet={learnMoreTimesheet}
          setLearnMoreTimesheet={setLearnMoreTimesheet}
        />
      )}
    </>
  );
};
