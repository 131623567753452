import React, { useEffect, useState } from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { getAllReports } from "../../api/fieldTimeReport";
import { FaEye, FaEdit, FaFileDownload } from "react-icons/fa";
import { ExistingFieldReportInfo } from "./TimeReporting/ExistingTimeReport/DisplayExistingTimeReport/ExistingFieldReportInfo";
import { isPermissionTypeHRAdmin, isPermissionTypeFieldAdmin } from "../../utility/permissionFunctions";
import CreateFieldTimesheetModal from "./CreateFieldTimesheetModal";
import { ExistingFieldReportFilter } from "./TimeReporting/ExistingTimeReport/ExistingTimeReportFilter/ExistingFieldReportFilter";
import FieldTimeBulkDownload from "./FieldTimeBulkDownload";
import { singleFieldTimePDF } from "../../api/fieldTimeReport";

// custom hooks
import usePermission from "../../hooks/permissions";
import useQuery from "../../hooks/useQuery";
import { useAuth } from "../../contexts/AuthContext";
import EditFieldTimesheetModal from "./EditFieldTimesheetModal";

export const FieldTimeReporting = () => {
  // state
  const [report, setReport] = useState(null);
  const [isReportList, setIsReportList] = useState(true);
  const [filteredReportList, setFilteredReportList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  // custom hooks
  const { email } = useAuth().currentUser;
  const { permission } = usePermission(email);
  const { data: timeSheets = [], error, isLoading, refetch: refetchTimesheets } = useQuery(
    'field-timesheets',
    () => getAllReports(email),
    null,
  );

  useEffect(() => {
    document.title = 'Field Timesheets'
    setFilteredReportList(timeSheets)
  }, [timeSheets]);

  // utility functions
  const downloadTimesheet = async (id) => { await singleFieldTimePDF(id) };

  const deleteTimesheet = () => {
    // TODO
  };

  const TimesheetRows = ({ timesheetList }) => {
    const hours = timesheetList.map((ts) => {
      let total = 0;
      ts.workCarriedOut.forEach((task) => {
        total += Number(task.hours);
      });

      return total;
    });

    return timesheetList.map((ts, i) => (
      <tr>
        <td>{ts.reportName}</td>
        <td>{ts.reportDescription.jobNo}</td>
        <td>{hours[i]}</td>
        <td>{new Date(ts.createdAt).toISOString().split('T')[0]}</td>
        <td>{new Date(ts.updatedAt).toISOString().split('T')[0]}</td>
        <td>
          <Alert variant={ts.approved ? 'success' : 'warning'} style={{ margin: '0', padding: '2%' }}>
            <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{ts.approved ? 'Approved' : 'Pending'}</p>
          </Alert>
        </td>
        <td>{ts.approvedBy || 'N/A'}</td>
        <td><FaEye size={22} onClick={() => { setReport(ts); setIsReportList(false) }} /></td>
        <td><FaEdit size={22} onClick={() => {
          setEditId(ts._id);
          setShowEdit(true);
        }} /></td>
        <td><FaFileDownload size={22} onClick={() => downloadTimesheet(ts._id)} /></td>
      </tr>
    )
    );
  }

  return (
    <div style={{ margin: '0% 1% 1% 1%' }}>
      <EditFieldTimesheetModal show={showEdit} setShow={setShowEdit} reportId={editId} onClose={async () => { await refetchTimesheets()}}/>
      <div style={{ maxHeight: '100vh', overflowY: 'auto', display: `${!isReportList ? 'block' : 'none'}` }}>
        {report && <ExistingFieldReportInfo
          report={report}
          isReportList={isReportList}
          setIsReportList={setIsReportList}
          onFinish={() => {setReport(null)}}
        />}
      </div>

      <div style={{ display: `${isReportList ? 'block' : 'none'}` }}>
        <Row style={{ marginBottom: '0.5%' }}>
          <Col md={6} style={{ textAlign: 'left' }}>
            <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Field Timesheets
              {(isPermissionTypeFieldAdmin(permission) || isPermissionTypeHRAdmin(permission)) && " - HR/Admin View"}
            </h5>
          </Col>
        </Row>
        <Row>
          <Col md={6} style={{ textAlign: 'left' }}>
            <div>
              <CreateFieldTimesheetModal onFinish={() => refetchTimesheets()} />
            </div>
          </Col>
          <Col md={6} style={{ textAlign: 'right' }}>
            <div>
              <FieldTimeBulkDownload />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '1%' }}>
          <Col md={12}>
            <ExistingFieldReportFilter
              originalReportList={timeSheets || []}
              filteredReportList={filteredReportList}
              setFilteredReportList={setFilteredReportList}
              setUpdate={() => { }}
              userType={permission}
            />
            <div style={{ maxHeight: '88vh', overflowY: 'auto', marginTop: '1%' }}>
              <Table striped hover>
                <thead style={{ position: 'sticky', top: 0, zIndex: '30' }}>
                  <tr>
                    <th style={{ width: '23%' }}>🧾 Timesheet Name</th>
                    <th>Job #</th>
                    <th>Hours</th>
                    <th>Created On</th>
                    <th>Last Updated</th>
                    <th>Approval</th>
                    <th>Approved By</th>
                    <th>View</th>
                    <th>Edit</th>
                    <th style={{ width: '8%' }}>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && <td colSpan={7}>Loading ...</td>}
                  {error && <td colSpan={7}>Error fetching timesheets.</td>}
                  {timeSheets && timeSheets.length === 0 && <td colSpan={8}>No Timesheets To Show.</td>}
                  {(!isLoading && filteredReportList.length > 0) && <TimesheetRows timesheetList={filteredReportList} />}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
