/**
 * UtilitySidebar.jsx
 * ------------------
 * Utility sidebar for Lab Data Dashboard.
 * 
 * Author: Oscar Jaimes
 */

import Switch from "react-switch";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Row, Col, Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAllLabData, getFilteredLabData } from "../../api/labdata";

const UtilitySidebar = ({
  exploreMode,
  workSpaceMode,
  setExploreMode,
  setWorkSpaceMode,
  setLabData,
  labData,
  setPage,
  added,
  setAdded,
  availableFields
}) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [testingType, setTestingType] = useState('EC Testing');

  const WorkspaceSwitcher = () => (
    <>
      <h5 style={{ fontSize: '17px', color: '#005E7D' }}>Workspace</h5>
      <Row>
        <Col md={8}>
          <p style={{ fontSize: '15px', color: '#005E7D', paddingTop: '5px' }}>Explore View</p>
          <p style={{ fontSize: '15px', color: '#005E7D' }}>Workspace View</p>
        </Col>
        <Col style={{ textAlign: 'right' }} md={4}>
          <div>
            <Switch onChange={async () => {
              const newVal = !exploreMode;
              setExploreMode(newVal);
              setWorkSpaceMode(!newVal);

              // set to list for explore mode
              if (newVal) {
                const ld = await getAllLabData(1);
                ld.labData.forEach((r) => {
                  r.isOpen = Object.keys(r).reduce((acc, key) => {
                    acc[key] = { isOpen: false };
                    return acc;
                  }, {})
                });
                setLabData(ld.labData);
                setPage(1);
              }

              // set to empty for workspace mode
              if (!newVal) {
                const newRowData = {};
                availableFields.forEach((f) => {
                  newRowData[f] = '';
                });
                newRowData._id = added;
                setAdded(added + 1);
                newRowData['isOpen'] = Object.keys(newRowData).reduce((acc, key) => {
                  acc[key] = { isOpen: false };
                  return acc;
                }, {});

                setLabData([newRowData]);
              }
            }}
              checked={exploreMode} />
          </div>
          <div style={{ marginTop: '5px' }}>
            <Switch onChange={async () => {
              const newVal = !workSpaceMode;
              setWorkSpaceMode(newVal);
              setExploreMode(!newVal);

              // set to empty for workspace mdoe
              if (newVal) {
                const newRowData = {};
                availableFields.forEach((f) => {
                  newRowData[f] = '';
                });
                newRowData._id = added;
                setAdded(added + 1);
                newRowData['isOpen'] = Object.keys(newRowData).reduce((acc, key) => {
                  acc[key] = { isOpen: false };
                  return acc;
                }, {});

                setLabData([newRowData]);
              }

              // set to list for explore mode
              if (!newVal) {
                const ld = await getAllLabData(1);
                ld.labData.forEach((r) => {
                  r.isOpen = Object.keys(r).reduce((acc, key) => {
                    acc[key] = { isOpen: false };
                    return acc;
                  }, {})
                });
                setLabData([...ld.labData]);
                setPage(1);
              }
            }}
              checked={workSpaceMode} />
          </div>
        </Col>
      </Row>
    </>
  );

  const DateFilter = () => (
    <>
      <h5 style={{ fontSize: '17px', color: '#005E7D', marginTop: '3%' }}>Filtering</h5>
      <Form.Group>
        <Form.Label>Start Date</Form.Label>
        <Form.Control
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          disabled={workSpaceMode}
          required
        />
      </Form.Group>
      <Form.Group style={{ marginTop: '10px' }}>
        <Form.Label>End Date</Form.Label>
        <Form.Control
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          disabled={workSpaceMode}
          required
        />
      </Form.Group>
    </>
  );

  const TestingTypeFilter = () => {
    return (
      <Form.Group style={{ marginTop: '3%' }}>
        <Form.Label>Testing Type</Form.Label>
        <Form.Control as="select"
          value={testingType}
          onChange={(e) => {e.preventDefault(); setTestingType(e.target.value)}}
          disabled={workSpaceMode}>
          <option value="Treatability Testing">Treatability Testing</option>
          <option value="EC Testing">EC Testing</option>
          <option value="Screen Testing">Screen Testing</option>
        </Form.Control>
      </Form.Group>
    );
  };

  const applyFilter = async (e) => {
    e.preventDefault();
    console.log(startDate, endDate);
    const data = await getFilteredLabData(startDate, endDate, testingType);
    data.labData.forEach((r) => {
      r.isOpen = Object.keys(r).reduce((acc, key) => {
        acc[key] = { isOpen: false };
        return acc;
      }, {})
    });

    console.log(data);

    setLabData(data.labData);
    setPage(1);
  };

  const clearFilters = async () => {
    const ld = await getAllLabData(1);
    ld.labData.forEach((r) => {
      r.isOpen = Object.keys(r).reduce((acc, key) => {
        acc[key] = { isOpen: false };
        return acc;
      }, {})
    });
    setLabData([...ld.labData]);
    setPage(1);
  };

  const Actions = () => (
    <>
      <Row style={{ marginTop: '10px' }}>
        <Col>
          <OverlayTrigger
            overlay={!exploreMode ? <Tooltip>Explore View must be enabled to filter data</Tooltip> : <div></div>}
            delayHide={150}
          >
            <span className="d-inline-block" style={{ width: '100%' }}>
              <Button variant="outline-dark" style={{ width: '100%' }} disabled={workSpaceMode} onClick={applyFilter}>
                Apply Filter
              </Button>
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={!exploreMode ? <Tooltip>Explore View must be enabled to filter data</Tooltip> : <div></div>}
            delayHide={150}
          >
            <span className="d-inline-block" style={{ width: '100%', marginTop: '1%' }}>
              <Button variant="outline-warning" style={{ width: '100%' }} disabled={workSpaceMode} onClick={clearFilters}>
                Clear Filters
              </Button>
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={!exploreMode ? <Tooltip>Explore View must be enabled to download data</Tooltip> : <div></div>}
            delayHide={150}
          >
            <span className="d-inline-block" style={{ width: '100%', marginTop: '1%' }}>
              <CSVLink
                data={labData}
                filename={`labdata.csv`}
              >
                <Button variant="outline-dark" style={{ width: '100%' }} disabled={workSpaceMode}>
                  Download CSV Data
                </Button>
              </CSVLink>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
    </>
  );

  return (
    <Card>
      <Card.Header style={{ backgroundColor: '#005E7D', color: 'white' }}>Utility</Card.Header>
      <Card.Body>
        <Form onSubmit={() => { }}>
          <Row>
            <Col>
              <WorkspaceSwitcher />
              <DateFilter />
              <TestingTypeFilter />
            </Col>
          </Row>
          <Actions />
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UtilitySidebar;
