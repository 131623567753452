import { React, useEffect, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { HRPolicySubHeaderPdf } from "./HRPolicySubHeaderPdf";
import { EditExistingPolicy } from "./EditExisitingPolicies/EditExistingPolicy";
import { useAuth } from "../../../../contexts/AuthContext";
import { ExistingHRPolicyAcknowledgeModal } from "./ExistingHRPolicyAcknowledgeModal";
import {
  getUserPermissionFromCache,
  isPermissionTypeHRAdmin,
} from "../../../../utility/permissionFunctions";
import { downloadPDF } from "../../HRFunctions";
import { getAllUserPolicy, getPolicyPDFByID } from "../../../../api/hrPolicy";
import { useHistory } from "react-router";

// Allows the user to learn more about the policy selected
export const HRLearnMorePolicy = (props) => {
  let {
    params: { id },
  } = props.match;

  const history = useHistory();
  const { email } = useAuth().currentUser;
  const [blobData, setBlobData] = useState(null);
  const [selectedSubHeader, setSelectedSubHeader] = useState({});
  const [learnMorePolicy, setLearnMorePolicy] = useState(null);
  const [updateReport, setUpdateReport] = useState(false);
  const [show, setShow] = useState(false);
  const [userAccountType, setUserAccountType] = useState([]);
  const [fileName, setFileName] = useState("");
  useEffect(async () => {
    // gets policy from database
    getAllUserPolicy(id, email).then((result) => {
      setLearnMorePolicy(result);
      // gets the policy manual data from the backend to be displayed
      getPolicyPDFByID(result._id, email).then((pdfResult) => {
        setFileName(pdfResult.fileName);
        let base64String = Buffer.from(pdfResult.fileData[0]).toString(
          "base64"
        );
        setBlobData("data:application/pdf;base64," + base64String);
      });
    });
    setUserAccountType(await getUserPermissionFromCache(email));
  }, [updateReport]);
  const [showPDF, setShowPDF] = useState(false);
  const [editPolicy, setEditPolicy] = useState(false);

  return (
    <>
      {!showPDF ? (
        <>
          <div className="d-flex justify-content-between pe-4">
            <Button
              onClick={() =>
                history.push(`/hr-tools/hr-policies/existingHRPolicies/`)
              }
            >
              Back
            </Button>
            {isPermissionTypeHRAdmin(userAccountType) ? (
              <div className="">
                {editPolicy ? (
                  <Button
                    onClick={() => {
                      setEditPolicy(false);
                    }}
                  >
                    Cancel Edit
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setEditPolicy(true);
                    }}
                  >
                    Edit Policy
                  </Button>
                )}
                <Button
                  className="ms-4"
                  variant="secondary"
                  onClick={() => downloadPDF(blobData, fileName)}
                >
                  Download Policy
                </Button>
              </div>
            ) : null}
          </div>
          {learnMorePolicy && (
            <div className="">
              {editPolicy ? (
                <EditExistingPolicy
                  learnMorePolicy={learnMorePolicy}
                  setUpdateReport={setUpdateReport}
                />
              ) : (
                <ListGroup>
                  {learnMorePolicy.subHeadersList.length == 0 ? (
                    <div className="text-center py-2">
                      No SubHeadings Listed for HR Policy
                    </div>
                  ) : (
                    <>
                      <Row>
                        <Col md={6} className="text-center">
                          Sub Headings
                        </Col>
                        <Col md={4} className="text-center">
                          Page #
                        </Col>
                      </Row>
                      {learnMorePolicy.subHeadersList.map((subHeader) => (
                        <ListGroupItem>
                          <Row>
                            <Col md={6} className="text-center">
                              {subHeader.subHeaderName}
                            </Col>
                            <Col md={4} className="text-center">
                              {subHeader.firstPage}-{subHeader.lastPage}
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  setSelectedSubHeader(subHeader);
                                  setShowPDF(true);
                                }}
                              >
                                View Content
                              </Button>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))}

                      <br />
                      <div className="d-flex justify-content-center">
                        {learnMorePolicy.acknowledgedBy.filter(
                          (user) => user.acknowledgedEmail == email
                        ).length == 0 ? (
                          <Button onClick={() => setShow(true)}>
                            Acknowledge
                          </Button>
                        ) : (
                          <div className="text-success">
                            <b> Acknowledged!</b>
                          </div>
                        )}
                      </div>
                      <ExistingHRPolicyAcknowledgeModal
                        show={show}
                        setShow={setShow}
                        _id={id}
                        setLearnMorePolicy={setLearnMorePolicy}
                        learnMorePolicy={learnMorePolicy}
                      />
                    </>
                  )}
                </ListGroup>
              )}
            </div>
          )}
        </>
      ) : (
        blobData != null && (
          <HRPolicySubHeaderPdf
            blobData={blobData}
            selectedSubHeader={selectedSubHeader}
            setShowPDF={setShowPDF}
          />
        )
      )}
    </>
  );
};
