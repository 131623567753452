import React from 'react'
import { Chart } from "react-google-charts";

export default function FiltrateGraph(props) {
    return (
        <Chart
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={props.filtrategraphdata}
            options={{
                hAxis: { format: 'dd/MM/yy' },
                titleTextStyle: {
                    fontFamily: 'Arial',
                    fontSize: 15, // 12, 18 whatever you want (don't specify px)
                    bold: true,    // true or false
                    italic: false   // true of false
                },
                title: 'Actual Filtrate, Predicted* Filtrate, & Inlet TSS vs Date',
                backgroundColor: { fill: 'transparent' },
                height: 450,
                seriesType: "line",
                series: {
                    0: { targetAxisIndex: 0, color: '#c7387b' },
                    1: { targetAxisIndex: 0, color: '#38C784', },
                    2: { targetAxisIndex: 0, color: '#C3C738' }
                },
                vAxes: {
                    0: { title: 'ppm', curveType: 'function'},
                },
                legend: { position: 'top' },
                chartArea: {
                    width: '80%',
                    height: '75%'
                }
            }}
        />
    )
}
