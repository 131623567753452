import React from "react";
import { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthContext";
import { ExistingFieldEODReportValues } from "./ExistingFieldEODReportValues";
import { AiOutlineEdit } from "react-icons/ai";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { getProperTime } from "../../fieldToolsFunctions";

// api
import { updateEODReport, getEODReportImages } from "../../../../api/fieldEODReport.js";

export const ExistingFieldEODReportComponent = ({
  report = {},
  setReport,
  setIsReportList,
  cancelChanges,
  setCancelChanges,
}) => {
  const { email } = useAuth().currentUser;
  const [editDescription, setEditDescription] = useState(false);
  const [images, setImages] = useState([]);


  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    const imgs = await getEODReportImages({imgUrls: report.imageUrlList});
    setImages(imgs);
  }

  // specify what font styles you want
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        { list: "ordered" },
        { list: "bullet" },
      ],
      [{ color: [] }, { background: [] }],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });
  // quill is used to create the textbox with font options
  useEffect(() => {
    if (quill) {
      // make the content of quill to report description
      quill.clipboard.dangerouslyPasteHTML(report.description);
      quill.on("text-change", (delta, oldDelta, source) => {
        const _report = { ...report };
        // set inner html of the quill to current description
        _report.description = quill.root.innerHTML;
        setReport(_report);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (!editDescription) {
      let existingDescriptionContainer =
        document.getElementById("eodDescription");
      // check the description
      existingDescriptionContainer.innerHTML =
        report.description != ""
          ? report.description
          : "No description provided...";
    }
  }, [editDescription, report]);

  // if cancel button is clicked hide quill and dusokay the actual description
  useEffect(() => {
    setEditDescription(false);
  }, [cancelChanges]);

  // call this function on change, to save the changed values
  const onChange = (value, valueType) => {
    value = valueType == "jobNumber" ? Number(value) : value;
    const _report = { ...report };
    _report[valueType] = value;
    setReport(_report);
  };

  // save the changes to the current report to the database and the original report list
  const saveChanges = () => {
    let _report = { ...report };
    _report.date = new Date(_report.date);
    const body = {
      _id: _report._id,
      email,
      updatedReport: _report,
    };
    updateEODReport(body)
      // update the report
      .then((res) =>{
        // alert if the update is succesful or not
        alert(
          res.status == 200
            ? "EOD Report has been updated successfully!"
            : "EOD Report could not be updated, please try again later."
        )
      }
      );
  };
  return (
    <Form>
      <Button onClick={() => setIsReportList((isReportList) => !isReportList)} variant="outline-dark">
        Close
      </Button>
      <div className="d-flex w-100 align-items-center flex-column pb-2">
        <h3>{report.reportName}</h3>
        <div>Created At: {getProperTime(report.createdAt)}</div>
        <div>Last Updated At: {getProperTime(report.updatedAt)}</div>
      </div>
      <Card>
        <Card.Body>
          <ExistingFieldEODReportValues
            report={report}
            valueType={"date"}
            onChange={onChange}
            cancelChanges={cancelChanges}
          />
          <ExistingFieldEODReportValues
            report={report}
            valueType={"jobNumber"}
            onChange={onChange}
            cancelChanges={cancelChanges}
          />
          <ExistingFieldEODReportValues
            report={report}
            valueType={"name"}
            onChange={onChange}
            cancelChanges={cancelChanges}
          />
          <ExistingFieldEODReportValues
            report={report}
            valueType={"client"}
            onChange={onChange}
            cancelChanges={cancelChanges}
          />
          <br />
          <div style={{ display: !editDescription ? "" : "none" }}>
            <div className="border p-2 d-flex">
              <Row style={{ width: "99%" }}>
                <Col md={12}>
                  <div id="eodDescription"></div>
                </Col>
              </Row>

              <AiOutlineEdit
                onClick={() => {
                  setEditDescription(true);
                }}
              />
            </div>
          </div>
          {/* change display instead of spawning the quill component, so that you do not break quill */}
          <div style={{ display: !editDescription ? "none" : "" }}>
            <div style={{ height: "300px" }}>
              <div style={{ height: "200px" }}>
                {/* creates the textbox using the quillref */}
                <div ref={quillRef} />
              </div>
            </div>
          </div>
          <div>
          {images.map((img, index) => (
                // Assuming the fileBlob is a base64 encoded string
                <img 
                    key={index} 
                    src={`data:image/jpeg;base64,${img.fileBlob}`}
                    alt={'hehe'}
                />
            ))}
          </div>
        </Card.Body>
      </Card>
      <Row className="mt-2">
        <Col md={6} style={{textAlign: 'left'}}>
        <Button
          variant="secondary"
          onClick={() => setCancelChanges(true)}
        >
          Cancel Changes
        </Button>
        </Col>

        <Col md={6} style={{textAlign: 'right'}}> <Button onClick={saveChanges}>Save Changes</Button></Col>
      </Row>
    
    </Form>
  );
};
