import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormCheckCreator } from "../FormCheckCreator";
import { FormGroupCreator } from "../FormGroupCreator";
import { v4 as uuidv4 } from "uuid";
import { convertKeyNameToDisplayName } from "../TimeReportFunctions";

// FieldTimeSheetTable uses the valueDict given to automatically create Rows and Columns to create form groups and labels propelrly

export const FieldTimeSheetTable = ({
  valueDict,
  setValueDict,
  valueDictExample,
  textFieldLength = 5,
  required = true,
  newReport,
  setNewReport,
  valueType,
}) => {
  // used to set value to the dict given and update the component
  const setValueToDict = (newDictValues) => {
    setValueDict(newDictValues);
    newReport[valueType] = newDictValues;
    setNewReport({ ...newReport });
  };

  // gets the md for the column based on the type of value, number has md 1, others have normal md
  const getMdForCol = (value) => {
    let keyType = typeof value;
    return keyType == "number" ? { md: 1 } : { md: textFieldLength };
  };

  // creates new task to be added to the valueDict given
  // The new task is created using the valueDictExample given in props and created id using uuvid
  const addTask = () => {
    valueDict.push({ ...valueDictExample, id: uuidv4() });
    setValueToDict(valueDict);
  };

  // removes task on valueDict based on the key
  const removeTask = (key) => {
    valueDict = valueDict.filter((work) => work.id !== key);
    setValueToDict(valueDict);
  };

  // sets new list value if the id match the given id
  const setNewListValues = (newValue, id, type) => {
    for (let value of valueDict) {
      if (value.id == id) {
        value[type] = newValue;
      }
    }
    setValueToDict(valueDict);
  };

  // e.target.value converts everything to string, so change the stringified number back to number
  const fixValueType = (value, type) => {
    if (typeof valueDictExample[type] == "number") return Number(value);
    else return value;
  };

  const changeValue = (value, valueType) => {
    let id = valueType.id;
    let type = valueType.type;
    let fixedValue = fixValueType(value, type);
    setNewListValues(fixedValue, id, type);
  };

  return (
    <div>
      {/* if there is value in value dict display it, otherwise show "New Task" and allow them to add new task */}
      {valueDict.length > 0 ? (
        <>
          <Row>
            {[valueDictExample].map((valueList) => {
              {
                return Object.keys(valueList).map((key) => {
                  {
                    return typeof valueList[key] == "boolean" ? (
                      <Col md={1}>
                        <div>{convertKeyNameToDisplayName(key)}</div>
                      </Col>
                    ) : key != "currencyType" ? (
                      <Col
                        {...getMdForCol(valueList[key])}
                        className="text-center"
                      >
                        <div>{convertKeyNameToDisplayName(key)}</div>
                      </Col>
                    ) : (
                      <Col md={1} className="text-center">
                        <div>{convertKeyNameToDisplayName(key)}</div>
                      </Col>
                    );
                  }
                });
              }
            })}
          </Row>
          <div>
            {valueDict.map((valueList) => {
              {
                return (
                  <Row>
                    {Object.keys(valueList).map((key) => {
                      {
                        return key !== "id" ? (
                          typeof valueList[key] == "boolean" ? (
                            <Col md={1} className="pt-2">
                              <FormCheckCreator
                                changeValue={changeValue}
                                valueDict={valueList}
                                valueType={`${key}`}
                              />
                            </Col>
                          ) : key != "currencyType" ? (
                            <Col {...getMdForCol(valueList[key])}>
                              <FormGroupCreator
                                type={typeof valueList[key]}
                                changeValue={changeValue}
                                valueType={{ type: key, id: valueList.id }}
                                valueDict={valueList}
                                id={`${valueList.id}-${key}`}
                              />
                            </Col>
                          ) : (
                            <Col className="" md={1}>
                              <select
                                class="form-select"
                                value={valueList[key]}
                                size="md"
                                onChange={(e) =>
                                  changeValue(e.target.value, {
                                    type: key,
                                    id: valueList.id,
                                  })
                                }
                              >
                                <option value="USD">USD</option>
                                <option value="CAD">CAD</option>
                              </select>
                            </Col>
                          )
                        ) : null;
                      }
                    })}
                    <Col md={1}>
                      <Button onClick={addTask}>+</Button>
                      {valueDict.length > 1 || required == false ? (
                        <Button
                          className="ms-2"
                          onClick={() => removeTask(valueList.id)}
                        >
                          -
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                );
              }
            })}
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center mt-3">
          <Button onClick={addTask}>New Task</Button>
        </div>
      )}
    </div>
  );
};
