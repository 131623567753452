import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import FileSelect from './FileSelect';
import ConfirmModal from './ConfirmModal';

const CsvToolPage = () => {

    const [files, setFiles] = useState([]);
    const [fileListElements, setFileListElements] = useState();

    useEffect(() => {
        document.title = "CSV Tool";
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        let currFiles = files;
        let currFileNames = currFiles.map(file => file.path);
        acceptedFiles.forEach(file => {
            if (!currFileNames.includes(file.path))
                currFiles.push(file);
        });
        setFiles(currFiles);
        filesList();
    });

    const filesList = () => {
        let listComponents = files.map(file => {
            return <li key={file.path}>{file.path}    -    {file.size} bytes</li>
        });
        setFileListElements(<ul>{listComponents}</ul>);
    };

    return (
        <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
            <Row style={{ paddingTop: '0%', margin: '10px' }}>
                <Col md={12}>
                    <Card>
                        <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>CSV Insert</Card.Header>
                        <Card.Body>
                            <Card.Text>Upload sensor data files from HMIs here. When ready, a script will execute to insert the sensor data into the Mongo Database</Card.Text>
                            <Row style={{ paddingTop: '2%' }}>
                                <Col md={12}>
                                    <FileSelect multiple onDrop={onDrop} accept={'.csv'} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <br />
                                    <h5> Uploaded Files Will Appear Here</h5>
                                    <br />
                                    {fileListElements}
                                </Col>
                                <Col md={2} style={{ textAlign: 'right' }}>
                                    <br />
                                    {files.length > 0 && <Button variant='outline-warning' onClick={() => { setFiles([]); setFileListElements([]); }}>Reset</Button>}
                                </Col>
                                <Col md={2} style={{ textAlign: 'right' }}>
                                    <br />
                                    {files.length > 0 && <ConfirmModal files={files} />}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CsvToolPage;
