import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { deleteUserAccount } from "../../../api/auth";

export const UserDeleteModal = ({
  email,
  emailToBeDeleted,
  users,
  setUsers,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteUser = () => {
    deleteUserAccount(email, emailToBeDeleted).then((result) => {
      if (result == 1) {
        let _users = [...users];
        _users = _users.filter((user) => user.email != emailToBeDeleted);
        setUsers([..._users]);
        alert("Account has been successfully deleted");
        handleClose();
      } else {
        alert("Account could not be deleted, please try again later!");
      }
    });
  };

  return (
    <>
      <Button variant="outline-danger" onClick={handleShow}>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>{emailToBeDeleted}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outline-danger" onClick={() => deleteUser()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
