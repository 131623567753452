import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { convertKeyNameToDisplayName } from "../../../field-tools/TimeReporting/TimeReportFunctions";
import ResultModal from "../../../../components/ResultModal";

// Creates a modal when jobcode is first created
export const HRShopTimesheetModal = ({
  show,
  setShow,
  job,
  setJob,
  jobNumber,
  shopTimesheet,
  setShopTimesheet,
  jobCodeDict,
  setEmployeeInitial = () => { },
}) => {
  const [editJob, setEditJob] = useState(JSON.parse(JSON.stringify(job)));
  const [errorList, setErrorList] = useState([]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorString, setErrorString] = useState("");
  const [titleColor, setTitleColor] = useState("orange");

  // creates the proper error for the modal
  useEffect(() => {
    let newErrorList = [];
    for (let key in editJob) {
      if (
        (key == "jobCode" || key == "employeeInitial") &&
        editJob[key] == ""
      ) {
        newErrorList.push(
          `${convertKeyNameToDisplayName(key)} cannot be empty!`
        );
      }
    }
    setErrorList(newErrorList);
  }, [editJob]);

  // changes the new job code value
  const changeValue = (value, valueType) => {
    editJob[valueType.type] =
      typeof editJob[valueType.type] == "number" ? Number(value) : value;
    if (valueType.type == "employeeInitial") setEmployeeInitial(value);
    setEditJob({ ...editJob });
  };

  const handleClose = () => setShow(false);

  // handles the close when user tries to close modal before saving
  const handleUnsavedClose = () => {
    if (!editJob.new) {
      setShow(false);
      setEditJob(JSON.parse(JSON.stringify(job)));
    } else {
      setErrorTitle('Error')
      setErrorString(`Please complete Job Code before continuing!`);
      setShowErrorModal(true);
      setTitleColor("orange");
    }
  };

  // saves the chaanges when save button is clicked
  const saveChange = (e) => {
    e.preventDefault();
    editJob.new = false;
    setJob(editJob);
    shopTimesheet.jobList = shopTimesheet.jobList.map((jobList) => {
      if (jobList.jobNumber == jobNumber) {
        jobList.jobCodeList = jobList.jobCodeList.map((job) => {
          if (job.id == editJob.id) {
            return editJob;
          } else {
            return job;
          }
        });
      }
      return jobList;
    });
    setShopTimesheet({ ...shopTimesheet });
    setJob(null);
    handleClose();
  };

  // siabled submit button if there is error in form
  const isSubmitButtonDisabled = () => {
    return errorList.length > 0 ? { disabled: true } : {};
  };

  return (
    <>
      <ResultModal title={errorTitle} titleColor={titleColor} content={errorString} showErrorModal={showErrorModal} handleClose={() => setShowErrorModal(false)} />
      <Modal
        show={show}
        onHide={handleUnsavedClose}
        className="modal-lg"
        centered
      >
        <div>
          <Modal.Header className="d-flex justify-content-center">
            <Modal.Title>Create Job Task</Modal.Title>
          </Modal.Header>
          <div
            className="text-center border rounded ms-2 p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            Select task from the drop down menu and enter number of hours.
          </div>
          <Modal.Body>
            {Object.keys(editJob).map((key) =>
              key !== "id" &&
                key != "new" &&
                key != "jobCode" &&
                key != "description" ? (
                <div className="text-center">
                  {key != "employeeInitial" ? (
                    <FormGroupCreator
                      label={convertKeyNameToDisplayName(key)}
                      valueDict={editJob}
                      type={typeof editJob[key]}
                      valueType={{ id: editJob.id, type: key }}
                      changeValue={changeValue}
                      min={0}
                    />
                  ) : (
                    <>
                      <br />
                      <div
                        className="text-center border rounded ms-2 p-2"
                        style={{ background: "rgba(0, 0, 0, 0.03)" }}
                      >
                        By writing my initals, I confirm that I am completing
                        this timesheet for myself and have completed the above
                        hours.
                      </div>
                      <FormGroupCreator
                        label={convertKeyNameToDisplayName(key)}
                        valueDict={editJob}
                        type={typeof editJob[key]}
                        valueType={{ id: editJob.id, type: key }}
                        changeValue={changeValue}
                        min={0}
                      />
                    </>
                  )}
                </div>
              ) : key == "jobCode" ? (
                <>
                  {/* for the job code select and description area */}
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col md={2} className="text-center">
                      Job Phase
                    </Col>
                    <Col>
                      <select
                        className="border py-2 rounded w-100"
                        md={10}
                        onChange={(e) => {
                          changeValue(
                            `${e.target.value} - ${jobCodeDict[e.target.value].jobName
                            }`,
                            { type: "jobCode" }
                          );
                          changeValue(jobCodeDict[e.target.value].description, {
                            type: "description",
                          });
                        }}
                        style={{ overflowY: "scroll" }}
                      >
                        <option default value={editJob.jobCode.split(" - ")[0]}>
                          {!editJob.new
                            ? editJob.jobCode
                            : "No value has been selected"}
                        </option>
                        {Object.keys(jobCodeDict).map((jobCode) => (
                          <option value={jobCode}>
                            {jobCode}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col md={2} className="text-center">
                      Description
                    </Col>
                    <Col>
                      <div className="border rounded py-2 px-2">
                        {editJob.description || "n/a"}
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null
            )}
          </Modal.Body>
          <div className="d-flex flex-column align-items-center">
            {errorList.map((error) => (
              <div className="text-danger">{error}</div>
            ))}
          </div>

          <Modal.Footer className="d-flex flex-column">
            {!editJob.new ? (
              <Button variant="secondary" onClick={handleUnsavedClose}>
                Cancel Changes
              </Button>
            ) : null}

            <Button
              variant="primary"
              {...isSubmitButtonDisabled()}
              onClick={saveChange}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
