// libs
import { useState, useEffect } from "react";
import { Offcanvas, Button, Row, Col, Alert, Modal, Spinner } from "react-bootstrap";
import { FaEdit, FaEnvelope, FaFileDownload } from "react-icons/fa";
import Select from 'react-select';

// Custom components
import WorkOrderPDF from "./WorkOrderPDF";
import WorkOrderForm from "./WorkOrderForm";

// api
import { getAllUserEmails } from "../../api/auth";
import { emailWorkOrder } from "../../api/workOrders";

export const ViewWO = ({ viewWO, setViewWO, setSelectedWO, downloadWorkOrder, selectedWO, setEdit }) => {
  return (
    <Offcanvas show={viewWO} onHide={() => { setViewWO(false); setSelectedWO(null) }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title style={{ color: '#005E7D' }}>Viewing Work Order</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <Button variant='outline-dark' onClick={async () => { await downloadWorkOrder(selectedWO._id, selectedWO.jobNumber, selectedWO.dateOfOrder) }}>Download <FaFileDownload className="mb-1" /></Button>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <Button variant='outline-dark' onClick={() => { setEdit(true); setViewWO(false); }}>Edit <FaEdit className="mb-1" /></Button>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <EmailWorkOrderModal workOrderToView={selectedWO}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <WorkOrderPDF workOrderId={selectedWO?._id} />
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const CreateWO = ({ create, setCreate, setViewWO, setSelectedWO }) => {
  return (
    <Offcanvas className='create-wo' show={create} onHide={() => { setCreate(false); }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title style={{ color: '#005E7D' }}>New Work Order</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ zoom: '85%' }}>
        <WorkOrderForm onSubmitEnd={(wo) => { setCreate(false); setViewWO(true); setSelectedWO(wo); }} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const EditWO = ({ edit, setEdit, setViewWO, selectedWO }) => {
  return (
    <Offcanvas className='create-wo' show={edit} onHide={() => { setEdit(false); setViewWO(true); }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title style={{ color: '#005E7D' }}>Editing Work Order</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ zoom: '85%' }}>
        <WorkOrderForm onSubmitEnd={() => { setEdit(false); setViewWO(true); }} mode="edit" id={selectedWO?._id} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const EmailWorkOrderModal = ({ workOrderToView }) => {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(async () => {
    const emails = await getAllUserEmails(false);
    setEmailList(emails.map((e) => ({ value: e, label: e })));
    setSelectedEmails(emails.map((e) => ({ value: e, label: e })));
  }, []);

  const onEmailSelectChange = (newValue, _) => {
    setSelectedEmails(newValue);
  }

  const sendAsEmail = async () => {
    setEmailLoading(true);
    setEmailError(false);
    setEmailSuccess(false);

    const formData = new FormData();
    formData.append('workOrderId', workOrderToView._id);
    formData.append('workOrderName', `WO-${workOrderToView.jobNumber}`);
    formData.append('emailList', selectedEmails.map((e) => e.value));
    const res = await emailWorkOrder(formData);
    if (res.status == 200) {
      setEmailError(false);
      setEmailSuccess(true);
    } else {
      setEmailError(true);
      setEmailSuccess(false);
    }

    setEmailLoading(false);
  };

  return (
    <>
      <Button variant='outline-dark' onClick={() => { setSendingEmail(true) }}>Email <FaEnvelope className="mb-1" /></Button>
      <Modal show={sendingEmail} size="xl" style={{ zoom: '90%' }}>
        <Modal.Header>
          <Modal.Title>Email Work Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {workOrderToView?.hasBeenEmailed && <Alert variant="danger">This WO has already been e-mailed, are you sure you want to send it again?</Alert>}
          <p>Select The Emails To Send Work Order To</p>
          {emailSuccess && <Alert variant='success'>Succesfully Emailed Work Order</Alert>}
          {emailError && <Alert variant='danger'>Could Not Email Work Order</Alert>}
          <Select
            className="basic-multi-select"
            classNamePrefix="select"
            defaultValue={emailList}
            options={emailList}
            onChange={onEmailSelectChange}
            closeMenuOnSelect={false}
            isMulti
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary'
            onClick={() => {
              setSendingEmail(false);
              setEmailError(false);
              setEmailSuccess(false);
            }}
          >Close</Button>
          {!emailLoading
            ? !emailSuccess
              ? <Button variant='dark' onClick={sendAsEmail}>Send Email</Button>
              : null
            : <Spinner animation="border" />
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};