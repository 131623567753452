/**
 * ApiKeyInsertModal.jsx
 * ---------------------
 * Component that contains insert functionality for inserting API Keys into the LWR database.
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 17/06/2021
 */

import React, { useState, useRef } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
// api
import { insertApiKey } from "../../../api/apikeys";

export default function ApiKeyInsertModal(props) {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [targetAPI, setTargetAPI] = useState("LWR API");

  // Refs
  const apiKeyRef = useRef();

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  // Form Submit
  const onFormSubmit = async (e) => {
    let keyNumber = props.numKeys + 1;
    let apiKey = apiKeyRef.current.value;

    const insertKeyResult = await insertApiKey(keyNumber, apiKey, targetAPI);
    if (insertKeyResult != 1) {
      setSuccessMsg("");
      setErrMsg("Error inserting new API Key into the database.");
    } else {
      setErrMsg("");
      setSuccessMsg(
        "Succesfully inserted new API Key into the database. You may now close this modal."
      );
      apiKeyRef.current.value = "";
    }
    e.preventDefault();
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New API Key
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{ zoom: '85%' }}>
        <Modal.Header>
          <Modal.Title>Add New API Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Fill out all fields below to insert a new API Key into the database.
          </div>
          <br />
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label>Target API</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setTargetAPI(e.target.value);
                  }}
                  required
                >
                  <option>LWR API</option>
                  <option>LOIS API</option>
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>API Key</Form.Label>
                <Form.Control type="text" ref={apiKeyRef} required />
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
