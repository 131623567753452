import { Card, Row, Col } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { operationInputs } from "./assessmentFormInputs";

const NewAssessmentForm = () => {
  return (
    <Card style={{ margin: '1%' }}>
      <Card.Header style={{ backgroundColor: '#005E7D', color: 'white' }}>Assessment Form</Card.Header>
      <Card.Body>
        <Form>

          <Row>
            {Object.keys(operationInputs).map((i) => {
              const input = operationInputs[i];

              if (input.type === 'text' || input.type === 'number') {
                return (
                  <Col md={3}>
                    <FloatingLabel controlId={input.controlId} label={i} className="mb-3">
                      <Form.Control type={input.type} placeholder="" defaultValue={input.defaultValue} />
                    </FloatingLabel>
                  </Col>
                );
              }

              if (input.type === 'select') {
                return (
                  <Col md={3}>
                    <FloatingLabel controlId={input.controlId} label={i}>
                      <Form.Select>
                        {input.options.map((o) => <option value={o} key={o}>{o}</option>)}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                );
              }

              return null;
            })}
          </Row>

        </Form>
      </Card.Body>
    </Card>
  );
};

export default NewAssessmentForm;