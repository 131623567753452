/**
 * api/organizations.js
 * --------------------
 * Module encapsulating functions related to the organizations collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 10 2023
 */

import http from "./http";

const getAllOrganizations = () => {
  return http.get('organizations/all')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const deleteOrganization = (orgName) => {
  return http.delete(`organizations/delete/?name=${orgName}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const updateOrganization = (bodyValues) => {
  return http.put(`organizations/update`, bodyValues)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const createOrganization = (bodyValues) => {
  return http.post('organizations/insert', bodyValues)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
}


export {
  getAllOrganizations,
  deleteOrganization,
  updateOrganization,
  createOrganization
}