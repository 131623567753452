/**
 * assessmentResponseModels.js
 * ----------------------------
 * This module holds the models for the assessment response data.
 * This is the format the LWR API sends the data in.
 * 
 * Author: Oscar Jaimes
 * Last Updated: 25/11/2021 
 */

const inputDataModel = {
    "System Type": "",
    "Operating Hours/Day": 0,
    "Operating Days/Year": 0,
    "Annual Flow": 0,
    "Screw Press Loop": true,
    "Screw Press Removed": false,
    "Total Solids (ppm)": 0,
    "TSS (ppm)": 0,
    "TDS (ppm)": 0,
    "Volatile Solids (%)": 0,
    "RO Recovery": 0,
    "Disolved Solids": {
        "Ammonium Nitrogen (ppm)": 0,
        "Organic Nitrogen (ppm)": 0,
        "TKN (ppm)": 0,
        "Potassium (ppm)": 0,
        "Phosphorus (ppm)": 0,
        "Other Dissolved Solids (ppm)": 0
    }
}

const polymerDosageModel = {
    "Dose": 0,
    "Units": "ppm"
}

const afterPolymerAdditionModel = {
    "Units": "kg/min",
    "Total Mass Flow": 0,
    "Solids Mass Flow": {
        "Total": 0,
        "Breakdown": {
            "TSS Mass Flow": 0,
            "TKN Mass Flow": 0,
            "Ammonium N Mass Flow": 0,
            "Organic N Mass Flow": 0,
            "Potassium Mass Flow": 0,
            "Phosphorus Mass Flow": 0,
            "Other": 0
        }
    },
    "Polymer Mass Flow": {
        "Total Polymer Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Polymer Water Mass Flow": 0
    },
    "Inlet Water Mass Flow": 0,
    "Microscreen Mass Flow": 0
}

const inputMassFlowModel = {
    "Units": "kg/min",
    "Total Mass Flow": 0,
    "Solids Mass Flow": {
        "Total": 0,
        "Breakdown": {
            "TSS Mass Flow": 0,
            "TKN Mass Flow": 0,
            "Ammonium N Mass Flow": 0,
            "Organic N Mass Flow": 0,
            "Potassium Mass Flow": 0,
            "Phosphorus Mass Flow": 0,
            "Other": 0
        }
    },
    "Polymer Mass Flow": {
        "Total Polymer Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Polymer Water Mass Flow": 0
    },
    "Inlet Water Mass Flow": 0,
    "Microscreen Mass Flow": 0
}

const volumetricTotalsModel = {
    "Input": {
        "Units": "US Gallon/min",
        "Total Mass Flow": 0,
        "Total Polymer Mass Flow": 0,
        "Total Solids Mass Flow": 0
    },
    "After Polymer Addition": {
        "Units": "US Gallon/min",
        "Total Mass Flow": 0
      },
    "Screw Press": {
        "Units": "US Gallon/min",
        "Total Mass Flow": 0,
        "SP Solids Flow": 0,
        "SP Filtrate Flow": 0
    },
    "MS. Filtrate": {
        "Units": "US Gallon/min",
        "Total Mass Flow": 0
    },
    "Clarifier": {
        "Units": "US Gallon/min",
        "Effluent Mass Flow": 0,
        "Sludge Flow": 0
    },
    "RO": {
        "Units": "US Gallon/min",
        "Concentrate Mass Flow": 0,
        "Permeate Flow": 0
    },
    "RO 2": {
        "Units": "US Gallon/min",
        "Concentrate Mass Flow": 0,
        "Permeate Flow": 0
    },
    "Clean Water": {
        "Units": "US Gallon/min",
        "Discharge Flow": 0,
        "Polymer Flow": 0,
    }
}

const microScreenSplitModel = {
    "Screw Press Mass Flows": {
        "Units": "kg/min",
        "Total Inlet Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    },
    "MS Filtrate Mass Flows": {
        "Units": "kg/min",
        "Total Inlet Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    },
    "Microscreen Output TSS": {
        "Total Suspended Solids": {
            "% TSS": 0,
            "ppm": 0
        }
    }
}

const microScreenInletModel = {
    'Units': 'kg/min',
    'Total Inlet Flow': 0,
    'Total Solids': 0,
    'Water Mass Flow': 0,
    'TSS Mass Flow': 0,
    'TDS Mass Flow': 0,
    'TKN Mass Flow': 0,
    'Phosphorus Mass Flow': 0,
    'Ammonium N Mass Flow': 0,
    'Organic N Mass Flow': 0,
    'Dry Polymer Mass Flow': 0,
    'Potassium Mass Flow': 0,
    'Other Dissolved Solids Mass Flow': 0
};

const screwPressRemovalModel = {
    "Mass Flows": {
        "Units": "kg/min",
        "Filtrate": {
            "Filtrate Mass Flow": 0,
            "Filtrate TSS Mass Flow": 0,
            "Filtrate Phosphorus Mass Flow": 0,
            "Filtrate Organic Nitrogen Mass Flow": 0,
            "Filtrate Potassium Mass Flow": 0,
            "Filtrate Ammonium N Mass Flow": 0,
            "Filtrate Other Dissolved Solids Mass Flow": 0,
            "Dry Polymer": 0,
            'Water Mass Flow': 0,
        },
        "Solids": {
            "Output Solids Mass Flow": 0,
            "Output TSS Mass Flow": 0,
            "Output Phosphorus Mass Flow": 0,
            "Output Organic N Mass Flow": 0,
            "Output Potassium Mass Flow": 0,
            "Output Ammonium N Mass Flow": 0,
            "Output Other Dissolved Solids Mass Flow": 0,
            "Volatile Solids Removal %": 0,
            "Dry Polymer": 0,
            'Water Mass Flow': 0,
        }
    },
    "Screw Press Output TSS": {
        "Total Suspended Solids": {
            "% TSS": 0,
            "ppm": 0
        }
    }
}


const clarifierModel = {
    "Effluent": {
        "Total Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium Nitrogen Mass Flow": 0,
        "Organic Nitrogen Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    },
    "Sludge": {
        "Total Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium Nitrogen Mass Flow": 0,
        "Organic Nitrogen Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    }
}

const roSystemFeedModel = {
    "Total": {
        "Total Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium Nitrogen Mass Flow": 0,
        "Organic Nitrogen Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    },
    "Antiscalant": {
        "Total Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium Nitrogen Mass Flow": 0,
        "Organic Nitrogen Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    }
}


const ROModel = {
    "Units": "kg/min",
    "Concentrate": {
        "Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    },
    "Permeate": {
        "Mass Flow": 0,
        "TSS Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
        'Water Mass Flow': 0,
    }
}

const massBalanceModel = {
    "Input/Output": {
        "Total Input Flow": 0,
        "SP Solids Flow": 0,
        "Liq Nutrient Flow": 0,
        "Water Flow": 0,
        "Polymer Flow": 0,
        "Total Outputs": 0,
        "Balance": 0,
        "% Error": 0
    },
    "Constituents": {
        "TSS": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        },
        "Ammonium Nitrogen": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        },
        "Organic Nitrogen": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        },
        "Potassium": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        },
        "Phosphorus": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        },
        "Other": {
            "Input": 0,
            "Solids": 0,
            "Liquids": 0,
            "Water": 0,
            "Total Output": 0,
            "Balance": 0,
            "% Error": 0
        }
    }
}

const nutrientConcentrationModel = {
    "Inlet Flush": {
        "Units": "lbs/1000gal",
        "Phosphorus Concentration": 0,
        "Ammonium N Concentration": 0,
        "Organic N Concentration": 0,
        "Potassium Concentration": 0,
        "Volatile Solids": 0
    },
    "Solids": {
        "Units": "lbs/ton",
        "Phosphorus Concentration": 0,
        "Organic N Concentration": 0,
        "Potassium Concentration": 0,
        "Ammonium N Concentration": 0,
        "Volatile Solids": 0
    },
    "Liquids": {
        "Units": "lbs/1000gal",
        "Phosphorus Concentration": 0,
        "Ammonium N Concentration": 0,
        "Organic N Concentration": 0,
        "Potassium Concentration": 0,
        "Volatile Solids": 0
    },
    "Water": {
        "Units": "ppm",
        "Phosphorus Concentration": 0,
        "Ammonium N Concentration": 0,
        "Organic N Concentration": 0,
        "Potassium Concentration": 0,
        "Volatile Solids": 0
    }
}

const yearlyTotalsModel = {
    "Units": "Gallons / Year",
    "LWR Solids": {
        "tons": 0,
        "gallons": 0,
        "tonnes": 0
    },
    "Clean Water": 0,
    "Nutrient Liquids": 0,
    "Metric": {
        "LWR Solids": 0,
        "Clean Water": 0,
        "Nutrient Liquids": 0
    },
    "Chemical costs": {
        "LWR 17": {
            "Units": 0,
            "Cost": 0
        },
        "Annual LWR 17": {
            "Units": 0,
            "Cost": 0
        }
    }
}

const antiscalantModel = {
    "Antiscalant": {
        "Total Mass Flow": 0,
    },
}

const CleanWaterModel = {
    "Units": "kg/min",
    "Discharge": {
        "Total Mass Flow": 0,
        "Total Solids": 0,
        "TSS Mass Flow": 0,
        "TKN Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0,
    },
    "Polymer": {
        "Total Mass Flow": 0,
        "Total Solids": 0,
        "TSS Mass Flow": 0,
        "TKN Mass Flow": 0,
        "Phosphorus Mass Flow": 0,
        "Ammonium N Mass Flow": 0,
        "Organic N Mass Flow": 0,
        "Potassium Mass Flow": 0,
        "Dry Polymer Mass Flow": 0,
        "Other Dissolved Solids Mass Flow": 0
    }
}

module.exports = {
    inputDataModel,
    polymerDosageModel,
    inputMassFlowModel,
    volumetricTotalsModel,
    microScreenSplitModel,
    screwPressRemovalModel,
    clarifierModel,
    roSystemFeedModel,
    ROModel,
    massBalanceModel,
    nutrientConcentrationModel,
    yearlyTotalsModel,
    antiscalantModel,
    CleanWaterModel,
    afterPolymerAdditionModel,
    microScreenInletModel
};