// third-party libvs
import { useParams } from 'react-router-dom';
import { React, useEffect, useState, useRef } from "react";
import { Row, Col, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { HiOutlineGlobe } from "react-icons/hi";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

// custom components and imports
import "../../styles/EngAssessment.css";
import ProcessFlowChart from './flow-charts/ProcessFlowChart';
import { SaveAndDownloadAssessmentReport } from "./SaveAndDownloadAssessmentReport";
import Header from "./horizontal-engineering-report/TableComponents/Header";
import NutrientConcentration from "./horizontal-engineering-report/EngineeringAssessmentTables/Balance/NutrientConcentration";
import YearlyOutputs from "./horizontal-engineering-report/EngineeringAssessmentTables/Outputs/YearlyOutputs";
import Footer from "./horizontal-engineering-report/TableComponents/Footer";
import TotalsChart from "./horizontal-engineering-report/EngineeringAssessmentTables/Outputs/TotalsChart";
import TotalMassBalance from "./horizontal-engineering-report/EngineeringAssessmentTables/Balance/TotalMassBalance";
import ConstituentMassBalance from "./horizontal-engineering-report/EngineeringAssessmentTables/Balance/ConstituentMassBalance";
import { getUserPermissionFromCache } from "../../utility/permissionFunctions";
import { useAuth } from "../../contexts/AuthContext";
import CarbonEmissions from "./horizontal-engineering-report/EngineeringAssessmentTables/CarbonEmissions";
import {
  inputDataModel,
  polymerDosageModel,
  inputMassFlowModel,
  microScreenSplitModel,
  screwPressRemovalModel,
  clarifierModel,
  roSystemFeedModel,
  ROModel,
  massBalanceModel,
  nutrientConcentrationModel,
  yearlyTotalsModel,
  antiscalantModel,
  CleanWaterModel,
  afterPolymerAdditionModel,
  microScreenInletModel,
} from "../../models/assessmentResponseModels";

// api
import { getAssessmentReport } from "../../api/reports";
import { runModel } from "../../api/carbonModelling";

// Mass flow diagrams
import FirstWaveDiagram from './flow-charts/fw/FirstWaveDiagram';
import FullSystemDiagram from './flow-charts/fs/FullSystemDiagram';

export const EngineeringAssessmentReport = (props) => {
  const params = useParams();
  const { currentUser } = useAuth();

  let clientName = params.name;
  try {
    clientName = decodeURIComponent(clientName);
  } catch (e) {
    clientName = params.name;
  }

  const [inputData, setInputData] = useState(inputDataModel);
  const [polymerDosage, setPolymerDosage] = useState(polymerDosageModel);
  const [inputMassFlows, setInputMassFlows] = useState(inputMassFlowModel);
  const [afterPolymerAdditionMassFlows, setAfterPolymerAdditionFlows] = useState(afterPolymerAdditionModel);
  const [microScreenSplit, setMicroScreenSplit] = useState(microScreenSplitModel);
  const [microScreenInlet, setMicroScreenInlet] = useState(microScreenInletModel);
  const [screwPressRemoval, setScrewPressRemoval] = useState(screwPressRemovalModel);
  const [clarifierFlows, setClarifierFlows] = useState(clarifierModel);
  const [roSystemFeed, setRoSystemFeed] = useState(roSystemFeedModel);
  const [roFlows, setRoFlows] = useState(ROModel);
  const [ro2Flows, setRo2Flows] = useState(ROModel);
  const [cleanWater, setCleanWater] = useState(CleanWaterModel);
  const [systemDischarge, setSystemDischarge] = useState(0);
  const [massBalance, setMassBalance] = useState(massBalanceModel);
  const [nutrientConcentrations, setNutrientConcentrations] = useState(nutrientConcentrationModel);
  const [antiscalant, setAntiscalant] = useState(antiscalantModel);
  const [yearlyTotals, setYearlyTotals] = useState(yearlyTotalsModel);
  const [isSaved, setIsSaved] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [updateReport, setUpdateReport] = useState(false);
  const [creatingPdf, setCreatingPdf] = useState(false);
  const [massBalanceView, setMassBalanceView] = useState(true);
  const [systemParameters, setSystemParameters] = useState({});
  const [polymerSystem, setPolymerSystem] = useState({
    'Units': '',
    'Polymer Dose (PPM)': 0,
    'Polymer Total Mass Flow': 0,
    'Dry Grō17 Mass Flow': 0,
    'Grō17 Water Mass Flow': 0,
  })
  const runCarbonModel = params.runCarbonModel === 'true' || params.runCarbonModel === true || params.runCarbonModel == 'true      ';


  const [flocculantPerYear, setFlocculantPerYear] = useState(0);
  const [carbonEmissions, setCarbonEmissions] = useState({
    net_emissions: 0,
    baseline_emissions: 0,
    leakage_emissions: 0,
    project_emissions: {
      solidStorageEmissions: 0,
      compostingEmissions: 0,
      electricityEmissions: 0,
      totalEmissions: 0,
    },
  });

  // assessment save refs
  const reportRef = useRef();
  const [errorVal, setErrorVal] = useState([]);
  const massBalanceRef = useRef();

  let isFW = params.sysType === "First Wave";

  useEffect(async () => {
    document.title = "Engineering Assessment";
    setAccountType(await getUserPermissionFromCache(currentUser.email));
    loadAssessmentData();
  }, [updateReport]);

  const loadAssessmentData = async () => {
    // Load query params
    const removal = params.removal;
    const operatingHours = params.hours;
    const operatingDays = params.days;
    const annualFlow = params.flow;
    const totalSolids = params.ts;
    const suspendedSolids = params.ss;
    const ammoniumNitrogen = params.ammNit;
    const tkn = params.tkNit;
    const potassium = params.pot;
    const phosphorus = params.phos;
    const volatileSolids = params.vs;
    const isFirstWave = params.sysType === "First Wave";
    const screwPressLoop = (params.sp == "true" || params.sp == "True") ? true : false;
    const screwPressRemoved = params.spRemoved == "true" ? true : false;
    const units = params.units;
    const roRecovery = params.roRecovery;
    const manure = params.manure;
    const secondRO = params.secondRO == "true";
    const ro2Recovery = params.ro2Recovery;
    const spOutput20Percent = params.spOutput20Percent == "true";
    const additional_constituents = JSON.parse(params.additional_constituents);
    const additional_constituents_ro_rejections = JSON.parse(params.additional_constituents_ro_rejections);
    const override_polymer_dose = params.override_polymer_dose == "true";
    const polymer_dose = Number(params.polymer_dose);

    const sysParams = {
      removal,
      operatingHours,
      operatingDays,
      annualFlow,
      totalSolids,
      suspendedSolids,
      ammoniumNitrogen,
      tkn,
      potassium,
      phosphorus,
      isFirstWave,
      screwPressLoop,
      screwPressRemoved,
      volatileSolids,
      roRecovery,
      reportType: "eng",
      units,
      manure,
      secondRO,
      ro2Recovery,
      spOutput20Percent,
      additional_constituents,
      additional_constituents_ro_rejections,
      override_polymer_dose,
      polymer_dose
    };

    setSystemParameters(sysParams);
    const assessment = await getAssessmentReport(sysParams);

    setFlocculantPerYear(((Number(assessment['Mass Balance']['Input/Output']['Polymer Flow']) * 60 * 24 * 365) / 1000) * 0.005);
    setInputData(assessment["Input Data"]);
    setPolymerDosage(assessment["Polymer Dosage"]);
    setInputMassFlows(assessment["Input Mass Flows"]);
    setMicroScreenSplit(assessment["Microscreen Split"]);
    setMicroScreenInlet(assessment["Microscreen Input"]);
    setScrewPressRemoval(assessment["Screw Press Removal"]);
    setClarifierFlows(assessment["Clarifier"]);
    setRoSystemFeed(assessment["RO System Feed"]);
    setRoFlows(assessment["Reverse Osmosis"]);
    setRo2Flows(assessment["Reverse Osmosis 2"])
    setSystemDischarge(assessment["System Discharge Permeate"]);
    setYearlyTotals(assessment["Yearly Totals"]);
    setNutrientConcentrations(assessment["Nutrient Concentrations"]);
    setMassBalance(assessment["Mass Balance"]);
    setAntiscalant(assessment["Antiscalant"]);
    setCleanWater(assessment["Clean Water"]);
    setAfterPolymerAdditionFlows(assessment["After Polymer Addition"]);
    setPolymerSystem(assessment['Polymer System']);


    if (runCarbonModel) {
      const carbonModel = await runModel({
        region: params.region.replace(/ /g, ''),
        baselineTreatmentType: params.baselineTreatment,
        livestockType: params.livestockType,
        numLivestock: Number(params.numLivestock) || 0,
        dryMatterIntake: Number(params.dryMatterIntake) || 0,
        manureSeparationPercent: Number(params.manureSeparation) / 100,
        separationEfficiency: Number(params.separationEfficiency) / 100,
        anerobicFraction: Number(params.anerobicTreatment) / 100,
        numberOfPumps: 1,
        pumpKw: Number(params.systemPower),
        pumpOperationTime: Number(params.operationTime),
        electricityRegion: params.electricityRegion.replace(/ /g, ''),
        yearlyCompostedWaste: Number(assessment["Yearly Totals"]['Metric']['LWR Solids']),
        flocculantPerYear: ((Number(assessment['Mass Balance']['Input/Output']['Polymer Flow']) * 60 * 24 * 365) / 1000) * 0.005,
        digestateKg: Number(params.digestateKg) || 0
      });

      setCarbonEmissions(carbonModel);
    }
  };

  let containerClassName = "fw-horizontal-report";
  let downloadFormat = isFW ? [20, 30] : [30, 58];


  return (
    <Row>
      <Col md={11} style={{ margin: 'auto' }}>
        <div>
          <Row style={{ paddingTop: '0.5%', color: '#005E7D' }}>
            <Col>
              <h5>{
                params.name.toLowerCase().includes('engineering assessment')
                  ? params.name.split('--time--')[0]
                  : `${params.name} - Engineering Assessment`
              }</h5>
            </Col>
          </Row>
          <Row style={{ zoom: '80%', paddingTop: '0.5%' }}>
            <Col md={4}>
              <Nav justify variant="tabs" sticky="top">
                <Nav.Item>
                  <Nav.Link
                    onClick={() => { setMassBalanceView(true) }}
                    style={massBalanceView ? { color: "#3c506b", borderStyle: `rounded`, border: '2px solid #3c506b' } : { color: "#3c506b" }}
                  >
                    Mass-Flow Diagram
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => { setMassBalanceView(false) }}
                    style={!massBalanceView ? { color: "#3c506b", borderStyle: `rounded`, border: '2px solid #3c506b' } : { color: "#3c506b" }}
                  >
                    Mass Balance & Outputs
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={6}></Col>
            <Col>
            </Col>
            <Col>
              <SaveAndDownloadAssessmentReport
                sysParams={systemParameters}
                massBalanceView={massBalanceView}
                hideDownload={massBalanceView}
                params={params}
                isSaved={isSaved}
                currentUser={currentUser}
                accountType={accountType}
                setIsSaved={setIsSaved}
                massBalanceRef={massBalanceRef}
                reportRef={reportRef}
                setCreatingPdf={setCreatingPdf}
                creatingPdf={creatingPdf}
                downloadFormat={downloadFormat}
                engReportDiagram={true}
                orientation="landscape"
                reportType={"eng"}
                inputData={inputData}
                setUpdateReport={setUpdateReport}
                runCarbonModel={runCarbonModel}
                carbonModel={{
                  region: params.region,
                  baselineTreatmentType: params.baselineTreatment,
                  livestockType: params.livestockType,
                  numLivestock: params.numLivestock,
                  dryMatterIntake: params.dryMatterIntake,
                  manureSeparationPercent: Number(params.manureSeparation) > 1 ? Number(params.manureSeparation) / 100 : Number(params.manureSeparation) * 100,
                  separationEfficiency: Number(params.separationEfficiency) > 1 ? Number(params.separationEfficiency) / 100 : Number(params.separationEfficiency) * 100,
                  anerobicFraction: Number(params.anerobicTreatment) > 1 ? Number(params.anerobicTreatment) / 100 : Number(params.anerobicTreatment) * 100,
                  numberOfPumps: 1,
                  pumpKw: Number(params.systemPower),
                  pumpOperationTime: Number(params.operationTime),
                  electricityRegion: params.electricityRegion,
                  yearlyCompostedWaste: yearlyTotals['Metric']['LWR Solids'],
                  flocculantPerYear
                }}
              />

              <Col>
                {(runCarbonModel && !massBalanceView) &&
                    <HiOutlineGlobe onClick={() => {
                      const element = document.querySelector("#carbon-report");
                      const rect = element.getBoundingClientRect();

                      html2canvas(element, {
                        windowWidth: rect.width,
                        windowHeight: rect.height
                      }).then(canvas => {
                        const imgData = canvas.toDataURL('image/png');

                        // Convert the canvas dimensions from pixels to millimeters.
                        const widthInMm = (canvas.width * 0.264583) - 80;
                        const heightInMm = (canvas.height * 0.264583);

                        // Create a new PDF with custom dimensions
                        const pdf = new jsPDF({
                          orientation: 'p',
                          unit: 'mm',
                          format: [widthInMm, heightInMm],
                          compress: true
                        });

                        // Add the image to the PDF.
                        pdf.addImage(imgData, 'PNG', 0, 0, widthInMm, heightInMm);
                        pdf.save(`Carbon Emissions Report: ${params.name}.pdf`);
                      });
                    }}
                      size={25}
                    />
                }
              </Col>
            </Col>
          </Row>
        </div>


        {massBalanceView &&
          <div className={`${containerClassName}`} style={{ zoom: '100%' }}>
            {/* <Header
            clientName={(clientName).split(" --time-- ")[0]}
            units={params.units}
            title="LWR System Mass Flow Diagram"
          /> */}
            <div style={{ height: '75vh' }}>
              {params.sysType === "First Wave"
                ? <FirstWaveDiagram
                  title={params.name.toLowerCase().includes('engineering assessment')
                    ? params.name.split('--time--')[0]
                    : `${params.name} - Engineering Assessment`}
                  sysParams={systemParameters}
                  inputData={inputData}
                  inletFlows={inputMassFlows}
                  afterPolymerAddition={afterPolymerAdditionMassFlows}
                  microscreenInlet={microScreenInlet}
                  msSolids={microScreenSplit['Screw Press Mass Flows']}
                  msFiltrate={microScreenSplit['MS Filtrate Mass Flows']}
                  spSolids={screwPressRemoval['Mass Flows'].Solids}
                  spFiltrate={screwPressRemoval['Mass Flows'].Filtrate}
                  polymerSystem={polymerSystem}
                />
                : <FullSystemDiagram
                  title={params.name.toLowerCase().includes('engineering assessment')
                    ? params.name.split('--time--')[0]
                    : `${params.name} - Engineering Assessment`}
                  sysParams={systemParameters}
                  inputData={inputData}
                  inletFlows={inputMassFlows}
                  afterPolymerAddition={afterPolymerAdditionMassFlows}
                  microscreenInlet={microScreenInlet}
                  msSolids={microScreenSplit['Screw Press Mass Flows']}
                  msFiltrate={microScreenSplit['MS Filtrate Mass Flows']}
                  spSolids={screwPressRemoval['Mass Flows'].Solids}
                  spFiltrate={screwPressRemoval['Mass Flows'].Filtrate}
                  polymerSystem={polymerSystem}
                  clarifierEffluent={clarifierFlows.Effluent}
                  clarifierSludge={clarifierFlows.Sludge}
                  antiscalant={roSystemFeed.Antiscalant}
                  roFeed={roSystemFeed.Total}
                  roConcentrate={roFlows.Concentrate}
                  roPermeate={roFlows.Permeate}
                  ro2Concentrate={ro2Flows.Concentrate}
                  ro2Permeate={ro2Flows.Permeate}
                  cleanWaterPolymer={cleanWater.Polymer}
                  cleanWaterDischarge={cleanWater.Discharge}
                />
              }
            </div>

          </div>
        }


        {!massBalanceView &&
          <div style={{ overflowY: 'scroll', height: '80vh', overflowX: 'hidden' }}>

            {!massBalanceView &&
              <>
                <div className="report" ref={massBalanceRef} style={{ margin: 'auto', zoom: '85%', marginTop: '1%' }}>
                  <Header
                    clientName={params.name.split(" --time-- ")[0]}
                    units={params.units}
                    title="Mass Balance - Nutrient Concentrations - Outputs"
                  />
                  <br />
                  <div className="row">
                    <ConstituentMassBalance
                      units={massBalance["Units"]}
                      data={massBalance["Constituents"]}
                      polymer={afterPolymerAdditionMassFlows}
                      setErrorVal={setErrorVal}
                      inputMassFlows={inputMassFlows}
                    />
                    <TotalMassBalance data={massBalance["Input/Output"]} />
                  </div>
                  <div className="row">
                    <NutrientConcentration
                      manure={inputData["Manure"]}
                      units={params.units}
                      concentration={nutrientConcentrations}
                      sysType={params.sysType}
                    />
                  </div>
                  <div className="row" style={{ marginTop: "30px" }}>
                    <h5 style={{ textAlign: 'left', color: '#005E7D', fontWeight: 'bold' }}>{'Process Flow'}</h5>
                  </div>
                  <div className="row" style={{ height: '300px' }}>
                    <div style={{ width: '100%', height: '100%' }}>
                      <ProcessFlowChart
                        inputData={inputMassFlows}
                        showPolymerDose={true}
                        polymerSystem={polymerSystem}
                        units={"Metric"}
                        roundUnits={false}
                        systemType={params.sysType}
                        manure={params.manure}
                        isFW={params.sysType === "First Wave"}
                        name={params.name.split(" --time-- ")[0]}
                        outputSolidsPerMinute={
                          params.spRemoved == "true"
                            ? microScreenSplit["Screw Press Mass Flows"]["Total Mass Flow"]
                            : screwPressRemoval["Mass Flows"]["Solids"]["Total Mass Flow"]
                        }
                        outputWaterPerMinute={
                          params.secondRO == "true" ? ro2Flows["Permeate"]["Total Mass Flow"] : roFlows["Permeate"]["Total Mass Flow"]}
                        outputLiquidsPerMinute={
                          roFlows["Concentrate"]["Total Mass Flow"] != 0
                            ? roFlows["Concentrate"]["Total Mass Flow"]
                            : microScreenSplit["MS Filtrate Mass Flows"]["Total Mass Flow"]
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row" style={{ paddingBottom: "0px" }}>
                    <YearlyOutputs
                      isFw={params.sysType === "First Wave"}
                      units={params.units}
                      type="eng"
                      inputData={inputData}
                      polymerDose={polymerDosage}
                      yearlyTotals={yearlyTotals}
                    />
                    <TotalsChart
                      data={[
                        Number(yearlyTotals["LWR Solids"]["gallons"]),
                        Number(yearlyTotals["Nutrient Liquids"]),
                        Number(yearlyTotals["Clean Water"]),
                      ]}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="row">
                    <Footer />
                  </div>
                </div>
                <br />
                <br />
                <br />
              </>
            }

            {(runCarbonModel && !massBalanceView && carbonEmissions?.project_emissions?.totalEmissions) &&
              <div id="carbon-report" className="report" style={{ margin: 'auto', zoom: '85%' }}>
                <CarbonEmissions
                  params={params}
                  other={{
                    yearlyCompostedWaste: Number(yearlyTotals['Metric']['LWR Solids']).toFixed(2),
                    flocculantPerYear
                  }}
                  carbonEmissions={carbonEmissions} />
              </div>
            }
          </div>
        }
      </Col>
    </Row>
  );
};


