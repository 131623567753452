import React from "react";
import { Col, Form, Row } from "react-bootstrap";

// creates the form group for the different form inputs

export const FormGroupCreator = ({
  label = "", //the label for the form input
  type = "text", //the type of value to be created
  id = "", //the id of the value to be created
  placeholder = null, //the placeholder for the input tag
  isRequired = { required: true }, // is the value required to proceed the form
  changeValue, //the function to be called on change
  valueType, // valuetype contains a type but also contains id ( used for values where we need both id and valuetype to be return on change)
  valueDict, // the dict of where the value is located so that it can be changed
  min = -1, // min of -1 for numbers
  max = Math.pow(10, 1000), // the max value for integers
}) => {
  const getKey = () => {
    return id !== "" ? { key: id } : {};
  };

  const getTypeofValueType = () => {
    return typeof valueType;
  };

  const getMdForCol = () => {
    return label == "" ? { md: 12 } : { md: 10 };
  };

  let dictValueType =
    getTypeofValueType() == "string" ? valueType : valueType.type;

  placeholder =
    placeholder == null
      ? `Enter ${label ? label : "value"} here...`
      : placeholder;
  const getMinMax = () => {
    if ((min > -1) & (max < Math.pow(10, 1000))) {
      return { min: `${min}`, max: `${max}` };
    } else if (min > -1) {
      return { min: `${min}` };
    } else if (max < Math.pow(10, 1000)) {
      return { max: `${max}` };
    } else {
      return {};
    }
  };

  return (
    <Form.Group className="my-1 ">
      <Row className="d-flex align-items-center justify-content-center">
        {label ? (
          <Col md={2} className="text-center">
            <Form.Label>{label}</Form.Label>
          </Col>
        ) : (
          <></>
        )}
        <Col {...getMdForCol}>
          <Form.Control
            {...getMinMax()}
            {...getKey()}
            type={type}
            placeholder={placeholder}
            {...isRequired}
            onChange={(e) => changeValue(e.target.value, valueType)}
            value={
              type != "number"
                ? valueDict[dictValueType]
                : Number(valueDict[dictValueType]).toString()
            }
            step="0.1"
          />
        </Col>
      </Row>
    </Form.Group>
  );
};
