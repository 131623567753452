/**
 * SystemsEditModal.jsx
 * ----------------------
 * Modal component that contains functionality for editing Client System in the LWR database.
 * This data update is done via a PUT request to the LWR API.
 *
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Alert, Form, Row, Col } from "react-bootstrap";
import SystemsDeleteModal from './SystemsDeleteModal';

// api
import { getAllSysTypes } from "../../../api/systemtypes";
import { updateSystemSite } from "../../../api/systemSites";

export default function SystemsEditModal(props) {
  // State vars
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [manureType, setManureType] = useState("Dairy");
  const [systemType, setSystemType] = useState("");
  const [systemTypeElements, setSystemTypeElements] = useState([]);

  // Refs
  const linkingIdRef = useRef();
  const jobNumberRef = useRef();

  const onManureTypeChange = (e) => {
    setManureType(e.target.value);
  };

  const onSystemTypeChange = (e) => {
    setSystemType(e.target.value);
  };

  useEffect(() => {
    populateOptions()
  }, []);

  const populateOptions = async () => {
    const systemList = await getAllSysTypes();
    setSystemTypeElements(
      systemList.map((system) => {
        return <option>{system.systemName}</option>;
      })
    );

    setSystemType(systemList[0].systemName);

  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const updateResult = await updateSystemSite(props.system.systemName, systemType, manureType, linkingIdRef.current.value, jobNumberRef.current.value);
    if (updateResult.status != 200) {
      setErr("Error updating system");
      e.preventDefault();

    } else {
      setSuccess("Succesfully updated system. You may now close this modal.");
      // callback function to update state in parent component
      props.onEnd();
      e.preventDefault();
    }
  };

  const handleClose = () => {
    setShow(false);
    setErr("");
    setSuccess("");
    props.onEnd();
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="lg" style={{ zoom: '85%', marginTop: '2%' }}>
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>View/Edit Client System - {props.system.systemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {err && <Alert variant="danger">{err}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>System Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={props.system.systemName}
                  disabled={true}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Parent Farm</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={props.system.farmId}
                  disabled={true}
                ></Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>System Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={props.system.systemType}
                  onChange={onSystemTypeChange}
                >
                  {systemTypeElements}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Manure Type</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={props.system.manureType}
                  onChange={onManureTypeChange}
                >
                  <option>Dairy</option>
                  <option>Hog</option>
                  <option>Digestate</option>
                  <option>Poultry</option>
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Linking ID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={props.system.linking_id}
                  ref={linkingIdRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Job #</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={props.system.jobNumber}
                  ref={jobNumberRef}
                  required
                />
              </Form.Group>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-dark" type="submit">
                    Update
                  </Button>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
