import LogOutModal from "../../pages/LogOutModal";
import SettingsModal from "../../pages/SettingsModal";

const NavButtonGroup = ({currentUser, accountType}) => {
  return (
    <div className="nav-bar-button-group">
      <div className="logout-container">
        <LogOutModal />
      </div>
      <div className="info-container">
        <SettingsModal
          account={currentUser}
          accountType={accountType}
        />
      </div>
    </div>
  );
}

export default NavButtonGroup;