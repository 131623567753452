import React, { useState } from "react";
import { Col, ListGroupItem, Row } from "react-bootstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useEffect } from "react";

// Displays job code values
export const ExistingHRShopTimesheetJobCode = ({
  valueDict,
  valueType,
  label,
  changeValue,
  deleteButton = false,
  deleteFunction = () => {},
  newJobCode = false,
  cancelChanges,
  jobCodeDict,
}) => {
  const [editValue, setEditValue] = useState(newJobCode);
  useEffect(() => {
    setEditValue(false);
  }, [cancelChanges]);

  const jobCode = valueDict[valueType.type].split(" - ")[0];
  const actualType = valueType.type ? valueType.type : valueType;
  return (
    <ListGroupItem>
      {!editValue ? (
        <>
          <Row className="py-2">
            <Col className="col-4">Job Phase</Col>
            <Col className="col-7">
              {valueDict[actualType]
                ? valueDict[actualType]
                : "No job code has been selected"}
            </Col>
            <Col className="col-1 p-0 d-flex justify-content-end">
              <AiOutlineEdit onClick={() => setEditValue(true)} />
              {deleteButton ? (
                <AiOutlineDelete
                  onClick={() => deleteFunction(valueType)}
                  className="ms-2"
                />
              ) : null}
            </Col>
          </Row>

          <Row className="pt-2 border-top">
            <Col className="col-4">Description</Col>
            <Col className="col-7">{valueDict?.description || "n/a"}</Col>
            <Col className="col-1"></Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={2} className="text-center">
              Job Code
            </Col>
            <Col>
              <select
                className="border py-2 rounded w-100"
                md={10}
                value={jobCode}
                onChange={(e) => {
                  changeValue(
                    e.target.value,
                    valueType
                  );
                  changeValue(jobCodeDict[e.target.value].description, {
                    ...valueType,
                    type: "description",
                  });
                }}
                style={{ overflowY: "scroll" }}
              >
                <option defaultValue={valueDict.jobCode.split(" - ")[0]}>
                  {valueDict.jobCode}
                </option>
                {Object.keys(jobCodeDict).map((jobCode) => (
                  <option value={jobCode}>
                    {jobCode}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="pt-2 border-top">
            <Col className="col-4">Description</Col>
            <Col className="col-7">{valueDict?.description || "n/a"}</Col>
            <Col className="col-1"></Col>
          </Row>
        </>
      )}
    </ListGroupItem>
  );
};
