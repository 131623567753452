import { React, useState } from "react";
import { workCarriedOutExample } from "../../NewReportExample";
import { FieldTimeReportingForm } from "../FieldTimeReportingFormCreator";
import { FieldTimeSheetTable } from "../FieldTimeSheetTableCreator";
import { Form, Row, Col, Button } from "react-bootstrap";

// Creates the form and sheet table for the work carried out

export const FieldTimeSheetWorkCarriedOut = ({
  formProps: { setNewReport, pageNumber, setPageNumber, newReport },
  editMode
}) => {
  const [rows, setRows] = useState(newReport.workCarriedOut);

  const handleAddRow = () => {
    setRows([...rows, { description: '', hours: '' }]);
    setNewReport({...newReport, workCarriedOut: [...rows, { description: '', hours: '' }]})
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    setNewReport({...newReport, workCarriedOut: newRows});
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
    setNewReport({...newReport, workCarriedOut: newRows});
  };

  const computeTotalHours = () => {
    return rows.reduce((sum, row) => sum + (isNaN(parseFloat(row.hours)) ? 0 : parseFloat(row.hours)), 0);
  };

  return (
    <div>
      <h4 className="text-center py-3 border-bottom">Work Carried Out</h4>
      <FieldTimeReportingForm
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        editMode={editMode}
      >

        <div>
          {newReport.workCarriedOut.map((row, index) => (
            <Row key={index} className="mb-2">
              <Col>
                <Form.Control
                  value={row.description}
                  required
                  placeholder="Description of work carried out"
                  onChange={(e) =>
                    handleInputChange(index, 'description', e.target.value)
                  }
                />
              </Col>
              <Col>
                <Form.Control
                  value={row.hours}
                  required
                  placeholder="Hours worked"
                  type="Number"
                  onChange={(e) => handleInputChange(index, 'hours', e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button variant="outline-danger" onClick={() => handleRemoveRow(index)} disabled={rows.length <= 1}>
                  Delete
                </Button>
              </Col>
            </Row>
          ))}
          <Row className="mb-2">
            <Col>
              <Form.Control disabled value="Total Hours" style={{ fontWeight: 'bold' }} />
            </Col>
            <Col>
              <Form.Control disabled value={computeTotalHours()} style={{ fontWeight: 'bold' }} />
            </Col>
            <Col xs="auto">
              <Button variant="secondary" disabled>Hours</Button>
            </Col>
          </Row>
          <Button onClick={handleAddRow} variant="outline-dark">Add Task</Button>
        </div>

      </FieldTimeReportingForm>
    </div>
  );
};
