import React, { useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import { createNodes, edges as initialEdges } from './processFlowElements';
import 'reactflow/dist/style.css';
import './processFlowChart.css';


const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

const ProcessFlowChart = ({ inputData, units, systemType, outputLiquidsPerMinute, outputSolidsPerMinute, outputWaterPerMinute, name, polymerSystem }) => {

  console.log(outputLiquidsPerMinute);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    createNodes(inputData,
      units,
      systemType,
      outputLiquidsPerMinute,
      outputSolidsPerMinute,
      outputWaterPerMinute,
      name,
      polymerSystem)
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  useEffect(() => {
    setNodes((
      createNodes(inputData,
        units,
        systemType,
        outputLiquidsPerMinute,
        outputSolidsPerMinute,
        outputWaterPerMinute,
        name, polymerSystem)));
  }, [inputData, units,
    systemType,
    outputLiquidsPerMinute,
    outputSolidsPerMinute,
    outputWaterPerMinute,
    name, polymerSystem]);

  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === 'custom').data.selects[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  return (
    <ReactFlow
      nodes={nodes}
      edges={edgesWithUpdatedTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onInit={onInit}
      fitView
      attributionPosition="top-right"
      preventScrolling
    >
    </ReactFlow>
  );
};

export default ProcessFlowChart;