import React from "react";
import { Tooltip } from "react-bootstrap";

import ReactToPdf from "react-to-pdf";
import LoadingModal from "../analytics-reporting/LoadingModal";

import { HiOutlineDownload, HiOutlineDatabase } from "react-icons/hi";

import {
  saveEngineeringReportToDatabase,
  saveClientReportToDatabase,
} from "./AssessmentSaveToDatabase";

import { EditModal } from "./horizontal-engineering-report/EngineeringAssessmentTables/EditModal";

export const SaveAndDownloadAssessmentReport = ({
  // params
  params,
  sysParams,
  runCarbonModel,
  // states
  isSaved,
  currentUser,
  accountType,
  setIsSaved,
  //   ref
  massBalanceRef,
  reportRef,
  setCreatingPdf,
  creatingPdf,
  downloadFormat = "",
  engReportDiagram = false,
  orientation = "portrait",
  microNutrients = {},
  reportType,
  carbonModel,
  inputData,
  setUpdateReport,
  massBalanceView,
  hideDownload
}) => {
  const optionsFlow = {
    orientation: orientation,
    unit: "in",
    format: downloadFormat ? downloadFormat : [16.5, 26],
    compress: true,
  };
  const optionsMassBalance = {
    orientation: "portrait",
    unit: "in",
    format: [16.5, 20.5],
    compress: true,
  };

  const saveAssessmentReport = () => {
    if (engReportDiagram == false) {
      saveClientReportToDatabase(
        params,
        currentUser,
        microNutrients,
        accountType,
        carbonModel
      );
    } else {
      saveEngineeringReportToDatabase(params, sysParams, currentUser, accountType, carbonModel);
    }

    setIsSaved(true);
  };

  const CustomToolTip = (id) => (
    <Tooltip id={id}>
      Save Assessment to Database
    </Tooltip>
  );

  return (
    <div className="row">
      {massBalanceView && !hideDownload  && <div className="col">
        <div>
          <ReactToPdf
            targetRef={reportRef}
            filename={`${params.name}_${reportType}_assessment_flows.pdf`}
            onComplete={() => {
              setCreatingPdf(false);
            }}
            options={optionsFlow}
            x={0}
            y={0}
            scale={0.99}
          >
            {({ toPdf }) => (
              <LoadingModal
                onClick={toPdf}
                show={creatingPdf}
                setShow={setCreatingPdf}
                title={
                  engReportDiagram
                    ? "Download Mass Flows Report"
                    : "Download Report"
                }
                useTooltip={true}
                icon={HiOutlineDownload}
                variant="outline-success"
              />
            )}
          </ReactToPdf>
        </div>
      </div>}
      {!massBalanceView && <div className="col">
        {engReportDiagram ? (
          <div>
            <ReactToPdf
              targetRef={massBalanceRef}
              filename={`${params.name}_${reportType}_assessment_mass_balance.pdf`}
              onComplete={() => {
                setCreatingPdf(false);
              }}
              options={optionsMassBalance}
              x={0}
              y={0}
              scale={0.99}
            >
              {({ toPdf }) => (
                <LoadingModal
                  useTooltip={true}
                  icon={HiOutlineDownload}
                  onClick={toPdf}
                  show={creatingPdf}
                  setShow={setCreatingPdf}
                  title="Download Mass Balance & PFD"
                  variant="outline-dark"
                />
              )}
            </ReactToPdf>
          </div>
        ) : (
          <></>
        )}
      </div>}

      <div className="col">
        <HiOutlineDatabase onClick={saveAssessmentReport} size={25} />
      </div>

      <div className="col">
        <EditModal
          params={params}
          inputData={inputData}
          accountType={accountType}
          setUpdateReport={setUpdateReport}
        />
      </div>
    </div>
  );
};
