import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  convertKeyNameToDisplayName,
  returnCorrectValue,
} from "../../TimeReporting/TimeReportFunctions";
import { AiOutlineEdit } from "react-icons/ai";
import { FormGroupCreator } from "../../TimeReporting/FormGroupCreator";
import { FormCheckCreator } from "../../TimeReporting/FormCheckCreator";

export const ExistingFieldReceiptValue = ({
  expenseReceipt,
  cancelChanges,
  keyName,
  changeValue,
}) => {
  const [editValue, setEditValue] = useState(false);
  useEffect(() => {
    setEditValue(false);
  }, [cancelChanges]);
  return (
    <Row className="border-bottom py-1 d-flex align-items-center">
      <Col md={4}>{convertKeyNameToDisplayName(keyName)}</Col>
      {!editValue ? (
        <>
          <Col md={7}>{returnCorrectValue(expenseReceipt[keyName])}</Col>
          <Col md={1}>
            <div className="download-file">
              <AiOutlineEdit onClick={() => setEditValue(true)} />
            </div>
          </Col>
        </>
      ) : (
        <Col md={8}>
          {typeof expenseReceipt[keyName] == "boolean" ? (
            <FormCheckCreator
              valueDict={expenseReceipt}
              valueType={keyName}
              type={typeof expenseReceipt[keyName]}
              changeValue={changeValue}
            />
          ) : (
            <FormGroupCreator
              valueDict={expenseReceipt}
              valueType={keyName}
              type={typeof expenseReceipt[keyName]}
              changeValue={changeValue}
            />
          )}
        </Col>
      )}
    </Row>
  );
};
