/**
 * OrganizationsInsertModal.jsx
 * ----------------------------
 * Modal component that contains functionality for inserting organizations into the LWR database.
 * This data insertion is done via a POST request to the LWR API.
 *
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useState, useRef } from "react";
import { Modal, Button, Form, Alert, Row, Col } from "react-bootstrap";

// api
import { createOrganization } from "../../../api/organizations";

export default function OrganizationInsertModal(props) {
  // State
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // Refs
  const orgNameRef = useRef();
  const contactNameRef = useRef();
  const contactNumberRef = useRef();
  const contactEmailRef = useRef();
  const addressRef = useRef();

  // funcs

  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };
  const handleShow = () => setShow(true);

  const clearForm = () => {
    orgNameRef.current.value = "";
    contactNameRef.current.value = "";
    contactNumberRef.current.value = "";
    contactEmailRef.current.value = "";
    addressRef.current.value = "";
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const bodyValues = {
      name: orgNameRef.current.value,
      address: addressRef.current.value,
      contact: contactNameRef.current.value,
      contactNumber: contactNumberRef.current.value,
      contactEmail: contactEmailRef.current.value,
    };

    const createResult = await createOrganization(bodyValues);

    if (createResult.status != 200) {
      setErrMsg("Error inserting new organization.");
    } else {
      setSuccessMsg("Succesfully inserted organization into database.");
      clearForm();
    }
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New Organization
      </Button>

      <Modal show={show} onHide={handleClose} style={{ zoom: '85%' }} size="lg">
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>Add New Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter org name"
                  ref={orgNameRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Main Contact</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter contact name"
                  ref={contactNameRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Contact Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter contact Email"
                  ref={contactEmailRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Contact Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter contact number"
                  ref={contactNumberRef}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter org address"
                  ref={addressRef}
                  required
                />
              </Form.Group>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-dark" type="submit">
                    Update
                  </Button>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
