/**
 * AuthContext.js
 * --------------
 * Boilerplate context to access firebase authentication functionality throughout
 * the whole scope of the application.
 * 
 * Implements custom sign up key authentication and user registration against LWR's API.
 * 
 * Author: Oscar Jaimes
 * Last Updated: 25/05/2021
 */

import React, { useContext, useState, useEffect, useRef } from "react";
import { auth } from "../firebase.js"
import 'firebase/auth';
import firebase from 'firebase/compat/app';

// api
import { clearAdminCache, signUp } from "../api/admin.js";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

async function sendVerificationCode(phoneNumber, recaptchaVerifier) {
  const phoneProvider = new firebase.auth.PhoneAuthProvider(auth);
  try {
    const verificationId = await phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
    return verificationId;
  } catch (error) {
    console.error("Error sending verification code: ", error);
    throw error;
  }
}

function verifyCode(verificationId, code) {
  const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
  return auth.signInWithCredential(credential);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signup(email, password, signUpKey, firstName, lastName) {
    email = email.toLowerCase();
    // First, validate sign up key and register user in LWR database
    return (await signUp(email, signUpKey, password, firstName, lastName));
  }

  function login(email, password) {
    email = email.toLowerCase();
    return auth.signInWithEmailAndPassword(email, password).then(userCredential => {
      const user = (userCredential.user);
      if (!user) {
        auth.signOut();
        return -1;
      }

      return 1;

    }).catch((error) => {
      console.log(error.code);
      return error;
    })
  }

  function logout() {
    clearAdminCache()
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // 6 hours session duration
  const sessionDurationInMilliseconds = 2.16e+7; 

  const handleActivity = () => {
    if (userSessionTimeout.current) {
      clearTimeout(userSessionTimeout.current);
      userSessionTimeout.current = setTimeout(() => auth.signOut(), sessionDurationInMilliseconds);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    auth.onAuthStateChanged((user) => {
      userSessionTimeout.current = null;

      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const authTime = idTokenResult.claims.auth_time * 1000;
          const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
          userSessionTimeout.current = setTimeout(() => auth.signOut(), expirationInMilliseconds);
        });
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('mousedown', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('scroll', handleActivity);
        window.addEventListener('touchstart', handleActivity);
      } else {
        if (userSessionTimeout.current) {
          clearTimeout(userSessionTimeout.current);
          userSessionTimeout.current = null;
        }
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('mousedown', handleActivity);
        window.removeEventListener('keydown', handleActivity);
        window.removeEventListener('scroll', handleActivity);
        window.removeEventListener('touchstart', handleActivity);
      }
    });

    return () => {
      unsubscribe();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('mousedown', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
    };
  }, []);

  const userSessionTimeout = useRef(null);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    sendVerificationCode,
    verifyCode
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
