/**
 * OrganizationsTab.jsx
 * --------------------
 * Component that contains all CRUD functionality for client Organizations in the LWR database.
 * Renders a table with client Organization entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";
// CRUD components
import OrganizationsInsertModal from "./OrganizationInsertModal";
import OrganizationsDeleteModal from "./OrganizationDeleteModal";
import OrganizationsEditModal from "./OrganizationEditModal";
import './OrganizationsTab.css';

// api
import { getAllOrganizations } from "../../../api/organizations";

const OrganizationsTab = (props) => {
  // State
  const [organizationTableElements, setOrgElements] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [orgToEdit, setOrgToEdit] = useState({});

  // Ref
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "Organizations - Admin Tool";
    fetchAndCreateOrgElements();
  }, []);

  const fetchAndCreateOrgElements = async () => {
    searchRef.current.value = "";
    const allOrgs = await getAllOrganizations();
    let orgElements = await createOrgTableElements(allOrgs);
    setOrgElements(orgElements);
    setOrganizations(allOrgs);
  };

  const createOrgTableElements = (orgs) => {
    return orgs.map((org) => (
      <tr onClick={() => {setShowEditModal(true); setOrgToEdit(org)}}>
        <td>{org.name}</td>
        <td>{org.contact}</td>
        <td>{org.contactNumber}</td>
        <td>{org.contactEmail}</td>
        <td>{org.address}</td>
      </tr>
    ));
  };

  /**
   * Search functionality. Edits table contents based on input text in search box.
   */
  const onChangeSearch = () => {
    let validOrgs = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();

    organizations.forEach((org) => {
      if (org.name.toLowerCase().includes(currentSearchVal)) {
        validOrgs.push(org);
      }
    });

    let newTableElements = createOrgTableElements(validOrgs);
    setOrgElements(newTableElements);
  };

  return (
    <div style={{margin: '10px'}}>
      <OrganizationsEditModal show={showEditModal} org={orgToEdit} onEnd={() => {fetchAndCreateOrgElements(); setShowEditModal(false); setOrgToEdit({})}}/>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          <OrganizationsInsertModal onEnd={fetchAndCreateOrgElements} />
        </div>
      </div>
      <br />
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Main Contact</th>
              <th>Contact Number</th>
              <th>Contact Email</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>{organizationTableElements}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default OrganizationsTab;
