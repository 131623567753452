/**
 * api/auth.js
 * ------------------
 * Module encapsulating functions related to firebase auth on the lwr-api server side.
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";

const generatePasswordResetToken = async (email) => {
  return http.post('auth/generate-password-reset-token', { email })
    .then((res) => {
      if (res.status === 200) {
        return 1;
      }
      return -1; // error
    }).catch((error) => {
      console.log(error);
      return error;
    });
};


const resetFirebasePassword = async (email, newPassword, token) => {
  return http.post('auth/reset-firebase-password', {
    email,
    newPassword,
    token
  }).then((res) => {
    if (res.status === 200) {
      return 1;
    } else if (res.status === 401) {
      return -2; // Invalid reset token
    }

    return -1; // Generic Error
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

const deleteUserAccount = async (email, emailToBeDeleted) => {
  return http.delete(`auth/deleteUser/?email=${email}&emailToBeDeleted=${emailToBeDeleted}`)
    .then((res) => {
      if (res.status === 200) {
        return 1;
      }
      return -1; // error
    }).catch((error) => {
      console.log(error);
      return error;
    });
};

const getAllUserEmails = async (includeShopEmployees) => {
  return http.post('auth/allUserEmails', {includeShopEmployees})
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

export {
  generatePasswordResetToken,
  resetFirebasePassword,
  deleteUserAccount,
  getAllUserEmails
};