import React, { useState, useCallback } from 'react';
import { Col, Row, Modal, Button, Alert } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';

export const DisplayImagesFromDocs = ({
  images = [],
  loading = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');
  const [modalImageBufferData, setModalImageBufferData] = useState(null);

  const imageBufferToSrc = useCallback((imageBufferData) => {
    if (imageBufferData.type === 'Buffer') {
      const buffer = imageBufferData.data;
      const b64 = Buffer.from(buffer).toString('base64');
      const mimeType = imageBufferData.mimeType || 'image/jpeg';
      return `data:${mimeType};base64,${b64}`;
    }
    return URL.createObjectURL(imageBufferData);
  }, []);

  const toggleModal = useCallback((src, bufferData) => {
    setShowModal(current => !current);
    setModalImageSrc(src);
    setModalImageBufferData(bufferData);
  }, []);

  const downloadImage = useCallback((imageSrc, fileName) => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const findImageSize = useCallback((report) => ({
    md: report.length === 1 ? 12 : 6,
  }), []);

  const imageDisplay = useCallback(() => (
    <Row className="d-flex justify-content-center">
      <Alert variant="primary">Select a receipt to enlarge and download</Alert>
      {images.map((bufferData, index) => (
        <Col
          key={index}
          {...findImageSize(images)}
          className="d-flex flex-column text-center align-items-center"
        >
          <div className="d-flex justify-content-center">
            <img
              src={imageBufferToSrc(bufferData.fileBlob[0])}
              style={{ width: "200px", height: "200px", cursor: "pointer" }}
              alt={`Receipt ${index + 1}`}
              onClick={() => toggleModal(imageBufferToSrc(bufferData.fileBlob[0]), bufferData)}
            />
          </div>
        </Col>
      ))}
    </Row>
  ), [images, findImageSize, imageBufferToSrc, toggleModal]);

  return (
    <div>
      {!loading ? imageDisplay() : (
        <div className="d-flex flex-column align-items-center">
          <RotatingLines strokeColor="#4fa94d" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
          <br />
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="outline-dark"
            onClick={() => downloadImage(modalImageSrc, modalImageBufferData.fileName)}
            style={{ width: '100%', marginBottom: '1%' }}
          >
            Download
          </Button>
          <img src={modalImageSrc} style={{ width: "100%" }} alt="Enlarged receipt" />
        </Modal.Body>
      </Modal>
    </div>
  );
};
