import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

import CreateFieldEodReportModal from "../CreateFieldEodReportModal";
import { ExistingFieldEODReport } from "./ExistingFieldEODReport/ExistingFieldEODReport";

import {
  deleteEODReport,
  getAllEODReport,
} from "../../../api/fieldEODReport";

// util
import {
  isPermissionTypeHRAdmin,
  isPermissionTypeFieldAdmin
} from "../../../utility/permissionFunctions";

// custom hooks
import usePermission from "../../../hooks/permissions";
import useQuery from "../../../hooks/useQuery";
import { useAuth } from "../../../contexts/AuthContext";

export const FieldEODReport = () => {
  // state
  const [isReportList, setIsReportList] = useState(true);
  const [reportId, setReportId] = useState(null);

  // custom hooks
  const { email } = useAuth().currentUser;
  const { permission } = usePermission(email);

  const { data: eodReports, isLoading, error, refetch } = useQuery(
    'field-eod-reports',
    () => getAllEODReport(email),
    null
  );

  const EODTable = () => {
    return (
      eodReports.map((r) => {
        return (
          <tr>
            <td>{r.reportName}</td>
            <td>{r.jobNumber}</td>
            <td>{r.client}</td>
            <td>{r.name}</td>
            <td>{new Date(r.date).toDateString()}</td>
            <td><FaEye size={22} onClick={() => {
              setReportId(r._id);
              setIsReportList(false);
            }} /></td>
          </tr>
        )
      })
    );
  }

  if (!isReportList) {
    return (
      <div style={{ margin: '0% 1% 1% 1%' }}>
        <ExistingFieldEODReport
          learnMoreReportId={reportId}
          setIsReportList={setIsReportList}
        />
      </div>
    );
  }

  return (
    <div style={{ margin: '0% 1% 1% 1%' }}>
      <Row style={{ marginBottom: '0.5%' }}>
        <Col md={6} style={{ textAlign: 'left' }}>
          <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Field EOD Reports
            {(isPermissionTypeFieldAdmin(permission) || isPermissionTypeHRAdmin(permission)) && " - HR/Admin View"}
          </h5>
        </Col>
        <Col md={6} style={{ textAlign: 'Right' }}>
          <CreateFieldEodReportModal onFinish={() => refetch()} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Table striped hover>
              <thead style={{ position: 'sticky', top: 0, zIndex: '30' }}>
                <tr>
                  <th style={{ width: '25%' }}>🧾 Report Name</th>
                  <th>Job #</th>
                  <th>Client</th>
                  <th>Created By</th>
                  <th>Created On</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && <td colSpan={6}> Loading ...</td>}
                {error && <td colSpan={6}>Error fetching expenses.</td>}
                {(eodReports && eodReports.length == 0) && <td colSpan={4}>No Reports to Show.</td>}
                {(eodReports && eodReports.length > 0) && <EODTable />}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};
