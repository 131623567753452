import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
} from "react-router-dom";
import { checkIfWindowIsMobile } from "../../utility/mobileFunctions";
import { HRPolicies } from "./HRPolicy/HRPolicies";
import { HRTimeSheet } from "./HRTimesheet/HRTimeSheet";
export const HRNav = () => {
  return (
    <>
      <Row>
        <Col md={11} style={{margin: 'auto', zoom: '85%'}}>
          <Row style={{ paddingTop: "2%" }}>
            <Col>
              <Card>
                <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Timesheets</Card.Header>
                <Card.Body>
                  <Card.Text>
                    View and Create Timesheets
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row style={{ paddingTop: "1%" }}>
            <Col>
              <div>
                <Router>
                  <Nav justify variant="tabs" sticky="top">
                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        style={{ color: "#3c506b" }}
                        to="/hr-tools/hr-timesheet"
                      >
                        Timesheet
                      </Nav.Link>
                    </Nav.Item>
                    {!checkIfWindowIsMobile() ? (
                      <Nav.Item>
                        <Nav.Link
                          as={Link}
                          style={{ color: "#3c506b" }}
                          to="/hr-tools/hr-policies"
                        >
                          Policies
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                  </Nav>
                  <Switch>
                    {!checkIfWindowIsMobile() ? (
                      <Route
                        path="/hr-tools/hr-policies"
                        component={HRPolicies}
                      />
                    ) : null}
                    <Route
                      path="/hr-tools/hr-timesheet"
                      component={HRTimeSheet}
                    />
                    <Redirect to="/hr-tools/hr-timesheet" />
                  </Switch>
                </Router>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
