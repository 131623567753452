import { useEffect, useState } from "react";
import { Row, Col, Card, Spinner, Modal, Badge, Accordion } from "react-bootstrap";
import ReactJson from 'react-json-view';

// api
import { getAllIotDevices } from "../../api/iot";
import { getMostRecentData } from "../../api/sensordata";

const StatusPage = () => {
  const [loading, setLoading] = useState(false);
  const [systems, setSystems] = useState([]);
  const [systemStatus, setSystemStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [recentData, setRecentData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const sysList = await getAllIotDevices();
        const statuses = await fetchSystemStatuses(sysList);
        setSystems(sysList);
        setSystemStatus(statuses);
      } catch (error) {
        console.error("Failed to fetch system data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchSystemStatuses = async (systems) => {
    const statuses = {};
    await Promise.all(
      systems.map(async (system) => {
        const sysStatus = await getMostRecentData(system.linking_id);
        statuses[system.linking_id] = sysStatus;
      })
    );
    return statuses;
  };

  const checkSystemOperational = (sysData) => {
    const flowTotals = getFlowTotals(sysData);
    const cumulativeFlow = flowTotals.reduce((acc, ft) => acc + sysData[ft], 0);
    // 20 == 2.0 gallons / minute (unadjusted)
    return cumulativeFlow >= 20;
  };

  const checkSystemData = (sysData) => {
    return sysData?.ft_1202_1 >= 0 || sysData?.ft_1201_1 >= 0;
  };

  const getAdjustedFlow = (sysData) => {
    const flowTotals = getFlowTotals(sysData);
    const cumulativeFlow = flowTotals.reduce((acc, ft) => acc + sysData[ft], 0);
    return Math.floor(cumulativeFlow / 10);
  };

  const getFlowTotals = (sysData) => {
    return Object.keys(sysData).filter((key) => key.includes('ft_1202') || key.includes('ft_1201'));
  };

  const SystemCard = ({ system, systemStatus, onClick }) => {
    const sysId = system.linking_id;
    return (
      <Col key={sysId} md={4}>
        <Card
          style={{ margin: '0.5%', cursor: 'pointer', transition: 'transform 0.2s, box-shadow 0.2s' }}
          onClick={() => onClick(systemStatus[sysId])}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.03)';
            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          <Card.Body>
            <div style={{ color: '#005e7d', fontWeight: 'bold' }}>{sysId.replaceAll('_', ' ')}</div>
            {checkSystemData(systemStatus[sysId]) && (
              <Badge bg={!checkSystemOperational(systemStatus[sysId]) ? "danger" : "success"}>
                {!checkSystemOperational(systemStatus[sysId]) ? "System Not Running" : "System Running"}
              </Badge>
            )}
            <Badge style={{ marginLeft: '1%', marginRight: '1%' }} bg={systemStatus[sysId]?.date === "not connected" ? "warning" : "secondary"} text={systemStatus[sysId]?.date === "not connected" ? "dark" : "light"}>
              Last Connected: {systemStatus[sysId]?.date === "not connected" ? 'N/A' : systemStatus[sysId]?.date}
            </Badge>
            {checkSystemData(systemStatus[sysId]) && (
              <Badge bg='' style={{ backgroundColor: '#005E7D' }}>
                Flow: {getAdjustedFlow(systemStatus[sysId])} gal/min
              </Badge>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const connectedSystems = systems.filter(system => systemStatus[system.linking_id]?.date !== 'not connected');
  const notConnectedSystems = systems.filter(system => systemStatus[system.linking_id]?.date === 'not connected');

  return (
    <div>
      <Modal show={showModal} size="lg" onHide={() => { setShowModal(false); setRecentData({}) }}>
        <Modal.Header>Most Recent Data For {recentData?.linkingId}</Modal.Header>
        <Modal.Body>
          <div style={{ height: "65vh", overflowY: 'auto' }}>
            <ReactJson src={recentData} />
          </div>
        </Modal.Body>
      </Modal>

      <Row style={{ margin: '0.4%' }}>
        <h5 style={{ margin: '0.2%', color: "#005E7D" }}>IoT System Status Dashboard</h5>

        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Connected Systems</Accordion.Header>
            <Accordion.Body>
              <Row>
                {!loading && connectedSystems.map((system) => (
                  <SystemCard key={system.linking_id} system={system} systemStatus={systemStatus} onClick={(data) => { setShowModal(true); setRecentData(data); }} />
                ))}
              </Row>
              {loading &&<div style={{ textAlign: "center" }}><Spinner animation="border" /></div>}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Disconnected Systems</Accordion.Header>
            <Accordion.Body>
              <Row>
                {!loading && notConnectedSystems.map((system) => (
                  <SystemCard key={system.linking_id} system={system} systemStatus={systemStatus} onClick={(data) => { setShowModal(true); setRecentData(data); }} />
                ))}
              </Row>
              {loading &&<div style={{ textAlign: "center" }}><Spinner animation="border" /></div>}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </div>
  );
};

export default StatusPage;
