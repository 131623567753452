import React, { useEffect, useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";

import LoisInsertModal from "./loisInsertModal";
import LoisDeleteModal from "./loisDeleteModal";
import { getUserPermissionFromCache } from "../../../utility/permissionFunctions";

// api
import { getAllLoisControls } from "../../../api/loiscontrol";

const LoisControlTab = (props) => {
  const [tableElements, setTableElements] = useState([]);
  const [loisControlElements, setLoisControlElements] = useState([]);
  const [userType, setUserType] = useState([]);
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "LOIS - Admin Tools";
    setUserType(await getUserPermissionFromCache(props.account.email));
    fetchAndCreateLoisControlElements();
  }, []);

  const fetchAndCreateLoisControlElements = async () => {
    searchRef.current.value = "";
    const loisControls = await getAllLoisControls();
    let deviceElements = createTableElements(loisControls);
    setTableElements(deviceElements);
    setLoisControlElements(loisControls);
  };

  const createTableElements = (results) => {
    return results.map((item) => (
      <tr>
        <td>{item.linkingId}</td>
        <td>{item.loisActive.toString().toUpperCase()}</td>
        {
          // <td>
          //   <LoisDeleteModal
          //     onEnd={fetchAndCreateLoisControlElements}
          //     linking_id={item.linkingId}
          //   />
          // </td>
        }
      </tr>
    ));
  };

  const onChangeSearch = () => {
    let valid = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();
    loisControlElements.forEach((item) => {
      if (item.linkingId.toLowerCase().includes(currentSearchVal)) {
        valid.push(item);
      }
    });

    let newTableElements = createTableElements(valid);
    setTableElements(newTableElements);
  };

  return (
    <div style={{margin: '10px'}}>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          {<LoisInsertModal onEnd={fetchAndCreateLoisControlElements} />}
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Linking Id</th>
              <th>LOIS Active</th>
              {/* <th>Delete</th> */}
            </tr>
          </thead>
          <tbody>{tableElements}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default LoisControlTab;
