/**
 * NavBar.jsx
 * ----------
 * Controls navigation for main dashboard and content.
 *
 * Author: Oscar Jaimes
 */

// imports
import { Navbar, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  useLocation,
} from "react-router-dom";

// custom components
import PrivateRoute from "../PrivateRoute";
import MainDashboard from "../../pages/MainDashboard";
import AssessmentNav from "../../pages/assessment-reporting/AssessmentNav";
import AnalyticsReportingPage from "../../pages/analytics-reporting/AnalyticsReportingPage";
import AdminToolsPage from "../../pages/AdminToolsPage";
import WeeklyReport from "../../pages/analytics-reporting/WeeklyReport";
import ClientAssessmentReport from "../../pages/assessment-reporting/ClientAssesmentReport";
import NotFound from "../../pages/404";
import CsvNav from "../../pages/csv-tool/CsvNav";
import WorkOrdersPage from "../../pages/work-orders/WorkOrdersPage";
import { FieldNav } from "../../pages/field-tools/FieldNav";
import { EngineeringAssessmentReport } from "../../pages/assessment-reporting/EngineeringAssessmentReport";
import { HRNav } from "../../pages/hr-tools/HRNav";
import ShopTimesheetPage from "../../pages/shop-timesheets/ShopTimesheetPage";
import OfficeTimesheetPage from "../../pages/office-timesheets/OfficeTimesheetPage";
import LabData from "../../pages/lab-data/LabData";
import NavButtonGroup from "./NavButtonGroup";
import StatusPage from "../../pages/status-page/StatusPage";

// css
import "./Navbar.css";

// hooks
import usePermission from "../../hooks/permissions";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect } from "react";

// constants
const navLinksByPermission = {
  admin: [
    "/",
    "/analytics-reporting",
    "/assessment-reporting",
    "/system-status",
    "/database",
    "/field-tools",
    "/csv-tools",
    "/office-timesheets",
    "/work-orders",
    "/shop-timesheets",
    "/lab-data",
  ],
  fieldAdmin: ["/", "/field-tools", "/shop-timesheets", "/system-status"],
  hrAdmin: ["/", "/field-tools", "/shop-timesheets","/office-timesheets", "/system-status"],
  employee: [
    "/",
    "/analytics-reporting",
    "/assessment-reporting",
    "/system-status",
    "/office-timesheets",
    "/database",
    "/csv-tools",
    "/work-orders",
    "/lab-data",
  ],
  "field-employee": [
    "/",
    "/field-tools",
    "/shop-timesheets",
    "/analytics-reporting",
    "/assessment-reporting",
    "/system-status"
  ],
  shopEmployee: ["/", "/shop-timesheets"],
  permissionsAdmin: [],
  woAdmin: [],
};

export default function NavBar() {
  const { currentUser } = useAuth();
  const { permission: accountType = [] } = usePermission(currentUser.email);
  const location = useLocation();

  const routes = [
    { path: '/assessment-reporting', component: () => <AssessmentNav account={currentUser} accountType={accountType} /> },
    { path: '/analytics-reporting', component: () => <AnalyticsReportingPage email={currentUser.email} /> },
    { path: '/system-status', component: () => <StatusPage />, },
    { path: '/lab-data', component: () => <LabData />, },
    { path: '/field-tools', component: () => <FieldNav /> },
    { path: '/hr-tools', component: () => <HRNav /> },
    { path: '/shop-timesheets', component: () => <ShopTimesheetPage /> },
    { path: '/office-timesheets', component: () => <OfficeTimesheetPage /> },
    { path: '/csv-tools', component: () => <CsvNav /> },
    { path: '/work-orders', component: () => <WorkOrdersPage /> },
    { path: '/404', component: () => <NotFound /> },
    { path: '/database', component: () => <AdminToolsPage account={currentUser} accountType={accountType} /> },
    { path: '/eng-assessment-h/:sysType/:removal/:name/:days/:hours/:flow/:ts/:ss/:ammNit/:tkNit/:pot/:phos/:vs/:sp/:roRecovery/:spRemoved/:units/:manure/:isProject/:runCarbonModel/:region/:livestockType/:numLivestock/:dryMatterIntake/:manureSeparation/:separationEfficiency/:anerobicTreatment/:baselineTreatment/:systemPower/:operationTime/:electricityRegion/:secondRO/:ro2Recovery/:spOutput20Percent/:additional_constituents/:additional_constituents_ro_rejections/:override_polymer_dose/:polymer_dose/:digestateKg', component: () => <EngineeringAssessmentReport /> },
    { path: '/client-assessment-report/:sysType/:removal/:name/:days/:hours/:flow/:ts/:ss/:ammNit/:tkNit/:pot/:phos/:vs/:sp/:roRecovery/:spRemoved/:units/:manure/:isProject/:runCarbonModel/:region/:livestockType/:numLivestock/:dryMatterIntake/:manureSeparation/:separationEfficiency/:anerobicTreatment/:baselineTreatment/:systemPower/:operationTime/:electricityRegion/:secondRO/:ro2Recovery/:spOutput20Percent/:additional_constituents/:additional_constituents_ro_rejections/:override_polymer_dose/:polymer_dose/:digestateKg', component: () => <ClientAssessmentReport /> },
    { path: '/weekly-report/:systemid/:startdate/:enddate/:units', component: () => <WeeklyReport /> },
    { path: '/', component: () => <MainDashboard account={currentUser} accountType={accountType} /> },
  ];
  
  useEffect(() => {
    console.log("new location: ", location.pathname);
  }, [location.pathname]);

  const generateRouteComponents = () => {
    return (
      <Switch>
        {routes.map((r) => {
          return <PrivateRoute path={r.path} component={r.component} />;
        })}
      </Switch>
    );
  };

  const Logo = () => {
    return (
      <Navbar.Brand href="/" style={{ padding: "10px" }}>
        <img
          src="https://www.livestockwaterrecycling.com/media/home/livestock-logo-img.webp"
          width="40"
          height="40"
          className="d-inline-block align-top"
          alt="LWR Logo"
        />
      </Navbar.Brand>
    );
  };

  return (
    <Router>
      <Navbar className="main-navbar" expand="sm" sticky="top">
        <Logo />
        <Navbar.Toggle aria-controls="lwr-nav" />
        <Navbar.Collapse id="lwr-nav">
          <div className="nav-content">
            <div className="lwr-nav-links">
              <LWRNav />
            </div>
            <NavButtonGroup
              currentUser={currentUser}
              accountType={accountType}
            />
          </div>
        </Navbar.Collapse>
      </Navbar>
      {generateRouteComponents()}
    </Router>
  );
}

const LWRNav = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { permission: accountType = [] } = usePermission(currentUser.email);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const isActivePath = (navPath) => {
    return navPath == "/"
      ? location.pathname == navPath
      : location.pathname.includes(navPath);
  };

  const pathToDisplay = (path) => {
    const parts = path.split("/").filter(Boolean);
    return (
      parts
        .map((part) => {
          const subParts = part.split("-").map(capitalize);
          return subParts.join(" ");
        })
        .join(" ") || "Dashboard"
    );
  };

  const getNavLinks = (permissions) => {
    const links = new Set();

    permissions.forEach((permission) => {
      const allowedLinks = navLinksByPermission[permission];
      allowedLinks.forEach((link) => links.add(link));
    });

    return Array.from(links).map((link, index) => {
      const linkText = pathToDisplay(link);
      return (
        <Nav.Link
          key={index}
          as={Link}
          to={link}
          style={
            isActivePath(link)
              ? { color: "#005E7D" }
              : { color: "grey" }
          }
        >
          {linkText}
        </Nav.Link>
      );
    });
  };

  return (
    <Nav className="lwr-nav">
      {getNavLinks(accountType)}
    </Nav>
  );
}
