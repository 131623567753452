import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getAllUser } from "../field-tools/fieldToolsFunctions";
import { CollapsingFilter } from "../field-tools/TimeReporting/ExistingTimeReport/ExistingTimeReportFilter/CollapsingFilter";
import { ExistingReportFilterByPerson } from "../field-tools/TimeReporting/ExistingTimeReport/ExistingTimeReportFilter/ExistingReportFilterByPerson";
import { FormGroupCreator } from "../field-tools/TimeReporting/FormGroupCreator";
import { getCurrentDate, getStartDate } from "./HRFunctions";
import ResultModal from "../../components/ResultModal";

export const HRTimesheetFilter = ({
  reportList,
  setFilteredReportList,
  children,
}) => {
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: getCurrentDate(),
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalTitleColor, setModalTitleColor] = useState("orange");

  // changes enddate to 10 workdays before enddate
  useEffect(() => {
    const newStartDate = getStartDate(filterDate.endDate);
    setFilterDate((filterDate) => ({
      ...filterDate,
      startDate: newStartDate,
    }));
  }, [filterDate.endDate]);

  // checks if the filter start date is after end date
  useEffect(() => {
    let _filteredTimesheetReportList = [...reportList];
    const properEndDate = new Date(filterDate.endDate);
    properEndDate.setDate(properEndDate.getDate() + 1);
    const properStartDate = new Date(filterDate.startDate);
    properStartDate.setDate(properStartDate.getDate() + 1);
    if (properStartDate <= properEndDate) {
      _filteredTimesheetReportList = _filteredTimesheetReportList.filter(
        (timesheet) =>
          new Date(timesheet.createdAt) > properStartDate &&
          new Date(timesheet.createdAt) < properEndDate
      );
      setFilteredReportList(_filteredTimesheetReportList);
    } else {
      setModalTitle("Error");
      setModalContent("Start Date should not be after End date!");
      setShowModal(true);
    }
  }, [filterDate]);
  // changes dropdown list based on email selected on filter
  const setDropDownUserList = (email) => {
    let _filteredReportList = [...reportList];
    if (email != "all") {
      _filteredReportList = _filteredReportList.filter(
        (report) => report.email == email
      );
    }
    setFilteredReportList(_filteredReportList);
  };
  // change timesheet list based on filter date
  const onDateChange = (value, valueType) => {
    const _filterDate = { ...filterDate };
    _filterDate[valueType] = value;
    setFilterDate(_filterDate);
  };

  return (
    <>
      <ResultModal title={modalTitle} content={modalContent} titleColor={modalTitleColor} showErrorModal={showModal} handleClose={() => setShowModal(false)} />
      <div className="w-100">
        <CollapsingFilter>
          <div className="">
            <Row className="mx-2">
              <Form>
                <ExistingReportFilterByPerson
                  allUsers={getAllUser(reportList)}
                  onChange={setDropDownUserList}
                />
              </Form>
            </Row>

            <Form>
              <Row className="mx-2 d-flex align-items-center">
                <Col md={2} className="text-center">
                  Created 
                </Col>
                <Col md={4}>
                  <FormGroupCreator
                    type="date"
                    valueDict={filterDate}
                    valueType="startDate"
                    changeValue={onDateChange}
                  />
                </Col>
                <Col md={4}>
                  <FormGroupCreator
                    type="date"
                    valueDict={filterDate}
                    valueType="endDate"
                    changeValue={onDateChange}
                  />
                </Col>
              </Row>
              {children}
            </Form>
          </div>
        </CollapsingFilter>
        <br />
      </div>
    </>
  );
};
