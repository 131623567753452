import { useState, useEffect, useRef, useCallback } from 'react';

/**
 * Custom hook for performing data fetching operations.
 * 
 * @param {string} queryKey - A unique key string that represents the query.
 * @param {Function} fetcher - A function that performs the data fetching operation. It should return a promise.
 * @param {number|null} refreshInterval - Optional. The interval in seconds at which to refetch the data. If null, data is fetched once.
 * @returns {Object} An object containing the following properties:
 *   - `data`: The data fetched by the `fetcher` function. Initially `null` until data is fetched successfully.
 *   - `error`: Any error that occurred during fetching. `null` if no error occurred.
 *   - `isLoading`: A boolean indicating if the fetch operation is in progress. `true` when fetching; `false` otherwise.
 *   - `refetch`: A function that lets you refetch the data
 * The hook implements caching to avoid refetching data that has already been fetched and stored in the cache.
 * If a `refreshInterval` is provided, the data will be refetched at the specified interval.
 */
const useQuery = (queryKey, fetcher, refreshInterval = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const cache = useRef({});
  const intervalId = useRef(null);

  // Wrapping fetchData in useCallback to ensure stability of the function reference
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    if (cache.current[queryKey]) {
      setData(cache.current[queryKey]);
      setIsLoading(false);
    } else {
      try {
        const response = await fetcher();
        cache.current[queryKey] = response;
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [queryKey, fetcher]);

  useEffect(() => {
    fetchData();

    if (refreshInterval) {
      intervalId.current = setInterval(fetchData, refreshInterval * 1000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [fetchData, refreshInterval]);

  // Expose fetchData as refetch for manual refetching
  const refetch = useCallback(() => {
    cache.current = {};
    fetchData();
  }, [fetchData]);

  return { data, error, isLoading, refetch };
};

export default useQuery;
