import { capitalizeFirstCharacter, parseKeyName } from "../fieldToolsFunctions";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

export const convertKeyNameToDisplayName = (key) => {
    if (key == key.toUpperCase()) return key;
    let capitalizeReplacedKey = parseKeyName(capitalizeFirstCharacter(key));
    return capitalizeReplacedKey.replace("No", "Number").trim();
};

export const getBooleanIcons = (value) => {
    if (typeof value == "boolean") {
        if (value == true) return <AiOutlineCheck className="text-success" />;
        else return <AiOutlineClose className="text-danger" />;
    } else return `${value}`;
};

export const returnCorrectValue = (value) => {
    return typeof value == "string" && value == ""
        ? "n/a"
        : getBooleanIcons(value);
};