/**
 * OfficeTimesheetForm.jsx
 * -----------------------
 * Component for creating, viewing, and editing office timesheets
 */

// libs
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState, useCallback } from 'react';
import { Card, Form, Row, Col, Button, Modal, Alert } from 'react-bootstrap';
import { RiPlayListAddLine } from 'react-icons/ri';
import { IoMdRemoveCircleOutline } from 'react-icons/io';

// api
import { getJobCodes } from '../../api/zoho';
import { createNewOfficeTimesheet, approveTimesheet, getOfficeTimesheet, getPayPeriods, getUserTasks, singleDownload, updateOfficeTimesheet } from "../../api/officeTimesheets";
import { getUserPermissionFromCache, isPermissionTypeHRAdmin } from '../../utility/permissionFunctions';
import { getUserData } from '../../api/admin';

// hooks
import { useAuth } from "../../contexts/AuthContext";

const OfficeTimesheetForm = ({ isViewMode, timeSheet, cancelView, onFinish }) => {
  // state
  const [timeSheetName, setTimesheetName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [jobNumberOptions, setJobNumberOptions] = useState([]);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [employeeInitials, setEmployeeInitials] = useState("");
  const [userType, setUserType] = useState([]);
  const [approved, setApproved] = useState(false);
  const [approvedBy, setApprovedBy] = useState(null);
  const [payPeriods, setPayPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [totals, setTotals] = useState([]);
  const [showResultModal, setShowResultModal] = useState(false);
  const [uploadResult, setUploadResult] = useState('true');
  const [taskRows, setTaskRows] = useState([{
    jobNumber: 'ENG R&D (Internal)',
    department: 'Engineering',
    task: null,
    hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }]);

  // consts
  const departments = ["Engineering", "Marketing", "Sales", "Chemistry", "Software Dev", "Production", "Purchasing"];
  const baseTasks = ['Vacation Pay', 'Sick Pay', 'Holiday Pay', 'Unpaid Time Off'];
  const [tasksByDepartment, setTasksByDepartment] = useState({
    "Engineering": [...baseTasks],
    "Marketing": [...baseTasks],
    "Sales": [...baseTasks],
    "Chemistry": [...baseTasks],
    "Software Dev": [...baseTasks],
    "Production": [...baseTasks],
    "Purchasing": [...baseTasks],
  })

  // effects
  useEffect(async () => {
    setUserType(await getUserPermissionFromCache(email));
    if (!isViewMode) {
      const { firstName, lastName, employeeNumber } = await getUserData(email);
      const employeeNameFromEmail = `${firstName} ${lastName}`
      setTimesheetName(`${employeeNameFromEmail} Office Timesheet`);
      setEmployeeNumber(employeeNumber);
    }
  }, []);

  // effect to load job numbers on component load
  useEffect(async () => {
    const jobNumberRes = await getJobCodes();
    setJobNumberOptions(jobNumberRes.map((job) => { return job.projectName }));
  }, []);

  // effect to fetch user-specific tasks for the dynamic dropdowns
  useEffect(async () => {
    const userTasks = await getUserTasks(email);
    const tts = Object.assign({}, tasksByDepartment);
    Object.entries(userTasks).forEach(([dep, tasks]) => {
      tasks.forEach((t) => {
        if(!tts[dep].includes(t)) {
          tts[dep].push(t);
        }
      })
    });
    setTasksByDepartment(tts);
  }, []);

  // effect to load pay periods on component load
  useEffect(async () => {
    const pps = await getPayPeriods();
    setPayPeriods(pps);
  }, []);

  // Effect to set state variables when viewing a timesheet
  useEffect(() => {
    if (isViewMode && timeSheet.jobList) {
      const ts = Object.assign({}, timeSheet);
      setTimesheetName(ts.timesheetName);
      setTaskRows(ts.jobList);
      setEmployeeNumber(ts.empNumber);
      setEmployeeInitials(ts.initials);
      setSelectedPeriod(true);
      setStartDate(new Date(ts.startDate));
      setEndDate(new Date(ts.endDate));
      setSelectedPeriod(ts.payPeriod);
      setApproved(ts.approved);
    }
  }, [timeSheet]);

  // Effect to reset form when editing is toggled
  useEffect(() => {
    if(!editMode && isViewMode) {
      const ts = Object.assign({}, timeSheet);
      setTimesheetName(ts.timesheetName);
      setTaskRows(ts.jobList);
      setEmployeeNumber(ts.empNumber);
      setEmployeeInitials(ts.initials);
      setSelectedPeriod(true);
      setStartDate(new Date(ts.startDate));
      setEndDate(new Date(ts.endDate));
      setSelectedPeriod(ts.payPeriod);
    }
  }, [editMode])

  useEffect(() => {
    setTotals(getTotals());
  }, [taskRows])

  // hooks
  const { email } = useAuth().currentUser;

  // helper functions
  const submitTimesheet = async (e) => {
    e.preventDefault();
    const ts = {
      email,
      officeTimesheet: {
        timesheetName: timeSheetName,
        totalHours: getTotals().reduce((a, b) => a + b, 0),
        startDate,
        endDate,
        empNumber: employeeNumber,
        jobList: taskRows,
        approved: false,
        approvedBy: '',
        initials: employeeInitials,
        payPeriod: selectedPeriod,
        zohoTimeEntryIds: [],
      },
    };

    // creating new timesheet scenario
    if (!editMode) {
      const uploadResult = await createNewOfficeTimesheet(ts);
      if (uploadResult.status === 200) {
        setUploadResult('Succesfully Uploaded Timesheet');
        setApproved(false);
        setApprovedBy(null);
      } else {
        if (uploadResult.response.data.includes('E11000')) {
          setUploadResult(`Error. There already exists a timesheet called: ${ts.officeTimesheet.timesheetName}`)
        } else {
          setUploadResult('Could Not Successfully Upload Timesheet. Please try again.')
        }
      }
    }

    // updating timesheet scenario
    if(editMode) {
      const body = {
        updatedTimesheet: {
          _id: timeSheet._id,
          timesheetName: timeSheetName,
          totalHours: getTotals().reduce((a, b) => a + b, 0),
          startDate,
          endDate,
          empNumber: employeeNumber,
          jobList: taskRows,
          approvedBy: '',
          initials: employeeInitials,
          payPeriod: selectedPeriod,
        },
        email: email
      };

      const updateResult = await updateOfficeTimesheet(body);
      if (updateResult.status === 200) {
        setUploadResult('Succesfully Updated Timesheet');
      } else {
        setUploadResult('Could Not Successfully Update Timesheet. Please try again.')
      }
      setEditMode(false);
    }

    setShowResultModal(true);
  };

  function getWeekdaysBetween(startDate, endDate) {
    const weekdays = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      if (currentDate.getDay() >= 1 && currentDate.getDay() <= 5) {
        weekdays.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weekdays.slice(0, 10);
  }

  const handleHoursChange = useCallback((e, taskIndex, hourIndex) => {
    const { value } = e.target;
    setTaskRows((prevTaskRows) => {
      const newTaskRows = [...prevTaskRows];
      newTaskRows[taskIndex].hours[hourIndex] = value ? parseFloat(value) : 0;
      return newTaskRows;
    });
  }, []);

  const getTotals = () => {
    const totals = new Array(10);
    for (let k = 0; k < totals.length; k++) { totals[k] = 0; }
    for (let i = 0; i < taskRows.length; i++) {
      for (let j = 0; j < taskRows[i].hours.length; j++) {
        totals[j] += taskRows[i].hours[j];
      }
    }

    return totals;
  };

  const approve = async (_id) => {
    const res = await approveTimesheet(_id, email);
    if (res.status == 200) {
      timeSheet = await getOfficeTimesheet(_id, email);
      setApproved(true);
      setApprovedBy(email);
      alert('Sucesfully approved timesheet');
    } else {
      alert('Encountered error approving timesheet.');
    }
  };

  const calculateTotalHours = () => {
    let runningCount = 0;
    for (let i = 0; i < taskRows.length; i++) {
      for (let j = 0; j < taskRows[i].hours.length; j++) {
        runningCount += taskRows[i].hours[j];
      }
    }
    return runningCount;
  };

  // components
  const ViewModeHeader = () => {
    return (
      <Row style={{ marginTop: '10px' }}>
        <Col style={{ textAlign: 'left' }}>
          <Button variant='outline-dark' onClick={cancelView}>Close Timesheet</Button>
        </Col>
        <Col style={{ textAlign: 'center' }}>
          {isViewMode && <h4 style={{ color: '#005E7D' }}>{timeSheet.timesheetName}</h4>}
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button variant='outline-success' onClick={async () => {
            await singleDownload(timeSheet._id);
          }}>Download Timesheet</Button>
          {timeSheet.email === email && <Button
            style={{ marginLeft: '1%' }}
            variant={!editMode ? 'outline-secondary' : 'secondary'}
            onClick={() => { setEditMode(!editMode) }}
          >
            {!editMode ? 'Edit Timesheet' : 'Cancel Edit'}
          </Button>}
        </Col>
      </Row>
    );
  };

  const AddRowButton = () => {
    return (
      <Button
        variant="outline-primary"
        className="w-100"
        onClick={() => {
          const tCopy = [...taskRows];
          tCopy.push({
            jobNumber: 'ENG R&D (Internal)',
            department: 'Engineering',
            task: null,
            hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          });
          setTaskRows(tCopy);
        }}>
        <RiPlayListAddLine size={25} />
      </Button>
    );
  };

  const RemoveRowButton = ({ indx }) => {
    return (
      <Button variant="outline-dark" className="w-100" disabled={taskRows.length <= 1} onClick={() => {
        setTaskRows(taskRows.filter((_, i) => i !== indx));
      }}>
        <IoMdRemoveCircleOutline size={25} />
      </Button>
    );
  };

  const TimeSheetHeader = () => {
    return (
      <Row style={{ marginTop: '0.5%' }}>
        <Col md={1}>
          <Form.Group>
            <Form.Control
              style={{ color: '#005e7d', fontWeight: 'bold' }}
              type="text"
              value={"Job Number"}
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={1}>
          <Form.Group>
            <Form.Control
              style={{ color: '#005e7d', fontWeight: 'bold' }}
              type="text"
              value={"Department"}
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Form.Control
              style={{ color: '#005e7d', fontWeight: 'bold' }}
              type="text"
              value={"Task"}
              disabled
            />
          </Form.Group>
        </Col>
        <Col md={8}>
          <Row>
            {
              getWeekdaysBetween(startDate, endDate).map((d) => <Col md={1}><Form.Group>
                <Form.Control
                  style={{ color: '#005e7d', fontWeight: 'bold' }}
                  type="text"
                  value={`${d.getMonth() + 1}/${d.getDate()}`}
                  disabled
                />
              </Form.Group>
              </Col>
              )
            }
            <Col md={(!isViewMode || editMode) ? 1 : 2}><Form.Group>
              <Form.Control
                type="text"
                style={{ fontWeight: 'bold', color: '#005E7D' }}
                value={"Total"}
                disabled
              />
            </Form.Group>
            </Col>
            {(!isViewMode || editMode) && <Col md={1} style={{ textAlign: 'center', color: '#005E7D' }}>
              <AddRowButton />
            </Col>}
          </Row>
        </Col>
      </Row>
    );
  };

  const TimesheetTotals = () => {
    return (
      <Row style={{ marginTop: '1%' }}>
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              style={{ fontWeight: 'bold', color: '#005E7D' }}
              disabled
              value="Time Totals"
            />
          </Form.Group>
        </Col>
        <Col md={8}>
          <Row>
            {
              totals.map((hours) => <Col md={1}>
                <Form.Group>
                  <Form.Control
                    style={{ fontWeight: 'bold', color: '#005E7D' }}
                    value={hours}
                    disabled
                  />
                </Form.Group>
              </Col>)
            }
            <Col md={(!isViewMode || editMode) ? 1 : 2}>
              <Form.Group>
                <Form.Control type="number" disabled style={{ fontWeight: 'bold', color: '#005E7D' }} value={calculateTotalHours()} />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const ApprovalStatus = () => {
    return <Alert variant={(timeSheet.approved || approved) ? 'success' : 'warning'} className='mt-3'>
      <Row>
        <div>
          Timesheet Approved: {(timeSheet.approved || approved) ? 'Yes' : 'No'}
        </div>
        <div>
          {((!timeSheet.approved && !approved) && (isPermissionTypeHRAdmin(userType))) &&
            <Button variant='outline-dark' onClick={() => { approve(timeSheet._id) }}>
              Approve Timesheet
            </Button>
          }
          {
            (timeSheet.approved || approved) &&
            <>
              {`Approved By: ${timeSheet.approvedBy || approvedBy}`}
            </>
          }
        </div>
      </Row>
    </Alert>
  }

  return (
    <div style={{ zoom: '90%' }} className="office-time">
      <Modal show={showResultModal} size="md">
        <Modal.Header>
          <Modal.Title>Upload Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Alert variant={uploadResult.includes('Error') ? 'danger' : 'success'}>{uploadResult}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {onFinish(); setShowResultModal(false);}}>Close</Button>
        </Modal.Footer>
      </Modal>
      {isViewMode && <ViewModeHeader />}
      {isViewMode && <ApprovalStatus />}
      <Card style={{ marginTop: '1%' }}>
        <Card.Body>
          <Form onSubmit={submitTimesheet} style={{ width: '100%' }}>
            {/* TIMESHEET INFO */}
            <>
              <Row style={{ marginTop: '0%' }}>
                <Col md={12} style={{ textAlign: 'left' }}>
                  <h5 style={{color: '#005e7d', fontWeight: 'bold'}}>Employee Information</h5>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 'bold' }}>Timesheet Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={timeSheetName}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 'bold' }}>Employee Number</Form.Label>
                    <Form.Control
                      type="number"
                      value={employeeNumber}
                      onChange={(e) => setEmployeeNumber(e.target.value)}
                      disabled={isViewMode && !editMode}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 'bold' }}>Employee Initial</Form.Label>
                    <Form.Control
                      type="text"
                      value={employeeInitials}
                      disabled={isViewMode && !editMode}
                      onChange={(e) => setEmployeeInitials(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label style={{ fontWeight: 'bold' }}>Pay Period</Form.Label>
                  <Select
                    options={payPeriods.map(pp => ({ label: `${pp.period} (${pp.startDate.split('T')[0]} - ${pp.endDate.split('T')[0]})`, value: pp.period }))}
                    value={selectedPeriod ? {value: selectedPeriod, label: selectedPeriod} : null}
                    isDisabled={isViewMode && !editMode}
                    placeholder={isViewMode && !editMode ? timeSheet.payPeriod : 'Select Pay Period'}
                    onChange={async (option) => {
                      if(!option) return;
                      setSelectedPeriod(option.value);
                      const target = payPeriods.find((p) => p.period === option.value);
                      const startDate = new Date(target.startDate.split('T')[0] + 'T00:00:00');
                      const endDate = new Date(target.endDate.split('T')[0] + 'T00:00:00');
                      setStartDate(startDate);
                      setEndDate(endDate);
                      const { firstName, lastName } = await getUserData(email);
                      const employeeNameFromEmail = `${firstName} ${lastName}`
                      setTimesheetName(`${employeeNameFromEmail} - ${target.period} - Office Timesheet`)
                    }}
                  />
                  </Form.Group>
                </Col>
              </Row>
            </>
            {!selectedPeriod && <Alert variant="primary" className="mt-4">Please Select a Pay Period</Alert>}
            {/* TIMESHEET BODY */}
            {selectedPeriod && <TimeSheetHeader />}
            {selectedPeriod &&
              taskRows.map((task, i) => {
                return <Row style={{ marginTop: '1%' }}>
                  <Col md={1}>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        required
                        disabled={isViewMode && !editMode}
                        value={task.jobNumber}
                        onChange={(e) => {
                          const newTasks = [...taskRows];
                          newTasks[i].jobNumber = e.target.value;
                          setTaskRows(newTasks);
                        }}
                      >
                        {jobNumberOptions.map((job) => <option value={job}>{job}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Group>
                      <Form.Control as="select"
                        required
                        disabled={isViewMode && !editMode}
                        value={task.department}
                        onChange={(e) => {
                          const tCopy = [...taskRows];
                          tCopy[i].department = e.target.value;
                          setTaskRows(tCopy);
                        }}>
                        {departments.map((d) => <option value={d}>{d}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <CreatableSelect
                        isClearable
                        required
                        placeholder="Select or type a new task"
                        options={tasksByDepartment[taskRows[i].department].map(task => ({ label: task, value: task }))}
                        value={taskRows[i].task ? { label: taskRows[i].task, value: taskRows[i].task } : null}
                        isDisabled={isViewMode && !editMode}
                        onChange={(option) => {
                          const tCopy = [...taskRows];
                          tCopy[i].task = option.label;
                          setTaskRows(tCopy);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Row>
                      {
                        task.hours.map((hours, hourIndex) => (
                          <Col md={1}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={hours}
                                disabled={isViewMode && !editMode}
                                min={0}
                                required
                                onChange={(e) => handleHoursChange(e, i, hourIndex)}
                              />
                            </Form.Group>
                          </Col>
                        ))
                      }
                      <Col md={(!isViewMode || editMode) ? 1 : 2}>
                        <Form.Group>
                          <Form.Control type="number" disabled value={task.hours.reduce((prev, curr) => prev + curr, 0)} />
                        </Form.Group>
                      </Col>
                      {(!isViewMode || editMode) && <Col md={1} style={{ textAlign: 'center', color: '#005E7D' }}>
                        <RemoveRowButton indx={i} />
                      </Col>}
                    </Row>
                  </Col>
                </Row>
              })
            }
            {selectedPeriod && <TimesheetTotals />}
            <Row className="mt-4">
            {(!isViewMode && selectedPeriod) &&
              <Col md={12}>
                <Button variant="outline-success" type="submit" className="w-100">
                  Submit Timesheet
                </Button>
              </Col>}
              {(isViewMode && editMode) && <Col md={12}>
                <Button variant="outline-warning" type="submit" className="w-100">
                  Update Timesheet
                </Button>
              </Col>}
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OfficeTimesheetForm;