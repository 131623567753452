import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { createNewReport, updateTimeReport } from "../../../../api/fieldTimeReport";
import { useAuth } from "../../../../contexts/AuthContext";
import { useHistory } from 'react-router-dom';

//Creates the reporting form that contains buttons such as next, submit and cancels, and all css related to it

export const FieldTimeReportingForm = (props) => {
  let { pageNumber, setPageNumber, disabled, newReport, editMode } = props;
  const { email } = useAuth().currentUser;
  const history = useHistory();

  async function nextPage() {
    setPageNumber((currPageNumber) => currPageNumber + 1);
  }

  async function prevPage() {
    setPageNumber((currPageNumber) => currPageNumber - 1);
  }

  function disableCancelButton() {
    if (pageNumber == 0) return { disabled: true };
  }

  function disableNextButton() {
    if (disabled == true) {
      return { disabled: true };
    }
  }

  async function submitForm() {
    createNewReport({ ...newReport, email })
      .then((res) => {
        if (res.status === 200) {
          alert("Report has been saved!")
        } else {
          alert('Error saving report, please try again. If the problem continues please contact the development team.');
        }
      })
  }

  async function submitButtonClicked(e) {
    e.preventDefault();
    if (pageNumber < 4) {
      nextPage();
    } else {
      if (editMode) {
        updateTimeReport({
          _id: newReport._id,
          updatedReport: newReport,
          email: email
        })
          .then(() => alert("Report has been updated!"))
          .catch(() =>
            alert("Time Report could not be updated, please try again.")
          );

          history.push('/field-tools/timesheets');
      } else {
        await submitForm();
        history.push('/field-tools/timesheets');
      }
    }
  }

  return (
    <Form onSubmit={(e) => submitButtonClicked(e)}>
      {props.children}
      <div className="d-flex justify-content-end">
        <div
          className="d-flex justify-content-between"
          style={{ width: "10%" }}
        >
          <Button
            {...disableCancelButton()}
            onClick={prevPage}
          >
            Back
          </Button>
          {pageNumber < 4 ? (
            <Button {...disableNextButton()} type="submit">
              Next
            </Button>
          ) : (
            <Button type="submit">{editMode ? 'Update' : 'Submit'}</Button>
          )}
        </div>
      </div>
    </Form>
  );
};
