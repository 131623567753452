import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FieldTimeReportingEditAmount } from "../FieldTimeReportingEditAmount";
import { returnCorrectValue } from "../TimeReportFunctions";
import { EditExistingTimeReportField } from "./EditExistingTimeReport/EditExistingTimeReportField";

export const ExistingTimeReportSubDictValues = ({
  returnMD,
  reportSubDict,
  keyName,
  subDictName,
  editIdList,
  setTimeSheet,
  timeSheet,
  md,
  multipleDict,
}) => {
  useEffect(() => {}, [editIdList]);
  const changeValue = (value, valueType) => {
    console.log(valueType);
    if (typeof reportSubDict[valueType.type] == "number") value = Number(value);
    reportSubDict[valueType.type] = value;
    // // reportSubDict[valueType.type] = value;
    let _timeSheet = { ...timeSheet };
    _timeSheet[subDictName] = _timeSheet[subDictName].map((value) =>
      valueType.id == reportSubDict._id ? reportSubDict : value
    );
    // console.log(_timeSheet[subDictName], subDictName, valueType.type);
    setTimeSheet(_timeSheet);

    // console.log(value, valueType);
  };
  return (
    <>
      {editIdList.includes(reportSubDict.newId) ? (
        keyName != "amount" ? (
          <Col
            className="border-bottom py-1"
            {...returnMD(reportSubDict[keyName], md)}
          >
            {/* {console.log(reportSubDict)} */}
            <EditExistingTimeReportField
              valueDict={reportSubDict}
              valueType={keyName}
              type={typeof reportSubDict[keyName]}
              setTimeSheet={setTimeSheet}
              timeSheet={timeSheet}
              subDictName={subDictName}
              multipleDict={multipleDict}
            />
          </Col>
        ) : (
          <Col className="border-bottom py-1">
            <Row>
              <FieldTimeReportingEditAmount
                changeValue={changeValue}
                id={reportSubDict._id || reportSubDict.newId}
                valueDict={reportSubDict}
              />
            </Row>
          </Col>
        )
      ) : keyName != "amount" ? (
        <Col
          className="border-bottom py-1"
          {...returnMD(reportSubDict[keyName], md)}
        >
          {returnCorrectValue(reportSubDict[keyName])}
        </Col>
      ) : (
        <Col className="border-bottom py-1">
          {returnCorrectValue(reportSubDict[keyName]) +
            ` ${reportSubDict.currencyType}`}
        </Col>
      )}
    </>
  );
};
