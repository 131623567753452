/**
 * OrganizationsEditModal.jsx
 * ----------------------------
 * Modal component that contains functionality for editing organizations into the LWR database.
 * This data insertion is done via a PUT request to the LWR API.
 *
 * Contains edit form and assosciated validation.
 *
 * Author: Oscar Jaimes
 */

import React, { useState, useRef } from "react";
import { Modal, Button, Alert, Form, Row, Col } from "react-bootstrap";

// api
import { updateOrganization } from "../../../api/organizations";

export default function OrganizationDeleteModal(props) {
  // State
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const originalOrgName = props.org.name;

  // Refs
  const orgNameRef = useRef();
  const contactNameRef = useRef();
  const contactNumberRef = useRef();
  const contactEmailRef = useRef();
  const addressRef = useRef();

  // Funcs
  const handleClose = () => {
    setShow(false);
    setErr("");
    setSuccess("");
    props.onEnd();
  };

  const editOrg = async (e) => {
    e.preventDefault();
    const bodyValues = {
      address: addressRef.current.value,
      name: originalOrgName,
      contact: contactNameRef.current.value,
      contactNumber: contactNumberRef.current.value,
      contactEmail: contactEmailRef.current.value,
    };

    const editResult = await updateOrganization(bodyValues);
    if (editResult.status != 200) {
      setErr("Error updating organization");
    } else {
      setSuccess(
        "Succesfully updated organization"
      );
    }
  }


  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="lg" style={{ zoom: '85%', marginTop: '4%' }}>
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>View/Edit Organization - {props.org.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {err && <Alert variant="danger">{err}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
          </div>
          <div>
            <Form onSubmit={editOrg}>
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Organization Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter org name"
                  ref={orgNameRef}
                  defaultValue={props.org.name}
                  disabled={true}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Main Contact</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter contact name"
                  ref={contactNameRef}
                  defaultValue={props.org.contact}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Contact Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter contact Email"
                  ref={contactEmailRef}
                  defaultValue={props.org.contactEmail}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Contact Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter contact number"
                  ref={contactNumberRef}
                  defaultValue={props.org.contactNumber}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label style={{ color: '#005E7D' }}>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter org address"
                  ref={addressRef}
                  defaultValue={props.org.address}
                  required
                />
              </Form.Group>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-dark" type="submit">
                    Update
                  </Button>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
