/**
 * EditModal.jsx
 * -------------
 * Editing component for Assessment Report input data.
 * 
 * Auhtor: Oscar Jaimes
 */

import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Alert } from "react-bootstrap";
import { createAssessmentEditTemplate } from "../TableComponents/TableComponentExample";
import { HiOutlinePencil } from "react-icons/hi";
import { validateAssessmentInputs } from "../../assessmentValidation";

export const EditModal = ({ params, inputData }) => {
  // state
  const [show, setShow] = useState(false);
  const [content, setContent] = useState({});

  // effects
  useEffect(() => {
    const dissolvedSolids = inputData["Disolved Solids"];
    const inletContent = createAssessmentEditTemplate(params, inputData, dissolvedSolids);
    setContent(inletContent);
  }, [inputData]);

  // form handlers
  // updates key value pairs
  const updateAssessmentKV = (e, k, apiIdentifier) => {
    const isCheckbox = e.target.type === "checkbox";
    const value = isCheckbox ? e.target.checked : e.target.value;
    setContent(prevContent => ({
      ...prevContent,
      [k]: {
        value: value,
        key: apiIdentifier
      }
    }));
  };

  // handles changes, creates new report in API, then opens the new report
  const submitChanges = (e) => {
    e.preventDefault();
    const newVals = {};
    Object.keys(content).forEach((k) => {
      const curr = content[k];
      newVals[curr.key] = curr.value;
    });

    // Validation for Assesment Edit Inputs 
    if (!validateAssessmentInputs({
      ...newVals,
      ts: newVals.totalSolids,
      ss: newVals.suspendedSolids,
      aNitrogen: newVals.ammoniumNitrogen,
      vs: newVals.volatileSolids,
      sysType: newVals.isFirstWave ? 'First Wave' : 'Full System'
    })) { return; }

    // create new URL
    const urlParts = [
      'eng-assessment-h',
      newVals.isFirstWave ? 'First Wave' : 'Full System',
      newVals.removal,
      newVals.name,
      newVals.operatingDays,
      newVals.operatingHours,
      newVals.annualFlow,
      newVals.totalSolids,
      newVals.suspendedSolids,
      newVals.ammoniumNitrogen,
      newVals.tkn,
      newVals.potassium,
      newVals.phosphorus,
      newVals.volatileSolids,
      newVals.screwPressLoop,
      newVals.roRecovery,
      !newVals.screwPressRemoved,
      params.units,
      params.manure,
      params.isProject,
      params.runCarbonModel,
      params.region,
      params.livestockType,
      params.numLivestock,
      params.dryMatterIntake,
      params.manureSeparation,
      params.separationEfficiency,
      params.anerobicTreatment,
      params.baselineTreatment,
      params.systemPower,
      params.operationTime,
      params.electricityRegion,
      newVals.secondRO,
      newVals.ro2Recovery,
      newVals.spOutput20Percent,
      params.additional_constituents,
      params.additional_constituents_ro_rejections,
      newVals.override_polymer_dose,
      newVals.polymer_dose,
      params.digestateKg
    ].map((x) => {
      // dont uri encode these objects
      if(x == params.additional_constituents || x == params.additional_constituents_ro_rejections) {
        return x;
      }
      return encodeURIComponent(x);
    }).join('/');

    console.log(params);

    const url = `${urlParts}`;
    const newWindow = window.open('/' + url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  // input groupings
  const textInputs = [];
  const checkboxes = [];

  Object.entries(content).forEach(([key, val]) => {
    if (val.value !== true && val.value !== false) {
      textInputs.push(
        <Col md={6} key={key} style={{ marginTop: '1%' }}>
          <Form.Group>
            <Form.Label>{key}</Form.Label>
            <Form.Control
              type="text"
              defaultValue={val.value}
              onChange={(e) => updateAssessmentKV(e, key, val.key)}
            />
          </Form.Group>
        </Col>
      );
    } else {
      checkboxes.push(
        <Col md={6} key={key} style={{ marginTop: '1%' }}>
          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={content[key].value}
              label={key}
              onChange={(e) => updateAssessmentKV(e, key, val.key)}
            />
          </Form.Group>
        </Col>
      );
    }
  });

  return (
    <>
      <HiOutlinePencil onClick={() => setShow(true)} size={24} />
      <Modal show={show} onHide={() => setShow(false)} size={"xl"} style={{ zoom: '90%' }}>
        <Form onSubmit={submitChanges}>
          <Modal.Header>
            <Modal.Title>Edit Assessment Input Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Alert variant="info">
                  A new version of the report will open in a new tab. Save the new report to the database if you wish to keep it.
                </Alert>
              </Col>
            </Row>
            <Row>
              {textInputs}
              {checkboxes}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="outline-dark" type="submit">
              Create New Report
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
