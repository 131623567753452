import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { NewHRSubHeading } from "../../NewHRPolicies/NewHRSubHeading";
import Select from "react-select";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import { updatePolicy } from "../../../../../api/hrPolicy.js";
import { getAllUserEmail } from "../../../../../api/admin";

export const EditExistingPolicy = ({ learnMorePolicy, setUpdateReport }) => {
  const { email } = useAuth().currentUser;
  const { REACT_APP_LWR_WEB_PORTAL_BASE_URL } = process.env;
  const subHeadingExample = {
    id: uuidv4(),
    subHeaderName: null,
    firstPage: 0,
    lastPage: 0,
  };

  const [newSubHeadingList, setNewSubHeadingList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [newEmailList, setNewEmailList] = useState([]);
  const [sendEmailToAllNewUsers, setSendEmailToAllNewUsers] = useState(true);
  const createCorrectUserEmail = (email) => {
    return {
      value: email,
      label: email,
    };
  };

  useEffect(async () => {
    let _newSubHeadingList = [...learnMorePolicy.subHeadersList];
    _newSubHeadingList = _newSubHeadingList.map((subHeading) => ({
      ...subHeading,
      id: uuidv4(),
    }));
    setNewSubHeadingList(_newSubHeadingList);
    setNewEmailList(
      learnMorePolicy.assignedTo.map((email) => createCorrectUserEmail(email))
    );
    const allUserEmailList = await getAllUserEmail(email);
    const getCorrectEmailValues = allUserEmailList
      .sort()
      .map((email) => createCorrectUserEmail(email));
    setEmailList(getCorrectEmailValues);
  }, [learnMorePolicy]);

  const updatePolicyFunction = (e) => {
    e.preventDefault();
    let newLearnMorePolicy = { ...learnMorePolicy };
    newLearnMorePolicy = {
      ...newLearnMorePolicy,
      assignedTo: newEmailList.map((email) => email.value),
      subHeadersList: newSubHeadingList,
    };

    const bodyVal = {
      updatedPolicyReport: newLearnMorePolicy,
      emailOnlyNewUser: sendEmailToAllNewUsers,
      noIDUrl: `${REACT_APP_LWR_WEB_PORTAL_BASE_URL}hr-tools/hr-policies/existingHRPolicies/`,
    };

    updatePolicy(bodyVal).then((res) => {
      alert(
        res.status == 200
          ? "Policy has beed successfully updated!"
          : "Policy could not be updated, please try again later!"
      );
      setUpdateReport((updateReport) => !updateReport);
    });
  };
  const changeCheckValue = () => {
    setSendEmailToAllNewUsers(!sendEmailToAllNewUsers);
  };

  const findIsChecked = () => {
    return sendEmailToAllNewUsers == true ? { checked: true } : {};
  };
  return (
    <Form onSubmit={updatePolicyFunction}>
      {!sendEmailToAllNewUsers ? (
        <div className="">
          <h4 className="text-center">Edit Sub Headings</h4>

          <NewHRSubHeading
            valueDict={newSubHeadingList}
            valueDictExample={subHeadingExample}
            setValueDict={setNewSubHeadingList}
          />
          <br />
        </div>
      ) : null}

      <h4 className="text-center">Edit User Assigned To Policy</h4>

      <Row>
        <Select
          isMulti
          options={emailList}
          onChange={(e) => setNewEmailList(e)}
          value={newEmailList}
        />
      </Row>
      <br />
      <div className="d-flex justify-content-start">
        <Form.Label className="pe-4">Email Only New Users</Form.Label>
        <Form.Check {...findIsChecked()} onChange={changeCheckValue} />
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit">Update Policy</Button>
      </div>
    </Form>
  );
};
