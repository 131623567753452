/**
 * NewsSection.jsx
 * ---------------
 * News component for application. Loads all news from GitHub repo and populates cards with links.
 * 
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */
import React, { useEffect, useState } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
const NEWS_API_URL = 'https://livestockwaterrecycling.github.io/lwr-news-scraper/featured.json';
export default function NewsSection() {

  const [newsElements, setNewsElements] = useState([]);

  useEffect(() => {
    fetch(`${NEWS_API_URL}`)
      .then(res => res.json())
      .then(res => {
        createCardElements(res);
      });
  }, []);

  const capitalize = (str) => {
    let arr = str.toLowerCase().split(" ");
    let upperArr = []
    arr.forEach(word => {
      if (word.length > 0) {
        upperArr.push(word[0].toUpperCase() + word.slice(1));
      } else {
        upperArr.push("");
      }
    });
    return upperArr.join(" ");
  }

  const createCardElements = (news) => {
    setNewsElements(news.slice(0, 4).map(entry => {
      const truncateContent = (content, maxLength) => {
        if (content.length > maxLength) {
          return content.substring(0, maxLength) + '...';
        }
        return content;
      };

      return (
        <Col md={3} style={{ paddingTop: '5px' }}>
          <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Card.Title style={{
              padding: "20px",
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              position: 'relative',
            }}>
              {capitalize(entry.title)}
            </Card.Title>
            <Card.Body className="card-body d-flex flex-column" style={{ flex: 1 }}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                {truncateContent(entry.content, 400)}
              </div>
              <Button onClick={() => window.open(entry.link, "_blank")} style={{ borderRadius: "10px", marginTop: '4%', marginRight: 'auto', alignSelf: 'start' }} variant="outline-dark">View Article</Button>
            </Card.Body>
          </Card>
        </Col>
      );
    }))
  };

  return (
    <>
      {newsElements}
    </>
  )
}
