import React, { useEffect, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import {
  deleteShopTimesheet,
  getAllShopTimesheet,
} from "../../../../api/hrShopTimeSheet";
import { useAuth } from "../../../../contexts/AuthContext";
import { getProperTime } from "../../../field-tools/fieldToolsFunctions";
import { AiOutlineDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { DeleteModal } from "../../../DeleteModal";
import { HRTimesheetFilter } from "../../HRTimesheetFilter";
import { ExistingHRShopTimesheetDateFilter } from "./ExistingHRShopTimesheetDateFilter";
import ResultModal from "../../../../components/ResultModal";

// Shows all shop timesheet
export const ExistingHRShopTimesheetList = () => {
  const { email } = useAuth().currentUser;
  const history = useHistory();
  const [allShopTimesheet, setAllShopTimesheet] = useState([]);
  const [allFilteredShopTimesheet, setAllFilteredShopTimesheet] = useState([]);
  const [show, setShow] = useState(false);
  const [reportToBeDeleted, setReportToBeDeleted] = useState(null);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalTitleColor, setModalTitleColor] = useState("orange");

  // gets all shoptimesheet
  useEffect(async () => {
    const shopTimesheet = await getAllShopTimesheet(email);
    setAllShopTimesheet(shopTimesheet);
    setAllFilteredShopTimesheet(shopTimesheet);
  }, []);

  // nagivates the user to the proper url when timesheet is selected
  const navigateUser = (id) => {
    history.push(`/hr-tools/hr-timesheet/existingShopTimesheet/${id}`);
  };

  // delete function for the modal popup
  const deleteTimesheetModal = (_id) => {
    deleteShopTimesheet(_id, email).then(async (res) => {
      if (res.status == 200) {
        const newAllShopTimesheet = allShopTimesheet.filter(
          (shopTimesheet) => shopTimesheet._id != _id
        );
        setAllShopTimesheet([...newAllShopTimesheet]);
        setAllFilteredShopTimesheet([...newAllShopTimesheet]); // refresh the filtered list
        setModalTitle("Success");
        setModalContent("Shop timesheet has been successfully deleted.");
        setModalTitleColor("green");
        setShowModal(true);
      } else {
        setModalTitle("Error");
        setModalContent("Shop timesheet could not be deleted, please try again later.");
        setModalTitleColor("orange");
        setShowModal(true);
      }
    });
    setReportToBeDeleted(null);
  };

  // shows the modal and selects the report to be deleted
  const deleteTimesheet = (report) => {
    setReportToBeDeleted(report);
    setShow(true);
  };

  return (
    <>
      <ResultModal title={modalTitle} content={modalContent} titleColor={modalTitleColor} showErrorModal={showModal} handleClose={() => setShowModal(false)} />
      <HRTimesheetFilter
        reportList={allShopTimesheet}
        setFilteredReportList={setAllFilteredShopTimesheet}
      >
        <ExistingHRShopTimesheetDateFilter
          allShopTimesheet={allShopTimesheet}
          setAllFilteredShopTimesheet={setAllFilteredShopTimesheet}
        />
      </HRTimesheetFilter>

      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col className="col-5 col-md-5 d-flex align-items-center justify-content-center">
              Timesheet Name
            </Col>
            <Col className="col-5 col-md-5 d-flex align-items-center justify-content-center">
              Created at
            </Col>
          </Row>
        </ListGroupItem>
        {allFilteredShopTimesheet.map((shopTimesheet) => (
          <ListGroupItem>
            <Row>
              <Col
                className="col-5 col-md-5 d-flex align-items-center justify-content-center"
                onClick={() => navigateUser(shopTimesheet._id)}
              >
                {shopTimesheet.shopTimesheetName}
              </Col>
              <Col
                className="col-5 col-md-5 d-flex align-items-center justify-content-center"
                onClick={() => navigateUser(shopTimesheet._id)}
              >
                {getProperTime(shopTimesheet.createdAt)}
              </Col>
              <Col
                className="col-2 col-md-2 d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <AiOutlineDelete
                  onClick={() => {
                    deleteTimesheet(shopTimesheet);
                  }}
                />
                {reportToBeDeleted && (
                  <DeleteModal
                    show={show}
                    setShow={setShow}
                    deleteFunction={deleteTimesheetModal}
                    report={reportToBeDeleted}
                    titleName={shopTimesheet.shopTimesheetName}
                    id={reportToBeDeleted._id}
                  />
                )}
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};
