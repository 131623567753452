import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import "quill/dist/quill.snow.css";
import { ExistingFieldEODReportComponent } from "./ExistingFieldEODReportComponent";
import { getEODReportByID } from "../../../../api/fieldEODReport";

// Contains the components and values regarding the existing EOD report
export const ExistingFieldEODReport = ({
  setIsReportList,
  learnMoreReportId,
}) => {
  const { email } = useAuth().currentUser;
  const [cancelChanges, setCancelChanges] = useState(false);
  const [report, setReport] = useState(null);

  // get the EOD report of the user from database
  useEffect(async () => {
    const response = await getEODReportByID(learnMoreReportId, email);
    response.date = response.date.split("T")[0];
    setReport(response);
    setCancelChanges(false);
  }, [cancelChanges]);

  return (
    <div>
      {report != null && (
        <ExistingFieldEODReportComponent
          report={report}
          setReport={setReport}
          setIsReportList={setIsReportList}
          setCancelChanges={setCancelChanges}
          cancelChanges={cancelChanges}
        />
      )}
    </div>
  );
};
