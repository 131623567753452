import React from 'react'
import { Chart } from "react-google-charts";

export default function OperatingHoursGraph(props) {
    return (
        <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={props.operatinghoursdata}
            options={{
                hAxis: { format: 'dd/MM/yy' },
                seriesType: 'bars',
                series: {
                    0: { targetAxisIndex: 0, color: "#add8e6" },
                },
                hAxis: { format: 'dd/MM/yy' },
                titleTextStyle: {
                    fontFamily: 'Arial',
                    fontSize: 15, // 12, 18 whatever you want (don't specify px)
                    bold: true,    // true or false
                    italic: false   // true of false
                },
                title: "Operating Hours per Day\n",
                bar: { groupWidth: "50%" },
                height: 450,
                vAxis: {
                    title: 'Hours',
                    minValue: 0,
                    max: 24,
                    ticks: [0, 4, 8, 12, 16, 20, 24],
                },
                chartArea: {
                    width: '75%',
                    height: '75%'
                },
                legend: { position: 'top', alignment: 'start' }
            }}
        />
    )
}
