import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { UploadNewFiles } from "../../../field-tools/FieldExpenses/UploadNewFiles";
import { NewHRSubHeading } from "./NewHRSubHeading";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { AiFillFilePdf, AiOutlineClose } from "react-icons/ai";
import { useAuth } from "../../../../contexts/AuthContext";
import { createNewHRPolicy } from "../../../../api/hrPolicy";
import { getAllUserEmail } from "../../../../api/admin";

// Component to create new HR policy
export const NewHRPolicy = () => {
  const { REACT_APP_LWR_WEB_PORTAL_BASE_URL } = process.env;
  const [allUserEmail, setAllUserEmails] = useState([]);
  const { email } = useAuth().currentUser;
  // sub heading example to be appended to list
  const subHeadingExample = {
    // id is there to identify each subheading
    id: uuidv4(),
    title: "",
    firstPage: 0,
    lastPage: 0,
  };
  const [newPolicy, setNewPolicy] = useState({
    uploadedPDF: null,
    policyName: "",
    emailList: [],
  });

  const [subHeadings, setSubHeading] = useState([]);

  // converts email to something that can be selected
  const createCorrectUserEmail = (email) => {
    return {
      value: email,
      label: email,
    };
  };

  // get all email registered to lwr and makes them selectable
  useEffect(async () => {
    const allUserEmailList = await getAllUserEmail(email);
    const getCorrectEmailValues = allUserEmailList
      .sort()
      .map((email) => createCorrectUserEmail(email));

    setAllUserEmails(getCorrectEmailValues);
    setNewPolicy((newPolicy) => ({
      ...newPolicy,
      emailList: getCorrectEmailValues,
    }));
  }, []);

  // on form submitted
  const onFormSubmit = async (e) => {
    e.preventDefault();
    // create form data with policy file and corret body values
    const formData = new FormData();
    formData.append("pdfFile", newPolicy.uploadedPDF);
    formData.append("policyName", newPolicy.policyName);
    formData.append(
      "noIDUrl",
      `${REACT_APP_LWR_WEB_PORTAL_BASE_URL}hr-tools/hr-policies/existingHRPolicies/`
    );
    const parseSubHeading = subHeadings.map((subHeading) => ({
      subHeaderName: subHeading.title,
      firstPage: subHeading.firstPage,
      lastPage: subHeading.lastPage,
    }));
    formData.append("subHeadersList", JSON.stringify(parseSubHeading));
    // change email list to just array of email to be stored
    const getProperEmailValues = newPolicy.emailList.map(
      (emailDict) => emailDict.value
    );
    formData.append("emailList", JSON.stringify(getProperEmailValues));

    createNewHRPolicy(formData).then((res) =>
      alert(
        res.status == 200
          ? "Policy has been successfuly created!"
          : "Could not create policy, please try again later."
      )
    );
  };

  // change value in policy form
  const changeValue = (value, valueType) => {
    setNewPolicy((newPolicy) => ({ ...newPolicy, policyName: value }));
  };

  // disable submit button if no file is uploaded
  const disableSubmitButton = () => {
    return !newPolicy.uploadedPDF ? { disabled: true } : {};
  };

  // remove file from input
  const removeFile = () => {
    setNewPolicy((newPolicy) => ({ ...newPolicy, uploadedPDF: null }));
  };

  return (
    <Form onSubmit={onFormSubmit}>
      <FormGroupCreator
        label="Policy Name"
        valueDict={newPolicy}
        valueType="policyName"
        changeValue={changeValue}
      />
      <br />
      <h3 className="text-center pt-4">Upload File</h3>
      {!newPolicy.uploadedPDF ? (
        <UploadNewFiles
          onChange={(file) => {
            setNewPolicy((newPolicy) => ({
              ...newPolicy,
              uploadedPDF: file[0],
            }));
          }}
          title="Upload PDF"
          acceptType="application/pdf"
          multiple={false}
        />
      ) : (
        <div className=" d-flex justify-content-center align-items-center">
          <div className="border rounded px-3 py-2">
            <AiFillFilePdf color="red" size={"25px"} />
            <span className="ps-1 pe-4">{newPolicy.uploadedPDF.name}</span>
            <AiOutlineClose
              onClick={removeFile}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      )}
      <h3 className="text-center pt-4">SubHeaders</h3>
      <NewHRSubHeading
        valueDict={subHeadings}
        setValueDict={setSubHeading}
        valueDictExample={subHeadingExample}
        textFieldLength={6}
      />
      <h3 className="text-center pt-4">Assign To</h3>
      <Row>
        <Select
          isMulti
          options={allUserEmail}
          onChange={(e) =>
            setNewPolicy((newPolicy) => ({ ...newPolicy, emailList: e }))
          }
          value={newPolicy.emailList}
        />
      </Row>
      <br />
      <div className="d-flex justify-content-center">
        <Button {...disableSubmitButton()} type="submit">
          Create HR Policy
        </Button>
      </div>
      {!newPolicy.uploadedPDF ? (
        <div className="text-danger text-center">
          Please upload a policy file to continue
        </div>
      ) : null}
    </Form>
  );
};
