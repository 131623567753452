import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PolicyDocument.css";

export const HRPDFRenderer = ({
  blobData,
  onDocumentLoadSuccess,
  pageNumber,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <Document file={blobData} onLoadSuccess={onDocumentLoadSuccess}>
        <Page scale={120 / 72} pageNumber={pageNumber} />
      </Document>
    </div>
  );
};
