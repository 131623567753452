import React, { useState } from "react";
import { Col, ListGroupItem, Row } from "react-bootstrap";
import { getProperTime } from "../../../field-tools/fieldToolsFunctions";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { useEffect } from "react";

// Displays the timesheet value
export const ExistingHRShopTimesheetValue = ({
  valueDict,
  valueType,
  label,
  date = false,
  changeValue,
  deleteButton = false,
  deleteFunction = () => {},
  newJobCode = false,
  cancelChanges,
}) => {
  const [editValue, setEditValue] = useState(newJobCode);

  // when cancel button is clicked, reset all values
  useEffect(() => {
    setEditValue(false);
  }, [cancelChanges]);

  const actualType = valueType.type ? valueType.type : valueType;

  return (
    <ListGroupItem>
      {!editValue ? (
        <Row>
          <Col className="col-4">{label}</Col>
          <Col className="col-6 col-md-7">
            {date
              ? "" + valueDict[actualType]
              : typeof valueDict[actualType] == "string"
              ? valueDict[actualType] || "n/a"
              : valueDict[actualType]}
          </Col>
          <Col className="col-2 col-md-1 p-0 d-flex justify-content-end align-items-center">
            <AiOutlineEdit onClick={() => setEditValue(true)} />
            {deleteButton ? (
              <AiOutlineDelete
                onClick={() => deleteFunction(valueType)}
                className="ms-2"
              />
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="col-10 col-md-11">
            <FormGroupCreator
              label={label}
              valueDict={valueDict}
              valueType={valueType}
              changeValue={changeValue}
              type={date ? "date" : typeof valueDict[actualType]}
              min={0}
            />
          </Col>
          {deleteButton ? (
            <Col className="col-2 col-md-1 d-flex align-items-center justify-content-end">
              <AiOutlineDelete
                onClick={() => deleteFunction(valueType)}
                style={{ minWidth: "20px" }}
              />
            </Col>
          ) : null}
        </Row>
      )}
    </ListGroupItem>
  );
};
