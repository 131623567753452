/**
 * IotTab.jsx
 * ------------
 * Component that contains all CRUD functionality for IoT Devices in the LWR database.
 * Renders a table with IoT Device entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form, Button } from "react-bootstrap";
// CRUD modals
import IoTInsertModal from "./IoTInsertModal";
import IoTDeleteModal from "./IoTDeleteModal";
import IoTEditModal from "./IoTEditModal";
import { getUserPermissionFromCache } from "../../../utility/permissionFunctions";

// api
import { getAllIotDevices } from "../../../api/iot";

const IotTab = (props) => {
  const [deviceTableElements, setDeviceTableElements] = useState([]);
  const [devices, setDevices] = useState([]);
  const [userType, setUserType] = useState([]);
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "Systems - Admin Tools";
    fetchAndCreateSystemElements();
    setUserType(await getUserPermissionFromCache(props.account.email));
  }, []);

  const fetchAndCreateSystemElements = async () => {
    searchRef.current.value = "";
    const allDevices = await getAllIotDevices();
    let deviceElements = createDeviceTableElements(allDevices);
    setDeviceTableElements(deviceElements);
    setDevices(allDevices);
  };

  const createDeviceTableElements = (devices) => {
    return devices.map((device) => (
      <tr>
        <td>{device.linking_id}</td>
        <td>{device.key}</td>
        {/* <td>
          <IoTEditModal onEnd={fetchAndCreateSystemElements} device={device} />
        </td>
        <td>
          <IoTDeleteModal
            onEnd={fetchAndCreateSystemElements}
            device={device}
          />
        </td> */}
      </tr>
    ));
  };

  const onChangeSearch = () => {
    let validDevices = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();

    devices.forEach((dev) => {
      if (dev.linking_id.toLowerCase().includes(currentSearchVal)) {
        validDevices.push(dev);
      }
    });

    let newTableElements = createDeviceTableElements(validDevices);
    setDeviceTableElements(newTableElements);
  };

  return (
    <div style={{ margin: '10px' }}>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          {<IoTInsertModal onEnd={fetchAndCreateSystemElements} />}
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Linking Id</th>
              <th>Authentication Key</th>
              {/* <th>Edit</th>
              {<th>Delete</th>} */}
            </tr>
          </thead>
          <tbody>{deviceTableElements}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default IotTab;
