import React from 'react'
import { Chart } from "react-google-charts";

export default function RecoveryGraph(props) {
    return (
        <Chart
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={props.recoverydata}
            options={{
                backgroundColor: {fill: "transparent"},
                chartArea: {width: "75%", height: "75%"},
                hAxis: {format: "dd/MM/yy"},
                height: 450,
                legend: {position: "top"},
                series: {0: {targetAxisIndex: 0, color: "#AE5153"}, 1: {targetAxisIndex: 0, color: "#528391", type: "line"}},
                seriesType: "line",
                title: "Screen Recovery & Phosphorous Removal vs Date",
                titleTextStyle: {fontFamily: "Arial", fontSize: 15, bold: true, italic: false},
                vAxes: {},
                vAxis: {title: "%"},
            }}
        />
    )
}
