/**
 * spreadSheetUtils.js
 * --------------------
 * Helper functions to interact with the excel-like grid shown
 * on the main Lab Data dashboard.
 * 
 * Author: Oscar Jaimes
 */

/**
 * Returns formatted columns for the excel-lke grid
 */
const getColumns = (labData) => {
  if (labData.length === 0) { return; }
  return Object.keys(labData[0])
    .filter((key) => !['_id', 'isOpen', '__v'].includes(key))
    .map((key) => ({ columnId: key, width: 200 }))
}

/**
 * Returns formatted rows for the excel-like grid
 */
const getRows = (labData, mappings) => {
  if (labData.length === 0 || mappings.length === 0) { return; }
  return [
    {
      rowId: "header",
      cells: Object.keys(labData[0])
        .filter((key) => !['_id', 'isOpen', '__v'].includes(key))
        .map((key) => ({ type: "header", text: key, background: 'blue', data: { isHeader: true } }))
    },
    ...labData.map((ld) => ({
      rowId: ld._id,
      cells: Object.keys(ld).filter((key) => !['_id', 'isOpen', '__v'].includes(key)).map((key) => {
        if (mappings[key].type === 'text') {
          return { type: "text", text: String(ld[key]) };
        }
        if (mappings[key].type === 'dropdown') {
          return {
            type: "dropdown",
            selectedValue: String(ld[key]),
            inputValue: String(ld[key]),
            values: mappings[key]['options'].map((o) => ({ label: o, value: o })),
            isOpen: ld.isOpen[key]
          };
        }
        if (mappings[key].type === 'number') {
          return { type: "number", value: Number(ld[key]) };
        }
        if (mappings[key].type === 'date') {
          return { type: "date", date: new Date(ld[key]) || new Date('01/01/1970') };
        }
      })
    }))
  ];
}

/**
 * Handles changes as they occur in the excel-like grid.
 * This functions is reponsible for handling all data updates in state.
 */
const applyChangesToLabData = (changes, prevLabData) => {
  const dataMap = new Map();
  prevLabData.forEach((data) => {
    dataMap.set(data._id, data);
  });

  // Iterates through the Grid's changes
  // and processes the different cell-type changes
  changes.forEach((change) => {
    const dataIndx = change.rowId;
    const fieldName = change.columnId;
    let target = dataMap.get(dataIndx);

    if (change.type === "dropdown") {
      // Always set the isOpen status for dropdown type
      target.isOpen[fieldName] = change.newCell.isOpen;

      // Handle when inputValue is present
      if (change.newCell.inputValue !== undefined) {
        target[fieldName] = change.newCell.inputValue;
      }
      // Handle when a new option is selected on a dropdown
      else if (change.newCell.selectedValue !== undefined &&
        change.newCell.selectedValue !== change.previousCell.selectedValue) {
        target[fieldName] = change.newCell.selectedValue;
      }
    }

    if (change.type === "date") {
      const localDate = new Date(change.newCell.value);
      const offsetMilliseconds = localDate.getTimezoneOffset() * 60 * 1000;
      const adjustedTimestamp = change.newCell.value + offsetMilliseconds;
      const adjustedDate = new Date(adjustedTimestamp);
      target[fieldName] = adjustedDate;
    }

    if (change.type === "number") {
      target[fieldName] = change.newCell.value;
    }

    if (change.type === "text") {
      target[fieldName] = change.newCell.text;
    }
  });

  return [...prevLabData];
};


export {
  applyChangesToLabData,
  getColumns,
  getRows
}