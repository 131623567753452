import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DisplayExistingReportValue } from "./DisplayExistingReportValue";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import "../DisplayExistingTimeReport/CanvasStyles.css";
export const ExistingReportInfoDictTableCreator = ({
  title,
  md = 2,
  subDictName,
  setTimeSheet,
  timeSheet,
  exampleList,
  cancelChanges,
}) => {
  useEffect(() => {
    timeSheet[subDictName] = timeSheet[subDictName].map((val, index) => {
      return { ...val, newId: index };
    });
  }, []);

  setTimeSheet(timeSheet);
  let reportDict = timeSheet[subDictName];
  const [editIdList, setEditIdList] = useState([]);

  useEffect(() => {
    setEditIdList([]);
  }, [cancelChanges]);

  const capitalizeFirstChar = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  const convertKeyNameToDisplayName = (key) => {
    if (key == key.toUpperCase()) return key;
    let replacedKey = key.replace(/[A-Z]/g, (char) => " " + char);
    let capitalizeReplacedKey = capitalizeFirstChar(replacedKey);
    return capitalizeReplacedKey.replace("No", "Number");
  };
  const returnMD = (value, md = 2) => {
    switch (typeof value) {
      case "string":
        return { md: md };
      case "number":
        return { md: 2 };
      default:
        return { md: 1 };
    }
  };

  const addIdToList = (reportDict, subDict) => {
    let id = reportDict[subDict].newId;
    console.log(id);
    setEditIdList((editIdList) => [...editIdList, id]);
  };

  const checkIdInList = (reportDict, subDict) => {
    return editIdList.includes(reportDict[subDict].newId);
  };

  const addNewObjectToDict = () => {
    const newId = uuidv4();
    let _timeSheet = { ...timeSheet };
    _timeSheet[subDictName] = [
      ..._timeSheet[subDictName],
      {
        ...exampleList,
        newId,
      },
    ];
    setTimeSheet(_timeSheet);
    setEditIdList((editIdList) => [...editIdList, newId]);
  };

  const removeObjectFromDict = (id) => {
    let _timeSheet = { ...timeSheet };

    _timeSheet[subDictName] = _timeSheet[subDictName].filter(
      (value) => value.newId != id
    );
    editIdList.filter((editId) => editId != id);
    setTimeSheet(_timeSheet);
    let _editList = [...editIdList];
    _editList = _editList.filter((editId) => editId != id);
    setEditIdList(_editList);
  };

  return (
    <Card>
      <Card.Body className="w-100">
        <Card.Title className=" w-100 text-center border-bottom p-3 ">
          {title}
        </Card.Title>
        {Object.keys(reportDict).length > 0 ? (
          <>
            <div className="d-flex align-items-center">
              <div style={{ width: "98%" }}>
                <Row>
                  {Object.keys(reportDict[0]).map((key) =>
                    key != "_id" && key != "newId" && key != "currencyType" ? (
                      <Col
                        className="border-bottom py-1"
                      >
                        {convertKeyNameToDisplayName(key)}
                      </Col>
                    ) : null
                  )}
                </Row>
              </div>
            </div>

            {Object.keys(reportDict).map((subDict) => {
              return (
                <div className="d-flex align-items-center">
                  <div style={{ width: "98%" }}>
                    <Row>
                      {Object.keys(reportDict[subDict]).map((k) => {
                        console.log(k);
                        if(['_id', 'newId'].includes(k)) return;
                        return (
                          <Col>
                            {reportDict[subDict][k]}
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
              </div>
            )})}
          </>
        ) : (
          <Row className="border-bottom download-file">
            <div className="py-2">n/a</div>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};
