import { max } from 'moment';
import React from 'react'
import { Chart } from "react-google-charts";

export default function PolymerGraph(props) {

    const getDataPoints = (arr) => {
        let all = [];

        for(let i = 1; i < arr.length; i++){
            all.push(Number(arr[i][1]));
            all.push(Number(arr[i][2]));
        }
        return all;
    }

    return (
        <div>
            <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={props.polymerdosedata}
                options={{
                    height: 450,
                    legend: { position: "top" },
                    chartArea: { width: "80%", height: "75%" },
                    title: "Polymer Dosage vs Date",
                    titleTextStyle: { fontFamily: "Arial", fontSize: 15, bold: true, italic: false },
                    hAxis: {
                        format: 'dd/MM/yy',
                        title: 'Date',
                    },
                    vAxis: {
                        title: 'ppm',
                        viewWindowMode: 'explicit',
                        viewWindow: {
                            min: 0,
                            max: Math.max(...getDataPoints(props.polymerdosedata)) * 1.5
                        }
                    },
                    series: {
                        0: { targetAxisIndex: 0, color: "#1ECBE1" },
                        1: { targetAxisIndex: 0, color: "#9dd47a", type: "line" },
                    },
                }}
            />
        </div>
    )
}
