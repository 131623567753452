import { useEffect } from "react";
import WorkOrdersListPage from "./WorkOrderListPage";
import TabNav from "./TabNav";

const WorkOrdersPage = () => {
  useEffect(() => {
    document.title = 'Work Orders';
  });

  return (
    <div style={{ margin: '1% 1% 1% 1%', zoom: '88%' }}>
      <TabNav />
    </div>
  );
};

export default WorkOrdersPage;
