import React from 'react';
import { Chart } from "react-google-charts";
import { Table } from 'react-bootstrap';

export default function InputChart(props) {
  let { formVal } = props

  return (
    <div className='row' style={{marginTop: '20px'}}>
      <div className='col-md-5'>
        <h5 style={{ textAlign: 'left', color: '#005E7D' }}>{props.language?.operating_information || 'Operation Information'}</h5>
        <Table className="table" striped>
          <tbody>
            <tr>
              <td className="header">{props.language?.operating_days_per_year || 'Operating Days/Year'}&emsp;&emsp;&emsp;&emsp;&emsp;</td>
              <td style={{ textAlign: 'right' }} id="operating-days">{props.data["Operating Days/Year"]}</td>
            </tr>
            <tr>
              <td className="header">{props.language?.operating_hours_per_day || 'Operating Hours/Day'}&emsp;</td>
              <td style={{ textAlign: 'right' }} id="operating-hours">{props.data["Operating Hours/Day"]}</td>
            </tr>
            <tr>
              <td className="header">{props.language?.system_type || "System Type"}</td>
              <td style={{ textAlign: 'right' }} id="system-type">{props.data["System Type"]}</td>
            </tr>
          </tbody>
        </Table>


        <h5 style={{ textAlign: 'left', color: '#005E7D' }}> {props.language?.nutrient_value || "Nutrient Valu"}</h5>
        <Table className="table" striped>
          {
            props.units == "Imperial" ?
              <tbody>
                <tr>
                  <td className="header">{props.language?.ammonium_nitrogen || "Ammonium Nitrogen"} ($/lb)</td>
                  <td id="input-dry-polymer-flow" style={{ textAlign: 'right' }}>0.50</td>
                </tr>
                <tr>
                  <td className="header">{props.language?.organic_nitrogen || "Organic Nitrogen"} ($/lb)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>0.50</td>
                </tr>
                <tr>
                  <td className="header"> {props.language?.potassium || "Potassium"} ($/lb)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>0.35</td>
                </tr>
                <tr>
                  <td className="header"> {props.language?.phosphorus || "Phosphorus"} ($/lb)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>0.53</td>
                </tr>
              </tbody>

              :

              // $/lb * 1lb/0.453592  = $/kg
              <tbody>
                <tr>
                  <td className="header">{props.language?.ammonium_nitrogen || "Ammonium Nitrogen"} ($/kg)</td>
                  <td id="input-dry-polymer-flow" style={{ textAlign: 'right' }}>1.10</td>
                </tr>
                <tr>
                  <td className="header">{props.language?.organic_nitrogen || "Organic Nitrogen"} ($/kg)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>1.10</td>
                </tr>
                <tr>
                  <td className="header"> {props.language?.potassium || "Potassium"} ($/kg)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>0.77</td>
                </tr>
                <tr>
                  <td className="header"> {props.language?.phosphorus || "Phosphorus"} ($/kg)</td>
                  <td id="input-polymer-water-flow" style={{ textAlign: 'right' }}>1.17</td>
                </tr>
              </tbody>
          }
        </Table>
      </div>
      <div className='col-md-7'>
        <div style={{ width: '800px', height: '500px', paddingTop: 0, marginTop: -30 }} >
          <Chart
            width={'100%'}
            height={'100%'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Output', '%'],
              [props.language?.total_suspended_solids || 'Total Suspended Solids', (formVal.ss)],
              [props.language?.ammonium_nitrogen || 'Ammonium Nitrogen', (formVal.aNitrogen)],
              [props.language?.organic_nitrogen || 'Organic Nitrogen', (formVal.tkn - formVal.aNitrogen)],
              [props.language?.potassium || 'Potassium', (formVal.potassium)],
              [props.language?.phosphorus || 'Phosphorus', (formVal.phosphorus)],
              [props.language?.calcium || 'Calcium', (formVal.calcium)],
              [props.language?.magnesium || 'Magnesium', (formVal.magnesium)],
              [props.language?.sodium || 'Sodium', (formVal.sodium)],
              [props.language?.iron || 'Iron', (formVal.iron)],
              [props.language?.manganese || 'Manganese', (formVal.manganese)],
              [props.language?.copper || 'Copper', (formVal.copper)],
              [props.language?.zinc || 'Zinc', (formVal.zinc)],
              [props.language?.sulfur || 'Sulfur', (formVal.sulfur)],
              [props.language?.other_dissolved_solids || 'Other Dissolved Solid', (props.other)],
            ]}
            options={{
              chartArea : { left: 50, top: 75, bottom: 50, right: 0},
              title: props.language?.input_data_ppm || 'Input Constituents (ppm)',
              titleTextStyle: { fontSize: 19, color: '#005E7D' },
              legend: { position: 'left', textStyle: { fontSize: props.language ? (props.language.language == 'ch' ? 21 : 18) : 18 } },
              colors: [
                // '#8a4b00',
                '#258d00',
                '#00afd6',
                '#008080',
                '#FA8072',
                '#CD5C5C',
                '#E9967A',
                '#93c47d',
                '#76a5af',
                '#b4a7d6',
                '#666633',
                '#8a4b00',
                '#3366ff',
                '#9a2329',
                '#00cc99'
              ],
              is3D: true,
              pieSliceText: 'value'
            }}
            rootProps={{ 'data-testid': '2' }}
          />

        </div>
      </div>
    </div>
  )
}
