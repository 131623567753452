import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthContext";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { UploadNewFiles } from "../../../field-tools/FieldExpenses/UploadNewFiles";
import { AiOutlineClose, AiFillFileExcel, AiFillFilePdf } from "react-icons/ai";
import { getCurrentDate, getStartDate } from "../../HRFunctions";
import {
  checkTimesheetDate,
  createNewTimesheet,
} from "../../../../api/hrTimeSheet";

// Created new Timesheet for HR Portal
export const NewHRTimeSheet = () => {
  const { email } = useAuth().currentUser;
  // timesheet state
  const [timesheet, setTimesheet] = useState({
    timesheetFile: null,
    fileType: null,
    startDate: "",
    endDate: getCurrentDate(),
    email: email,
    payPeriod: 0,
  });

  // contains all error messages
  const [errorList, setErrorList] = useState({});

  useEffect(() => {
    // when end date changes, change the pay period to the correct value for the year
    const originalDate = new Date("2022-12-23");
    const endDate = new Date(timesheet.endDate);
    let currentPayPeriod = 0;
    while (originalDate < endDate) {
      const originalDateYear = originalDate.getFullYear();
      originalDate.setDate(originalDate.getDate() + 14);
      const newOriginalDateYear = originalDate.getFullYear();
      if (newOriginalDateYear > originalDateYear) currentPayPeriod = 0;
      currentPayPeriod += 1;
    }
    // when end Date changes, change start date to 10 work days before end date

    const newStartDate = getStartDate(timesheet.endDate);
    setTimesheet((timesheet) => ({
      ...timesheet,
      startDate: newStartDate,
      payPeriod: Math.ceil(currentPayPeriod),
    }));
  }, [timesheet.endDate]);

  // removes error message from being displayed
  const removeErrorMessage = (errorKey) => {
    if (errorKey in errorList) {
      let _errorList = { ...errorList };
      delete _errorList[errorKey];
      setErrorList(_errorList);
    }
  };

  // add error messages to display
  const addErrorMessage = (errorKey, errorMessage) => {
    let _errorList = { ...errorList };
    _errorList[errorKey] = errorMessage;
    setErrorList(_errorList);
  };

  // add error messages depending on exceptions
  useEffect(() => {
    const properEndDate = new Date(timesheet.endDate);
    const properStart = new Date(timesheet.startDate);
    // check if start date is after end date
    if (properStart > properEndDate) {
      addErrorMessage(
        "startDateError",
        "Cannot have start time be after end time!"
      );
    } else {
      removeErrorMessage("startDateError");
    }
    properEndDate.setDate(properEndDate.getDate() - 13);
    // check if start date is more than 14 days before end date
    if (properEndDate > properStart) {
      addErrorMessage(
        "dateRangeError",
        "Cannot have start time be more than 2 weeks before end time!"
      );
    } else {
      removeErrorMessage("dateRangeError");
    }
    // check if timesheet file is uploaded
    if (timesheet.timesheetFile != null) {
      removeErrorMessage("timesheetFile");
    } else {
      addErrorMessage("timesheetFile", "Please upload timesheet to submit!");
    }
  }, [timesheet]);

  const onChange = (value, valueType) => {
    let _timeSheet = { ...timesheet };
    _timeSheet[valueType] = value;
    setTimesheet(_timeSheet);
  };

  const submitForm = async (fileExists) => {
    // if no timesheet for period exists
    if (!fileExists) {
      // create formdata with body value
      const formData = new FormData();
      formData.append("csvFile", timesheet.timesheetFile);
      formData.append("startDate", timesheet.startDate);
      formData.append("endDate", timesheet.endDate);
      formData.append("payPeriod", timesheet.payPeriod);
      formData.append("fileType", timesheet.fileType);
      formData.append("email", timesheet.email);
      createNewTimesheet(formData).then((res) =>
        alert(
          res.status == 200
            ? "Timesheet has been successfuly created!"
            : "Could not upload timesheet, please try again later."
        )
      );
    } else {
      alert(
        "Timesheet for this period already exists.\n Please delete old timesheet before trying again"
      );
    }
  };

  // checks is timesheet for the start and end date already exists
  // only 1 timesheet for a time period can exist
  const checkIfTimesheetForPeriodExists = async () => {
    const bodyVal = {
      startDate: timesheet.startDate,
      endDate: timesheet.endDate,
      email,
    };
    checkTimesheetDate(bodyVal).then((res) => {
      submitForm(res.status != 200);
    });
  };

  // submits form value
  const onSubmit = async (e) => {
    e.preventDefault();
    checkIfTimesheetForPeriodExists();
  };

  // remove file from input
  const removeFile = () => {
    setTimesheet((timesheet) => ({ ...timesheet, timesheetFile: null }));
  };

  // disables submit button if any error is present
  const checkIfSubmitButtonDisabled = () => {
    return Object.keys(errorList).length > 0 ? { disabled: true } : null;
  };

  return (
    <Form onSubmit={onSubmit} className="mx-2">
      <FormGroupCreator
        label="Start Date"
        type="date"
        valueDict={timesheet}
        valueType="startDate"
        changeValue={onChange}
      />
      <FormGroupCreator
        label="End Date"
        type="date"
        valueDict={timesheet}
        valueType="endDate"
        changeValue={onChange}
      />

      <Row className="py-2">
        <Col className="col-md-2 col-sm-4 text-center">Pay Period #</Col>
        <Col className="col-md-4 col-sm-6">{timesheet.payPeriod}</Col>
      </Row>
      <br />
      {!timesheet.timesheetFile ? (
        <UploadNewFiles
          onChange={(file) => {
            setTimesheet((timesheet) => ({
              ...timesheet,
              timesheetFile: file[0],
              fileType: file[0].name.split(".")[1],
            }));
          }}
          title="Upload Timesheet"
          acceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
          multiple={false}
        />
      ) : (
        <div className="d-flex justify-content-center">
          <div className="border rounded p-2" style={{ width: "350px" }}>
            <Row>
              <Col className="col-1 d-flex align-items-center">
                <div className="">
                  {timesheet.fileType == "pdf" ? (
                    <AiFillFilePdf color="red" size={"25px"} />
                  ) : (
                    <AiFillFileExcel color="green" size={"25px"} />
                  )}
                </div>
              </Col>
              <Col className="col-10">
                <div className=" text-center w-100">
                  {timesheet.timesheetFile.name}
                </div>
              </Col>
              <Col className="col-1 p-0 d-flex align-items-center">
                <AiOutlineClose
                  onClick={removeFile}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}

      <br />
      <div className="d-flex justify-content-center">
        <Button
          id="submitButton"
          {...checkIfSubmitButtonDisabled()}
          type="submit"
        >
          Submit Timesheet
        </Button>
      </div>
      {Object.keys(errorList).map((errorKey) => (
        <div className="text-danger text-center">{errorList[errorKey]}</div>
      ))}
    </Form>
  );
};
