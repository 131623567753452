import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import "../styles/Login.css";

// api
import { resetFirebasePassword, generatePasswordResetToken } from "../api/auth";
import LoginScreenBoiler from "./LoginScreenBoiler";

const PasswordReset = () => {
  const emailRef = useRef();
  const resetTokenRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const history = useHistory();

  // states
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");


  const resetPasswordApi = async (e) => {
    const strongPwRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    e.preventDefault();

    const email = emailRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const token = resetTokenRef.current.value;
    const newPasswordConfirm = confirmPasswordRef.current.value;

    if (!email) {
      setError("Please ensure you have entered your email in the form above");
      return;
    }

    const doPasswordsMatch = newPassword == newPasswordConfirm;
    if (!doPasswordsMatch) {
      setError("Passwords do not match");
      return;
    }

    const isPassValid = strongPwRegex.test(newPassword);
    if (!isPassValid) {
      setError(
        "Password must contain at least 8 characters, one special symbol, at least 1 lower case and upper case letter, and at least one number"
      );
      return;
    }

    const resetPswdResult = await resetFirebasePassword(
      email,
      newPassword,
      token
    );
    if (resetPswdResult == 1) {
      alert(
        "Your password has been succefully reset. You will now be redirected to the login page."
      );
      history.push("/");
    } else if (resetPswdResult === -2) {
      setError(
        "Invalid Reset Token. Please ensure you have entered the correct token."
      );
    } else {
      setError(
        `Error resetting password: ${resetPswdResult.response.data}`
      );
    }

    setLoading(false);
  };

  const backTologin = () => {
    history.push("/");
  };

  return (
    <LoginScreenBoiler>
      <div>
        <>
          <Card
            className="border-0"
            style={{ padding: "15%", marginTop: '7%', zoom: '85%' }}
          >
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={resetPasswordApi}>
              <Form.Group id="email" autocomplete="off">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autocomplete="new-password"
                  type="email"
                  ref={emailRef}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required
                />
              </Form.Group>
              <Form.Group id="reset-token" autocomplete="off">
                <Form.Label>Reset Token</Form.Label>
                <Form.Control
                  autocomplete="new-password"
                  type="text"
                  ref={resetTokenRef}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required
                />
              </Form.Group>
              <Form.Group id="new-password" autocomplete="off">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  autocomplete="new-password"
                  type="password"
                  ref={newPasswordRef}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required
                />
              </Form.Group>
              <Form.Group id="confirm-password" autocomplete="off">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={confirmPasswordRef}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required
                />
              </Form.Group>
              <br></br>
              <Button
                disabled={loading}
                className="w-100 mt-2"
                type="submit"
                style={{backgroundColor: '#005E7D'}}
              >
                Reset Password
              </Button>
              <Button
                className="w-100 mt-2"
                onClick={backTologin}
                variant="outline-dark"
              >
                Back to login
              </Button>
            </Form>
          </Card>
        </>
      </div>
    </LoginScreenBoiler>
  );
};

export default PasswordReset;
