/**
 * SystemTypesTab.jsx
 * --------------------
 * Component that contains all CRUD functionality for System Types in the LWR database.
 * Renders a table with client System Type entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";
// CRUD components
import SystemTypesInsertModal from "./SystemTypesInsertModal";
import SystemTypesDeleteModal from "./SystemTypesDeleteModal";
import { getUserPermissionFromCache } from "../../../utility/permissionFunctions";

// api
import { getAllSysTypes } from "../../../api/systemtypes";

export default function SystemTypesTab(props) {
  const [systemTypeElements, setSystemTypeElements] = useState([]);
  const [systemTypes, setSystemTypes] = useState([]);
  const [userType, setUserType] = useState([]);
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "System Types - Admin Tools";
    const allSysTypes = await getAllSysTypes();
    setUserType(await getUserPermissionFromCache(props.account.email));
    updateSystemTypes(allSysTypes);
  }, []);

  const fetchAndCreateSystemTypeElements = async () => {
    searchRef.current.value = "";
    const allSysTypes = await getAllSysTypes();
    let sysElements = createSystemTableElements(allSysTypes);
    setSystemTypeElements(sysElements);
    setSystemTypes(allSysTypes);
  };

  const updateSystemTypes = (systems) => {
    let sysElements = createSystemTableElements(systems);
    setSystemTypeElements(sysElements);
    setSystemTypes(systems);
  };

  const insertSystemType = (newSysType) => {
    setSystemTypes(oldSysTypes => [...oldSysTypes, newSysType]);
    setSystemTypeElements(oldSysElements => [...oldSysElements, createSystemTableElement(newSysType)]);
  };

  const deleteSystemType = (sysTypeToDelete) => {
    setSystemTypes(oldSysTypes => oldSysTypes.filter(sysType => sysType.id !== sysTypeToDelete.id));
    setSystemTypeElements(oldSysElements => oldSysElements.filter(sysElement => sysElement.key !== sysTypeToDelete.id));
  };

  const createSystemTableElements = (systems) => {
    return systems.map(sys => createSystemTableElement(sys));
  };

  const createSystemTableElement = (sys) => {
    return (
      <tr key={sys.id}>
        <td>{sys.systemName}</td>
        {/* <td>
          <SystemTypesDeleteModal
            system={sys}
            onEnd={deleteSystemType}
          />
        </td> */}
      </tr>
    );
  };

  const onChangeSearch = () => {
    let validSystems = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();
    systemTypes.forEach((sys) => {
      if (sys.systemName.toLowerCase().includes(currentSearchVal)) {
        validSystems.push(sys);
      }
    });

    let newTableElements = createSystemTableElements(validSystems);
    setSystemTypeElements(newTableElements);
  };

  return (
    <div style={{margin: '10px'}}>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          {<SystemTypesInsertModal onEnd={fetchAndCreateSystemTypeElements} />}
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>System Type</th>
              {/* {<th>Delete</th>} */}
            </tr>
          </thead>
          <tbody>{systemTypeElements}</tbody>
        </Table>
      </div>
    </div>
  );
}
