import React from 'react'
import { Chart } from "react-google-charts";

export default function TSRemovalGraph(props) {
  return (
    <Chart
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={props.recoverydata}
      options={{
        backgroundColor: { fill: "transparent" },
        chartArea: { width: "88%", height: "75%" },
        hAxis: { format: "dd/MM/yy" },
        height: 450,
        legend: { position: "top" },
        series: { 0: { targetAxisIndex: 0, color: "#00cf8d" }, 1: { targetAxisIndex: 0, color: "#00cf8d", type: "line" } },
        seriesType: "line",
        title: "Volatile Solids Removal vs Date",
        titleTextStyle: { fontFamily: "Arial", fontSize: 15, bold: true, italic: false },
        vAxes: {},
        vAxis: { title: "%" },
      }}
    />
  )
}
