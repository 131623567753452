/**
 * IoTInsertModal.jsx
 * ------------------
 * Component that contains insert functionality for inserting IoT Devices into the LWR database.
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 17/06/2021
 */

import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

// api
import { createIotDevice } from "../../../api/iot";
import { getAllSystemSites } from "../../../api/systemSites";


export default function IoTInsertModal(props) {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [linkingIdElements, setLinkingIdElements] = useState([]);
  const [selectedLinkingId, setSelectedLinkingId] = useState("");

  // Refs
  const authenticationKeyRef = useRef();

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  useEffect(() => {
    fetchAndLoadLinkingIdOptions();
  }, []);

  const fetchAndLoadLinkingIdOptions = async () => {
    const systemSites = await getAllSystemSites();
    setLinkingIdElements(
      systemSites.map((entry) => {
        return <option value={entry.linking_id}>{entry.linking_id}</option>;
      }));
    setSelectedLinkingId(systemSites[0].linking_id);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let iotKey = authenticationKeyRef.current.value;
    let linkingId = selectedLinkingId;

    const createIotDeviceResult = await createIotDevice(linkingId, iotKey);
    if (createIotDeviceResult.status != 200) {
      setSuccessMsg("");
      setErrMsg("Error inserting new IoT Device into the database.");
    } else {
      setErrMsg("");
      setSuccessMsg("Succesfully inserted new IoT Device into the database");
      authenticationKeyRef.current.value = "";
    }

    e.preventDefault();
  };
  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New IoT Device
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Add New IoT Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Fill out all fields below to insert a new linked IoT Device into the
            database.
          </div>
          <br />
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label>System Linking Id</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSelectedLinkingId(e.target.value);
                  }}
                  required
                >
                  {linkingIdElements}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Authentication Key</Form.Label>
                <Form.Control type="text" ref={authenticationKeyRef} required />
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
