import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function SignupForm() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const keyRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const { signup } = useAuth();

    // states
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [success, setSuccess] = useState('');

    function clearForm() {
        emailRef.current.value = "";
        passwordRef.current.value = "";
        keyRef.current.value = "";
        passwordConfirmRef.current.value = "";
    }

    async function handleSubmit(e) {
        e.preventDefault();

        // validate passwords
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        try {
            setError('');
            setSuccess('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value, keyRef.current.value, firstNameRef.current.value, lastNameRef.current.value).then(result => {
                // If signup was sucessful

                if (result == 1) {
                    setError("");
                    setSuccess("Sign up successful");
                    clearForm();
                }
                else if (result == -2) {
                    setSuccess("");
                    setError('An account using this email already exists.');
                }
                else {
                    setSuccess("");
                    setError(`Failed to create an account: ${result.response.data}`);
                }
            });
        } catch (e) {
            console.error(e);
            setSuccess("");
            setError('Failed to create an account');
        }
        setLoading(false);
    }

    return (
        <div>
            <Card className="border-0" style={{ padding: "15%", zoom: '85%' }}>
                <Card.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <input type="hidden" value="something"/>
                        <Form.Group id="email">
                            <Form.Label style={{ color: '#005E7D' }}>Email</Form.Label>
                            <Form.Control type="email" autoComplete="email1" ref={emailRef} required />
                        </Form.Group>
                        <br></br>
                        <Form.Group id="firstName">
                            <Form.Label style={{ color: '#005E7D' }}>First Name</Form.Label>
                            <Form.Control type="text" autoComplete="text1" ref={firstNameRef} required />
                        </Form.Group>
                        <br></br>
                        <Form.Group id="lastName">
                            <Form.Label style={{ color: '#005E7D' }}>Last Name</Form.Label>
                            <Form.Control type="text" autoComplete="text2" ref={lastNameRef} required />
                        </Form.Group>
                        <br></br>
                        <Form.Group id="key">
                            <Form.Label style={{ color: '#005E7D' }}>Sign Up Key</Form.Label>
                            <Form.Control type="text" autoComplete="new-password" ref={keyRef} required />
                        </Form.Group>
                        <br></br>
                        <Form.Group id="authentication">
                            <Form.Label style={{ color: '#005E7D' }}>Password</Form.Label>
                            <Form.Control type="password" autoComplete="new-password" ref={passwordRef} required />
                        </Form.Group>
                        <br></br>
                        <Form.Group id="password-confirm">
                            <Form.Label style={{ color: '#005E7D' }}>Confirm Password</Form.Label>
                            <Form.Control type="password" autoComplete="new-password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-2" type="submit" style={{ backgroundColor: '#005E7D' }}>Sign Up</Button>
                    </Form>
                    <div className="w-100 text-center mt-2" style={{ color: '#005E7D' }}>
                        Already have an account? <Link style={{ color: '#005E7D' }} to="/login">Log In</Link>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SignupForm
