import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { DisplayFieldExpenseReceiptComponent } from "./DisplayFieldExpenseReceiptComponent";
import { newExpenseExample } from "../FieldExpenseValues";
import { getExpenseById } from "../../../../api/fieldExpenseReport";

export const DisplayFieldExpenseReceipt = ({
  setIsReportList,
  showReport,
  images,
  setImages,
  loading,
  uploadedImages,
  setUploadedImages,
  setCancelButtonClicked,
  cancelButtonClicked,
}) => {
  const { email } = useAuth().currentUser;
  const [expenseReceipt, setExpenseReceipt] = useState({
    ...newExpenseExample,
    imageUrlList: [],
  });

  // get the unchanged report from database when cancel button is clicked
  useEffect(() => {
    getExpenseById(showReport._id, email).then((result) =>
      setExpenseReceipt(result)
    );
  }, [cancelButtonClicked]);

  return (
    <DisplayFieldExpenseReceiptComponent
      setIsReportList={setIsReportList}
      expenseReceipt={expenseReceipt}
      setExpenseReceipt={setExpenseReceipt}
      images={images}
      setImages={setImages}
      loading={loading}
      uploadedImages={uploadedImages}
      setUploadedImages={setUploadedImages}
      setCancelButtonClicked={setCancelButtonClicked}
      cancelButtonClicked={cancelButtonClicked}
    />
  );
};
