/**
 * api/farms.js
 * ------------------
 * Module encapsulating functions related to the farms collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";
import { shouldReturnCachedData } from "./cache";

let totalFarmCount = null;
let lastPolled     = null;

const getTotalFarmCount = async () => {
  // Return cached totalFar Coount
  if(shouldReturnCachedData(totalFarmCount, lastPolled)) {
    return totalFarmCount;
  }

  return http.get('farms/count/')
    .then((res) => {
      totalFarmCount = res.data;
      lastPolled = Date.now();
      return totalFarmCount;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const createFarm = async (body) => {
  return http.post('farms/insert', body)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

const editFarm = async (body) => {
  return http.put('farms/update', body)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

const getAllFarms = async () => {
  return http.get('farms/all')
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log(error);
      return error;
    });
};

const deleteFarm = async (farmName) => {
  return http.delete(`farms/delete/?name=${farmName}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};



export {
  getTotalFarmCount,
  createFarm,
  editFarm,
  getAllFarms,
  deleteFarm
};