import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { NewFieldReport } from './TimeReporting/NewFieldReport';

const EditFieldTimesheetModal = ({show, setShow, reportId, onClose}) => {
  return ( <>
    <Modal show={show} onHide={() => {setShow(false)}} size="xl">
      <Modal.Header>
        <Modal.Title>Edit Field Timesheet</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{zoom: '90%'}}>
        <NewFieldReport editMode={true} reportId={reportId} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setShow(false); onClose(); }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>)
};

export default EditFieldTimesheetModal;