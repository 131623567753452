import { MarkerType, Position } from 'reactflow';

import Logo from '../../../../img/logo.jpeg';

export function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const TableFromObject = ({ dataObject, fixedDecimal = 4 }) => {
  const formatValue = (value) => {
    // Check if the value is a number and format it to fixed decimal places
    if (typeof value === 'number') {
      return numberWithCommas(value.toFixed(fixedDecimal));
    }
    return value?.toString() || value;
  };

  const renderRows = (obj, isNested = false) => {
    if (!obj) return;
    return Object.entries(obj).flatMap(([key, value], index) => {
      const isParent = value && typeof value === 'object' && !Array.isArray(value);
      if (isParent) {
        return [
          <tr key={key}>
            <td style={{
              paddingLeft: isNested ? '20px' : '5px',
              textAlign: 'left',
              fontWeight: 'bold' // Bold only for parent keys
            }}>
              {key}
            </td>
            <td></td>
          </tr>,
          ...renderRows(value, true)
        ];
      } else {
        return (
          <tr key={key}>
            <td style={{
              paddingLeft: isNested ? '20px' : '5px',
              textAlign: 'left'
            }}>
              {key}
            </td>
            <td style={{ textAlign: 'right' }}>{formatValue(value)}</td>
          </tr>
        );
      }
    });
  };

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr style={{ backgroundColor: '#005E7D', color: 'white' }}>
          <th style={{ textAlign: 'left', backgroundColor: '#005E7D', color: 'white' }}>Constituent</th>
          <th style={{ textAlign: 'right', backgroundColor: '#005E7D', color: 'white' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {renderRows(dataObject)}
      </tbody>
    </table>
  );
};

export const titleStyle = {
  color: '#005E7D',
  fontWeight: 'bold',
}


export const createNodes = (sysParams, inputData, inletFlows, afterPolymerAddition, microscreenInlet, msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, title) => {
  return [
    {
      id: 'title',
      data: {
        label: <div className="title">
          <div className="row">
            <div className="col">
              <img src={Logo} alt="logo" width="320px" />

            </div>
            <div className="col">
              <div style={{ ...titleStyle, fontSize: '28px', marginTop: '6%' }}>{title}</div>

            </div>
          </div>
        </div>
      },
      position: { x: -700, y: -300 },
      sourcePosition: Position.Left,
      targetPosition: Position.Left,
      draggable: false,
      selectable: false,
      connectable: false,
      dragHandle: 'hidden',
      style: {
        'width': '1420px',
        'border': 'none'
      }
    },
    {
      id: 'input_data',
      data: {
        label: <div>
          <div style={titleStyle}>Input Parameters</div>
          <TableFromObject dataObject={inputData} fixedDecimal={0} />
        </div>
      },
      position: { x: -500, y: 0 },
      sourcePosition: Position.Left,
      targetPosition: Position.Left,
      draggable: false,
      selectable: false,
    },
    {
      id: 'inlet_flows',
      data: {
        label: <div>
          <div style={titleStyle}>System Inlet Flows</div>
          <TableFromObject dataObject={inletFlows} />
        </div>
      },
      position: { x: -150, y: 0 },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      draggable: false,
      selectable: false,
    },
    {
      id: 'ammonia_loss',
      data: {
        label: <div>
          <div style={titleStyle}>15% Loss of Ammonium Nitrogen Mass Flow</div>
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
      position: { x: 250, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'polymer_addition',
      data: {
        label: <div>
          <div style={titleStyle}>Polymer Addition</div>
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
      position: { x: 250, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'polymer_addition2',
      data: {
        label: <div>
          <div style={titleStyle}>Polymer Addition</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
      position: { x: 250, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'polymer_addition3',
      data: {
        label: <div>
          <div style={titleStyle}>Polymer Addition</div>
        </div>
      },
      sourcePosition: Position.Top,
      targetPosition: Position.Bottom,
      position: { x: 250, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'after_polymer',
      data: {
        label: <div>
          <div style={titleStyle}>After Polymer Addition</div>
          <TableFromObject dataObject={afterPolymerAddition} />
        </div>
      },
      position: { x: 650, y: 0 },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      draggable: false,
      selectable: false,
    },
    {
      id: 'polymer_system',
      data: {
        label: <div>
          <div style={titleStyle}>Polymer System</div>
          <TableFromObject dataObject={polymerSystem} />
        </div>
      },
      position: { x: 250, y: 350 },
      sourcePosition: Position.Top,
      targetPosition: Position.Bottom,
      draggable: false,
      selectable: false,
    },
    {
      id: 'filtrate_recycling_loop',
      data: {
        label: <div>
          <div style={titleStyle}>Filtrate Recycling Input</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 1020, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'filtrate_recycling_loop2',
      data: {
        label: <div>
          <div style={titleStyle}>Filtrate Recycling Input</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
      position: { x: 1020, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'filtrate_recycling_loop3',
      data: {
        label: <div>
          <div style={titleStyle}>Filtrate Recycling Input</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
      position: { x: 1020, y: 200 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ms_inlet',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen Inlet</div>
          <TableFromObject dataObject={microscreenInlet} />
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 1390, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'microscreen',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen</div>
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
      position: { x: 1760, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'microscreen2',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 1760, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ms_solids',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen Solids</div>
          <TableFromObject dataObject={msSolids} />
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
      position: { x: 1760, y: 250 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ms_filtrate',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen Filtrate (Output Stream)</div>
          <TableFromObject dataObject={msFiltrate} />
        </div>
      },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      position: { x: 2135, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ms_filtrate2',
      data: {
        label: <div>
          <div style={titleStyle}>Microscreen Filtrate (Output Stream)</div>
          <TableFromObject dataObject={msFiltrate} />
        </div>
      },
      targetPosition: Position.Right,
      sourcePosition: Position.Bottom,
      position: { x: 2135, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'screw_press',
      data: {
        label: <div>
          <div style={titleStyle}>Screw Press</div>
        </div>
      },
      sourcePosition: Position.Left,
      targetPosition: Position.Top,
      position: { x: 1760, y: 1100 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'screw_press2',
      data: {
        label: <div>
          <div style={titleStyle}>Screw Press</div>
        </div>
      },
      sourcePosition: Position.Right,
      position: { x: 1760, y: 1100 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'sp_solids',
      data: {
        label: <div>
          <div style={titleStyle}>Screw Press Solids (Output Stream)</div>
          <TableFromObject dataObject={spSolids} />
        </div>
      },
      targetPosition: Position.Left,
      position: { x: 2135, y: 700 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'sp_filtrate',
      data: {
        label: <div>
          <div style={titleStyle}>Screw Press Filtrate</div>
          <TableFromObject dataObject={spFiltrate} />
        </div>
      },
      targetPosition: Position.Right,
      sourcePosition: Position.Left,
      position: { x: 1390, y: 800 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'sp_filtrate2',
      data: {
        label: <div>
          <div style={titleStyle}>Screw Press Filtrate</div>
          <TableFromObject dataObject={spFiltrate} />
        </div>
      },
      targetPosition: Position.Right,
      sourcePosition: Position.Bottom,
      position: { x: 1390, y: 800 },
      draggable: false,
      selectable: false,
    },
  ]
}

export const edgeStyle = {
  strokeWidth: 2,
  stroke: '#005E7D',
}

export const markerStyle = {
  type: MarkerType.ArrowClosed,
  width: 10,
  height: 10,
  color: '#005E7D',
}

export const edges = (sysParams) => {
  let base = [
    // system inlet flow -> polymer addition
    { id: 'inlet_to_polymer', style: edgeStyle, source: 'inlet_flows', target: 'polymer_addition', markerEnd: markerStyle, type: 'smoothstep' },
    // ammonia loss -> polymer addition
    { id: 'ammonia_loss_to_poly', style: edgeStyle, source: 'polymer_addition3', target: 'ammonia_loss', markerEnd: markerStyle, type: 'smoothstep' },
    // polymer system -> polymer addition
    { id: 'poly_sys_to_poly_add', style: edgeStyle, source: 'polymer_system', target: 'polymer_addition3', markerEnd: markerStyle, type: 'smoothstep' },
    // polymer addition -> after polymer addition
    { id: 'poly_add_to_after_poly_add', style: edgeStyle, source: 'polymer_addition2', target: 'after_polymer', markerEnd: markerStyle, type: 'smoothstep' },
    // after poly addtion -> filtrate recycling loop
    { id: 'after_poly->filtrate_recycling', style: edgeStyle, source: 'after_polymer', target: 'filtrate_recycling_loop', markerEnd: markerStyle, type: 'smoothstep' },
    // filtrate recycling loop -> ms inlet
    { id: 'recycling->ms_inlet', style: edgeStyle, source: 'filtrate_recycling_loop2', target: 'ms_inlet', markerEnd: markerStyle, type: 'smoothstep' },
    // ms inlet -> microscreen
    { id: 'ms_inlet->microscreen', style: edgeStyle, source: 'ms_inlet', target: 'microscreen', markerEnd: markerStyle, type: 'smoothstep' },
    // ms -> solids
    { id: 'microscreen->solids', style: edgeStyle, source: 'microscreen', target: 'ms_solids', markerEnd: markerStyle, type: 'smoothstep' },
    // ms -> ms filt
    { id: 'microscreen->filtrate', style: edgeStyle, source: 'microscreen2', target: 'ms_filtrate', markerEnd: markerStyle, type: 'smoothstep' },
    // ms solids -> screw press
    { id: 'ms_solids->screw_press', style: edgeStyle, source: 'ms_solids', target: 'screw_press', markerEnd: markerStyle, type: 'smoothstep' },
    // sp -> sp solids
    { id: 'screw_press->solids', style: edgeStyle, source: 'screw_press2', target: 'sp_solids', markerEnd: markerStyle, type: 'smoothstep' },
    // sp -> sp filtrate
    { id: 'screw_press->filtrate', style: edgeStyle, source: 'screw_press', target: 'sp_filtrate', markerEnd: markerStyle, type: 'smoothstep' },

  ];


  if (!sysParams.screwPressRemoved && sysParams.screwPressLoop) {
    // only add the recycling arrow if the screw press is not removed and iteration is turned on
    base.push(
      // sp filtrate -> recycling
      { id: 'sp_filtrate->recycling', style: edgeStyle, source: 'sp_filtrate', target: 'filtrate_recycling_loop2', markerEnd: markerStyle, type: 'smoothstep' }
    )
  } else if(!sysParams.screwPressRemoved) {
    // we added to ms filtrate from sp filtrate so show that arrow
    base.push(
      { id: 'sp_filtrate->ms_filtrate', style: edgeStyle, source: 'sp_filtrate2', target: 'ms_filtrate2', markerEnd: markerStyle, type: 'smoothstep' }
    )
  }

  return base;
}
