import React from "react";
import { allowedPermissionList } from "./AllowedPermissionsList";
import Select from "react-select";
import { convertKeyNameToDisplayName } from "../../field-tools/TimeReporting/TimeReportFunctions";

export const UserPermissionSelect = ({
  newUser,
  setNewUser,
  allPermission,
}) => {
  // creates the premission list to select multiple permissions
  const createPermissionsList = (permissionsList) => {
    return permissionsList.map((permission) => ({
      value: permission,
      label: convertKeyNameToDisplayName(permission),
    }));
  };

  // checks to check with permission is selected by default
  const isSelectedPermission = (permission) => {
    return permission == newUser.permissions ? { selected: true } : {};
  };

  const getProperPermissionName = (permission) => {
    return permission
      .replaceAll(" ", "")
      .split(",")
      .map((permissionName) => convertKeyNameToDisplayName(permissionName))
      .toString()
      .replaceAll(",", ", ");
  };

  // change permission
  const changePermission = (newPermission) => {
    newUser.permissions = newPermission;
    newUser.morePermissions = [];
    setNewUser({ ...newUser });
  };

  return (
    <div className="pt-2">
      Permission:
      <select
        className="border rounded p-1 w-100"
        onChange={(e) => changePermission(e.target.value)}
      >
        {allPermission.map((permission) => (
          <option {...isSelectedPermission(permission)} value={permission}>
            {getProperPermissionName(permission)}
          </option>
        ))}
      </select>
      {allowedPermissionList[newUser.permissions] ? (
        <>
          <div className="pt-2">More Permissions:</div>
          <Select
            isMulti
            value={newUser.morePermissions}
            options={createPermissionsList(
              allowedPermissionList[newUser.permissions]
            )}
            onChange={(value) => {
              newUser.morePermissions = value;
              setNewUser({ ...newUser });
            }}
          />
        </>
      ) : (
        <div className="pt-2 text-danger">
          User cannot have additional permissions
        </div>
      )}
    </div>
  );
};
