import React, { useEffect, useState } from "react";
import "../styles/MainDashboard.css";
import { Card, Row, Col } from "react-bootstrap";
import NewsSection from "../pages/NewsSection";
import { useAuth } from "../contexts/AuthContext";
import { BsClipboardData as AnalyticsIcon } from "react-icons/bs";
import { RiBuildingLine as Farm } from "react-icons/ri";
import { GiChemicalTank as ChemIcon } from "react-icons/gi";
import {GiChecklist as ReportIcon } from "react-icons/gi";
import { MdOutlineSettingsInputAntenna } from "react-icons/md";
import {
  getUserPermissionFromCache,
  isPermissionTypeDistributor,
} from "../utility/permissionFunctions";
import TwoFactorSignupModal from "../components/auth/TwoFactorSignupModal";

// api
import { getSensorDataDocumentCount } from "../api/sensordata";
import { getTotalIotCount } from "../api/iot";
import { getTotalFarmCount } from "../api/farms";
import { getTotalChemicalCount } from "../api/chemicals";
import { getAssessmentReportCount } from "../api/reports";

export default function MainDashboard(props) {
  const { currentUser } = useAuth();

  const [show2FASignUp, setShow2FASignUp] = useState(false);

  useEffect(() => {
    if(currentUser.multiFactor.enrolledFactors.length === 0) {
      setShow2FASignUp(true);
    };
  }, [currentUser]);

  useEffect(async () => {
    document.title = "Main Dashboard";
    await getUserPermissionFromCache(currentUser.email);
    if (!isPermissionTypeDistributor(props.accountType)) {
      Promise.all([
        getTotalSensorDataDocumentCount(),
        getTotalIoTCount(),
        getFarmCount(),
        getTotalChemCount(),
        getReportCount(),
      ]);
    }
  }, []);

  const [numIot, setNumIot] = useState(0);
  const [numDataPoints, setNumDataPoints] = useState(0);
  const [numFarms, setNumFarms] = useState(0);
  const [numChemicals, setNumChemicals] = useState(0);
  const [numReports, setNumReports] = useState(0);

  const getTotalSensorDataDocumentCount = async () => {
    const c = await getSensorDataDocumentCount();
    setNumDataPoints(c);
  };

  const getTotalIoTCount = async () => {
    const c = await getTotalIotCount();
    setNumIot(c);
  };

  const getFarmCount = async () => {
    const c = await getTotalFarmCount();
    setNumFarms(c);
  };

  const getTotalChemCount = async () => {
    const c = await getTotalChemicalCount();
    setNumChemicals(c);
  };

  const getReportCount = async () => {
    const c = await getAssessmentReportCount();
    setNumReports(c);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getGreeting = () => {
    var d = new Date();
    var hours = d.getHours();

    if (hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", height: "100vh", zoom: '85%' }}>
      <TwoFactorSignupModal show={show2FASignUp} setShow={setShow2FASignUp}/>
      <div className="row" style={{ padding: "1%" }}>
        <div className="col-md-11" style={{margin: 'auto'}}>
          <div className="row">
            <div className="col-md-12">
              <Card>
                <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Main Dashboard</Card.Header>
                <Card.Body>
                  <Card.Title>
                    <Row>
                      <Col md={6}>{getGreeting()}</Col>
                    </Row>
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {!isPermissionTypeDistributor(props.accountType) && (
        <div className="row" style={{ padding: "1%" }}>
          <div className="col-md-11" style={{margin: 'auto'}}>
            <Card>
              <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Database Statistics</Card.Header>
              <Card.Body>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <MdOutlineSettingsInputAntenna size={80} color="#346170" />
                    <h5 style={{ fontSize: "large", paddingTop: "2%" }}>
                      {numberWithCommas(numIot)} <br />
                      IoT Devices
                    </h5>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <AnalyticsIcon size={80} color="#346170" />
                    <h5 style={{ fontSize: "large", paddingTop: "2%" }}>
                      {numberWithCommas(numDataPoints)}
                      <br />
                      Sensor Data Points
                    </h5>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <ReportIcon size={80} color="#346170" />
                    <h5 style={{ fontSize: "large", paddingTop: "2%" }}>
                      {numberWithCommas(numReports)}
                      <br />
                      Assessment Reports
                    </h5>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <Farm size={80} color="#346170" />
                    <h5 style={{ fontSize: "large", paddingTop: "2%" }}>
                      {numberWithCommas(numFarms)}
                      <br />
                      Client Farms
                    </h5>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <ChemIcon size={80} color="#346170" />
                    <h5 style={{ fontSize: "large", paddingTop: "2%" }}>
                      {numberWithCommas(numChemicals)}
                      <br />
                      Chemicals
                    </h5>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
      <div className="row" style={{ padding: "1%" }}>
        <div className="col-md-11" style={{margin: 'auto'}}>
          <Card>
            <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>LWR Featured News</Card.Header>
            <Card.Body>
              <div className="row">
                <NewsSection />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
