import http from "./http";

const { REACT_APP_LWR_API_BASE_URL, REACT_APP_LWR_API_KEY } = process.env
const axios = require('axios').default;

export const getAllPolicy = async (email) => {
    return http.get(`hr-tools/hr-policy/?email=${email}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getAllUserPolicy = async (_id, email) => {
    return http.get(`hr-tools/find-hr-policy/?_id=${_id}&email=${email}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getPolicyPDFByID = async (_id, email) => {
    return http.get(`hr-tools/policyManual/?_id=${_id}&email=${email}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const createNewHRPolicy = async (formData) => {
    return axios({
        url: `${REACT_APP_LWR_API_BASE_URL}hr-tools/hr-policy`,
        method: "post",
        data: formData,
        headers: {
            Accept: "application/json",
            'Authorization': `Basic ${REACT_APP_LWR_API_KEY}`
        }
    })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const updatePolicy = async (body) => {
    return http.put(`hr-tools/hr-policy/`, body)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const updateFavorite = async (_id, email, favorite) => {
    return http.put(`hr-tools/hr-policy-favorite/?_id=${_id}&email=${email}&favorite=${favorite}`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export const acknowledgePolicy = async (body) => {
    return http.put(`hr-tools/hr-acknolwedge-policy/`, body)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const deleteHRPolicy = async (_id) => {
    return http.delete(`hr-tools/hr-policy/?_id=${_id}`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};