/**
 * FarmsInsertModal.jsx
 * --------------------
 * Modal component that contains functionality for inserting farms into the LWR database.
 * This data insertion is done via a POST request to the LWR API.
 *
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */
import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Alert, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// api
import { getAllOrganizations } from "../../../api/organizations";
import { createFarm } from "../../../api/farms";

export default function FarmsInsertModal(props) {
  // History
  const history = useHistory();

  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [orgElements, setOrgElements] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");

  // Refs
  const farmNameRef = useRef();
  const contactNameRef = useRef();
  const contactNumberRef = useRef();
  const contactEmailRef = useRef();
  const addressRef = useRef();
  const latRef = useRef();
  const longRef = useRef();
  const numSytsemRef = useRef();
  const regionCodeRef = useRef();

  // Executes on component load
  useEffect(() => {
    getAllOrgIds();
  }, []);

  const handleClose = () => {
    setShow(false);
    history.push("/admin-tools/farms");
    setErrMsg("");
    setSuccessMsg("");
  };

  const handleShow = () => {
    setShow(true);
  };

  const clearForm = () => {
    farmNameRef.current.value = "";
    contactNameRef.current.value = "";
    contactNumberRef.current.value = "";
    contactEmailRef.current.value = "";
    addressRef.current.value = "";
    latRef.current.value = "";
    longRef.current.value = "";
    numSytsemRef.current.value = "";
  };

  const getAllOrgIds = async () => {
    const allOrgs = await getAllOrganizations();
    let orgsList = [];
    allOrgs.forEach((org) => {
      orgsList.push(org.name);
    });
    if (orgsList.length > 0) {
      setSelectedOrg(orgsList[0]);
    }
    createOrgDropDownElements(orgsList);
  };

  const onOrgIdSelectChange = (e) => {
    setSelectedOrg(e.target.value);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let name = farmNameRef.current.value;
    let address = addressRef.current.value;
    let contact = contactNameRef.current.value;
    let contactNumber = contactNumberRef.current.value;
    let contactEmail = contactEmailRef.current.value;
    let region = regionCodeRef.current.value;
    let organizationId = selectedOrg;
    let lat = latRef.current.value;
    let long = longRef.current.value;
    let numSystems = numSytsemRef.current.value;

    const bodyValues = {
      regionId: region,
      organizationId,
      name,
      numSystems,
      address,
      lat,
      long,
      owner: contact,
      contactNumber,
      contactEmail,
    };

    const createFarmResult = await createFarm(bodyValues);
    if (createFarmResult.status != 200) {
      setErrMsg("Error inserting new Farm into database.");
    } else {
      setSuccessMsg(
        `Succesfully inserted Farm: ${name} into the database.`
      );
      clearForm();
      props.onEnd();
    }
  };

  const createOrgDropDownElements = (orgs) => {
    let elements = [];
    orgs.forEach((org) => {
      elements.push(<option>{org}</option>);
    });
    setOrgElements(elements);
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New Farm
      </Button>

      <Modal size="lg" show={show} onHide={handleClose} style={{ zoom: '80%' }}>
        <Modal.Header>
          <Modal.Title style={{ color: '#005E7D' }}>Add New Farm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                {errMsg && <Alert variant="danger">{errMsg}</Alert>}
                {successMsg && <Alert variant="success">{successMsg}</Alert>}
              </Col>
            </Row>
            <Row>
              <Form onSubmit={onFormSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Farm Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter farm name"
                        ref={farmNameRef}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Parent Organization</Form.Label>
                      <Form.Control as="select" onChange={onOrgIdSelectChange}>
                        {orgElements}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Main Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact name"
                    ref={contactNameRef}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Contact Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter contact Email"
                    ref={contactEmailRef}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Contact Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter contact number"
                    ref={contactNumberRef}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter farm address"
                    ref={addressRef}
                    required
                  />
                </Form.Group>
                <br />
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Region Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="region code"
                        ref={regionCodeRef}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Latitude</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.00001}
                        placeholder="latitude"
                        ref={latRef}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label style={{ color: '#005E7D' }}>Longitude</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.00001}
                        placeholder="longitude"
                        ref={longRef}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Form.Group>
                  <Form.Label style={{ color: '#005E7D' }}>Number of systems</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Number of systems"
                    ref={numSytsemRef}
                    required
                  />
                </Form.Group>
                <br />
                <Row>
                  <Col>
                    <Button variant="outline-dark" type="submit">
                      Update
                    </Button>
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
