import React, { useEffect } from "react";
import "../../../../../styles/EngAssessment.css";

import { Table } from "react-bootstrap";

export default function ConstituentMassBalance(props) {
  let { setErrorVal } = props;

  useEffect(() => {
    // reset error val every time
    setErrorVal([]);
    checkErrorVals();
  }, [props.data]);

  // check if the error values for constituent mass balance is different than expected
  function checkErrorVals() {
    // iterate through the props and check the error values for each compound
    for (const property in props.data) {
      let currentErrorValue = props.data[`${property}`]["% Error"];
      switch (currentErrorValue <= 1) {
        case false:
          setErrorVal((curr) => [...curr, [property, currentErrorValue]]);
          break;
        default:
          break;
      }
    }
  }
  return (
    <>
      <div className="col-md-9">
        <h5 style={{ textAlign: "left", color: "#005E7D" }}> Constituent Mass Balance ({props.units})</h5>
        <Table striped style={{height: '88%'}}>
          <tr>
            <th scope="col"
              style={{ textAlign: "left", color: 'white', backgroundColor: '#005E7D' }}
            >
              Constituent
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Input
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Dry Polymer (IN)
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Loss
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              SP. Solids 
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Liquid Nutrient 
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Water 
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Balance
            </th>
            <th scope="col"
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              Error 
              (%)
            </th>
          </tr>
          <tbody style={{textAlign: 'center'}}>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}>TSS</td>
              <td className="treatment-row">{props.data["TSS"]["Input"]}</td>
              <td>{Number(props.polymer["Dry Grō 17 Mass Flow"]).toFixed(6)}</td>
              <td>0</td>
              <td className="treatment-row">{props.data["TSS"]["Solids"]}</td>
              <td className="treatment-row">{props.data["TSS"]["Liquids"]}</td>
              <td className="treatment-row">{props.data["TSS"]["Water"]}</td>
              <td className="treatment-row">{props.data["TSS"]["Balance"]}</td>
              <td className="treatment-row">{props.data["TSS"]["% Error"]}</td>
            </tr>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}>Ammonium Nitrogen</td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["Input"]}
              </td>
              <td>0</td>
              <td>{(Number(props.inputMassFlows['Dissolved Solids']['Ammonium N Mass Flow']) * 0.15).toFixed(6)}</td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["Solids"]}
              </td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["Liquids"]}
              </td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["Water"]}
              </td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["Balance"]}
              </td>
              <td className="treatment-row">
                {props.data["Ammonium Nitrogen"]["% Error"]}
              </td>
            </tr>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}>Organic Nitrogen</td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["Input"]}
              </td>
              <td>0</td>
              <td>0</td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["Solids"]}
              </td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["Liquids"]}
              </td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["Water"]}
              </td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["Balance"]}
              </td>
              <td className="treatment-row">
                {props.data["Organic Nitrogen"]["% Error"]}
              </td>
            </tr>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}> Potassium </td>
              <td className="treatment-row">
                {props.data["Potassium"]["Input"]}
              </td>
              <td>0</td>
              <td>0</td>
              <td className="treatment-row">
                {props.data["Potassium"]["Solids"]}
              </td>
              <td className="treatment-row">
                {props.data["Potassium"]["Liquids"]}
              </td>
              <td className="treatment-row">
                {props.data["Potassium"]["Water"]}
              </td>
              <td className="treatment-row">
                {props.data["Potassium"]["Balance"]}
              </td>
              <td className="treatment-row">
                {props.data["Potassium"]["% Error"]}
              </td>
            </tr>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}> Phosphorus</td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["Input"]}
              </td>
              <td>0</td>
              <td>0</td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["Solids"]}
              </td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["Liquids"]}
              </td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["Water"]}
              </td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["Balance"]}
              </td>
              <td className="treatment-row">
                {props.data["Phosphorus"]["% Error"]}
              </td>
            </tr>
            <tr>
              <td className="treatment-row" style={{ fontWeight: 'bold', textAlign: "left"}}> Other</td>
              <td className="treatment-row">{props.data["Other"]["Input"]}</td>
              <td>0</td>
              <td>0</td>
              <td className="treatment-row">{props.data["Other"]["Solids"]}</td>
              <td className="treatment-row">
                {props.data["Other"]["Liquids"]}
              </td>
              <td className="treatment-row">{props.data["Other"]["Water"]}</td>
              <td className="treatment-row">
                {props.data["Other"]["Balance"]}
              </td>
              <td className="treatment-row">
                {props.data["Other"]["% Error"]}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
