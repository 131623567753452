/**
 * work-orders/FilterCard.jsx
 * --------------------------
 * Component encapsulating filtering functionality for the WO list page
 * 
 * Author: Oscar Jaimes
 */

import { useEffect, useState } from "react";
import { Badge, Form, Row, Col, Button } from "react-bootstrap";

// api
import { getAvailableFilters, getAllWorkOrders, getFilteredWorkOrders, getAllJobNumbers } from "../../api/workOrders";

const FilterCard = ({ reset, filter, handleFilterChange, setWorkOrders, setLoading, setFilter, availableFilters, setAvailableFilters }) => {
  // component state
  const [show, setShow] = useState(true);

  // filter states
  const [selectedJobNumber, setSelectedJobNumber] = useState(() => sessionStorage.getItem('selectedJobNumber') || null);
  const [selectedOrderedBy, setSelectedOrderedBy] = useState(() => sessionStorage.getItem('selectedOrderedBy') || null);
  const [selectedOrderDate, setSelectedOrderDate] = useState(() => sessionStorage.getItem('selectedOrderDate') || null);
  const [jobNumbers, setJobNumbers] = useState([]);
  const [numFilters, setNumFilters] = useState(0);

  const load = async () => {
    setWorkOrders([]);
    setLoading(true);

    const jn = selectedJobNumber == "null" ? null : selectedJobNumber;
    const ob = selectedOrderedBy == "null" ? null : selectedOrderedBy;
    const od = selectedOrderDate == "null" ? null : selectedOrderDate;

    const filteredWos = await getFilteredWorkOrders({
      selectedJobNumber: jn,
      selectedOrderDate: od,
      selectedOrderedBy: ob
    });

    let filts = 0;
    [jn, ob, od].map((f) => { if(f) filts ++; });
    setNumFilters(filts);
    setWorkOrders(filteredWos);
    setLoading(false);
  }

  // effects
  useEffect(() => {
    const loadFilters = async () => {
      const f = await getAvailableFilters();
      setAvailableFilters(f);
    };
    fetchAllJobNumbers();
    loadFilters();
  }, []);

  useEffect(() => {
    if (selectedJobNumber || selectedOrderedBy || selectedOrderDate) {
      load();
    } else {
      resetFilters({ preventDefault: () => { } });
    }
  }, []);

  useEffect(() => {
    if (!reset) {
      resetFilters({ preventDefault: () => { } });
    }
  }, [reset]);

  const fetchAllJobNumbers = async () => {
    const res = await getAllJobNumbers();
    setJobNumbers(res.data.map((j) => j.jobNumber));
  };

  // onClick handlers
  const onFilterSubmit = async (e) => {
    e.preventDefault();
    load();
  };

  const resetFilters = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFilter('');
    setSelectedJobNumber(null);
    setSelectedOrderedBy(null);
    setSelectedOrderDate(null);
    sessionStorage.removeItem('selectedJobNumber');
    sessionStorage.removeItem('selectedOrderedBy');
    sessionStorage.removeItem('selectedOrderDate');
    const wos = await getAllWorkOrders();
    setWorkOrders(wos.data);
    setLoading(false);
    setNumFilters(0);
  };

  // component
  return (
    <Row>
      <Col md={2}>
        <div>
          <Form.Control
            as="select"
            value={selectedJobNumber || ''}
            onChange={(e) => {
              const value = e.target.value === "" ? null : e.target.value;
              setSelectedJobNumber(value)
              sessionStorage.setItem('selectedJobNumber', value);
            }
            }
          >
            <option value="">Select Job Number</option>
            {jobNumbers.map((jn) => <option value={jn}>{jn}</option>)}
          </Form.Control>
        </div>
      </Col>
      <Col md={2}>
        <div style={{ marginTop: '1%' }}>
          <Form.Control
            type="date"
            value={selectedOrderDate || ''}
            onChange={(e) => {
              setSelectedOrderDate(e.target.value)
              sessionStorage.setItem('selectedOrderDate', e.target.value);
            }}
          >
          </Form.Control>
        </div>
      </Col>
      <Col md={2}>
        <div>
          <Form.Control
            as="select"
            value={selectedOrderedBy || ''}
            onChange={(e) => {
              const value = e.target.value === "" ? null : e.target.value;
              setSelectedOrderedBy(value);
              sessionStorage.setItem('selectedOrderedBy', value);
            }
            }
          >
            <option value="">Select Ordered By</option>
            {availableFilters.orderedBy.map((ob) => <option value={ob}>{ob}</option>)}
          </Form.Control>
        </div>
      </Col>
      <Col md={4}>
        <Form.Control
          type="text"
          placeholder="Filter by job number, date, or creator"
          value={filter}
          onChange={handleFilterChange}
        />
      </Col>
      <Col md={1}>
        <Button style={{ width: '100%' }} variant="outline-success" onClick={onFilterSubmit}>
          Filter <Badge bg="success">{numFilters}</Badge>
        </Button>
      </Col>
      <Col md={1}>
        <Button style={{ width: '100%' }} variant="outline-secondary" onClick={resetFilters}>Clear Filters</Button>
      </Col>
    </Row>
  );
};

export default FilterCard;