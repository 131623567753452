/**
 * auth/TwoFactorSignupModal.jsx
 * -----------------------------
 * Component for users to register their phone numbers as a second factor
 * for Firebase authentication.
 * 
 */

import { useRef, useState } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../firebase.js"
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier
} from "firebase/auth";
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'

const TwoFactorSignupModal = ({ show, setShow }) => {
  const { currentUser: user, logout } = useAuth();
  const codeRef = useRef();

  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const on2FASubmit = (e) => {
    e.preventDefault();
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', { size: 'invisible' });
    multiFactor(user).getSession()
      .then(function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession
        };

        setPhoneNumber(phoneInfoOptions.phoneNumber);
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      }).then(function (verificationId) {
        setVerificationId(verificationId);
        setPhoneNumberSubmitted(true);
      }).catch(async (error) => {
        console.log(error);
        if (error.code === 'auth/unverified-email') {
          await user.sendEmailVerification();
          alert('In order to add a second factor, you must first verify your email address. You will recieve an email with a link to verify your account.');
          await logout();
        } else {
          alert(error);
        }
      })
  }

  const codeVerify = (e) => {
    e.preventDefault();
    // Ask user for the verification code. Then:
    const cred = PhoneAuthProvider.credential(verificationId, codeRef.current.value);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete enrollment.
    multiFactor(user).enroll(multiFactorAssertion, 'Phone Number').then(() => {
      alert(`Succesfully Added 2-Factor Authentication With ${phoneNumber}`);
    }).catch((err) => {
      console.log(err);
      alert(`Error. Could Not Add 2-Factor Authentication: ${err}`);
    })

    setShow(false);
  }

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <div>
          {!phoneNumberSubmitted && <Form onSubmit={on2FASubmit}>
            <Alert>LWR is enhancing it's security practices. From now on, you will need to provide a code sent to your phone number to log in. Enter the phone number you would like to use as your second factor.</Alert>
            <Form.Group className="mb-3" controlId="2faNumber">
              <Form.Label>Phone Number</Form.Label>
              <br />
              <Input
                country="CA"
                placeholder="ex. 403-123-4567"
                value={phoneNumber}
                onChange={setPhoneNumber}
                required
              />
            </Form.Group>
            <Button type="submit" variant="outline-success">
              Submit
            </Button>
            <Button onClick={async () => { await logout(); }} variant="outline-secondary">
              Cancel/Logout
            </Button>
          </Form>}

          {
            phoneNumberSubmitted && <Form onSubmit={codeVerify}>
              <Alert variant="warning">Please allow up to 1 minute to recieve the verification code to {phoneNumber}</Alert>
              <Form.Group className="mb-3" controlId="2faCode">
                <Form.Label>2FA Verification Code</Form.Label>
                <Form.Control type="text" ref={codeRef} />
              </Form.Group>
              <Button variant="outline-success" type="submit">
                Submit
              </Button>
            </Form>
          }
        </div>
        {!phoneNumberSubmitted && <div id="recaptcha-container"></div>}
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorSignupModal;