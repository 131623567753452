import React from "react";
import "../../../../../styles/EngAssessment.css";

import { Table } from "react-bootstrap";

export default function NutrientConcentration(props) {
  const isFW = props.sysType !== "First Wave";
  return (
    <>
      <div className="col-md-12" style={{ paddingTop: "0%" }}>
        {props.outputLabel == true ? (
          <>
            <h5
              style={{
                textAlign: "left",
                color: "#005E7D",
                fontWeight: "bold",
              }}
            >
              {props.language?.output_data || 'Output Data'}
            </h5>
          </>
        ) : (
          ""
        )}

        <h5 style={{ textAlign: "left", color: "#005E7D" }}>{props.language?.nutrient_concentration || "Nutrient Concentrations"}</h5>
        <Table className="table" striped>
          <tr>
            <th scope="col"  style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}>
              {props.language?.nutrient || 'Nutrient'}
            </th>
            <th
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}
              scope="col"
            >
              {props.language?.inputs || 'Inputs'} (
              {props.concentration["Inlet Flush"]["Units"]})
            </th>
            <th
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}
              scope="col"
            >
              {props.language?.liquid_nutrient || "Liquid Nutrient"} ({props.concentration["Liquids"]["Units"]})
            </th>
            <th
              style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}
              scope="col"
            >
              {props.language?.nutrient_solids || "Nutrient Solids"} ({props.concentration["Solids"]["Units"]})
            </th>
            {isFW ? (
              <th
                style={{ textAlign: "center", color: 'white', backgroundColor: '#005E7D' }}
                scope="col"
              >
                {props.language?.clean_water || 'Clean Water'} ({props.concentration["Water"]["Units"]})
              </th>
            ) : null}
          </tr>
          <tbody>
            <tr>
              <td className="header">{props.language?.ammonium_nitrogen || 'Ammonium Nitrogen'}</td>
              <td
                className="header"
                style={{ textAlign: "center" }}
              >
                {props.concentration["Inlet Flush"][
                  "Ammonium N Concentration"
                ] != null
                  ? props.concentration["Inlet Flush"][
                    "Ammonium N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Liquids"]["Ammonium N Concentration"] !=
                  null
                  ? props.concentration["Liquids"][
                    "Ammonium N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Solids"]["Ammonium N Concentration"] !=
                  null
                  ? props.concentration["Solids"][
                    "Ammonium N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              {isFW ? (
                <td className="header" style={{ textAlign: "center" }}>
                  {props.concentration["Water"]["Ammonium N Concentration"] !=
                    null
                    ? props.concentration["Water"][
                      "Ammonium N Concentration"
                    ].toFixed(3)
                    : 0}
                </td>
              ) : null}
            </tr>
            <tr>
              <td className="header">{props.language?.organic_nitrogen || "Organic Nitrogen"}</td>
              <td
                className="header"
                style={{ textAlign: "center" }}
              >
                {props.concentration["Inlet Flush"][
                  "Organic N Concentration"
                ] != null
                  ? props.concentration["Inlet Flush"][
                    "Organic N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Liquids"]["Organic N Concentration"] !=
                  null
                  ? props.concentration["Liquids"][
                    "Organic N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Solids"]["Organic N Concentration"] !=
                  null
                  ? props.concentration["Solids"][
                    "Organic N Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              {isFW ? (
                <td className="header" style={{ textAlign: "center" }}>
                  {props.concentration["Water"]["Organic N Concentration"] !=
                    null
                    ? props.concentration["Water"][
                      "Organic N Concentration"
                    ].toFixed(3)
                    : 0}
                </td>
              ) : null}
            </tr>
            <tr>
              <td className="header"> {props.language?.phosphorus || 'Phosphorus as P205'} </td>
              <td
                className="header"
                style={{ textAlign: "center" }}
              >
                {props.concentration["Inlet Flush"][
                  "Phosphorus Concentration"
                ] != null
                  ? props.concentration["Inlet Flush"][
                    "Phosphorus Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Liquids"]["Phosphorus Concentration"] !=
                  null
                  ? props.concentration["Liquids"][
                    "Phosphorus Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Solids"]["Phosphorus Concentration"] !=
                  null
                  ? props.concentration["Solids"][
                    "Phosphorus Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              {isFW ? (
                <td className="header" style={{ textAlign: "center" }}>
                  {props.concentration["Water"]["Phosphorus Concentration"] !=
                    null
                    ? props.concentration["Water"][
                      "Phosphorus Concentration"
                    ].toFixed(3)
                    : 0}
                </td>
              ) : null}
            </tr>
            <tr>
              <td className="header"> {props.language?.potassium || 'Potassium as K20'} </td>
              <td
                className="header"
                 style={{ textAlign: "center" }}
              >
                {props.concentration["Inlet Flush"][
                  "Potassium Concentration"
                ] != null
                  ? props.concentration["Inlet Flush"][
                    "Potassium Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Liquids"]["Potassium Concentration"] !=
                  null
                  ? props.concentration["Liquids"][
                    "Potassium Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Solids"]["Potassium Concentration"] !=
                  null
                  ? props.concentration["Solids"][
                    "Potassium Concentration"
                  ].toFixed(3)
                  : 0}
              </td>
              {isFW ? (
                <td className="header" style={{ textAlign: "center" }}>
                  {props.concentration["Water"]["Potassium Concentration"] !=
                    null
                    ? props.concentration["Water"][
                      "Potassium Concentration"
                    ].toFixed(3)
                    : 0}
                </td>
              ) : null}
            </tr>
            <tr>
              <td className="header"> {props.language?.volatile_solids || 'Volatile Solids'} </td>
              <td
                className="header"
                 style={{ textAlign: "center" }}
              >
                {props.concentration["Inlet Flush"]["Volatile Solids"] != null
                  ? props.concentration["Inlet Flush"][
                    "Volatile Solids"
                  ].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Liquids"]["Volatile Solids"] != null
                  ? props.concentration["Liquids"]["Volatile Solids"].toFixed(3)
                  : 0}
              </td>
              <td className="header" style={{ textAlign: "center" }}>
                {props.concentration["Solids"]["Volatile Solids"] != null
                  ? props.concentration["Solids"]["Volatile Solids"].toFixed(3)
                  : 0}
              </td>
              {isFW ? (
                <td className="header" style={{ textAlign: "center" }}>
                  {props.concentration["Water"]["Volatile Solids"] != null
                    ? props.concentration["Water"]["Volatile Solids"].toFixed(3)
                    : 0}
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
