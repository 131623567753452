import http from "./http";

const { REACT_APP_LWR_API_BASE_URL, REACT_APP_LWR_API_KEY } = process.env
const axios = require('axios').default;

export const getAllTimesheet = async (email) => {
    return http.get(`hr-tools/hr-timesheet/?email=${email}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getTimesheetByID = async (_id, email) => {
    return http.get(`hr-tools/hr-timesheet-data/?_id=${_id}&email=${email}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const checkTimesheetDate = async (body) => {
    return http.post(`hr-tools/hr-check-timesheet-date/`, body)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const createNewTimesheet = async (formData) => {
    return axios({
        url: `${REACT_APP_LWR_API_BASE_URL}hr-tools/hr-timesheet`,
        method: "post",
        data: formData,
        headers: {
            Accept: "application/json",
            'Authorization': `Basic ${REACT_APP_LWR_API_KEY}`
        }
    })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const deleteTimesheet = async (_id, email) => {
    return http.delete(`hr-tools/hr-timesheet/?_id=${_id}&email=${email}`)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};