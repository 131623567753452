import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { editUserPermission } from "../../../api/admin";
import { convertKeyNameToDisplayName } from "../../field-tools/TimeReporting/TimeReportFunctions";
import { getUserPermission } from "../../../utility/permissionFunctions";
import { UserPermissionSelect } from "./UserPermissionSelect";

export const UserPermissionEditModal = ({
  user,
  allPermission,
  users,
  setUsers,
}) => {
  useEffect(() => {}, [allPermission]);

  const createPermissionsList = (permissionsList) => {
    return permissionsList.map((permission) => ({
      value: permission,
      label: convertKeyNameToDisplayName(permission),
    }));
  };

  const [newUser, setNewUser] = useState({
    ...user,
    permissions: getUserPermission(user)[0],
    morePermissions: createPermissionsList(getUserPermission(user)).slice(1),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const updatePermission = (email, newPermission) => {
    let newPermissionArray = newPermission.replaceAll(" ", "").split(",");
    newPermissionArray = newPermissionArray.concat(
      newUser.morePermissions.map((permissionDict) => permissionDict.value)
    );
    const body = {
      email,
      newPermission: newPermissionArray,
    };
    editUserPermission(body).then((res) => {
      if (res.status == 200) {
        let _users = [...users];
        _users = _users.map((existingUser) =>
          user.email == existingUser.email
            ? { ...existingUser, permissions: newPermissionArray }
            : existingUser
        );
        setUsers(_users);
        alert("User permissions updated succesfully.");
      } else {
        alert("User Permissions could not be changed. Please try again later!");
      }
    });
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} size="lg" style={{ zoom: '85%' }}>
        <Modal.Header>
          <Modal.Title>Edit Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">Email: {newUser.email}</div>
          <UserPermissionSelect
            newUser={newUser}
            setNewUser={setNewUser}
            allPermission={allPermission}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              updatePermission(newUser.email, newUser.permissions);
              handleClose();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
