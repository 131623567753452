import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Button, Spinner, Modal } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { useHistory } from "react-router-dom";
import ReportDeleteModal from "./ReportDeleteModal";
import SavedAssessmentFilter from "./SavedAssessmentFilter";

import { MdAssignmentAdd, MdOpenInNew } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';

import AssesmentReportingPage from "./AssesmentReportingPage";

// api
import { getAllAssessmentReports } from "../../api/reports";
import { getAllAdmins } from "../../api/admin";

const SavedReports = () => {
  // History hook
  const history = useHistory();

  const defaultReportTypes = ["eng", "client", "hubSpoke"];

  // State
  const [loadingReports, setLoadingReports] = useState(true);
  const [assessmentCards, setAssessmentCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [assessments, setAssessments] = useState([]);
  const [showLatestDate, setShowLatestDate] = useState(true);
  const [showDisplayedReports, setShowDisplayedReports] = useState(defaultReportTypes);
  const [open, setOpen] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [showIndividualAssessment, setShowIndividualAssessment] = useState("all");
  const [isProject, setIsProject] = useState(true);
  // Refs
  const searchRef = useRef();

  // On Component Load
  useEffect(() => {
    document.title = "Saved Assessments";
    fetchAssessments();
    fetchAllAdmins();
  }, [
    showLatestDate,
    showDisplayedReports,
    showIndividualAssessment,
    isProject,
  ]);

  // Functions
  const fetchAssessments = async () => {
    const savedAssessments = await getAllAssessmentReports();

    if (savedAssessments.status != 200) {
      alert("Error fetching saved assessments from database");
    } else {
      let assessments = savedAssessments.data;
      setAssessments(assessments);
      createAssessmentCards(assessments);
    }

    setLoadingReports(false);
  };

  const fetchAllAdmins = async () => {
    const allAdmins = await getAllAdmins();

    if (allAdmins.status != 200) {
      alert("Error fetching saved all admins from database");
    } else {
      let admins = allAdmins.data;
      setAllAdmins(
        admins
          .map((person) => person.email)
          .sort(function (a, b) {
            return a.localeCompare(b);
          })
      );
    }
  };

  const createAssessmentCards = (assessments) => {
    let assessmentCards = [];
    // Sort by descending date (newest to oldest, then create components based on type) if showLatestDate is true, else show the oldest document
    assessments
      .sort((a, b) => {
        return returnDateType(a, b);
      })
      .forEach((assessment) => {
        if (
          showIndividualAssessment === "all" ||
          assessment.createdBy === showIndividualAssessment
        ) {
          // shows all assessments if isProject === true
          // show non project if it is false
          if (isProject || isProject === assessment.isProject) {
            if (showDisplayedReports.includes(assessment.type)) {
              assessmentCards.push(
                <EngReportCard assessment={assessment} />
              );
            }
          }
        }
      });
    setAssessmentCards(assessmentCards);
  };

  const returnDateType = (a, b) => {
    // get save the name of a and b
    const aName = a.name;
    const bName = b.name;

    // get the date that a and b were created
    a = new Date(a.dateCreated);
    b = new Date(b.dateCreated);

    // if the name has a time of creation use it to set a with that hh:mm:ss
    if (aName.split(" --time-- ").length > 1) {
      const aHourMinuteSecond = `${new Date(
        Date.parse(aName.split(" --time-- ")[1])
      )}`.split(" ")[4];
      const splitted_aHourMinuteSecond = aHourMinuteSecond.split(":");
      a.setHours(
        splitted_aHourMinuteSecond[0],
        splitted_aHourMinuteSecond[1],
        splitted_aHourMinuteSecond[2]
      );
    }

    // if the name has a time of creation use it to set b with that hh:mm:ss
    if (bName.split(" --time-- ").length > 1) {
      const bHourMinuteSecond = `${new Date(
        Date.parse(bName.split(" --time-- ")[1])
      )}`.split(" ")[4];
      const splitted_bHourMinuteSecond = bHourMinuteSecond.split(":");
      b.setHours(
        splitted_bHourMinuteSecond[0],
        splitted_bHourMinuteSecond[1],
        splitted_bHourMinuteSecond[2]
      );
    }

    // if filter is to show the latest date, show latest most recent assessment, otherwise show the oldest assessment first
    return showLatestDate ? b - a : a - b;
  };

  const EngReportCard = (props) => {
    let meridiem = "";
    let stringifiedTime = "";

    if (props.assessment.name.split(" --time-- ").length > 1) {
      const timeInSeconds = Date.parse(
        props.assessment.name.split(" --time-- ")[1]
      );
      let time = new Date(timeInSeconds);
      let hour = time.getHours() > 12 ? time.getHours() - 12 : time.getHours();
      meridiem = time.getHours() > 12 ? `PM` : `AM`;
      time.setHours(hour);
      stringifiedTime = `${time}`.split(" ")[4];
    }

    return (
      <div style={{ paddingTop: "1%" }}>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <span style={{ fontSize: "medium", fontWeight: "bold" }}>
                  {props.assessment.name.split(" --time-- ")[0]}
                </span>
                <Stack direction="horizontal" gap={2} style={{ marginTop: '1%' }}>
                  {props.assessment.assessment.isFirstWave ? <Badge bg="info" className="assessment-badge">First Wave</Badge>
                    : <Badge bg="" className="assessment-badge">Full System</Badge>
                  }
                  {props.assessment.assessment.secondRO && <Badge bg="success" className="assessment-badge">2 ROs</Badge>}
                  {props.assessment.assessment.screwPressRemoved && <Badge bg="success" className="assessment-badge">Screw Press Removed</Badge>}
                </Stack>
              </Col>
              <Col md={4} style={{ textAlign: "center" }}>
                <div> {props.assessment.dateCreated}
                  {stringifiedTime.length > 0 ? (
                    <>
                      {" - "}
                      {stringifiedTime} {meridiem}{" "}
                    </>) :
                    null}
                </div>
              </Col>
              <Col md={2} style={{ textAlign: "right" }}>
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    openEngAssessment(props.assessment);
                  }}
                >
                  Open Assessment {' '}
                  <MdOpenInNew size={20}/>
                </Button>
              </Col>
              <Col md={2} style={{ textAlign: "right" }}>
                <ReportDeleteModal
                  onEnd={fetchAssessments}
                  assessment={props.assessment}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  };

  const openEngAssessment = (assessment) => {
    const values = assessment.assessment;
    const sysType = values.isFirstWave ? "First Wave" : "Full System";
    const removal = assessment.assessment.removal
      ? assessment.assessment.removal
      : sysType === "Full System"
        ? 98.5
        : 84;


    const carbonValues = assessment.carbonModel || {
      region: '0',
      baselineTreatmentType: '0',
      livestockType: '0',
      numLivestock: '0',
      dryMatterIntake: '0',
      manureSeparationPercent: '0',
      separationEfficiency: '0',
      anerobicFraction: '0',
      numberOfPumps: '0',
      pumpKw: '0',
      pumpOperationTime: '0',
      electricityRegion: '0',
      yearlyCompostedWaste: '0',
      flocculantPerYear: '0',
      digestateKg: '0'
    };

    console.log(assessment.carbonModel);

    const runCarbonModel = assessment.hasOwnProperty('carbonModel') && assessment.carbonModel.anerobicFraction !== null;


    console.log(values);

    const url = (
      `${assessment.type == "eng" ? '/eng-assessment-h': '/client-assessment-report'}/${sysType}/${removal}/${encodeURIComponent(assessment.name)}/${values.operatingDays}/${values.operatingHours}/${values.annualFlow
      }/${values.totalSolids}/${values.suspendedSolids}/${values.ammoniumNitrogen
      }/${values.tkn}/${values.potassium}/${values.phosphorus}/${values.volatileSolids
      }/${values.screwPressLoop}/${values.roRecovery}/${values.screwPressRemoved
      }/${values.units}/${values.manure}/${assessment.isProject}/${runCarbonModel}
      /${carbonValues.region}/${carbonValues.livestockType}/${carbonValues.numLivestock}/${carbonValues.dryMatterIntake}
      /${carbonValues.manureSeparationPercent * 100}/${carbonValues.separationEfficiency * 100}/${carbonValues.anerobicFraction * 100}/${carbonValues.baselineTreatmentType}/${carbonValues.pumpKw}/${carbonValues.pumpOperationTime}/${carbonValues.electricityRegion}
      /${values.secondRO}/${values.ro2Recovery}/${values.spOutput20Percent}/${JSON.stringify(values.additional_constituents || {})}/${JSON.stringify(values.additional_constituents_ro_rejections || {})}/${values.override_polymer_dose}/${values.polymer_dose}/${carbonValues.digestateKg || '0'}`
    );

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openClientAssessment = (assessment) => {
    const values = assessment.assessment;
    const sysType = values.isFirstWave ? "First Wave" : "Full System";
    const removal = assessment.removal
      ? assessment.removal
      : sysType === "Full System"
        ? 98.5
        : 84;


    const carbonValues = assessment.carbonModel || {
      region: '0',
      baselineTreatmentType: '0',
      livestockType: '0',
      numLivestock: '0',
      dryMatterIntake: '0',
      manureSeparationPercent: '0',
      separationEfficiency: '0',
      anerobicFraction: '0',
      numberOfPumps: '0',
      pumpKw: '0',
      pumpOperationTime: '0',
      electricityRegion: '0',
      yearlyCompostedWaste: '0',
      flocculantPerYear: '0'
    };


    const runCarbonModel = assessment.hasOwnProperty('carbonModel') && assessment.carbonModel.region !== "null";

    const url = (
      `/client-assessment-report/${sysType}/${removal}/${encodeURIComponent(assessment.name)}/${values.operatingDays}/${values.operatingHours}/${values.annualFlow
      }/${values.totalSolids}/${values.suspendedSolids}/${values.ammoniumNitrogen
      }/${values.tkn}/${values.potassium}/${values.phosphorus}/${values.volatileSolids
      }/${values.calcium}/${values.magnesium}/${values.sodium}/${values.iron}/${values.manganese
      }/${values.copper}/${values.zinc}/${values.sulfur}/${values.roRecovery}/${values.units
      }/${values.manure}/${assessment.isProject}/${runCarbonModel}
      /${carbonValues.region}/${carbonValues.livestockType}/${carbonValues.numLivestock}/${carbonValues.dryMatterIntake}
      /${carbonValues.manureSeparationPercent * 100}/${carbonValues.separationEfficiency * 100}/${carbonValues.anerobicFraction * 100}/${carbonValues.baselineTreatmentType}/${carbonValues.pumpKw}/${carbonValues.pumpOperationTime}/${carbonValues.electricityRegion}
      /${values.secondRO}/${values.ro2Recovery}/${values.spOutput20Percent}`
    );

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onChangeSearch = () => {
    let validAssessments = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();
    assessments.forEach((assessment) => {
      if (assessment.name.toLowerCase().includes(currentSearchVal)) {
        validAssessments.push(assessment);
      }
    });
    createAssessmentCards(validAssessments);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);

  // Render
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <Modal show={showCreateModal} onHide={() => { setShowCreateModal(false) }} dialogClassName="modal-90w">
        <div style={{ zoom: '90%' }}>
          <AssesmentReportingPage />
        </div>
      </Modal>

      <Row>
        <Col md={11} style={{ margin: 'auto' }}>
          <Row style={{ paddingTop: "1%" }}>
            <Col md={2}>
              <Button
                style={{ width: '100%' }}
                variant="outline-dark"
                onClick={() => setShowCreateModal(true)}>
                <MdAssignmentAdd
                  size={23}
                /> New Assessment </Button>

            </Col>
            <Col md={10}>
              <Form.Control
                size="md"
                type="text"
                placeholder="Search..."
                ref={searchRef}
                onChange={onChangeSearch}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "1%" }}>
            <Col md={12}>
              <Card>
                <Card.Header style={{ backgroundColor: '#005E7D', color: 'white', fontWeight: 'bold' }}>
                  <Row>
                    <Col md={8}>
                      <h5 style={{ marginTop: '0.5%', fontSize: '17px'}}>{assessmentCards.length} Assessment Reports</h5>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      <Row>
                        <Col>
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                              Sort By
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>New to Old</Dropdown.Item>
                              <Dropdown.Item>Old to New</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>

                        <Col>
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                              Report Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Engineering Assessment</Dropdown.Item>
                              <Dropdown.Item>Client Assessment</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>

                        <Col>
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                              System Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>First Wave</Dropdown.Item>
                              <Dropdown.Item>Full System / PLANT</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body style={{
                    marginBottom: '10px',
                    width: '100%',
                    height: '75vh',
                    overflowY: 'scroll',
                  }}>
                    {loadingReports && <div style={{ textAlign: 'center' }}>
                      <Spinner animation="border" role="status" style={{ width: '100px', height: '100px' }} />
                    </div>}
                    {!loadingReports && assessmentCards}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SavedReports;
