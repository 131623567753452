import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormCheckCreator } from "../../FormCheckCreator";
import { FormGroupCreator } from "../../FormGroupCreator";
import { useAuth } from "../../../../../contexts/AuthContext";

// api
import { getAllJobNumbers, getMetadataForJob } from "../../../../../api/workOrders";

// Creates the inputs for each of the field description value
export const FieldTimeSheetDescriptionForm = ({
  changeValue,
  newReportDescription,
}) => {

  useEffect(() => {
  }, [newReportDescription]);

  const [jobNumbers, setJobNumbers] = useState([]);
  const [selectedJobNumber, setSelectedJobNumber] = useState(null);
  const { email } = useAuth().currentUser;

  useEffect(async () => {
    const jobNums = await getAllJobNumbers();
    setJobNumbers(jobNums.data);
  }, []);

  useEffect(() => {
    const capitalizeWords = (s) => {
      const arr = s.split(' ');
      const cap = arr.map((w) => w.charAt(0).toUpperCase() + w.slice(1));
      return cap.join(' ');
    };

    try {
      changeValue(capitalizeWords(email.split('@')[0].split('.').join(' ')), 'name');
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onSelectJobNumber = async (jobNumber) => {
    setSelectedJobNumber(jobNumber);
    changeValue(jobNumber, 'jobNo');

    if (['Sick', 'Vacation', 'Stat Holiday', ''].includes(jobNumber)) {
      changeValue('N/A', 'siteAddress');
      changeValue('N/A', 'client');
      changeValue('N/A', 'contact');
      changeValue('N/A', 'phoneNo');
      return;
    }

    const jobMetadata = await getMetadataForJob(jobNumber);
    changeValue(jobMetadata.shipTo.replaceAll('\n', ' '), 'siteAddress');
    changeValue(jobMetadata.farmName, 'client');
    changeValue(jobMetadata.att.join(', '), 'contact');
    changeValue(jobMetadata.tel.join(', '), 'phoneNo');
  };

  const onSelectJobType = (jobType) => {
    changeValue(jobType, 'jobType');
  };

  return (
    <div>
      <FormGroupCreator
        label="Date"
        type="date"
        changeValue={changeValue}
        valueType="date"
        valueDict={newReportDescription}
      />
      <FormGroupCreator
        label="Name"
        type="text"
        changeValue={changeValue}
        valueType="name"
        valueDict={newReportDescription}
      />
      <Form.Group className="my-1 ">
        <Row className="d-flex align-items-center justify-content-center">
          <Col md={2} className="text-center">
            <Form.Label>Job #</Form.Label>
          </Col>

          <Col md={5}>
            <Form.Control
              as="select"
              value={selectedJobNumber || newReportDescription.jobNo}
              required
              onChange={(e) => onSelectJobNumber(e.target.value)}
            >
              <option value=''>Select a Job Number</option>
              <option value='Sick'>Sick</option>
              <option value='Vacation'>Vacation</option>
              <option value='Stat Holiday'>Stat Holiday</option>
              {jobNumbers.map((job) => (
                <option key={job._id} value={job.jobNumber}>
                  {job.jobNumber}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col md={5}>
            <Form.Control
              as="select"
              value={newReportDescription.jobType}
              required
              onChange={(e) => onSelectJobType(e.target.value)}
            >
              <option value=''>Select Job Type</option>
              <option value='Training'>Training</option>
              <option value='Testing'>Testing</option>
              <option value='Service'>Service</option>
              <option value='Comissioning'>Comissioning</option>
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
      <FormGroupCreator
        label="Client"
        changeValue={changeValue}
        valueType="client"
        valueDict={newReportDescription}
      />
      <FormGroupCreator
        label="Contact Details"
        changeValue={changeValue}
        valueType="contact"
        valueDict={newReportDescription}
      />
      <FormGroupCreator
        label="P.O. #"
        isRequired={{ required: false }}
        changeValue={changeValue}
        valueType="poBox"
        valueDict={newReportDescription}
      />
      <FormGroupCreator
        label="Phone #"
        isRequired={{ required: false }}
        changeValue={changeValue}
        valueType="phoneNo"
        valueDict={newReportDescription}
      />

      <FormGroupCreator
        label="Work Description"
        changeValue={changeValue}
        valueType="workDescription"
        valueDict={newReportDescription}
      />
      <FormGroupCreator
        label="Site Address"
        changeValue={changeValue}
        valueType="siteAddress"
        valueDict={newReportDescription}
      />
      <FormCheckCreator
        label="Completed"
        changeValue={changeValue}
        valueType="completed"
        valueDict={newReportDescription}
      />
    </div>
  );
};
