import React from "react";
import "../styles/Login.css";
import { checkIfWindowIsMobile } from "../utility/mobileFunctions";

export default function LoginScreenBoiler(props) {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className={`${!checkIfWindowIsMobile() ? "row" : "d-flex flex-column"}`}
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <div
          className={`${
            !checkIfWindowIsMobile()
              ? "col-4 "
              : "row d-flex justify-content-center align-items-center"
          }`}
          style={{
            backgroundColor: "#005E7D",
            height: `${!checkIfWindowIsMobile() ? "100vh" : "20vh"}`,
            textAlign: "center",
          }}
        >
          <img
            src="https://www.livestockwaterrecycling.com/media/home/livestock-logo-img.webp"
            alt="LWR Icon"
            className={`${
              !checkIfWindowIsMobile() ? "" : "d-flex justify-content-center"
            }`}
            style={{
              marginTop: `${!checkIfWindowIsMobile() ? "55%" : "5%"}`,
              width: "140px",
              paddingRight: "20px",
            }}
          />

          {!checkIfWindowIsMobile() ? (
            <img
              src=" https://www.livestockwaterrecycling.com/media/home/livestock-logo-text.webp"
              alt="LWR Logo"
              style={{
                marginTop: "55%",
              }}
            />
          ) : null}
        </div>
        <div
          className={`${!checkIfWindowIsMobile() ? "col-8" : "row"}`}
          style={{ overflowY: "hidden", overflowX: "hidden" }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
