/**
 * client-report-translations/en.js
 * ---------------------------------
 * 
 * English strings for the client assessment report.
 * 
 * Author: Oscar Jaimes
 * Date: February 1, 2023
 */

const translations = {
  languageL :'en',
  treatment_assessment_report: 'Treatment Assessment Report',
  client_assessment: 'Client Assessment',
  metric_units: 'Metric Units',
  imperial_units: 'Imperial Units',
  annual_flow: 'Annual Flow',
  total_solids_ppm: 'Total Solids',
  input_data_ppm: 'Input Constituents (ppm)',
  total_suspended_solids: 'Total Suspended Solids',
  ammonium_nitrogen: 'Ammonium Nitrogen',
  organic_nitrogen: 'Organic Nitrogen',
  potassium: 'Potassium',
  phosphorus: 'Phosphorus',
  calcium: 'Calcium',
  magnesium: 'Magnesium',
  sodium: 'Sodium',
  iron: 'Iron',
  sulfur: 'Sulfur',
  manganese: 'Manganese',
  copper: 'Copper',
  zinc: 'Zinc',
  other_dissolved_solids: 'Other Dissolved Solids',
  other: 'Other',
  operating_information: 'Operating Information',
  operating_days_per_year: 'Operating Days/Year',
  operating_hours_per_day: 'Operating Hours/Day',
  nutrient_value: 'Nutrient Value',
  process_flow: 'Process Flow & Yearly Outputs',
  system_type: 'System Type',
  full_system: 'Full System',
  first_wave: 'First Wave',
  dollar_per_kg: '$/kg',
  kg_per_min: 'kg/min',
  lb_per_min: 'lb/min',
  nutrient_concentration: 'Nutrient Concentration',
  inputs: 'Inputs',
  system_outputs: 'System Outputs',
  nutrient: 'Nutrient',
  digestate: 'Digestate',
  liquid_nutrient: 'Liquid Nutrient',
  nutrient_solids: 'Nutrient Solids',
  clean_water: 'Clean Water',
  kg_per_m3: 'kg/m^3',
  lb_per_1000_gal: 'lb/1000gallons',
  kg_per_ton: 'kg/ton',
  kg_per_tonne: 'kg/tonne',
  ppm: 'ppm',
  output_data: 'Output Data',
  yearly_outputs: 'Yearly Outputs',
  annual_liquid_nutrient: 'Annual Liquid Nutrient',
  annual_nutrient_solids: 'Annual Nutrient Solids',
  annual_clean_water: 'Annual Clean Water',
  volatile_solids: 'Volatile Solids'
};

export default translations;