import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useAuth } from "../../../../contexts/AuthContext";
import { getUserPermissionFromCache } from "../../../../utility/permissionFunctions";
import { getProperTime } from "../../../field-tools/fieldToolsFunctions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { updateFavorite } from "../../../../api/hrPolicy";
// Converts HR Policy to a report card format
export const ExistingHRReportCard = ({
  reportList,
  learnMoreFunction,
  deleteFunction,
  setPoliciesList,
}) => {
  const { email } = useAuth().currentUser;
  const [userPermission, setUserPermission] = useState([]);
  useEffect(async () => {
    setUserPermission(await getUserPermissionFromCache(email));
  }, []);

  const changeFavorite = async (report) => {
    const _id = report._id;
    const favorite = report.favorite ? !report.favorite : true;
    await updateFavorite(_id, email, favorite).then(() => {
      let _reportList = [...reportList];
      _reportList = _reportList.map((report) =>
        report._id == _id ? { ...report, favorite: favorite } : report
      );
      setPoliciesList(_reportList);
    });
  };

  return (
    <Card>
      <ListGroup variant="flush">
        {reportList.map((report, index) => (
          <ListGroupItem key={report._id}>
            <Row>
              <Col
                md={7}
                onClick={() => learnMoreFunction(report)}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex align-items-center h-100 justify-content-between">
                  <div>
                    {index + 1}. {report.policyName}
                  </div>
                  <div>{getProperTime(report.createdAt)}</div>
                </div>
              </Col>
              <Col md={1} />
              <Col md={2}>
                <Button
                  onClick={() => learnMoreFunction(report)}
                  style={{ cursor: "pointer" }}
                  variant="dark"
                >
                  Learn More
                </Button>
              </Col>
              {userPermission.includes("hrAdmin") ? (
                <>
                  <Col md={1}>
                    <Button
                      onClick={() => deleteFunction(report)}
                      style={{ cursor: "pointer" }}
                      variant="danger"
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col
                    md={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {report.favorite && report.favorite == true ? (
                      <AiFillHeart
                        style={{
                          color: "red",
                          fontSize: "1.3em",
                          cursor: "pointer",
                        }}
                        onClick={() => changeFavorite(report)}
                      />
                    ) : (
                      <AiOutlineHeart
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                        onClick={() => changeFavorite(report)}
                      />
                    )}
                  </Col>
                </>
              ) : null}
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Card>
  );
};
