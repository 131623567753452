/**
 * ApiKeyTab.jsx
 * -------------
 * Component that contains all CRUD functionality for API keys.
 * Renders a table with entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form, Button } from "react-bootstrap";

// CRUD Components
import ApiKeyInsertModal from "./ApiKeyInsertModal";
import ApiKeyDeleteModal from "./ApiKeyDeleteModal";
import {
  getUserPermissionFromCache,
  isPermissionTypeAdmin,
} from "../../../utility/permissionFunctions";

// api
import { getAllApiKeys } from "../../../api/apikeys";

export default function ApiKeyTab(props) {
  const [keyElements, setKeyElements] = useState([]);
  const [keys, setKeys] = useState([]);
  const [userType, setUserType] = useState([]);
  const searchRef = useRef();

  // Executes when component loads.
  useEffect(async () => {
    document.title = "Systems - Admin Tools";
    fetchAndCreateAPIKeyElements();
    setUserType(await getUserPermissionFromCache(props.account.email));
  }, []);

  const fetchAndCreateAPIKeyElements = async () => {
    searchRef.current.value = "";
    const apiKeys = await getAllApiKeys();
    let keys = createKeyTableElements(apiKeys);
    setKeyElements(keys);
    setKeys(apiKeys);
  };

  const createKeyTableElements = (keys) => {
    return keys.map((key) => (
      <tr>
        <td>{key.keyNumber}</td>
        <td>{key.key}</td>
        <td>{key.target}</td>
        {/* <td>
          <ApiKeyDeleteModal
            onEnd={fetchAndCreateAPIKeyElements}
            apiKey={key}
          />
        </td> */}
      </tr>
    ));
  };

  /**
   * Search functionality. Edits table contents based on input text in search box.
   */
  const onChangeSearch = () => {
    let validKeys = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();

    keys.forEach((key) => {
      if (key.key.toLowerCase().includes(currentSearchVal)) {
        validKeys.push(key);
      }
    });

    let newTableElements = createKeyTableElements(validKeys);
    setKeyElements(newTableElements);
  };

  return (
    <div style={{margin: '10px'}}>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          {isPermissionTypeAdmin(userType) && (
            <ApiKeyInsertModal
              onEnd={fetchAndCreateAPIKeyElements}
              numKeys={keys.length}
            />
          )}
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Key #</th>
              <th>Key</th>
              <th>Target API</th>
              {/* {isPermissionTypeAdmin(userType) && <th>Delete</th>} */}
            </tr>
          </thead>
          <tbody>{keyElements}</tbody>
        </Table>
      </div>
    </div>
  );
}
