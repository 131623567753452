import http from "./http";

export const getAllEODReport = async (email) => {
  return http.get(`field-eod-reports/all/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getEODReportByID = async (id, email) => {
  return http.get(`field-eod-reports/${id}/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const createNewEODReport = async (body) => {
  return http(
    {
      url: `field-eod-reports/create/`,
      method: "post",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getEODReportImages = async (body) => {
  return http.post('field-eod-reports/images', body)
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log(error);
      return error;
    });
}

export const updateEODReport = async (body) => {
  return http.put(`field-eod-reports/update/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const deleteEODReport = async (id, email) => {
  return http.delete(`field-eod-reports/delete/?_id=${id}&email=${email}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}