import { Alert, Table } from "react-bootstrap";
import useQuery from "../../../hooks/useQuery";
import { getAssetProjects } from "../../../api/zoho";

const AssetsTab = () => {
  const {
    data: assets = [],
    isLoading: loading = true,
  } = useQuery(
    'assets',
    () => getAssetProjects(),
    null
  );

  return (
    <div style={{ margin: '10px' }}>
      <br />
      <Alert variant="primary">Assets can only be created through Zoho Books</Alert>
      <br />
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Asset Name</th>
              <th>Description</th>
              <th>LWR Asset Code</th>
              <th>Asset Type</th>
            </tr>
          </thead>
          <tbody>
            {
              loading && <tr>
                <td colSpan={4}>Loading...</td>
              </tr>
            }
            {!loading &&
              (assets || []).map((asset) => {
                return <tr>
                  <td>{asset.project_name}</td>
                  <td>{asset.description}</td>
                  <td>{asset.cf_lwr_job_code}</td>
                  <td>{asset.cf_project_type}</td>
                </tr>
              })
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
};

export default AssetsTab;