import React, { useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { convertKeyNameToDisplayName } from "../../../field-tools/TimeReporting/TimeReportFunctions";
import { HRShopTimesheetModal } from "../NewHRShopTimesheet/HRShopTimesheetModal";
import { ExistingHRShopTimesheetValue } from "./ExistingHRShopTimesheetValue";
import { IoIosAddCircleOutline } from "react-icons/io";
import {
  exampleJobCode,
  exampleJobCodeDict,
} from "../HRShopTimesheetConstants";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ExistingHRShopTimesheetJobCode } from "./ExistingHRShopTimesheetJobCode";

// Displays the Job Values
export const ExistingHRShopTimesheetJob = ({
  job,
  cancelChanges,
  shopTimesheet,
  setShopTimesheet,
}) => {
  const [show, setShow] = useState(false);
  const [newJobCode, setNewJobCode] = useState(null);
  const [jobCodeDict, setJobCodeDict] = useState(exampleJobCodeDict);
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    const newJobCodeDict = { ...exampleJobCodeDict };
    job.jobCodeList.map(
      (jobCodes) => delete newJobCodeDict[jobCodes.jobCode.split(" - ")[0]]
    );
    setJobCodeDict({ ...newJobCodeDict });
  }, [job.jobCodeList, update]);

  // chanegs the job code value
  const changeJobCodeValue = (value, valueType) => {
    for (const job of shopTimesheet.jobList) {
      if (job.id == valueType.jobID) {
        for (const jobCode of job.jobCodeList) {
          if (jobCode.id == valueType.jobCodeID) {
            jobCode[valueType.type] =
              typeof jobCode[valueType.type] == "number"
                ? Number(value)
                : value;
          }
        }
      }
    }
    setShopTimesheet({ ...shopTimesheet });
    setUpdate(!update);
  };

  // adds job code to the existing shop timesheet
  const addJobCode = (jobNumberId) => {
    const newJobCode = { ...exampleJobCode };
    newJobCode.new = true;
    newJobCode.id = uuidv4();
    shopTimesheet.jobList.map((job) =>
      job.id == jobNumberId || job._id == jobNumberId
        ? { ...job, jobCodeList: job.jobCodeList.push(newJobCode) }
        : job
    );
    setNewJobCode(newJobCode);
    setShopTimesheet({ ...shopTimesheet });
    setShow(true);
  };

  // remoes job code from existing shop timesheet
  const removeJobCode = (jobCodeID, jobNumberID) => {
    shopTimesheet.jobList = shopTimesheet.jobList.map((job) =>
      job.id == jobNumberID
        ? {
            ...job,
            jobCodeList: job.jobCodeList.filter(
              (jobCode) => jobCode.id != jobCodeID
            ),
          }
        : job
    );
    setShopTimesheet({ ...shopTimesheet });
  };

  return (
    <div className="d-flex flex-row flex-wrap pt- mt-2 ">
      {job.jobCodeList.length > 0 ? (
        job.jobCodeList?.map((jobCode) => (
          <ListGroup
            className="px-1"
            style={{ minWidth: "340px", width: "50%" }}
          >
            {Object.keys(jobCode).map((key) =>
              key != "id" &&
              key != "_id" &&
              key != "new" &&
              key != "jobCode" &&
              key != "description" ? (
                <ExistingHRShopTimesheetValue
                  valueDict={jobCode}
                  valueType={{
                    type: key,
                    jobID: job.id,
                    jobCodeID: jobCode.id,
                  }}
                  label={convertKeyNameToDisplayName(key)}
                  changeValue={changeJobCodeValue}
                  newJobCode={jobCode.new || false}
                  cancelChanges={cancelChanges}
                />
              ) : key == "jobCode" ? (
                <ExistingHRShopTimesheetJobCode
                  valueDict={jobCode}
                  valueType={{
                    type: key,
                    jobID: job.id,
                    jobCodeID: jobCode.id,
                  }}
                  label={convertKeyNameToDisplayName(key)}
                  changeValue={changeJobCodeValue}
                  newJobCode={jobCode.new || false}
                  cancelChanges={cancelChanges}
                  jobCodeDict={jobCodeDict}
                />
              ) : null
            )}
            <div className="d-flex justify-content-center align-items-center my-2">
              <Button
                variant="outline-danger"
                onClick={() => removeJobCode(jobCode.id, job.id)}
              >
                Remove
              </Button>
            </div>
          </ListGroup>
        ))
      ) : (
        <div className="d-flex justify-content-center w-100 mt-2 pt-2">n/a</div>
      )}
      <div
        className="border-top w-100 d-flex justify-content-center pt-2"
        style={{ cursor: "pointer" }}
      >
        <IoIosAddCircleOutline onClick={() => addJobCode(job.id || job._id)} />
      </div>
      {newJobCode && (
        <HRShopTimesheetModal
          show={show}
          setShow={setShow}
          job={newJobCode}
          setJob={setNewJobCode}
          jobNumber={job.jobNumber}
          shopTimesheet={shopTimesheet}
          setShopTimesheet={setShopTimesheet}
          jobCodeDict={jobCodeDict}
        />
      )}
    </div>
  );
};
