export const operationInputs = {
  'Client Name': {
    controlId: 'clientName',
    type: 'text'
  },
  'Operating Days/Year': {
    controlId: 'operatingDaysPerYear',
    type: 'number',
    defaultValue: 365
  },
  'Operating Hours/Day': {
    controlId: 'operatingHoursPerday',
    type: 'number',
    defaultValue: 22
  },
  'Annual Flow': {
    controlId:  'annualFlow',
    type: 'number'
  }, 
  'System Type': {
    controlId: 'systemSelect',
    type: 'select',
    options: ['First Wave', 'Full System']
  },
  'Report Units': {
    controlId: 'unitSelect',
    type: 'select',
    options: ['Imperial', 'Metric']
  },
  'Feed Type': {
    controlId: 'feedSelect',
    type: 'select',
    options: ['Manure', 'Digestate']
  },
  'Screw Press Handling': {
    controlId: 'screwPressHandling',
    type: 'select',
    options: ['Recycle to Front', 'Add to MS. Filtrate', 'Remove Screw Press' ]
  }
};