import { useState, useEffect } from 'react';
import { getUserPermissionFromCache } from '../utility/permissionFunctions';

const usePermission = (email) => {
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    const loadPermission = async () => {
      const p = await getUserPermissionFromCache(email);
      setPermission(p);
    };

    if (email) {
      loadPermission();
    }
  }, [email]);

  return { permission };
};

export default usePermission;