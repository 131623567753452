import http from "./http";

const sendPermissionEmail = async (body) => {
    return http.post(`permissionToken/generate-permission-token/`, body)
        .then((res) => {
            return res;
        }).catch((error) => {
            console.log(error);
            return error;
        })
}

export {
    sendPermissionEmail
};