/**
 * ChemicalTab.jsx
 * -------------
 * Component that contains all CRUD functionality for Chemicals in the LWR database.
 * Renders a table with Chemical entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";

// CRUD components
import ChemicalInsertModal from "./ChemicalInsertModal";
import ChemicalDeleteModal from "./ChemicalDeleteModal";
import ChemicalEditModal from "./ChemicalEditModal";
import {
  getUserPermissionFromCache,
  isPermissionTypeAdmin,
} from "../../../utility/permissionFunctions";

// chem api
import { getAllChemicals } from "../../../api/chemicals";

export default function ChemicalTab(props) {
  const [chemTableElements, setChemicalElements] = useState([]);
  const [chemicals, setChemicals] = useState([]);
  const [userType, setUserType] = useState("");
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "Systems - Admin Tools";
    fetchAndCreateChemicalElements();
    setUserType(await getUserPermissionFromCache(props.account.email));
  }, []);

  const fetchAndCreateChemicalElements = async () => {
    searchRef.current.value = "";

    const allChemicals = await getAllChemicals();
    if (allChemicals) {
      let chemElements = createChemicalTableElements(allChemicals);
      setChemicalElements(chemElements);
      setChemicals(allChemicals);
    }
  };

  const createChemicalTableElements = (chemicals) => {
    return chemicals.map((chem) => (
      <tr>
        <td>{chem.name}</td>
        <td>$ {chem.cost.toFixed(2)}</td>
        {/* <td>
          <ChemicalEditModal
            onEnd={fetchAndCreateChemicalElements}
            chemical={chem}
          />
        </td>
        <td>
          <ChemicalDeleteModal
            onEnd={fetchAndCreateChemicalElements}
            chemical={chem}
          />
        </td> */}
      </tr>
    ));
  };

  /**
   * Search functionality. Edits table contents based on input text in search box.
   */
  const onChangeSearch = () => {
    let validChems = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();

    chemicals.forEach((chem) => {
      if (chem.name.toLowerCase().includes(currentSearchVal)) {
        validChems.push(chem);
      }
    });

    let newTableElements = createChemicalTableElements(validChems);
    setChemicalElements(newTableElements);
  };

  return (
    <div style={{ margin: '10px' }}>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          {isPermissionTypeAdmin(userType) && (
            <ChemicalInsertModal onEnd={fetchAndCreateChemicalElements} />
          )}
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Chemical Name</th>
              <th>Cost ($/kg)</th>
              {/* <th>Edit</th>
              {isPermissionTypeAdmin(userType) && <th>Delete</th>} */}
            </tr>
          </thead>
          <tbody>{chemTableElements}</tbody>
        </Table>
      </div>
    </div>
  );
}
