import React, { useState } from 'react';

import { Form, Row, Col } from 'react-bootstrap';

function CarbonModellingForm(props) {
  const {
    regionRef,
    livestockTypeRef,
    numLivestockRef,
    dmiRef,
    manureSeparationRef,
    anerobicTreatmentRef,
    baselineTreatmentRef,
    sysPowerRef,
    electricityRegionRef,
    digestateKgRef
  } = props.formRefs;

  const blueBorderStyle = {
    border: '0.5px dotted #005E7D',
  };

  const [isDigestate, setIsDigestate] = useState(false);

  return (
    <div style={{marginBottom: '15%'}}>
      <div className="row">
        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Region
          </label>
          <Form.Control
            as="select"
            style={blueBorderStyle}
            required
            ref={regionRef}
          >
            <option value="north_america">North America</option>
            <option value="western_europe">Western Europe </option>
            <option value="eastern_europe">Eastern Europe </option>
            <option value="oceania">Oceania </option>
            <option value="latin_america">Latin America </option>
            <option value="africe">Africa </option>
            <option value="middle_east">Middle East </option>
            <option value="asia">Asia </option>
            <option value="india">India </option>
          </Form.Control>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            <Row>
              <Col>
                Feed Type
              </Col>
            </Row>
          </label>
          <Form.Control
            as="select"
            style={blueBorderStyle}
            required
            ref={livestockTypeRef}
            onChange={(e) => { 
              if (e.target.value.includes('digestate')) { 
                setIsDigestate(true);
              } else {
                setIsDigestate(false);
              } 
            }}
          >
            <option value="dairy">Dairy</option>
            <option value="swine">Swine</option>
            <option value="buffalo">Buffalo</option>
            <option value="non_dairy">Non-Dairy</option>
            <option value="digestate_fw">Digestate - Food Waste (60%), Slaughterhouse Waste (25%), Industrial Waste(15%)</option>
            <option value="digestate_municipal">Digestate - Organic Fraction of Municipal Houshold Waste (100%)</option>
            <option value="digestate_municipal_garden">Digestate - Organic Fraction of Municipal Houshold Waste (98%), Garden Waste (2%)</option>
            <option value="digestate_starch">Digestate - Starch Slurry (80%), Cereals (18%), Corn Silage(2%)</option>
            <option value="digestate_manure">Digestate - Manure (67%), Grease Sludge (16%), Meat Waste (17%)</option>
          </Form.Control>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Number of Livestock
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            required
            disabled={isDigestate}
            ref={numLivestockRef}
          />
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Dry Matter Intake (kg/day/animal)
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            required
            disabled={isDigestate}
            ref={dmiRef}
          />
        </div>
      </div>

      <div className="row" style={{ marginTop: '1%' }}>
        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Manure Separation %
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            required
            ref={manureSeparationRef}
          />
          <div
            className="text-center border rounded p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            % of manure that is delivered to separation process
          </div>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Separation Efficiency %
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            placeholder={props.removalValue.primary}
            disabled
          />
          <div
            className="text-center border rounded p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            Solids separation efficiency (%) of LWR system
          </div>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Anerobic Treatment %
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            required
            ref={anerobicTreatmentRef}
          />
          <div
            className="text-center border rounded p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            % of manure handled in baseline anerobic system
          </div>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Baseline Treatment Type
          </label>
          <Form.Control
            as="select"
            style={blueBorderStyle}
            required
            ref={baselineTreatmentRef}
          >
            <option value="lagoon">Lagoon</option>
            <option value="bedding">Bedding</option>
            <option value="composting">Composting</option>
            <option value="effluent_pond">Effluent Pond</option>
          </Form.Control>
        </div>
      </div>

      <div className="row" style={{ marginTop: '1%' }}>
        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            System Power Consumption (kW)
          </label>
          <Form.Control
            as="select"
            style={blueBorderStyle}
            required
            ref={sysPowerRef}
          >
            <option value="5">5kW (FW Sprout)</option>
            <option value="9">9kW (LWR Sprout)</option>
            <option value="14">14kW(FW-10)</option>
            <option value="18">18kW (LWR-10)</option>
            <option value="15">15kW (FW-40)</option>
            <option value="28">28kW (FW-40 HF)</option>
            <option value="26">26kW (LWR-40)</option>
            <option value="27">27kW (FW-85)</option>
            <option value="32">32kW (FW-85HF)</option>
            <option value="44">44kW (LWR-85)</option>
            <option value="37">37kW (FW-185)</option>
            <option value="46">46kW (FW-185HF)</option>
            <option value="53">53kW (FW-270)</option>
          </Form.Control>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Operation Time (Hours/Day)
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            placeholder={props.operatingHours}
            disabled
          />
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Electricity Region
          </label>
          <Form.Control
            as="select"
            style={blueBorderStyle}
            required
            ref={electricityRegionRef}
          >
            <option value="alaska">Alaska</option>
            <option value="california">California</option>
            <option value="new_england">New England</option>
            <option value="rockies">Rockies</option>
            <option value="east">US East</option>
            <option value="west">US West</option>
            <option value="north">US North</option>
            <option value="south">US South</option>
            <option value="us_avg">US Avg.</option>
          </Form.Control>
        </div>

        <div className="col-md-3">
          <label style={{ fontWeight: '550' }}>
            Digestate Per Year (kg)
          </label>
          <Form.Control
            type="number"
            style={blueBorderStyle}
            required
            disabled={!isDigestate}
            ref={digestateKgRef}
          />
          <div
            className="text-center border rounded p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            Total digestate (kg) treated in 1 year
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarbonModellingForm;