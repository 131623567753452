import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import {
  capitalizeFirstCharacter,
  parseKeyName,
} from "../../fieldToolsFunctions";
import { FormGroupCreator } from "../../TimeReporting/FormGroupCreator";

export const ExistingFieldEODReportValues = ({
  report,
  valueType,
  onChange,
  cancelChanges,
}) => {
  const [editValue, setEditValue] = useState(false);

  useEffect(() => {
    if (cancelChanges) {
      setEditValue(false);
    }
  }, [cancelChanges]);

  const label = parseKeyName(capitalizeFirstCharacter(valueType));

  return (
    <div>
      {!editValue ? (
        <div className="d-flex justify-content-start border-bottom">
          <Row className="p-1" style={{ width: "98%" }}>
            <Col md={2}>{label}:</Col>
            <Col md={10}>
              <div className="d-flex justify-content-start">
                {report[valueType]}
              </div>
            </Col>
          </Row>

          <div>
            <AiOutlineEdit onClick={() => setEditValue(true)} />
          </div>
        </div>
      ) : (
        <FormGroupCreator
          label={label}
          valueDict={report}
          valueType={valueType}
          changeValue={onChange}
          type={valueType == "date" ? "date" : typeof report[valueType]}
        />
      )}
    </div>
  );
};
