/**
 * zoho.js
 * -------
 * Functions for interfacing with Zoho Auth & Books.
 * 
 * Author: Oscar Jaimes
 */

import http from './http'

export const getJobCodes = async () => {
  return http.get('/zoho/job-codes').then((res) => {
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const projectNameByJobCode = async (lwrJobCode) => {
  return http.get(`/zoho/project-name?lwrJobCode=${lwrJobCode}`).then((res) => {
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const getAssetProjects = async () => {
  return http.get('/zoho/asset-projects').then((res) => {
    return res.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const getTasksForProject = async (projectId) => {
  return http.get(`/zoho/tasks/?projectId=${projectId}`).then((res) => {
    return res.data;
  }).catch((err) => {
    console.log(err);
  })
};
