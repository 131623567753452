import React from "react";
import { AiOutlineUpload } from "react-icons/ai";

export const UploadNewFiles = ({
  onChange,
  acceptType = "image/*",
  title = "Upload Image(s)",
  multiple = true,
}) => {
  const uploadFiles = () => {
    document.getElementById("selectFile").click();
  };
  const allowMultipleFiles = () => {
    return multiple ? { multiple: true } : {};
  };
  return (
    <div className="mt-4">
      <div className="d-flex flex-column align-items-center">
        <div className="border rounded-circle p-3">
          <AiOutlineUpload
            size={"70px"}
            onClick={uploadFiles}
            style={{ cursor: "pointer" }}
          ></AiOutlineUpload>
        </div>
      </div>
      <div className="d-flex justify-content-center">{title}</div>
      <input
        className="form-control"
        type="file"
        id="selectFile"
        {...allowMultipleFiles()}
        accept={acceptType}
        onChange={(e) => onChange([...e.target.files])}
        style={{ display: "none" }}
      />
    </div>
  );
};
