import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import {
  convertKeyNameToDisplayName,
  returnCorrectValue,
} from "../TimeReportFunctions";
import { EditExistingTimeReportField } from "./EditExistingTimeReport/EditExistingTimeReportField";
import "./DisplayExistingTimeReport/CanvasStyles.css";
export const ExistingTimeReportValue = ({
  reportSubDict,
  keyName,
  setTimeSheet,
  timeSheet,
  subDictName,
  multipleDict,
  cancelChanges,
}) => {
  const [editValue, setEditValue] = useState(false);
  useEffect(() => {
    setEditValue(false);
  }, [cancelChanges]);
  return (
    <Row className="border-bottom py-1 d-flex align-items-center">
      <Col md={4}>{convertKeyNameToDisplayName(keyName)}</Col>
      {!editValue ? (
        <>
          <Col md={7}>{returnCorrectValue(reportSubDict[keyName])}</Col>
        </>
      ) : (
        <Col md={7}>
          <EditExistingTimeReportField
            valueDict={reportSubDict}
            valueType={keyName}
            type={typeof reportSubDict[keyName]}
            setTimeSheet={setTimeSheet}
            timeSheet={timeSheet}
            subDictName={subDictName}
            multipleDict={multipleDict}
          />
        </Col>
      )}
    </Row>
  );
};
