import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { checkIfWindowIsMobile } from "../../../utility/mobileFunctions";
import {
  getUserPermissionFromCache,
  isPermissionTypeAdmin,
  isPermissionTypeEmployee,
  isPermissionTypeHRAdmin,
  isPermissionTypeShopEmployee,
} from "../../../utility/permissionFunctions";
import { ExistingHRShopTimesheet } from "../HRShopTimesheet/ExistingHRShopTimesheet/ExistingHRShopTimesheet";
import { ExistingHRShopTimesheetList } from "../HRShopTimesheet/ExistingHRShopTimesheet/ExistingHRShopTimesheetList";
import { NewHRShopTimesheet } from "../HRShopTimesheet/NewHRShopTimesheet/NewHRShopTimesheet";
import { ExistingHRTimeSheet } from "./ExistingHRTimesheet/ExistingHRTimeSheet";
import { NewHRTimeSheet } from "./NewHRTimeSheet/NewHRTimeSheet";

export const HRTimeSheetNav = () => {
  const { email } = useAuth().currentUser;
  const [userAccountType, setUserAccountType] = useState([]);

  useEffect(async () => {
    setUserAccountType(await getUserPermissionFromCache(email));
  }, []);

  const AdminRoutes = () => {
    return (
      <Switch>
        {/* <Route
          path="/hr-tools/hr-timesheet/newTimesheet"
          component={NewHRTimeSheet}
        />
        <Route
          path="/hr-tools/hr-timesheet/existingTimesheet"
          component={ExistingHRTimeSheet}
        /> */}
        <Route
          path="/hr-tools/hr-timesheet/newShopTimesheet"
          component={NewHRShopTimesheet}
        />
        <Route
          path="/hr-tools/hr-timesheet/existingShopTimesheet"
          component={ExistingHRShopTimesheetList}
          exact
        />
        <Route
          path="/hr-tools/hr-timesheet/existingShopTimesheet/:id"
          component={ExistingHRShopTimesheet}
        />
        <Redirect to="/hr-tools/hr-timesheet/newTimesheet" />
      </Switch>
    );
  };

  const EmployeeRoutes = () => {
    return (
      <Switch>
        {/* <Route
          path="/hr-tools/hr-timesheet/newTimesheet"
          component={NewHRTimeSheet}
        /> */}
        {/* {!checkIfWindowIsMobile() ? (
          <Route
            path="/hr-tools/hr-timesheet/existingTimesheet"
            component={ExistingHRTimeSheet}
          />
        ) : null}
        <Redirect to="/hr-tools/hr-timesheet/newTimesheet" /> */}
      </Switch>
    );
  };

  const ShopEmployeeRoutes = () => {
    return (
      <Switch>
        <Route
          path="/hr-tools/hr-timesheet/newShopTimesheet"
          component={NewHRShopTimesheet}
        />
        <Route
          path="/hr-tools/hr-timesheet/existingShopTimesheet"
          component={ExistingHRShopTimesheetList}
          exact
        />
        <Route
          path="/hr-tools/hr-timesheet/existingShopTimesheet/:id"
          component={ExistingHRShopTimesheet}
        />
        <Redirect to="/hr-tools/hr-timesheet/newShopTimesheet" />
      </Switch>
    );
  };

  const MobileRoutes = () => {
    return (
      <Switch>
        {!isPermissionTypeEmployee(userAccountType) ? (
          <>
            {/* <Route
              path="/hr-tools/hr-timesheet/newShopTimesheet"
              component={NewHRShopTimesheet}
            />
            <Route
              path="/hr-tools/hr-timesheet/existingShopTimesheet"
              component={ExistingHRShopTimesheetList}
              exact
            /> */}
            <Route
              path="/hr-tools/hr-timesheet/existingShopTimesheet/:id"
              component={ExistingHRShopTimesheet}
            />
            <Redirect to="/hr-tools/hr-timesheet/newShopTimesheet" />
          </>
        ) : (
          <>
            <Route
              path="/hr-tools/hr-timesheet/newTimesheet"
              component={NewHRTimeSheet}
            />
            <Redirect to="/hr-tools/hr-timesheet/newTimesheet" />
          </>
        )}
      </Switch>
    );
  };

  const shopTimesheetNavItems = () => {
    return (
      <>
        <Nav.Item>
          <Nav.Link
            as={Link}
            style={{ color: "#3c506b" }}
            to="/hr-tools/hr-timesheet/newShopTimesheet"
          >
            New Shop Timesheet
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            as={Link}
            style={{ color: "#3c506b" }}
            to="/hr-tools/hr-timesheet/existingShopTimesheet"
          >
            Existing Shop Timesheets {isPermissionTypeHRAdmin(userAccountType) && ('- HR Admin View')}
          </Nav.Link>
        </Nav.Item>
      </>
    );
  };

  const timesheetNavItems = () => {
    return (
      <>
        {/* {!checkIfWindowIsMobile() ? (
          <Nav.Item>
            <Nav.Link
              as={Link}
              style={{ color: "#3c506b" }}
              to="/hr-tools/hr-timesheet/newTimesheet"
            >
              New TimeSheet
            </Nav.Link>
          </Nav.Item>
        ) : null} */}
        {/* {!checkIfWindowIsMobile() ? (
          <Nav.Item>
            <Nav.Link
              as={Link}
              style={{ color: "#3c506b" }}
              to="/hr-tools/hr-timesheet/existingTimesheet"
            >
              Existing TimeSheet
            </Nav.Link>
          </Nav.Item>
        ) : null} */}
      </>
    );
  };

  const getHrNavItemsByPermission = () => {
    switch (true) {
      case isPermissionTypeAdmin(userAccountType) ||
        isPermissionTypeHRAdmin(userAccountType):
        return (
          <Nav justify variant="tabs" sticky="top" className="border-bottom">
            {/* {timesheetNavItems()} */}
            {shopTimesheetNavItems()}
          </Nav>
        );
      case isPermissionTypeShopEmployee(userAccountType):
        return (
          <Nav justify variant="tabs" sticky="top" className="border-bottom">
            {shopTimesheetNavItems()}
          </Nav>
        );
      // assume user is employee
      default:
        return (
          <Nav justify variant="tabs" sticky="top" className="border-bottom">
            {/* {timesheetNavItems()} */}
          </Nav>
        );
    }
  };

  const getRoutesByPermission = () => {
    if (!checkIfWindowIsMobile()) {
      switch (true) {
        case isPermissionTypeAdmin(userAccountType):
          return AdminRoutes();
        case isPermissionTypeShopEmployee(userAccountType):
          return ShopEmployeeRoutes();
        default:
          return EmployeeRoutes();
      }
    } else {
      return MobileRoutes();
    }
  };

  return (
    <>
      <Router>
        {getHrNavItemsByPermission()}
        <br />
        {getRoutesByPermission()}
      </Router>
    </>
  );
};
