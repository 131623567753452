export const createTableContent = (volumetricUnit) => {
  return {
    [`Volumetric Flow (${volumetricUnit})`]: 0,
    "Total Mass Flow": 0,
    "Total Water Mass Flow": 0,
    "Total Solids Mass Flow": 0,
    "Total Suspended Solids Mass Flow": 0,
    "Total Dissolved Solids Mass Flow": 0,
    "TKN": 0,
    "Ammonium Nitrogen": 0,
    "Organic Nitrogen": 0,
    "Potassium": 0,
    "Phosphorus": 0,
    "Dry grō 17": 0,
    "Other": 0,
    "Total grō 17 Mass Flow": 0,
  };
}

export const tabbedTableContent = {
  "TKN": 0,
  "Ammonium Nitrogen": 0,
  "Organic Nitrogen": 0,
  "Potassium": 0,
  "Phosphorus": 0,
  "Dry grō 17": 0,
  "Other": 0,
};

export const createAssessmentEditTemplate = (params, inputData, dissolvedSolidContent) => {
  return {
    "Assessment Name": {
      value: params.name.split('--time--')[0],
      key: "name"
    },
    "First Wave System": {
      value: params.sysType == "First Wave",
      key: "isFirstWave"
    },
    "Operating Hours/Day": {
      value: Number(params.hours),
      key: "operatingHours"
    },
    "Operating Days/Year": {
      value: Number(params.days),
      key: "operatingDays"
    },
    "Annual Flow (US gallons)": {
      value: Number(inputData["Annual Flow"]),
      key: "annualFlow"
    },
    "Screw Press Present": {
      value: !inputData["Screw Press Removed"],
      key: "screwPressRemoved"
    },
    "Screw Press Loop (Iteration)": {
      value: inputData["Screw Press Loop"] == 'true' || inputData['Screw Press Loop'] == 'True' || inputData['Screw Press Loop'] == true,
      key: "screwPressLoop"
    },
    "Add Second RO": {
      value: params.secondRO !== "false",
      key: "secondRO"
    },
    "Removal (%)": {
      value: Number(params.removal),
      key: "removal"
    },
    "Total Solids (ppm)": {
      value: Number(inputData["Total Solids (ppm)"]),
      key: "totalSolids"
    },
    "Total Suspended Solids (ppm)": {
      value: Number(inputData["TSS (ppm)"]),
      key: "suspendedSolids"
    },
    "Ammonium Nitrogen (ppm)": {
      value: Number(inputData["Ammonium Nitrogen (ppm)"]),
      key: "ammoniumNitrogen"
    },
    "TKN (ppm)": {
      value: Number(inputData["TKN (ppm)"]),
      key: "tkn"
    },
    "Potassium (ppm)": {
      value: Number(inputData["Potassium (ppm)"]),
      key: "potassium"
    },
    "Phosphorus (ppm)": {
      value: Number(inputData["Phosphorus (ppm)"]),
      key: "phosphorus"
    },
    "Volatile Solids (%)": {
      value: Number(inputData["Volatile Solids (%)"]),
      key: "volatileSolids"
    },
    "RO Recovery": {
      value: Number(inputData["RO Recovery"] * 100),
      key: "roRecovery"
    },
    "RO #2 Recovery": {
      value: Number(params.ro2Recovery) || 0.73 * 100,
      key: "ro2Recovery"
    },
    "Force SP Solids Output to be 20% TSS": {
      value: Boolean(params.spOutput20Percent == "true"),
      key: "spOutput20Percent"
    },
    "Override Polymer Dose": {
      value: params.override_polymer_dose !== "false",
      key: "override_polymer_dose"
    },
    "Forced Polymer Dose (ppm)": {
      value: params.polymer_dose === "undefined" ? 'N/A' : params.polymer_dose,
      key: "polymer_dose"
    }
  }
};