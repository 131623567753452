import React from "react";
import { useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HRShopTimesheetModal } from "./HRShopTimesheetModal";

export const HRShopTimesheetJobCodes = ({
  jobCodeList,
  shopTimesheet,
  setShopTimesheet,
  job,
  jobCodeDict,
  setEmployeeInitial,
}) => {
  const [editJob, setEditJob] = useState(jobCodeList);
  const [show, setShow] = useState(jobCodeList.new ? true : false);

  const handleShow = () => setShow(true);

  const deleteJob = (jobNumber, jobCodeList) => {
    shopTimesheet.jobList = shopTimesheet.jobList.map((jobList) => {
      if (jobList.jobNumber == jobNumber) {
        jobList.jobCodeList = jobList.jobCodeList.filter(
          (job) => job.id != jobCodeList.id
        );
        return jobList;
      } else {
        return jobList;
      }
    });
    setShopTimesheet({ ...shopTimesheet });
  };

  return (
    <>
      <ListGroup.Item>
        <Row>
          <Col className="col-6">
            {jobCodeList.jobCode ? jobCodeList.jobCode : "None Selected"}
          </Col>
          <Col className="col-md-4 col-3 d-flex align-items-center">
            {jobCodeList.hoursWorked + " hrs"}
          </Col>
          <Col className="col-md-2 col-3 d-flex align-items-center justify-content-end">
            <Row>
              <Col className="md-6 p-0 d-flex justify-content-end align-items-center">
                <AiOutlineEdit
                  onClick={() => {
                    setEditJob(jobCodeList);
                    handleShow();
                  }}
                />
              </Col>
              <Col className="md-6 d-flex align-items-center">
                <AiOutlineDelete
                  onClick={() => deleteJob(job.jobNumber, jobCodeList)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
      {editJob && (
        <HRShopTimesheetModal
          setShow={setShow}
          show={show}
          job={editJob}
          setJob={setEditJob}
          shopTimesheet={shopTimesheet}
          setShopTimesheet={setShopTimesheet}
          jobNumber={job.jobNumber}
          jobCodeDict={jobCodeDict}
          setEmployeeInitial={setEmployeeInitial}
        />
      )}
    </>
  );
};
