/**
 * ChemicalInsertModal.jsx
 * -----------------------
 * Component that contains insert functionality for Chemicals in the LWR database.
 * Contains appropraite form and validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 17/06/2021
 */

import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

// chem api
import { createChemical } from "../../../api/chemicals";

const ChemicalInsertModal = (props) => {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // Refs
  const chemicalNameRef = useRef();
  const chemicalCostRef = useRef();

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  // Form Submit
  const insertNewChemical = async (e) => {
    let name = chemicalNameRef.current.value;
    let cost = Number(chemicalCostRef.current.value);

    const createChemicalRes = await createChemical(name, cost);

    if (createChemicalRes.status != 200) {
      setSuccessMsg("");
      setErrMsg("Error inserting new Chemical into the database.");
    } else {
      setErrMsg("");
      setSuccessMsg(
        "Succesfully inserted new Chemical into the database. You may now close this modal."
      );
      chemicalNameRef.current.value = "";
      chemicalCostRef.current.value = "";
    }

    e.preventDefault();
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New Chemical
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Add New Chemical</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Fill out all fields below to insert a new chemical and its
            assosciated cost to the database.
          </div>
          <br />
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={insertNewChemical}>
              <Form.Group>
                <Form.Label>Chemical Name</Form.Label>
                <Form.Control type="text" ref={chemicalNameRef} required />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Chemical Cost</Form.Label>
                <Form.Control
                  pattern="^\d*(\.\d{0,2})?$"
                  placeholder="0.00"
                  ref={chemicalCostRef}
                  required
                />
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChemicalInsertModal;
