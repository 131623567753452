export const getProperTime = (time) => {
    const localTime = new Date(time).toLocaleString("en-US");
    const splitLocalTime = localTime.split(",");
    return `${splitLocalTime[1]}, ${splitLocalTime[0]}`;
};

export const capitalizeFirstCharacter = (word) => {
    const capitalizedFirstLetter = word[0].toUpperCase();
    const slicedWord = word.substring(1);
    return capitalizedFirstLetter + slicedWord;
};

export const parseKeyName = (keyName) => {
    return `${keyName.replace(
        /([A-Z])/g,
        " $1"
    )}`
}

export const getAllUser = (reportList) => {
    let allUserList = [];
    for (let report of reportList) {
        if (!allUserList.includes(report.email)) allUserList.push(report.email);
    }
    return allUserList;
};
