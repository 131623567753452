import { Position } from 'reactflow';

import {
  TableFromObject,
  titleStyle,
  createNodes as createFirstWaveNodes,
  markerStyle,
  edges as createFirstWaveEdges,
  edgeStyle
} from '../fw/FirstWaveNodes';

export const createNodes = (
  sysParams, inputData, inletFlows, afterPolymerAddition, microscreenInlet,
  msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, clarifierEffluent,
  clarifierSludge, antiscalant, roFeed, roConcentrate, roPermeate, ro2Concentrate, ro2Permeate,
  cleanWaterPolymer, cleanWaterDischarge, title
) => {
  const baseNodes = createFirstWaveNodes(
    sysParams,
    inputData,
    inletFlows,
    afterPolymerAddition,
    microscreenInlet,
    msSolids,
    msFiltrate,
    spSolids,
    spFiltrate,
    polymerSystem,
    title
  );

  baseNodes.push(...[
    {
      id: 'clarifier1',
      data: {
        label: <div>
          <div style={titleStyle}>Clarifier</div>
        </div>
      },
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
      position: { x: 2501, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'clarifier2',
      data: {
        label: <div>
          <div style={titleStyle}>Clarifier</div>
        </div>
      },
      sourcePosition: Position.Right,
      position: { x: 2501, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'clarifier_sludge',
      data: {
        label: <div>
          <div style={titleStyle}>Clarifier Sludge</div>
          <TableFromObject dataObject={clarifierSludge} />
        </div>
      },
      sourcePosition: Position.Left,
      targetPosition: Position.Bottom,
      position: { x: 2501, y: -450 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'clarifier_effluent',
      data: {
        label: <div>
          <div style={titleStyle}>Clarifier Effluent</div>
          <TableFromObject dataObject={clarifierEffluent} />
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 2900, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'cartridge_filter1',
      data: {
        label: <div>
          <div style={titleStyle}>Cartridge Filter</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      position: { x: 3300, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'cartridge_filter2',
      data: {
        label: <div>
          <div style={titleStyle}>Cartridge Filter</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
      position: { x: 3300, y: 150 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'antiscalant',
      data: {
        label: <div>
          <div style={titleStyle}>Antiscalant Pump</div>
          <TableFromObject dataObject={antiscalant} />
        </div>
      },
      sourcePosition: Position.Top,
      position: { x: 3300, y: 250 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_system_feed',
      data: {
        label: <div>
          <div style={titleStyle}>RO System Feed</div>
          <TableFromObject dataObject={roFeed} />
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
      position: { x: 3700, y: 0 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_system1',
      data: {
        label: <div>
          <div style={titleStyle}>Reverse Osmosis System</div>
        </div>
      },
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
      position: { x: 3700, y: 650 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_system2',
      data: {
        label: <div>
          <div style={titleStyle}>Reverse Osmosis System #1</div>
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
      position: { x: 3700, y: 650 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_system3',
      data: {
        label: <div>
          <div style={titleStyle}>Reverse Osmosis System #1</div>
        </div>
      },
      targetPosition: Position.Left,
      position: { x: 3700, y: 650 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_permeate',
      data: {
        label: <div>
          <div style={titleStyle}>RO #1 Permeate</div>
          <TableFromObject dataObject={roPermeate} />
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
      position: { x: 3700, y: 750 },
      draggable: false,
      selectable: false,
    },
    {
      id: 'ro_concentrate',
      data: {
        label: <div>
          <div style={titleStyle}>RO #1 Concentrate (Output Stream)</div>
          <TableFromObject dataObject={roConcentrate} />
        </div>
      },
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
      position: { x: 4100, y: 550 },
      draggable: false,
      selectable: false,
    },
  ]);

  // Single RO config
  if(!sysParams.secondRO) {
    baseNodes.push(...[
      {
        id: 'clean_water_polymer',
        data: {
          label: <div>
            <div style={titleStyle}>Clean Water Polymer Makdown</div>
            <TableFromObject dataObject={cleanWaterPolymer} />
          </div>
        },
        sourcePosition: Position.Left,
        targetPosition: Position.Right,
        position: { x: 3300, y: 1300 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'clean_water_discharge',
        data: {
          label: <div>
            <div style={titleStyle}>Clean Water Discharge (Output Stream)</div>
            <TableFromObject dataObject={cleanWaterDischarge} />
          </div>
        },
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
        position: { x: 4100, y: 1300 },
        draggable: false,
        selectable: false,
      },
    ]);
  }

  // Second RO config
  if(sysParams.secondRO) {
    baseNodes.push(...[
      {
        id: 'ro2_system_1',
        data: {
          label: <div>
            <div style={titleStyle}>Reverse Osmosis System #2</div>
          </div>
        },
        sourcePosition: Position.Bottom,
        targetPosition: Position.Top,
        position: { x: 3700, y: 1350 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'ro2_system_2',
        data: {
          label: <div>
            <div style={titleStyle}>Reverse Osmosis System #2</div>
          </div>
        },
        sourcePosition: Position.Left,
        targetPosition: Position.Top,
        position: { x: 3700, y: 1350 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'ro2_permeate',
        data: {
          label: <div>
            <div style={titleStyle}>RO #2 Permeate</div>
            <TableFromObject dataObject={ro2Permeate} />
          </div>
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
        position: { x: 3700, y: 1500 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'ro2_permeate2',
        data: {
          label: <div>
            <div style={titleStyle}>RO #2 Permeate</div>
            <TableFromObject dataObject={ro2Permeate} />
          </div>
        },
        sourcePosition: Position.Left,
        targetPosition: Position.Top,
        position: { x: 3700, y: 1500 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'ro2_concentrate',
        data: {
          label: <div>
            <div style={titleStyle}>RO #2 Concentrate</div>
            <TableFromObject dataObject={ro2Concentrate} />
          </div>
        },
        sourcePosition: Position.Top,
        targetPosition: Position.Right,
        position: { x: 3300, y: 1100 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'clean_water_polymer',
        data: {
          label: <div>
            <div style={titleStyle}>Clean Water Polymer Makdown</div>
            <TableFromObject dataObject={cleanWaterPolymer} />
          </div>
        },
        sourcePosition: Position.Left,
        targetPosition: Position.Right,
        position: { x: 3300, y: 1700 },
        draggable: false,
        selectable: false,
      },
      {
        id: 'clean_water_discharge',
        data: {
          label: <div>
            <div style={titleStyle}>Clean Water Discharge (Output Stream)</div>
            <TableFromObject dataObject={cleanWaterDischarge} />
          </div>
        },
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
        position: { x: 4100, y: 1700 },
        draggable: false,
        selectable: false,
      },
    ]);
  }
  

  return baseNodes;
}


export const edges = (sysParams) => {
  const baseEdges = createFirstWaveEdges(sysParams);

  baseEdges.push(...[
    { id: 'ms_filtrate->clarifier', style: edgeStyle, source: 'ms_filtrate', target: 'clarifier1', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'clarifier->sludge', style: edgeStyle, source: 'clarifier1', target: 'clarifier_sludge', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'clarifier->effluent', style: edgeStyle, source: 'clarifier2', target: 'clarifier_effluent', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'clarifier_sludge->recycling', style: edgeStyle, source: 'clarifier_sludge', target: 'filtrate_recycling_loop3', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'effluent->cartridge', style: edgeStyle, source: 'clarifier_effluent', target: 'cartridge_filter1', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'antiscalant->cartridge', style: edgeStyle, source: 'antiscalant', target: 'cartridge_filter2', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'cartridge->ro_feed', style: edgeStyle, source: 'cartridge_filter2', target: 'ro_system_feed', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'ro_feed->ro_system', style: edgeStyle, source: 'ro_system_feed', target: 'ro_system1', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'ro_sys->permeate', style: edgeStyle, source: 'ro_system2', target: 'ro_permeate', markerEnd: markerStyle, type: 'smoothstep' },
    { id: 'ro_sys->concentrate', style: edgeStyle, source: 'ro_system1', target: 'ro_concentrate', markerEnd: markerStyle, type: 'smoothstep' },
  ]);

  if(sysParams.secondRO) {
    baseEdges.push(...[
      { id: 'ro1_permeate->ro2system', style: edgeStyle, source: 'ro_permeate', target: 'ro2_system_1', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro2system->permeate', style: edgeStyle, source: 'ro2_system_1', target: 'ro2_permeate', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro2system->concentrate', style: edgeStyle, source: 'ro2_system_2', target: 'ro2_concentrate', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro2Concentrate->RO1Input', style: edgeStyle, source: 'ro2_concentrate', target: 'ro_system3', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro2Perm->polywater', style: edgeStyle, source: 'ro2_permeate2', target: 'clean_water_polymer', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro2Perm->dischargeWater', style: edgeStyle, source: 'ro2_permeate', target: 'clean_water_discharge', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'polywater->polysystem', style: edgeStyle, source: 'clean_water_polymer', target: 'polymer_system', markerEnd: markerStyle, type: 'smoothstep' },
    ]);
  } else {
    baseEdges.push(...[
      { id: 'ro1Perm->polywater', style: edgeStyle, source: 'ro_system3', target: 'clean_water_polymer', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'ro1Perm->dischargeWater', style: edgeStyle, source: 'ro_system2', target: 'clean_water_discharge', markerEnd: markerStyle, type: 'smoothstep' },
      { id: 'polywater->polysystem', style: edgeStyle, source: 'clean_water_polymer', target: 'polymer_system', markerEnd: markerStyle, type: 'smoothstep' },
    ]);
  }

  return baseEdges;
}
