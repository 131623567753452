/**
 * CarbonEmissions.jsx
 * -------------------
 * Carbon Emissions Component to be used in Assessment Reports
 * 
 * Author: Oscar Jaimes
 */

import { Table } from "react-bootstrap";
import { Chart } from "react-google-charts";
import Header from "../../client-report-content/Header";

const CarbonEmissions = (props) => {
  const {
    params,
    carbonEmissions,
    ref,
    other
  } = props;

  const fractionOfYearLeft = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1); // January 1st
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31); // December 31st
    const yearInMilliseconds = endOfYear - startOfYear;
    const millisecondsPassed = currentDate - startOfYear;
    const fractionLeft = 1 - (millisecondsPassed / yearInMilliseconds);
    return fractionLeft;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const data = ['2024', '2025', '2026', '2027', '2028'].map((year, i) => {
    if (i == 0) {
      const fractionLeft = fractionOfYearLeft();
      return [year, carbonEmissions.baseline_emissions * fractionLeft, (carbonEmissions.project_emissions.totalEmissions + carbonEmissions.leakage_emissions) * fractionLeft];
    }

    return [year, carbonEmissions.baseline_emissions, (carbonEmissions.project_emissions.totalEmissions + carbonEmissions.leakage_emissions)];
  });

  data.unshift(["Year", "Baseline Emissions", "Project + Leakage Emissions"]);

  function replaceUnderscoresAndCapitalize(str) {
    // Replace underscores with spaces
    str = str.replace(/_/g, ' ');
  
    // Capitalize the first letter of each word
    str = str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  
    return str;
  }

  return (
    <div 
    ref={ref}
    style={{
      maxWidth: '1600px',
      minWidth: '1200px',
      padding: '20px',
    }}>
      <Header
        clientName={params.name.split(" --time-- ")[0]}
        units={params.units}
        title="Carbon Emissions Modelling"
        hideUnits={true}
      />

      <br />

      <div className="row">
        <h5 style={{ marginLeft: -10, color: "#005E7D" }}>Model Parameters</h5>
        <Table striped>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Region</td>
              <td>{replaceUnderscoresAndCapitalize(params.region)}</td>
              <td style={{ fontWeight: 'bold' }}>Feed Type</td>
              <td>{replaceUnderscoresAndCapitalize(params.livestockType.includes('digestate') ? 'Digestate' : params.livestockType)}</td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold' }}>Number of Livestock</td>
              <td>{params.numLivestock !== 'null' ? params.numLivestock : 0}</td>
              <td style={{ fontWeight: 'bold' }}>Dry Matter Intake</td>
              <td>{params.dryMatterIntake !== 'null' ? params.dryMatterIntake : 0} kg/day/animal</td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold' }}>Manure Separation %</td>
              <td>{params.manureSeparation} %</td>
              <td style={{ fontWeight: 'bold' }}>LWR System Separation Efficiency %</td>
              <td>{params.separationEfficiency} %</td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold' }}>Anerobic Treatment Fraction %</td>
              <td>{params.anerobicTreatment} %</td>
              <td style={{ fontWeight: 'bold' }}>Baseline Treatment Type</td>
              <td>{replaceUnderscoresAndCapitalize(params.baselineTreatment)}</td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold' }}>System Power Consumption</td>
              <td>{params.systemPower} kW</td>
              <td style={{ fontWeight: 'bold' }}>System Operation Time</td>
              <td>{params.operationTime} Hours</td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bold' }}>Electricity Region</td>
              <td>{replaceUnderscoresAndCapitalize(params.electricityRegion)}</td>
              <td style={{ fontWeight: 'bold' }}>Waste Composted</td>
              {/* <td>{numberWithCommas(other.yearlyCompostedWaste)} Tonnes/Year</td> */}
            </tr>
            <tr>
              {/* <td style={{ fontWeight: 'bold' }}>Total Flocculant</td> */}
              {/* <td>{numberWithCommas(Number(other.flocculantPerYear).toFixed(2))} Tonnes/Year</td> */}

              {/* <td style={{ fontWeight: 'bold' }}>-</td> */}
              {/* <td>-</td> */}
            </tr>
          </tbody>
        </Table>
      </div>
      <br />
      <br />

      <div className="row">
        <h5 style={{ marginLeft: -10, color: "#005E7D" }}>CO2 Equivalent Emission Estimates (5-Year Projection)</h5>
        <Table striped>
          <thead style={{ backgroundColor: '#005E7D', color: 'white' }}>
            <tr>
              <th>Year</th>
              <th>Estimated Baseline Emissions (tCO2e)</th>
              <th>Estimated Leakage Emissions (tCO2e)</th>
              <th>Estimated Project Emissions (tCO2e)</th>
              <th>Estimated Net Reductions (tCO2e)</th>
            </tr>
          </thead>
          <tbody>
            {
              [2024, 2025, 2026, 2027, 2028].map((year, i) => {

                if (i == 0) {
                  const fractionLeft = fractionOfYearLeft();

                  return (<tr>
                    <td>{year}</td>
                    <td>{numberWithCommas(Number(carbonEmissions.baseline_emissions * fractionLeft).toFixed(2))}</td>
                    <td>{numberWithCommas(Number(carbonEmissions.leakage_emissions * fractionLeft).toFixed(2))}</td>
                    <td>{numberWithCommas(Number(carbonEmissions.project_emissions.totalEmissions * fractionLeft).toFixed(2))}</td>
                    <td>{numberWithCommas(Number(carbonEmissions.net_emissions * fractionLeft).toFixed(2))}</td>
                  </tr>)
                }

                return (<tr>
                  <td>{year}</td>
                  <td>{numberWithCommas(Number(carbonEmissions.baseline_emissions).toFixed(2))}</td>
                  <td>{numberWithCommas(Number(carbonEmissions.leakage_emissions).toFixed(2))}</td>
                  <td>{numberWithCommas(Number(carbonEmissions.project_emissions.totalEmissions).toFixed(2))}</td>
                  <td>{numberWithCommas(Number(carbonEmissions.net_emissions).toFixed(2))}</td>
                </tr>)
              })
            }
            <tr style={{ backgroundColor: '#a6e7f5' }}>
              <td style={{ fontWeight: 'bold' }}>Total</td>
              <td>{numberWithCommas(Number((4 * carbonEmissions.baseline_emissions) + (carbonEmissions.baseline_emissions * fractionOfYearLeft())).toFixed(2))}</td>
              <td>{numberWithCommas(Number((4 * carbonEmissions.leakage_emissions) + (carbonEmissions.leakage_emissions * fractionOfYearLeft())).toFixed(2))}</td>
              <td>{numberWithCommas(Number((4 * carbonEmissions.project_emissions.totalEmissions) + (carbonEmissions.project_emissions.totalEmissions * fractionOfYearLeft())).toFixed(2))}</td>
              <td>{numberWithCommas(Number((4 * carbonEmissions.net_emissions) + (carbonEmissions.net_emissions * fractionOfYearLeft())).toFixed(2))}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <br />
      <br />

      <div className="row">
        <div className="col-md-5 " style={{ margin: 0, padding: 0 }}>
          <h5 style={{ marginLeft: -10, color: "#005E7D" }}>Estimated Project Emissions Breakdown</h5>
          <Table className="striped-table" style={{ marginLeft: -10 }}>
            <tbody>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Solids Storage Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.solidStorageEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold' }}>Electricity Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.electricityEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold' }}>Solids Composting Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.compostingEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold' }}>Total Project Emissions</td>
                <td style={{ color: '#005E7D', }}>{numberWithCommas(Number(carbonEmissions.project_emissions.totalEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>
            </tbody>
          </Table>

          <br />
          <br />

          <h5 style={{ marginLeft: -10, color: "#005E7D" }}>Project Solids Composting Emissions Breakdown</h5>
          <Table className="striped-table" style={{ marginLeft: -10 }}>
            <tbody>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Fossil Fuel Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.fossilFuelEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Methane Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.methaneEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Nitrogen Oxides Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.nitrousOxideEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Electricity Emissions</td>
                <td>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.electricityEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Total Composting Emissions</td>
                <td style={{ color: '#005E7D', }}>{numberWithCommas(Number(carbonEmissions.project_emissions.compostingEmissions.compostingEmissions).toFixed(2))} (tCO2e / year)</td>
              </tr>


            </tbody>
          </Table>
        </div>

        <div className="col-md-7" style={{ margin: 0, padding: 0, }}>
          <h5 style={{ color: "#005E7D" }}>Emissions Projection</h5>
          <Chart
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              title: '',
              vAxis: { title: 'tC02e', scaleType: 'linear', minValue: 0 },
              titleTextStyle: {
                fontFamily: 'Arial',
                fontSize: 15,
                bold: true,
                italic: false
              },
              series: { 0: { targetAxisIndex: 0, color: "red" }, 1: { targetAxisIndex: 0, color: "#00D100" } },
              backgroundColor: { fill: 'transparent' },
              height: 500,
              chartArea: {
                width: '80%',
                height: '80%'
              },
              legend: { position: 'top', alignment: 'start' }
            }} />
        </div>
      </div>
    </div>
  );
};

export default CarbonEmissions;