import React from 'react';
import { Chart } from "react-google-charts";

export default function TotalsChart(props) {
    return (
        <>
            <div className="col-md-12" style={{marginTop: 20}}>
            <br />
                <Chart
                    width={'100%'}
                    height={'350px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Output', '%'],
                        [props.language?.nutrient_solids || 'Nutrient Solids', (props.data[0] / (props.data[0] + props.data[1] + props.data[2])) * 100],
                        [props.language?.liquid_nutrient || 'Liquid Nutrient', (props.data[1] / (props.data[0] + props.data[1] + props.data[2])) * 100],
                        [props.language?.clean_water || 'Clean Water', (props.data[2] / (props.data[0] + props.data[1] + props.data[2])) * 100],
                    ]}
                    options={{
                        chartArea : { left: 0, top: 30, bottom: 0},
                        title: `${props.language?.yearly_outputs || 'Yearly Outputs'} (%)`,
                        titleTextStyle: { fontSize: 19, color: '#005E7D' },
                        legend: { position: 'left', textStyle: { fontSize: props.language ? (props.language.language == 'ch' ? 21 : 18) : 18 } },
                        colors: [
                            '#8a4b00',
                            '#258d00',
                            '#00afd6',],
                        is3D: true,
                    }}
                    rootProps={{ 'data-testid': '2' }}
                />
            </div>
        </>
    )
}
