import React from 'react'
import { Chart } from "react-google-charts";

export default function InletTSSAndPhGraph(props) {
    return (
        <Chart
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={props.inlettssandphdata}
            options={{
                backgroundColor: { fill: "transparent" },
                hAxis: { format: "dd/MM/yy" },
                height: 450,
                chartArea: { width: "80%", height: "75%" },
                legend: { position: "top" },
                series: { 0: { targetAxisIndex: 0, color: "#38761D" }, 1: { targetAxisIndex: 1, color: "#BF9000", type: "line" } },
                seriesType: "line",
                title: "Inlet TSS & Inlet pH vs Date ",
                titleTextStyle: { fontFamily: "Arial", fontSize: 15, bold: true, italic: false },
                vAxes: {
                    0: { title: "ppm", viewWindow: { min: 0 } },
                    1: { title: "pH", viewWindowMode: "explicit", viewWindow: { min: 0, max: 14 }, gridlines: { color: "transparent" } },
                },
                axes: {
                    y: {
                        all: {
                            range: {
                                min: 0
                            }
                        }
                    }
                },
            }}
        />
    )
}
