export const newExpenseExample = {
    expenseName: "",
    expenseType: "", // one of: meals, lodging, fuel, materials, subcontractors
    paidUsingCompanyCard: true,
    visa: true,
    cash: false,
    inventory: 0,
    description: "",
    quantity: 0,
    amount: 0,
    currencyType: "USD",
    expenseTimesheet: null
};


export const showFileExample = { name: "", url: "" };