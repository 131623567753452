import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ShopTimesheetForm from "./ShopTimesheetForm";
import OfficeTimesheetForm from "../office-timesheets/OfficeTimesheetForm";
import "./timesheetModal.css";

const NewShopTimesheetModal = ({onFinish, office}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button variant="outline-success" onClick={handleShow} style={{width: '100%'}}>
        New {office ? 'Office' : 'Shop'} Timesheet
      </Button>

      <Modal show={show} size="xl" className="office-time">
        <Modal.Header>
          <Modal.Title>Create {office ? 'Office' : 'Shop'} Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { office 
            ? <OfficeTimesheetForm isViewMode={false} onFinish={() => {handleClose(); onFinish();}} cancelView={() => {handleClose(); onFinish();}}/>
            : <ShopTimesheetForm isViewMode={false} onFinish={() => {handleClose(); onFinish();}}/>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            handleClose();
            onFinish();
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewShopTimesheetModal;