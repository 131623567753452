import React from "react";
import { Col } from "react-bootstrap";
import { FormGroupCreator } from "./FormGroupCreator";

export const FieldTimeReportingEditAmount = ({
  changeValue,
  id,
  valueDict,
}) => {
  return (
    <>
      <Col className="p-0" style={{ maxWidth: "20vw" }}>
        <FormGroupCreator
          type="number"
          valueDict={valueDict}
          valueType={{ type: "amount", id }}
          changeValue={changeValue}
          min={0}
        />
      </Col>

      <Col md={1} className=" ps-4 d-flex justify-content-center">
        <select
          value={valueDict.currencyType}
          onChange={(e) =>
            changeValue(e.target.value, {
              type: "currencyType",
              id,
            })
          }
        >
          <option value="USD">USD</option>
          <option value="CAD">CAD</option>
        </select>
      </Col>
    </>
  );
};
