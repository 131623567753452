import React, { useState } from "react";
import {
  Modal,
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "react-bootstrap";
import TwoFactorSignupModal from "../components/auth/TwoFactorSignupModal";
import { useAuth } from "../contexts/AuthContext";
import { BsFillPersonFill } from "react-icons/bs";
import { convertKeyNameToDisplayName } from "./field-tools/TimeReporting/TimeReportFunctions";

const SettingsModal = (props) => {
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const [show2FA, setShow2FA] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={props.isDarkMode ? 'outline-light': 'outline-dark'}onClick={handleShow}>
        <BsFillPersonFill />
      </Button>
      <TwoFactorSignupModal show={show2FA} setShow={setShow2FA}/>
      <Modal show={show} onHide={handleClose} size="lg" style={{ zoom: '90%' }}>
        <Modal.Header>
          <Modal.Title>Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Account Information</Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroupItem>Email: {props.account.email}</ListGroupItem>
                    <ListGroupItem>
                      Account Permission:{" "}
                      {props.accountType
                        .map((permissionType) =>
                          convertKeyNameToDisplayName(permissionType)
                        )
                        .toString()
                        .replaceAll(",", ", ")}
                    </ListGroupItem>
                    <ListGroupItem>
                      Last Login: {props.account.metadata.lastSignInTime}
                    </ListGroupItem>
                    <ListGroupItem>
                      Email Verified: {currentUser.emailVerified ? 'Yes' : 'No'} 
                    </ListGroupItem>
                    <ListGroupItem>
                      2-Factor Authentication: {currentUser.multiFactor.enrolledFactors.length > 0 ? 'Yes' : 'No'}
                    </ListGroupItem>
                    <ListGroupItem>
                      <Row style={{textAlign: 'center'}}>
                        {!currentUser.emailVerified && <Col>
                          <Button variant="dark" onClick={async () => { await currentUser.sendEmailVerification(); alert(`An email has been sent to ${currentUser.email}`) }}>Verify Email Address</Button>
                        </Col>}
                        {((currentUser.multiFactor.enrolledFactors.length == 0) && currentUser.emailVerified) && <Col>
                          <Button variant="dark" onClick={() => {
                            setShow2FA(true);
                          }}>Set Up 2-Factor Authentication</Button>
                        </Col>}
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <Card.Text></Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingsModal;
