import { React, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { HRPDFRenderer } from "../../HRPDFRenderer";
// import "./PolicyDocument.css";

// Converts the given data to be displayed as a pdf
export const HRPolicySubHeaderPdf = ({
  blobData,
  selectedSubHeader,
  setShowPDF,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(selectedSubHeader.firstPage);
  useEffect(() => {}, [pageNumber]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const disableLastPageButton = () => {
    return pageNumber <= selectedSubHeader.firstPage ? { disabled: true } : {};
  };

  const disableNextPageButton = () => {
    return selectedSubHeader.lastPage <= pageNumber ? { disabled: true } : {};
  };

  return (
    <div>
      <div className="">
        <Button onClick={() => setShowPDF(false)}>Back</Button>
      </div>
      <HRPDFRenderer
        blobData={blobData}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        pageNumber={pageNumber}
      />

      <div className="d-flex justify-content-between px-5">
        <Button
          {...disableLastPageButton()}
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
        >
          prev page
        </Button>
        <Button
          {...disableNextPageButton()}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          next page
        </Button>
      </div>
    </div>
  );
};
