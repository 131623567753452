import React, { Component } from "react";
import "../styles/Login.css";
import SignupForm from "../components/auth/SignupForm";
import LoginScreenBoiler from "./LoginScreenBoiler";

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    document.title = "Signup";
  }

  render() {
    return (
      <LoginScreenBoiler>
        <SignupForm />
      </LoginScreenBoiler>
    );
  }
}
