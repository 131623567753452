import React, { Component } from "react";
import { Card, Button, Form, Alert, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";

// api
import { getUserPermissionFromCache } from "../../utility/permissionFunctions";
import { getAvailableDevices } from "../../api/iot";

export default class AnalyticsReportingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systems: [],
      startDateWeekly: new Date(),
      endDateWeekly: new Date(),
      startDateDaily: new Date(),
      selectedSystemWeekly: "skyridge_farms",
      selectedSystemDaily: "skyridge_farms",
      weeklyErrorMsg: "",
      dailyErrorMsg: "",
      weeklyUnits: "Imperial",
    };
    this.email = props.email;
    this.onWeeklyReportSubmit = this.onWeeklyReportSubmit.bind(this);
    this.onDailyReportSubmit = this.onDailyReportSubmit.bind(this);
  }

  async componentDidMount() {
    document.title = "Analytics Reporting";
    await getUserPermissionFromCache(this.email);
    this.init();
  }

  async init() {
    const allSystems = await getAvailableDevices();
    this.setState({
      systems: allSystems,
    });
  }

  onWeeklyReportSubmit(e) {
    e.preventDefault();

    // Error check and go to report
    const startDateInSeconds = this.state.startDateWeekly.getTime();
    const endDate = new Date(this.state.endDateWeekly);
    const endDateInSeconds = this.state.endDateWeekly.getTime();
    const yearOldCalendarDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - 365
    );

    const yearDateInSeconds = yearOldCalendarDate.getTime();

    if (startDateInSeconds < yearDateInSeconds) {
      this.setState({
        weeklyErrorMsg: "Error. Start date must be less than a year old",
      });
      return;
    } else if (
      this.state.startDateWeekly == "" ||
      this.state.endDateWeekly == ""
    ) {
      this.setState({ weeklyErrorMsg: "Error. Please fill out all fields" });
      return;
    } else if (startDateInSeconds > endDateInSeconds) {
      this.setState({
        weeklyErrorMsg: "Error. Start date must be before end date",
      });
      return;
    } else if (this.state.startDateWeekly > this.state.endDateWeekly) {
      // Error check and go to report
      this.setState({
        weeklyErrorMsg: "Error. Start date must be before end date",
      });
      return;
    } else {
      let startdate = new Date(
        Date.parse(this.state.startDateWeekly)
      ).toISOString();
      let enddate = new Date(
        Date.parse(this.state.endDateWeekly)
      ).toISOString();

      // Open report in new window
      const url = `/weekly-report/${this.state.selectedSystemWeekly}/${startdate}/${enddate}/${this.state.weeklyUnits}`;
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  }

  onDailyReportSubmit(e) {
    e.preventDefault();
    if (this.state.startDateDaily == "") {
      this.setState({ dailyErrorMsg: "Error. Please fill out all fields" });
    } else {
      if (this.state.startDateDaily > new Date()) {
        this.setState({ dailyErrorMsg: "Error. Invalid date." });
        return;
      }
      let startdate = new Date(
        Date.parse(this.state.startDateDaily)
      ).toISOString();
      const { history } = this.props;
      if (history)
        history.push(
          `/daily-report/${this.state.selectedSystemDaily}/${startdate}`
        );
    }
  }

  render() {
    const { systems, startDateWeekly, endDateWeekly, startDateDaily } = this.state;
    const optionItems = systems.map((system) => (
      {value: system.linkingId, label: system.linkingId}
    ));

    return (
      <div>
        <div style={{ backgroundColor: "#FFFFFF", height: "100vh", zoom: '85%' }}>
          <div className="row" style={{ padding: "1%" }}>
            <div className="col-md-11" style={{ margin: 'auto' }}>
              <div className="row">
                <div className="col-md-12">
                  <Card>
                    <Card.Header style={{ backgroundColor: '#005E7D', color: 'white' }}>Analytics Reporting</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        View & Generate Analytics reports for IoT connected systems.
                        <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "1%" }}>
            <div className="col-md-11" style={{ margin: 'auto' }}>
              <div className="row">
                <div className="col-md-12" style={{ margin: 'auto' }}>
                  <Card style={{ height: '100%' }}>
                    <Card.Header style={{ backgroundColor: '#005E7D', color: 'white' }}>Weekly Reporting</Card.Header>
                    <Card.Body>
                      {this.state.weeklyErrorMsg && (
                        <Alert variant="danger">
                          {this.state.weeklyErrorMsg}
                        </Alert>
                      )}
                      <Form onSubmit={this.onWeeklyReportSubmit}>
                        <Row>
                          <Form.Group>
                            <Form.Label>Select System:</Form.Label>
                            <Select
                              options={optionItems}
                              onChange={(e) =>{
                                this.setState({
                                  selectedSystemWeekly: e.value,
                                })
                              }
                              }
                            />
                          </Form.Group>
                        </Row>
                        <br />
                        <Row>
                          <Form.Group>
                            <Form.Label>Select Units:</Form.Label>
                            <select
                              className="form-control"
                              name="systems"
                              onChange={(e) =>
                                this.setState({ weeklyUnits: e.target.value })
                              }
                            >
                              <option value="Imperial">Imperial</option>
                              <option value="Metric">Metric</option>
                            </select>
                          </Form.Group>
                        </Row>
                        <br />
                        <Row>
                          <label>Start Date:</label>
                          <br />
                          <DatePicker
                            value={startDateWeekly}
                            onChange={(date) =>
                              this.setState({ startDateWeekly: date })
                            }
                          />
                        </Row>
                        <br></br>
                        <Row>
                          <label>End Date:</label>
                          <br />
                          <DatePicker
                            value={endDateWeekly}
                            onChange={(date) =>
                              this.setState({ endDateWeekly: date })
                            }
                          />
                        </Row>
                        <br></br>
                        <Button variant="outline-dark" type="submit">
                          Generate Report
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
