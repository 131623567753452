import React from "react";
import { useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { AiOutlineCaretDown } from "react-icons/ai";

export const CollapsingFilter = (props) => {
  const [collapse, setCollpase] = useState(false);

  return (
    <Card className="mx-2 py-2">
      <h4 className="d-flex justify-content-center">Search Filters</h4>

      <Collapse in={collapse}>{props.children}</Collapse>

      <div className="d-flex justify-content-center pt-2">
        {!collapse ? (
          <AiOutlineCaretDown
            style={{ cursor: "pointer" }}
            onClick={() => setCollpase((collpase) => !collpase)}
          >
            no collpase
          </AiOutlineCaretDown>
        ) : (
          <AiOutlineCaretDown
            style={{ cursor: "pointer", transform: "rotate(180deg)" }}
            onClick={() => setCollpase((collpase) => !collpase)}
          >
            no collpase
          </AiOutlineCaretDown>
        )}
      </div>
    </Card>
  );
};
