import React from "react";
import "../../../../styles/EngAssessment.css";

export default function Footer() {
  return (
    <div className="row w=">
      <div
        className="col-4"
        style={{
          textAlign: "left",
          color: "rgb(0, 94, 125)",
          fontWeight: 550,
        }}
      >
        <p className="external-notice">NOT FOR EXTERNAL DISTRIBUTION</p>
      </div>
      <div className="col-4" style={{ textAlign: "center" }}>
        <p> © 2024 Livestock Water Recycling</p>
      </div>
      <div
        className="col-4"
        style={{
          textAlign: "right",
          color: "rgb(0, 94, 125)",
          fontWeight: 550,
        }}
      >
        <p className="external-notice">NOT FOR EXTERNAL DISTRIBUTION</p>
      </div>
    </div>
  );
}
