import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { HRPoliciesNav } from "./HRPoliciesNav";
export const HRPolicies = () => {
  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <Row style={{ paddingTop: "2%" }}>
        <Col md={12}>
          <Card>
            <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>HR Policies</Card.Header>
            <Card.Body>
              <HRPoliciesNav />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
