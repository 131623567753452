import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const FormCheckCreator = ({
  label = "",
  changeValue,
  valueType,
  valueDict,
}) => {
  const findIsChecked = () => {
    return valueDict[valueType] == true ? { checked: true } : {};
  };
  return (
    <Form.Group className="mb-3 ">
      <Row className="d-flex align-items-center justify-content-center">
        {label ? (
          <Col className="col-2 text-center">
            <Form.Label>{label}</Form.Label>
          </Col>
        ) : (
          <></>
        )}
        <Col className="col-10">
          <Form.Check
            {...findIsChecked()}
            inline
            onChange={() => changeValue(!valueDict[valueType], valueType)}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};
