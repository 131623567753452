// libs
import { Row, Col, Button, Form, Spinner, Table, Alert } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { FaEye, FaTrashAlt, FaFileDownload, FaEdit } from "react-icons/fa";

// components
import NewShopTimesheetModal from '../shop-timesheets/NewShopTimesheetModal';
import { DeleteResultModal, DeleteShopTimesheetModal } from "../shop-timesheets/ShopTimesheetModals";

// api
import { getAllUserEmails } from '../../api/auth';
import { isPermissionTypeHRAdmin, getUserPermissionFromCache } from '../../utility/permissionFunctions';
import { getAllOfficeTimesheets, deleteOfficeTimesheet, singleDownload } from "../../api/officeTimesheets";

// hooks
import { useAuth } from "../../contexts/AuthContext";
import usePermission from '../../hooks/permissions';
import useQuery from '../../hooks/useQuery';
import OfficeTimesheetForm from "./OfficeTimesheetForm";

const OfficeTimesheetPage = () => {
  const { email } = useAuth().currentUser;
  const { permission } = usePermission(email);

  // state
  const [userType, setUserType] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteResultModal, setShowDeleteResultModal] = useState(false);
  const [timeSheetToDelete, setTimesheetToDelete] = useState({});
  const [deleteResult, setDeleteResult] = useState('');
  const [performEdit, setPerformEdit] = useState(false);
  const [filter, setFilter] = useState('');

  // effects
  useEffect(async () => {
    document.title = 'Office Timesheets';
    setUserType(await getUserPermissionFromCache(email));
  }, []);

  // API data
  const { data: timeSheets = [], isLoading: loading = true, refetch: refetchTimesheets } = useQuery(
    'office-timesheets',
    () => getAllOfficeTimesheets(email),
    null
  );

  // funcs
  const handleFilterChange = () => { };
  const getAllTimesheets = () => {
    refetchTimesheets();
  };

  const deleteTimeSheet = async () => {
    const res = await deleteOfficeTimesheet(timeSheetToDelete._id, timeSheetToDelete.email);
    if (res.status == 200) {
      setDeleteResult('Succesfully deleted timesheet');
    } else {
      setDeleteResult('Could not delete timesheet. Please try again.')
    }

    setShowDeleteModal(false);
    setShowDeleteResultModal(true);
    getAllTimesheets();
  };

  const deleteTimesheetShow = (ts) => {
    setShowDeleteModal(true);
    setTimesheetToDelete(ts);
  };

  if (selectedTimesheet) {
    return <div style={{ margin: '1% 1% 1% 1%', zoom: '95%' }}>
      <OfficeTimesheetForm isViewMode={true} editMode={performEdit} timeSheet={selectedTimesheet} cancelView={() => { getAllTimesheets(); setSelectedTimesheet(null) }} onFinish={() => { getAllTimesheets() }} />
      </div>
  }

  return (
    <div style={{ margin: '1% 1% 1% 1%', zoom: '88%' }}>
      <DeleteShopTimesheetModal
        showDeleteModal={showDeleteModal}
        timeSheetToDelete={timeSheetToDelete}
        deleteTimeSheet={deleteTimeSheet}
        setTimesheetToDelete={setTimesheetToDelete}
        setShowDeleteModal={setShowDeleteModal}
      />
      <DeleteResultModal
        showDeleteResultModal={showDeleteResultModal}
        deleteResult={deleteResult}
        setShowDeleteResultModal={setShowDeleteResultModal}
        setDeleteResult={setDeleteResult}
      />
      <Row>
        <Col md={10} style={{ textAlign: 'left' }}>
          <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Office Timesheets
            {(isPermissionTypeHRAdmin(permission)) && " - HR/Admin View"}
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 mt-3">
        <Col md={10}>
          <Form.Control
            type="text"
            placeholder="Filter by timesheet name, date, or creator"
            value={filter}
            onChange={handleFilterChange}
          />
        </Col>
        <Col md={2} style={{ textAlign: 'right' }}>
          <NewShopTimesheetModal onFinish={() => { getAllTimesheets() }} office={true} />
        </Col>
      </Row>

      {
        loading && <div style={{ textAlign: 'center' }}>
          <Spinner animation="border" role="status" style={{ width: '100px', height: '100px', marginTop: '10%' }} />
        </div>
      }
      {!loading &&
        <div style={{ maxHeight: '85vh', overflowY: 'auto', marginTop: '1%' }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>🧾 Timesheet Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Created By</th>
                <th>Approval</th>
                <th>Approved By</th>
                <th>Hours</th>
                <th>View</th>
                <th>Delete</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {(timeSheets || []).map((ts) => (
                <tr>
                  <td>{ts.timesheetName}</td>
                  <td>{ts.startDate.split('T')[0]}</td>
                  <td>{ts.endDate.split('T')[0]}</td>
                  <td>{ts.email}</td>
                  <td>
                    <Alert variant={ts.approved ? 'success' : 'warning'} style={{ margin: '0', padding: '2%' }}>
                      <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{ts.approved ? 'Approved' : 'Pending'}</p>
                    </Alert>
                  </td>
                  <td>{ts.approvedBy || 'N/A'}</td>
                  <td>{ts.totalHours}</td>
                  <td><FaEye size={22} onClick={() => {
                    setSelectedTimesheet(ts);
                  }} /></td>
                  <td><FaTrashAlt size={22} onClick={() => { deleteTimesheetShow(ts) }} /></td>
                  <td><FaFileDownload size={22} onClick={async () => {
                    await singleDownload(ts._id);
                  }} /></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </div>
  );
};

export default OfficeTimesheetPage;