import React, { useEffect } from "react";
import { Nav, Row, Col } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { getUserPermissionFromCache } from "../../utility/permissionFunctions";
import { FieldEODReport } from "./FieldEODReport/FieldEODReport";
import { FieldExpenses } from "./FieldExpenses/FieldExpenses";
import { FieldTimeReporting } from "./FieldTimeReporting";
import { FaTools } from "react-icons/fa";
import "./FieldNav.css";

export const FieldNav = () => {
  const { email } = useAuth().currentUser;

  useEffect(async () => {
    await getUserPermissionFromCache(email);
  }, []);

  return (
    <div style={{ zoom: '85%', overflowY: 'hidden'}}>
      <Router>
        <FieldNavInner />
      </Router>
    </div>
  );
};

const RouteNav = () => {
  const location = useLocation();

  const isActivePath = (path) => {
    return location.pathname === path;
  }

  return (
    <Nav className="flex-column" style={{marginLeft: '2%'}}>
      <h5 style={{textAlign: 'center', fontWeight: 'bold', color: '#005E7D'}}><FaTools />   Field Tools</h5>
      <Nav.Item className="timesheets">
        <Nav.Link
          className="timesheets"
          as={Link}
          style={isActivePath("/field-tools/timesheets") ? { backgroundColor: "#005E7D", color: "white" } : { color: "grey" }}
          to="/field-tools/timesheets"
        >
          Field Timesheets
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className="timesheets"
          as={Link}
          style={isActivePath("/field-tools/expenses") ? { backgroundColor: "#005E7D", color: "white" } : { color: "grey" }}
          to="/field-tools/expenses"
        >
          Field Expense Reports
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className="timesheets"
          as={Link}
          style={isActivePath("/field-tools/eod") ? { backgroundColor: "#005E7D", color: "white" } : { color: "grey" }}
          to="/field-tools/eod"
        >
          Field EOD reports
        </Nav.Link>
      </Nav.Item>

    </Nav>
  );
};

const FieldNavInner = () => {
  return (
      <div className="field-nav-container">
        <div className="timesheets-sidebar">
          <RouteNav />
        </div>

        <div className="timesheets-content">
          <Switch>
            <Route exact path="/field-tools" render={() => <Redirect to="/field-tools/timesheets" />} />
            <Route path="/field-tools/timesheets" component={FieldTimeReporting} />
            <Route path="/field-tools/expenses" component={FieldExpenses} />
            <Route path="/field-tools/eod" component={FieldEODReport} />
          </Switch>
        </div>
      </div>
  );
};
