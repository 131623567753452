import React from "react";

export default function Header(props) {
  return (
    <>
      <div className="row" style={{ minWidth: "1150px" }}>
        <div className="col-md-3 ">
          {!props.hideUnits && <p
            className="external-notice  text-wrap"
            style={{
              color: "rgb(0, 94, 125)",
              fontWeight: 550,
            }}
          >
            INTERNAL USE ONLY
          </p>}
        </div>
        <div className="col-md-6 text-center" style={{ textAlign: "center" }}>
          <h5 className="text-wrap">{props.title} - {props.clientName}  ({!props.hideUnits && `${props.units} Units`})</h5>
        </div>

        {!props.hideUnits && <div className="col-md-3">
          <p
            className="external-notice text-wrap"
            style={{
              color: "rgb(0, 94, 125)",
              fontWeight: 550,
              textAlign: "right",
            }}
          >
            INTERNAL USE ONLY
          </p>
        </div>}
      </div>
    </>
  );
}
