// libs
import { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Spinner, Form, Table, Alert } from 'react-bootstrap';
import { FaEye, FaTrashAlt, FaFileDownload, FaEdit } from "react-icons/fa";

// components
import NewShopTimesheetModal from './NewShopTimesheetModal';
import ShopTimesheetForm from './ShopTimesheetForm';
import { DeleteResultModal, DeleteShopTimesheetModal, BulkShopTimesheetDownloadModal } from './ShopTimesheetModals';

// api
import { deleteShopTimesheet, getAllShopTimesheet, bulkDownload, singleDownload, bulkAggregatedDownload } from '../../api/hrShopTimeSheet';
import { getAllUserEmails } from '../../api/auth';
import { isPermissionTypeHRAdmin, getUserPermissionFromCache } from '../../utility/permissionFunctions';

// hooks
import { useAuth } from "../../contexts/AuthContext";
import usePermission from '../../hooks/permissions';

const ShopTimesheetList = () => {
  const { email } = useAuth().currentUser;
  const { permission } = usePermission(email);

  const [timeSheets, setTimeSheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteResultModal, setShowDeleteResultModal] = useState(false);
  const [timeSheetToDelete, setTimesheetToDelete] = useState({});
  const [deleteResult, setDeleteResult] = useState('');
  const [showBulkdDownloadModal, setShowBulkDownloadModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [performEdit, setPerformEdit] = useState(false);

  const [filter, setFilter] = useState('');

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(async () => {
    setUserType(await getUserPermissionFromCache(email));

    const emails = await getAllUserEmails(true);
    setEmailList(emails);
  }, []);

  useEffect(() => {
    if(!selectedTimesheet) {
      setPerformEdit(false);
    }
  }, [selectedTimesheet]);

  const getAllTimesheets = async () => {
    setLoading(true);
    const timesheets = await getAllShopTimesheet(email);
    setTimeSheets(timesheets);
    setLoading(false);
  }

  const filteredTimeSheets = () => {
    if (!filter) return timeSheets;
    return timeSheets.filter((ts) => {
      const lowercasedFilter = filter.toLowerCase();
      return (
        ts.shopTimesheetName.toLowerCase().includes(lowercasedFilter) ||
        ts.date.split("T")[0].includes(lowercasedFilter) ||
        ts.email.toLowerCase().includes(lowercasedFilter)
      );
    });
  };

  const deleteTimesheetShow = (ts) => {
    setShowDeleteModal(true);
    setTimesheetToDelete(ts);
  };

  const deleteTimeSheet = async () => {
    const res = await deleteShopTimesheet(timeSheetToDelete._id, timeSheetToDelete.email);

    if (res.status == 200) {
      setDeleteResult('Succesfully deleted timesheet');
    } else {
      setDeleteResult('Could not delete timesheet. Please try again.')
    }

    setShowDeleteModal(false);
    setShowDeleteResultModal(true);
    getAllTimesheets();
  }

  useEffect(() => {
    document.title = 'Shop Timesheets'
    getAllTimesheets();
  }, []);

  const bulkDownloadTimesheet = async (e) => {
    e.preventDefault();
    if(selectedEmail === 'All Users') {
      await bulkAggregatedDownload(startDateRef.current.value, endDateRef.current.value);
    } else {
      await bulkDownload(selectedEmail, startDateRef.current.value, endDateRef.current.value);
    }
  };

  if (selectedTimesheet) {
    return <ShopTimesheetForm isViewMode={true} editMode={performEdit} timesheet={selectedTimesheet} cancelView={() => { getAllTimesheets(); setSelectedTimesheet(null) }} onFinish={() => { getAllTimesheets() }} />
  }

  return (
    <div>
      <Row>
        <Col md={10} style={{textAlign: 'left'}}>
          <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Shop Timesheets
            {(isPermissionTypeHRAdmin(permission)) && " - HR/Admin View"}
          </h5>
        </Col>
        <Col md={2} style={{ textAlign: 'right' }}>
          {isPermissionTypeHRAdmin(userType) && 
          <Button style={{width: '100%'}} variant="outline-dark" onClick={() => setShowBulkDownloadModal(true)}>
            Bulk Timesheet Download
          </Button>}
        </Col>
      </Row>
      <Row className="mb-3 mt-3">
        <Col md={10}>
          <Form.Control
            type="text"
            placeholder="Filter by timesheet name, date, or creator"
            value={filter}
            onChange={handleFilterChange}
          />
        </Col>
        <Col md={2} style={{ textAlign: 'right' }}>
          <NewShopTimesheetModal onFinish={() => { getAllTimesheets() }} />
        </Col>
      </Row>
      <DeleteShopTimesheetModal
        showDeleteModal={showDeleteModal}
        timeSheetToDelete={timeSheetToDelete}
        deleteTimeSheet={deleteTimeSheet}
        setTimesheetToDelete={setTimesheetToDelete}
        setShowDeleteModal={setShowDeleteModal}
      />
      <DeleteResultModal
        showDeleteResultModal={showDeleteResultModal}
        deleteResult={deleteResult}
        setShowDeleteResultModal={setShowDeleteResultModal}
        setDeleteResult={setDeleteResult}
      />
      
      <BulkShopTimesheetDownloadModal
        showBulkdDownloadModal={showBulkdDownloadModal}
        setShowBulkDownloadModal={setShowBulkDownloadModal}
        bulkDownloadTimesheet={bulkDownloadTimesheet}
        startDateRef={startDateRef}
        endDateRef={endDateRef}
        emailList={emailList}
        setSelectedEmail={setSelectedEmail}
      />

      {loading && <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" role="status" style={{ width: '100px', height: '100px', marginTop: '10%' }} />
      </div>}
      {!loading &&
        <div style={{ maxHeight: '85vh', overflowY: 'auto', marginTop: '1%' }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>🧾 Timesheet Name</th>
                <th>Date</th>
                <th>Created By</th>
                <th>Approval</th>
                <th>Approved By</th>
                <th>Hours</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {filteredTimeSheets().map((ts) => (
                <tr>
                  <td>{ts.shopTimesheetName}</td>
                  <td>{ts.date.split('T')[0]}</td>
                  <td>{ts.email}</td>
                  <td>
                    <Alert variant={ts.approved ? 'success' : 'warning'} style={{ margin: '0', padding: '2%' }}>
                      <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{ts.approved ? 'Approved' : 'Pending'}</p>
                    </Alert>
                  </td>
                  <td>{ts.approvedBy || 'N/A'}</td>
                  <td>{ts.totalHours}</td>
                  <td><FaEye size={22} onClick={() => {
                    setSelectedTimesheet(ts);
                  }}/></td>
                  <td><FaEdit size={22} onClick={() => {
                    if(ts.email === email){
                    setPerformEdit(true);
                    setSelectedTimesheet(ts);
                    } else {
                      alert('You cannot edit this timesheet as it was created by another user.')
                    }
                  }}/></td>
                  <td><FaTrashAlt size={22} onClick={() => {deleteTimesheetShow(ts)}}/></td>
                  <td><FaFileDownload size={22} onClick={async () => {
                    await singleDownload(ts._id);
                  }}/></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      }
    </div>
  );
};

export default ShopTimesheetList;
