/**
 * api/admin.js
 * ------------------
 * Module encapsulating functions related to the adminToolAccount collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";
import { auth } from "../firebase.js"
import { shouldReturnCachedData } from "./cache";
import { getUserPermission } from "../utility/permissionFunctions";

const DUPLICATE_EMAIL_ERROR = -2;
const ERROR = -1;

let userPermission = null;
let lastPolled = null;

const signUp = async (email, signupKey, password, firstName, lastName) => {
  return http.post(`adminToolsAccounts/insert/?email=${email}&signUpKey=${signupKey}&firstName=${firstName}&lastName=${lastName}`)
    .then(async (res) => {
      if (res.status === 200) {
        const userCredential = await auth.createUserWithEmailAndPassword(email, password);
        auth.signOut();
        return 1;
      } else if (res.status === 400) {
        // duplicate email
        return DUPLICATE_EMAIL_ERROR;
      } else {
        return ERROR;
      }

    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const getAllAdmins = () => {
  return http.get('adminToolsAccounts/allAdmins/')
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
}

const getUser = (email) => {
  if (shouldReturnCachedData(userPermission, lastPolled)) {
    return userPermission;
  } else {
    return http.get(`adminToolsAccounts/getUser/?email=${email}`)
      .then((res) => {
        const parseUserPermission = getUserPermission(res.data)
        userPermission = parseUserPermission;
        lastPolled = Date.now();
        return parseUserPermission
      }).catch((error) => {
        console.log(error);
        return error;
      })
  }
}

const getAllUserEmail = (email) => {
  return http.get(`adminToolsAccounts/getAllUserEmail/?email=${email}`)
    .then((res) => {
      return res.data;
    }).catch((error) => {
      console.log(error);
      return error;
    })
}


const editUserPermission = async (body) => {
  return http.put(`adminToolsAccounts/editPermission/`, body).then((res) => {
    return res;
  })
    .catch((error) => {
      return error;
    });

}

const clearAdminCache = () => {
  userPermission = null;
  lastPolled = null;
}

const getUserData = (email) => {
  return http.get(`adminToolsAccounts/getUser/?email=${email}`)
  .then((res) => {
    return res.data;
  }).catch((error) => {
    console.log(error);
    return error;
  })
};

export {
  signUp,
  getAllAdmins,
  getUser,
  getAllUserEmail,
  editUserPermission,
  clearAdminCache,
  getUserData
};