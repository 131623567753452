import ShopTimesheetList from "./ShopTimesheetList";

import { isPermissionTypeHRAdmin } from "../../utility/permissionFunctions";
import usePermission from "../../hooks/permissions";
import { useAuth } from "../../contexts/AuthContext";

const ShopTimesheetPage = ({}) => {
  const {email} = useAuth().currentUser;
  const { permission } = usePermission(email);

  return (
    <div style={{ margin: '1% 1% 1% 1%', zoom: '88%' }}>
      <ShopTimesheetList />
    </div>
  );
};

ShopTimesheetPage.propTypes = {

};

export default ShopTimesheetPage;