import React from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

// Tabs
import AssessmentReportingPage from "./AssesmentReportingPage";
import ClientAssesmentReport from "./ClientAssesmentReport";
import SavedReports from "./SavedReports";
import { EngineeringAssessmentReport } from "./EngineeringAssessmentReport";
import { TbReport, TbFileSearch } from 'react-icons/tb';
import NewAssessmentForm from "./NewAssessmentForm";

import "./AssessmentNav.css"

const NavListItem = ({ to, children }) => {
  const location = useLocation();

  return (
    <ListGroup.Item
      action
      href={to}
      to={to}
      as={Link}
      style={{
        backgroundColor: 'transparent',
        color: location.pathname === to ? '#005E7D' : 'grey',
        border: 'none',
        width: '100%',
        fontSize: '18px',
        marginTop: '10px',
      }}
    >
      {children}
    </ListGroup.Item>
  );
}

const AssessmentNav = () => {
  return (
    <div style={{ zoom: '90%' }}>
      <Router>
            <Switch>
              <Route
                path="/assessment-reporting/single-assessment"
                component={AssessmentReportingPage}
              />
              <Route
                path="/assessment-reporting/new-assessment"
                component={NewAssessmentForm}
              />
              <Route
                path="/eng-assessment-horizontal/:sysType/:removal/:name/:days/:hours/:flow/:ts/:ss/:ammNit/:tkNit/:pot/:phos/:vs/:sp/:roRecovery/:spRemoved/:units/:manure/:isProject/:saved"
                component={EngineeringAssessmentReport}
              />
              <Route
                path="/client-assessment-report/:sysType/:removal/:name/:days/:hours/:flow/:ts/:ss/:ammNit/:tkNit/:pot/:phos/:vs/:calc/:mag/:sod/:iron/:manganese/:copper/:zinc/:sulfur/:roRecovery/:units/:manure/:isProject/:saved"
                component={ClientAssesmentReport}
              />
              <Route
                path="/assessment-reporting/saved-reports"
                component={SavedReports}
              />
              <Redirect to="/assessment-reporting/saved-reports" />
            </Switch>
      </Router>
    </div>
  );
};

export default AssessmentNav;
