/**
 * api/systemSites.js
 * ------------------
 * Module encapsulating functions related to the systems collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 10 2023
 */

import http from "./http";
import { shouldReturnCachedData } from "./cache";

let systemSites    = null;
let lastPolled     = null;

const getAllSystemSites = (force = false) => {
   // Return cached system sites
  if(!force && shouldReturnCachedData(systemSites, lastPolled)) {
    return systemSites;
  }

  return http.get('systemSites/all')
    .then((res) => {
      systemSites = res.data;
      lastPolled = Date.now();
      return systemSites;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const deleteSystemSite = (systemName) => {
  return http.delete(`systemSites/delete/?systemName=${systemName}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
}

const updateSystemSite = (systemName, systemType, manureType, linkingId, jobNumber) => {
  return http.put(`systemSites/update/?systemName=${encodeURIComponent(systemName)}&systemType=${encodeURIComponent(systemType)}&manureType=${encodeURIComponent(manureType)}&linkingId=${encodeURIComponent(linkingId)}&jobNumber=${encodeURIComponent(jobNumber)}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const createSystemSite = (farmId, systemName, sysType, jobNumber, linkingId, manureType) => {
  let params = new URLSearchParams();
  
  if (farmId) params.append('farmId', farmId);
  if (systemName) params.append('systemName', systemName);
  if (sysType) params.append('systemType', sysType);
  if (jobNumber) params.append('jobNumber', jobNumber);
  if (linkingId) params.append('linkingID', linkingId);
  if (manureType) params.append('manureType', manureType);

  return http.post(`systemSites/insert?${params.toString()}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

export {
  getAllSystemSites,
  deleteSystemSite,
  updateSystemSite,
  createSystemSite
};
