/**
 * loisInsertModal.jsx
 * -------------------
 * Component that contains insert functionality for inserting loisControl records into
 * the database.
 *
 * Author: Oscar Jaimes
 * Date: 28/07/2021
 */

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

// api
import { createLoisControl } from "../../../api/loiscontrol";
import { getAllSystemSites } from "../../../api/systemSites";

const LoisInsertModal = (props) => {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [linkingIdElements, setLinkingIdElements] = useState([]);
  const [selectedLinkingId, setSelectedLinkingId] = useState("");
  const [loisActivated, setLoisActivated] = useState(true);

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  useEffect(() => {
    fetchAndLoadLinkingIdOptions();
  }, []);

  const fetchAndLoadLinkingIdOptions = async () => {
    const systemSites = await getAllSystemSites();
    setLinkingIdElements(
      systemSites.map((entry) => {
        return <option value={entry.linking_id}>{entry.linking_id}</option>;
      }));
      setSelectedLinkingId(systemSites[0].linking_id);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let linkingId = selectedLinkingId;
    let loisAc = loisActivated;

    const createResult = await createLoisControl(linkingId, loisAc);
    if (createResult.status != 200) {
      setSuccessMsg("");
      setErrMsg("Error inserting new LOIS Control record to database.");
    } else {
      setErrMsg("");
      setSuccessMsg(
        "Succesfully inserted new LOIS Control record into the database"
      );
    }

 
  };

  return (
    <>
      <Button  style={{backgroundColor: '#005E7D'}} onClick={handleShow}>
        Add New Record
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{ zoom: '85%' }}>
        <Modal.Header>
          <Modal.Title>Add New LOIS Control Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Fill out all fields below to insert a new LOIS Control record into
            the database.
          </div>
          <br />
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label>System Linking Id</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setSelectedLinkingId(e.target.value);
                  }}
                  required
                >
                  {linkingIdElements}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>LOIS Activated</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setLoisActivated(e.target.value);
                  }}
                  required
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Form.Control>
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoisInsertModal;
