import React from "react";
import { useState } from "react";
import { subConstractorsListExample } from "../../NewReportExample";
import { FieldTimeReportingForm } from "../FieldTimeReportingFormCreator";
import { FieldTimeSheetTable } from "../FieldTimeSheetTableCreator";

// Creates inputs, form groups and styles for the subcontractors part in time report

export const FieldTimeSheetSubContractors = ({
  formProps: { setNewReport, pageNumber, setPageNumber, newReport },
  editMode
}) => {
  const [subContractorsList, setSubConstractorList] = useState(
    newReport.subContractorsList
  );

  return (
    <div>
      <h4 className="text-center py-3 border-bottom">Subcontractors, Materials, and Expenses</h4>
      <FieldTimeReportingForm
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        editMode={editMode}
      >
        <div
          className="text-center border rounded p-2"
          style={{ background: "rgba(0, 0, 0, 0.03)" }}
        >
          Please submit expense reports for any subcontractors, materials, and expenses and link them to this timesheet. You can do this on the <a style={{fontWeight: 'bold'}}>Expense Report</a> tab at the top of this page.
          <br />
          Once submitted, you will be able to see your expenses on the timesheet.
        </div>
      </FieldTimeReportingForm>
    </div>
  );
};
