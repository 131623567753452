import React, { useState, useEffect } from 'react';
import { Modal, Button} from 'react-bootstrap';

export default function LoadingModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.setShow(false);
  }
  const handleShow = () => {
    setShow(true);
    props.setShow(true);
  }
  const handleClick = () => {
    handleShow();
    props.onClick();
  }

  useEffect(() => {
    if (props.show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [props.show]);

  return (
    <div>
      {props.useTooltip
        ?
        <props.icon onClick={handleClick} size={24} />
        : <Button variant={props.variant != null ? props.variant : 'outline-dark'} onClick={handleClick}>{props.title != null ? props.title : 'Download Report'}</Button>

      }
      <Modal show={props.show} onHide={handleClose} keyboard={false} size="lg" style={{ zoom: '90%' }}>
        <Modal.Header>
          <Modal.Title>{props.title || 'Downloading PDF...'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content || 'Please wait while the report is converted to PDF'}</Modal.Body>
      </Modal>
    </div>
  );
}
