// api
import { getUser } from "../api/admin";
import 'firebase/auth';

export const getUserPermissionFromCache = async (email) => {
    let userPermission
    try {
        userPermission = await getUser(email)
    } catch (e) {
        userPermission = []
    }
    return userPermission
}

export const getUserPermission = (user) => {
    return user.permissions.length > 0 ? user.permissions : [user.userType]
}

const isPermissionType = (userPermission, permissionWanted) => {
    return userPermission.includes(permissionWanted)
}

export const isPermissionTypeAdmin = (userPermission) => {
    return isPermissionType(userPermission, "admin")
}

export const isPermissionTypeFieldAdmin = (userPermission) => {
    return isPermissionType(userPermission, "fieldAdmin")
}

export const isPermissionTypeHRAdmin = (userPermission) => {
    return isPermissionType(userPermission, "hrAdmin")
}

export const isPermissionTypePemissionAdmin = (userPermission) => {
    return isPermissionType(userPermission, "permissionsAdmin")
}

export const isPermissionTypeEmployee = (userPermission) => {
    return isPermissionType(userPermission, "employee")
}

export const isPermissionTypeFieldEmployee = (userPermission) => {
    return isPermissionType(userPermission, "fieldEmployee") || isPermissionType(userPermission, "field-employee");
}

export const isPermissionTypeShopEmployee = (userPermission) => {
    return isPermissionType(userPermission, "shopEmployee")
}

export const isPermissionTypeDistributor = (userPermission) => {
    return isPermissionType(userPermission, "distributor")
}

export const isPermissionWOAdmin = (userPermission) => {
    return isPermissionType(userPermission, "woAdmin");
}