import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { createNewShopTimesheet } from "../../../../api/hrShopTimeSheet";
import { useAuth } from "../../../../contexts/AuthContext";

import ResultModal from "../../../../components/ResultModal";

// Creates the Form for HR ShopTimesheet
export const HRShopTimesheetFormCreator = (props) => {
  let { pageNumber, setPageNumber, disabled, finalPage, shopTimesheet } = props;
  const { email } = useAuth().currentUser;

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorString, setErrorString] = useState("");
  const [titleColor, setTitleColor] = useState("orange");

  const handleErrorModalClose = () => setShowErrorModal(false);

  async function nextPage() {
    setPageNumber(pageNumber + 1);
  }

  async function prevPage() {
    setPageNumber(pageNumber - 1);
  }

  function disableCancelButton() {
    if (pageNumber == 0) return { disabled: true };
  }

  function disableNextButton() {
    if (disabled == true) {
      return { disabled: true };
    }
  }

  function validateJobHours() {
    const jobs = shopTimesheet.jobList;
    let totalJobHours = 0;

    jobs.forEach((j) => {
      const jobCodes = j.jobCodeList;
      jobCodes.forEach((jc) => {
        totalJobHours += jc.hoursWorked;
      })
    });

    if(totalJobHours <= 0 || shopTimesheet.totalHours <= 0) {
      setErrorTitle('Validation Error')
      setErrorString(`Individual Job Hours and Total Day Hours Must Be Greater Than 0 Hours.`);
      setShowErrorModal(true);
      setTitleColor("orange");
      return false;
    }


    if (totalJobHours !== shopTimesheet.totalHours) {
      setErrorTitle('Validation Error')
      setErrorString(`Individual job hours must add up to total day hours. Total must add up to ${shopTimesheet.totalHours} Hours`);
      setShowErrorModal(true);
      setTitleColor("orange");
      return false;
    }

    return true;
  }

  async function submitForm() {
    // Validate timesheet hours before submitting
    if (!validateJobHours()) { return; }

    const timesheetDate = new Date(shopTimesheet.date);
    timesheetDate.setDate(timesheetDate.getDate() + 1);
    const bodyVal = {
      shopTimesheet: { ...shopTimesheet, date: timesheetDate },
      email,
    };
    createNewShopTimesheet(bodyVal).then((res) =>{
      const message = res.status == 200 ? "Timesheet has been successfully created.": "Field Timesheet could not be created, please try again later.";
      const title = res.status == 200 ? "Success" : "Error";
      const color = res.status == 200 ? "green" : "orange";
      setErrorTitle(title)
      setErrorString(message);
      setTitleColor(color);
      setShowErrorModal(true);
  });
  }

  function submitButtonClicked(e) {
    e.preventDefault();
    pageNumber < finalPage ? nextPage() : submitForm();
  }

  return (
    <>
    <ResultModal title={errorTitle} titleColor={titleColor}content={errorString} showErrorModal={showErrorModal} handleClose={handleErrorModalClose}/>
      <Form onSubmit={(e) => submitButtonClicked(e)}>
        {props.children}
        <div className="d-flex justify-content-around pt-2 ">
          <Button className="me-3" {...disableCancelButton()} onClick={prevPage}>
            Back
          </Button>
          {pageNumber < finalPage ? (
            <Button {...disableNextButton()} type="submit">
              Next
            </Button>
          ) : (
            <Button type="submit">Submit</Button>
          )}
        </div>
      </Form>
    </>
  );
};
