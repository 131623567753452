import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { getAllUser } from "../../../fieldToolsFunctions";
import { ExistingReportFilterByPerson } from "./ExistingReportFilterByPerson";

export const ExistingFieldReportFilter = ({
  originalReportList,
  filteredReportList,
  setFilteredReportList,
  setUpdate,
  userType,
}) => {
  let [filterValue, setFilterValue] = useState("Report Name");

  const buttonVariantExample = Array(2).fill({ variant: "outline-dark" });
  const [buttonsVariant, setButtonsVariant] = useState(buttonVariantExample);
  const [currentReportSort, setCurrentReportSort] = useState("none");
  useEffect(() => {
    setFilteredReportList(originalReportList);
  }, [filterValue]);

  useEffect(() => {
    let _filteredReportList = JSON.parse(JSON.stringify(filteredReportList));
    switch (currentReportSort) {
      case "ascending":
        _filteredReportList.sort(function (a, b) {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        setFilteredReportList(_filteredReportList);
        setUpdate((update) => !update);
        break;
      case "desending":
        _filteredReportList.sort(function (a, b) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        setFilteredReportList(_filteredReportList);
        setUpdate((update) => !update);
        break;
      default:
        setFilteredReportList(originalReportList);
        setUpdate((update) => !update);
        break;
    }
  }, [currentReportSort]);

  const allValues = userType.includes("fieldAdmin")
    ? [
      "Employee Name",
      "Job #",
      "Client Name",
      "Last Updated",
      "Report Date",
      "Report Name",
    ]
    : ["Job #", "Client Name", "Last Updated", "Report Date", "Report Name"];

  const filterReportDescriptionValue = (value, valueType) => {
    filteredReportList = originalReportList.filter((val) =>
      val.reportDescription[valueType].includes(value)
    );
    setFilteredReportList(filteredReportList);
  };

  const filterReportList = (value) => {
    switch (filterValue) {
      case "Employee Name":
        filterReportDescriptionValue(value, "name");
        break;
      case "Client Name":
        filterReportDescriptionValue(value, "client");
        break;
      case "Job #":
        filterReportDescriptionValue(value, "jobNo");
        break;
      case "Report Date":
        filterReportDescriptionValue(value, "date");
        break;
      case "Last Updated":
        filteredReportList = originalReportList.filter((val) =>
          val.updatedAt.includes(value)
        );
        setFilteredReportList(filteredReportList);
        break;
      case "Report Name":
        filteredReportList = originalReportList.filter((val) =>
          val.reportName.includes(value)
        );
        setFilteredReportList(filteredReportList);
        break;
      default: {
        return "";
      }
    }
  };

  const getFilterValueType = (value) => {
    switch (value) {
      case "Job #":
        return "number";
      case "Last Updated":
        return "date";
      case "Report Date":
        return "date";
      default: {
        return "text";
      }
    }
  };

  const createDropdownList = (value) => {
    return (
      <Dropdown.Item
        id={`${value}`}
        onClick={(e) => setFilterValue(e.target.id)}
      >
        {value}
      </Dropdown.Item>
    );
  };

  const setDropDownUserList = (email) => {
    let _filteredReportList = [...originalReportList];
    if (email != "all") {
      _filteredReportList = _filteredReportList.filter(
        (report) => report.email == email
      );
    }

    setFilteredReportList(_filteredReportList);
  };

  const buttonClicked = (id) => {
    let _buttonsVariant = buttonVariantExample;
    _buttonsVariant[id] = { variant: "dark" };
    setButtonsVariant(_buttonsVariant);
    if (id == 0) setCurrentReportSort("ascending");
    else setCurrentReportSort("desending");
  };

  return (
      <Row>
        <Col md={5}>
          <Form>
            <Form.Group>
              <InputGroup>
                <Form.Control
                  id="form-control"
                  aria-label="Text input with dropdown button"
                  type={`${getFilterValueType(filterValue)}`}
                  placeholder={`Enter ${filterValue} here...`}
                  onChange={(e) => {
                    e.preventDefault();
                    filterReportList(e.target.value);
                  }}
                />
                <DropdownButton
                  variant="outline-secondary"
                  title={`${filterValue}`}
                  align="end"
                >
                  {allValues.map((value) => createDropdownList(value))}
                </DropdownButton>
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>

        {(userType.includes("fieldAdmin") || userType.includes("hrAdmin")) && <Col md={5}>
          <div>
            <ExistingReportFilterByPerson
              allUsers={getAllUser(originalReportList)}
              onChange={setDropDownUserList}
            />
          </div>
        </Col>}

        <Col md={2}>
          <Button
            style={{width: '100%'}}
            onClick={() => {
              setFilteredReportList(originalReportList);
              document.getElementById("form-control").value = "";
              setButtonsVariant(buttonVariantExample);
              setCurrentReportSort("none");
            }}
            variant="secondary"
          >
            Reset Filters
          </Button>
        </Col>
      </Row>
  );
};
