/**
 * api/iot.js
 * ------------------
 * Module encapsulating functions related to running reports from the LWR API
 * 
 * Author: Oscar Jaimes
 * Date: February 12 2023
 */

import http from "./http";

const getDailyReport = (linkingId, date) => {
  return http.get(`dailyReport/${linkingId}/?date=${date}`)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      throw err;
    })
};

const getWeeklyReport = (linkingId, startDate, endDate, units) => {
  return http.get(`weeklyReport/${linkingId}/?startDate=${startDate}&endDate=${endDate}&units=${units}`)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      throw err;
    })
};

const saveAssessmentReport = (assessmentName, date, createdBy, isProject, assessmentData, type, carbonModel) => {
  return http.post(`assessments/save/?name=${assessmentName}&dateCreated=${date}&createdBy=${createdBy}&isProject=${isProject}&assessment=${JSON.stringify(assessmentData)}&type=${type}`, { carbonModel })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const findSimilarDocumentName = (name) => {
  return http.get(`assessments/findSimilarDocumentName/?name=${name}`)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAssessmentReport = (assessmentData) => {
  return http.post(`assesment-report/`, assessmentData)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAssessmentReportResponse = (assessmentData) => {
  return http.post(`assesment-report/`, assessmentData)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const deleteAssessmentReport = (id) => {
  return http.delete(`assessments/deleteById/?id=${id}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAllAssessmentReports = () => {
  return http.get('assessments/all/')
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};


const getAssessmentReportCount = () => {
  return http.get('assessments/count')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

export {
  getDailyReport,
  getWeeklyReport,
  saveAssessmentReport,
  findSimilarDocumentName,
  getAssessmentReport,
  deleteAssessmentReport,
  getAllAssessmentReports,
  getAssessmentReportResponse,
  getAssessmentReportCount
}

