import React from "react";
import "../../../../../styles/EngAssessment.css";

import { Table } from "react-bootstrap";

export default function TotalMassBalance(props) {
  return (
    <>
      <div className="col-md-3">
        <h5 style={{color: "#005E7D"}}>Total Mass Balance (kg/min)</h5>
        <Table striped style={{textAlign: 'left'}}>
          <tbody>
            <tr>
              <td className="header">Input Mass Flow</td>
              <td id="input-mass-flow-balance">
                {Number(props.data["Total Input Flow"]).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="header">SP Solids Mass Flow</td>
              <td id="sp-solids-mass-flow-p2">
                {Number(props.data["SP Solids Flow"]).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="header">Liquid Nutrient Mass Flow</td>
              <td id="liquid-nutrient-mass-flow-p2">
                {Number(props.data["Liq Nutrient Flow"]).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="header">Water Mass Flow</td>
              <td id="water-mass-flow-p2">{Number(props.data["Water Flow"]).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="header">Poylmer Mass Flow</td>
              <td id="polymer-mass-flow-p2">{Number(props.data["Polymer Flow"]).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="header">Total Outputs</td>
              <td id="total-outputs-balance">{Number(props.data["Total Outputs"]).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="header">Balance</td>
              <td id="total-balance-balance">{Number(props.data["Balance"]).toFixed(2)}</td>
            </tr>
            <tr>
              <td className="header">% Error</td>
              <td id="percentage-error-balance">{props.data["% Error"]}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
