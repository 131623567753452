import React from "react";
import { useState } from "react";
import { FieldTimeSheetWorkCarriedOut } from "./NewTimeReport/FieldTimeSheetWorkCarriedOut/FieldTimeSheetWorkCarriedOut";
import { FieldTimeSheetTime } from "./NewTimeReport/FieldTimeSheetTime/FieldTimeSheetTime";
import { FieldTimeSheetProjectRemarks } from "./NewTimeReport/FieldTimeSheetProjectRemarks";
import { useEffect } from "react";
import { FieldTimeSheetDescription } from "./NewTimeReport/FieldTimeSheetDescription/FieldTimeSheetDescription";
import { FieldTimeSheetSubContractors } from "./NewTimeReport/FieldTimeSheetSubContractors/FieldTimeSheetSubContractors";
import { FieldTimeSheetMaterials } from "./NewTimeReport/FieldTimeSheetMaterials/FieldTimeSheetMaterials";
import { FieldTasksYetToBeCompleted } from "./NewTimeReport/FieldTasksYetToBeCompleted/FieldTasksYetToBeCompleted";

// Lets us route between the different component of field time sheet

export const FieldTimeSheet = ({ setNewReport, newReport, editMode }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [update, setUpdate] = useState(false);

  // useEffect on update because sometimes when subdict value changes, react will not update its component
  // So, changing update will force it to update components
  useEffect(() => {}, [update]);

  function getCurrentComponent() {
    const formProps = {
      setNewReport,
      newReport,
      pageNumber,
      setPageNumber,
      setUpdate,
    };
    switch (pageNumber) {
      case 0:
        return <FieldTimeSheetDescription formProps={formProps} editMode={editMode}/>;
      case 1:
        return <FieldTimeSheetWorkCarriedOut formProps={formProps} editMode={editMode}/>;
      case 2:
        return <FieldTasksYetToBeCompleted formProps={formProps} editMode={editMode}/>;
      case 3:
        return <FieldTimeSheetSubContractors formProps={formProps} editMode={editMode}/>;
      case 4:
        return <FieldTimeSheetProjectRemarks formProps={formProps} editMode={editMode}/>;
      default:
        return <FieldTimeSheetDescription formProps={formProps} editMode={editMode}/>;
    }
  }

  return (
    <div>
      <div>{getCurrentComponent()}</div>
    </div>
  );
};
