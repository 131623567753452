import React from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { getProperTime } from "../../../field-tools/fieldToolsFunctions";
import { useAuth } from "../../../../contexts/AuthContext";
import { getTimesheetByID } from "../../../../api/hrTimeSheet";
import { downloadPDF } from "../../HRFunctions";

// Displays all existing HR Timesheet in a card format
export const ExistingHRReportCard = ({
  reportList,
  setReportList,
  learnMoreFunction,
  deleteFunction,
}) => {
  const { email } = useAuth().currentUser;

  const selectReport = (_id) => {
    let _reportList = [...reportList];
    _reportList = _reportList.map((report) =>
      report._id == _id ? { ...report, selected: !report.selected } : report
    );
    setReportList([..._reportList]);
  };

  const downloadReport = async () => {
    let _reportList = [...reportList];
    _reportList = _reportList.filter((report) => report.selected);
    const filesData = [];
    const promises = [];
    for (let report of _reportList) {
      promises.push(
        await getBlobData(
          report._id,
          report.fileType,
          report.timesheetName,
          filesData
        )
      );
    }

    Promise.all(promises).then((v) => {
      let link = document.createElement("a");

      filesData.map((fileData) => {
        if (fileData.fileType == "pdf") {
          let base64String = Buffer.from(fileData.data[0]).toString("base64");
          const blobData = "data:application/pdf;base64," + base64String;
          downloadPDF(blobData, fileData.timesheetName + ".pdf");
        } else {
          let buffer = Buffer.from(fileData.data[0]);
          let arraybuffer = Uint8Array.from(buffer).buffer;
          const blobData = new Blob([arraybuffer], { type: "octet/stream" });
          const blobUrl = window.URL.createObjectURL(blobData);
          link.href = blobUrl;
          link.download = `${fileData.timesheetName}.xlsx`;
          link.addEventListener("click", () => ({ view: window }));
          link.click();
        }
      });
    });
  };

  const getBlobData = async (_id, fileType, timesheetName, filesData) => {
    return new Promise((resolve) => {
      getTimesheetByID(_id, email).then((val) => {
        filesData.push({ fileType, timesheetName, data: val });
        resolve();
      });
    });
  };

  return (
    <Card className="mx-2">
      <ListGroup variant="flush">
        {reportList.map((report, index) => (
          <ListGroupItem key={report._id}>
            <Row>
              <Col md={7} style={{ cursor: "pointer" }}>
                <Row>
                  <Col md={1}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={report.selected}
                      onClick={() => selectReport(report._id)}
                      id="flexCheckDefault"
                    />
                  </Col>
                  <Col md={11}>
                    <div className="d-flex align-items-center h-100 justify-content-between">
                      <div>
                        {index + 1}. {report.timesheetName}
                      </div>
                      <div>{getProperTime(report.createdAt)}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={1} />
              <Col md={2}>
                <Button
                  onClick={() => learnMoreFunction(report)}
                  style={{ cursor: "pointer" }}
                  variant="dark"
                >
                  Learn More
                </Button>
              </Col>
              {email == report.email ? (
                <Col md={1}>
                  <Button
                    onClick={() => deleteFunction(report)}
                    style={{ cursor: "pointer" }}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </Col>
              ) : null}
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
      <div className="d-flex justify-content-center py-2">
        <Button onClick={downloadReport}>Download</Button>
      </div>
    </Card>
  );
};
