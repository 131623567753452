import React from "react";
import { useState } from "react";
import { taskToBeCompletedExample } from "../../NewReportExample";
import { FieldTimeReportingForm } from "../FieldTimeReportingFormCreator";
import { FieldTimeSheetTable } from "../FieldTimeSheetTableCreator";

// Creates field groups and styles for the tasks to be completed section

export const FieldTasksYetToBeCompleted = ({
  formProps: { setNewReport, pageNumber, setPageNumber, newReport },
  editMode
}) => {
  const [taskToBeCompleted, setTaskToBeCompleted] = useState(
    newReport.taskToBeCompleted
  );

  return (
    <div>
      <h4 className="text-center py-3 border-bottom">
        Tasks Yet to be Completed on Site & Reccomendations
      </h4>
      <FieldTimeReportingForm
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        editMode={editMode}
      >
        <FieldTimeSheetTable
          valueDict={taskToBeCompleted}
          setValueDict={setTaskToBeCompleted}
          valueDictExample={taskToBeCompletedExample}
          textFieldLength={10}
          newReport={newReport}
          setNewReport={setNewReport}
          valueType="taskToBeCompleted"
        />
      </FieldTimeReportingForm>
    </div>
  );
};
