import { parseDate } from "../HRFunctions";

export const exampleJobCode = {
    jobCode: "",
    hoursWorked: 0,
    employeeInitial: "",
};

export const examplejobNumber = {
    jobNumber: 0,
    jobCodeList: [],
}

export const exampleHRShopTimesheet = {
    shopTimesheetName: "",
    date: parseDate(new Date()),
    empNumber: 0,
    totalHours: 0,
    jobList: [],
};

export const exampleJobCodeDict = {
    'Control Panel': { description: "Control Panel Work", jobName: '' },
    'Floc Tank': { description: "Floc Tank Work", jobName: '' },
    'groControl': { description: "groControl Work", jobName: '' },
    'RO System': { description: "RO System Work", jobName: '' },
    'Microscreen': { description: "Microscreen Work", jobName: '' },
    'Polymer System': { description: "Polymer System Work", jobName: '' },
    'Shipping/Recieving': { description: "Shipping/Recieving Work", jobName: '' },
    'Overhead': { description: "Overhead (meetings, cleaning, organizing, non-shop tasks)", jobName: '' },
    'Other': { description: "R&D, Warranty, IWR Jobs, Misc.", jobName: '' },
    'Vacation Pay': { description: "Vacation Pay", jobName: '' },
    'Sick Pay': { description: "Sick Pay", jobName: '' },
    'Statutory Holiday': { description: "Stat Holidays", jobName: '' },
    'Unpaid Time Off': { description: "Unpaid Time of", jobName: '' },
}
