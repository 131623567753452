import React from "react";
import { useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import { newReportExample } from "../../NewReportExample";
import { useEffect } from "react";
import "./CanvasStyles.css";
import { ExistingFieldReportInfoComponent } from "./ExistingFieldReportInfoComponent";
import { getReport } from "../../../../../api/fieldTimeReport";

export const ExistingFieldReportInfo = ({
  report,
  isReportList,
  setIsReportList,
  onFinish
}) => {
  const { currentUser } = useAuth();
  const { email } = currentUser;
  const [cancelChanges, setCancelChanges] = useState(false);

  const [timeSheet, setTimeSheet] = useState({
    ...newReportExample,
  });
  useEffect(async () => {
    const response = await getReport(report._id, email);
    setTimeSheet(response);
  }, [cancelChanges]);
  return (
      <ExistingFieldReportInfoComponent
        report={report}
        isReportList={isReportList}
        setIsReportList={setIsReportList}
        timeSheet={timeSheet}
        setTimeSheet={setTimeSheet}
        cancelChanges={cancelChanges}
        setCancelChanges={setCancelChanges}
        onFinish={onFinish}
      />
  );
};
