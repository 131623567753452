import React from "react";
import { Card, Col } from "react-bootstrap";
import { DisplayExistingReportValue } from "./DisplayExistingReportValue";

export const ExistingReportInfoTableCreator = ({
  title,
  setTimeSheet,
  subDictName,
  timeSheet,
  cancelChanges,
}) => {
  let reportSubDict = timeSheet[subDictName];
  return (
    <Card>
      <Card.Body className="w-100">
        <Card.Title className="text-center border-bottom p-3">
          {title}
        </Card.Title>
        <DisplayExistingReportValue
          reportSubDict={reportSubDict}
          setTimeSheet={setTimeSheet}
          subDictName={subDictName}
          timeSheet={timeSheet}
          cancelChanges={cancelChanges}
        />
      </Card.Body>
    </Card>
  );
};
