/**
 * Header.jsx
 * -----------
 * Component to render the header in client and engineering assessment reports.
 * 
 * Author: Oscar Jaimes
 * Last Updated: 25/11/2021
 */

import React from 'react';
import Logo from '../../../img/logo.jpeg';

export default function Header(props) {

    let units = '';
    if(props.units == 'Metric' || props.units == 'metric') {
        units = props.language?.metric_units || 'Metric Units';
    } else {
        units = props.language?.imperial_units || 'Imperial Units';
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <img src={Logo} alt="logo" width="200px" />
                </div>
                <div className="col-md-4" style={{ textAlign: 'center' }}>
                    <h5 style={{ fontSize: 'large', color: '#005E7D' }}> {props.title || props.language?.treatment_assessment_report || 'Treatment Assessment Report'}</h5>
                    <h5 style={{ fontSize: 'large', color: '#005E7D' }}>{props.clientName} {!props.hideUnits && `- ${units}`} </h5>
                    <h5 style={{ fontSize: 'large', color: '#005E7D' }}>{props.date}</h5>
                </div>
                <div className="col-md-4">

                </div>
            </div>
        </>
    )
}
