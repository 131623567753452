import http from "./http";

const { REACT_APP_LWR_API_BASE_URL, REACT_APP_LWR_API_KEY } = process.env
const axios = require('axios').default;

export const getExpenseImages = async (expenseName) => {
  return http.get(`field-expenses/images/?expenseName=${expenseName}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getAllExpense = async (email) => {
  return http.get(`field-expenses/all/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getExpenseById = async (id, email) => {
  return http.get(`field-expenses/${id}?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const createNewExpense = async (formData) => {
  return axios({
    url: `${REACT_APP_LWR_API_BASE_URL}field-expenses/upload/`,
    method: "post",
    data: formData,
    headers: {
      Accept: "application/json",
      'Authorization': `Basic ${REACT_APP_LWR_API_KEY}`
    }
  }).then((res) => {
    return res;
  }).catch((error) => {
    console.log(error);
    return error;
  });
}

export const uploadExpenseImage = async (formData) => {
  return axios({
    url: `${REACT_APP_LWR_API_BASE_URL}field-expenses/upload-image/`,
    method: "post",
    data: formData,
    headers: {
      Accept: "application/json",
      'Authorization': `Basic ${REACT_APP_LWR_API_KEY}`
    }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const updateExpense = async (body) => {
  return http.put(`field-expenses/update/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const deleteExpenseDocument = async (body) => {
  return http.delete(`field-expenses/delete-document/`, { data: { ...body } })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const deleteExpense = async (body) => {
  return http.delete(`field-expenses/delete-report/`, { data: { ...body } })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}