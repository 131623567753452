/**
 * http.js
 * -------
 * Axios http client wrapper connected to the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8th, 2023
 */
const axios = require('axios').default;

const { REACT_APP_LWR_API_BASE_URL, REACT_APP_LWR_API_KEY } = process.env;

const http = axios.create({
  baseURL: REACT_APP_LWR_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    'Authorization': `Basic ${REACT_APP_LWR_API_KEY}`
  }
});

export default http;