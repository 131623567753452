import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FormGroupCreator } from "../../TimeReporting/FormGroupCreator";
import { useAuth } from "../../../../contexts/AuthContext";
import { capitalizeFirstCharacter } from "../../fieldToolsFunctions";
import { createNewEODReport } from "../../../../api/fieldEODReport";
import { UploadNewFiles } from "../../FieldExpenses/UploadNewFiles";
import { FieldImagesDisplay } from "../../FieldExpenses/NewFieldExpenses/FieldImagesDisplay";

// api
import { getAllJobNumbers, getMetadataForJob } from "../../../../api/workOrders";

// This component creates the form that makes new EOD report
export const NewFieldEODReport = () => {
  const [jobNumbers, setJobNumbers] = useState([]);
  const [imgDatas, setImgDatas] = useState([]);
  const [selectedJobNumber, setSelectedJobNumber] = useState(null);

  useEffect(async () => {
    const jobNums = await getAllJobNumbers();
    setJobNumbers(jobNums.data);
  }, []);

  const onSelectJobNumber = async (jobNumber) => {
    setSelectedJobNumber(jobNumber);

    if (!jobNumber) {
      changeValue('N/A', 'client');
      return;
    }

    const jobMetadata = await getMetadataForJob(jobNumber);
    changeValue(jobMetadata.farmName, 'client');
  };

  const onChange = (fileList) => {
    setImgDatas(fileList);
  };

  const { email } = useAuth().currentUser;
  // font styles for quill
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        { list: "ordered" },
        { list: "bullet" },
      ],
      [{ color: [] }, { background: [] }],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });
  // get the name of the user
  const getName = email.split("@")[0].split(".");
  const name = getName.map((word) => capitalizeFirstCharacter(word));

  const [eodReport, setEODReport] = useState({
    reportName: "",
    jobNumber: 0,
    date: new Date().toISOString().split("T")[0],
    name: name.toString().replace(",", " "),
    client: "",
    email: email
  });

  let [eodDescription, setEODDescription] = useState("");
  // set the quill to the value inputted by the user
  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setEODDescription(quill.root.innerHTML);
      });
    }
  }, [quill]);

  // change valueType of eodReport to the value given in arg
  const changeValue = (value, valueType) => {
    const _eodReport = { ...eodReport };
    _eodReport[valueType] = value;
    setEODReport(_eodReport);
  };

  // submit report
  const submitEODReport = (e) => {
    e.preventDefault();
    // make the EOD date into an actual date value to be stored
    eodReport.date = new Date(eodReport.date);
    // post EOD report to database
    const formData = new FormData();
    imgDatas.forEach((imgData) => {
      formData.append("imgData", imgData);
    });

    formData.append('report', JSON.stringify({ ...eodReport, description: eodDescription, jobNumber: selectedJobNumber }));
    createNewEODReport(formData).then((res) =>
      res.status == 200
        ? alert("EOD Report has been created")
        : alert("EOD Report could not be created")
    );
  };

  return (
    <Form onSubmit={(e) => submitEODReport(e)}>
      {/* create form group for all values expect for description */}
      {Object.keys(eodReport).map((key) => {
        if (key === 'jobNumber') {
          return (<Form.Group className="my-1 ">
            <Row className="d-flex align-items-center justify-content-center">
              <Col md={2} className="text-center">
                <Form.Label>Job #</Form.Label>
              </Col>

              <Col md={10}>
                <Form.Control
                  as="select"
                  value={selectedJobNumber}
                  required
                  onChange={(e) => onSelectJobNumber(e.target.value)}
                >
                  <option value=''>Select a Job Number</option>
                  {jobNumbers.map((job) => (
                    <option key={job._id} value={job.jobNumber}>
                      {job.jobNumber}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>);
        }

        return (
          <FormGroupCreator
            label={`${capitalizeFirstCharacter(key)
              .replace(/([A-Z])/g, " $1")
              .trim()}`}
            type={key == "date" ? "date" : typeof eodReport[key]}
            changeValue={changeValue}
            valueDict={eodReport}
            valueType={`${key}`}
          />
        )
      })}
      {/* create the description text field */}
      <div style={{ height: "300px" }}>
        <div style={{ height: "200px" }}>
          <div ref={quillRef} />
        </div>
      </div>
      <div>
        <Row>
          <Col md={1}>
            <UploadNewFiles onChange={onChange} />
          </Col>

          <Col>
            <FieldImagesDisplay imgDatas={imgDatas} handleShow={() => { }} />
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit">Submit EOD Report</Button>
      </div>
    </Form>
  );
};
