import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { exampleJobCodeDict } from "../HRShopTimesheetConstants";
import { HRShopTimesheetJobCodes } from "./HRShopTimesheetJobCodes";

export const HRShopTimesheetJobCodesList = ({
  job,
  shopTimesheet,
  setShopTimesheet,
  setEmployeeInitial,
}) => {
  const [jobCodeDict, setJobCodeDict] = useState(exampleJobCodeDict);
  useEffect(() => {
    const newJobCodeDict = { ...exampleJobCodeDict };
    job.jobCodeList.map(
      (jobCodes) => delete newJobCodeDict[jobCodes.jobCode.split(" - ")[0]]
    );
    setJobCodeDict({ ...newJobCodeDict });
  }, [job.jobCodeList]);
  return (
    <ListGroup>
      {job.jobCodeList.map((jobCodeList) => (
        <HRShopTimesheetJobCodes
          jobCodeList={jobCodeList}
          shopTimesheet={shopTimesheet}
          setShopTimesheet={setShopTimesheet}
          job={job}
          jobCodeDict={jobCodeDict}
          setEmployeeInitial={setEmployeeInitial}
        />
      ))}
    </ListGroup>
  );
};
