import http from "./http";

/**
 * @typedef {Object} FieldTimesheet
 * @property {String} reportName
 * @property {String} email
 * @property {String} remarks
 * @property {String} remarks
 * @property {String} approvedBy
 * @property {Boolean} approved
 * @property {Array} workCarriedOut
 * @property {Array} taskToBeCompleted
 * @property {Array} workExpensesList
 * @property {Array} subContractorsList
 * @property {Array} fieldMaterialsList
 */

/**
 * @returns {Array<FieldTimesheet> | null}
 */
export const getAllReports = async (email) => {
  return http.get(`field-timesheets/all/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getReport = async (id, email) => {
  return http.get(`field-timesheets/${id}/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getReportData = async (email) => {
  return http.get(`field-timesheets/all-data/?email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const createNewReport = async (body) => {
  return http.post(`field-timesheets/upload/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const updateTimeReport = async (body) => {
  return http.put(`field-timesheets/update/`, body)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const deleteTimeReport = async (_id, email) => {
  return http.delete(`field-timesheets/delete/?_id=${_id}&email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const singleFieldTimePDF = async (_id) => {
  return http({
    method: 'post',
    url: '/field-timesheets/pdf',
    data: {_id},
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `fieldTimesheet.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const bulkFieldTimePDF = async (email, startDate, endDate) => {
  return http({
    method: 'post',
    url: '/field-timesheets/bulk-pdf',
    data: { email, startDate, endDate },
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `fieldTimesheet.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const approveTimesheet = async (_id, approvedBy) => {
  return http.put('/field-timesheets/approve', {
    _id,
    approvedBy
  })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};
