import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Form, Table } from "react-bootstrap";
import { getAllAdmins } from "../../../api/admin";
import { getAllPermissions } from "../../../api/adminToolSignupKeys";
import { getUserPermission } from "../../../utility/permissionFunctions";
import { convertKeyNameToDisplayName } from "../../field-tools/TimeReporting/TimeReportFunctions";
import { UserDeleteModal } from "./UserDeleteModal";
import { UserPermissionAddModal } from "./UserPermissionAddModal";
import { UserPermissionEditModal } from "./UserPermissionEditModal";

export const UserPermissionControlTab = ({ account, accountType }) => {
  const [users, setUsers] = useState([]);
  const [userPermissionTable, setUserPermissionTable] = useState([]);
  const [allPermission, setAllPermission] = useState([]);
  const searchRef = useRef();

  useEffect(async () => {
    document.title = "User Permissions - Admin Tool";
    getAllAdmins().then((result) => setUsers(result.data));
    const allPermissionArray = await getAllPermissions();
    setAllPermission(allPermissionArray.sort());
  }, []);

  useEffect(() => {
    setUserPermissionTable(createTableElements(users));
  }, [users, allPermission]);

  const onSearch = () => {
    let valid = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();
    users.forEach((user) => {
      if (user.email.toLowerCase().includes(currentSearchVal)) {
        valid.push(user);
      }
    });

    let newTableElements = createTableElements(valid);
    setUserPermissionTable(newTableElements);
  };

  const createTableElements = (usersList) => {
    return usersList.map((user) => {
      if (user.email != account.email) {
        const userPermissionArray = getUserPermission(user);
        const displayPermission = userPermissionArray
          .map((permission) => convertKeyNameToDisplayName(permission))
          .toString()
          .replaceAll(",", ", ");
        return (
          <tr>
            <td>{`${user.firstName || ''} ${user.lastName || ''}`}</td>
            <td>{user.email}</td>
            <td>{displayPermission}</td>
            <td>
              <UserPermissionEditModal
                user={user}
                allPermission={allPermission}
                users={users}
                setUsers={setUsers}
              />
            </td>
            <td>
              <UserDeleteModal
                email={account.email}
                emailToBeDeleted={user.email}
                users={users}
                setUsers={setUsers}
              />
            </td>
          </tr>
        );
      }
    });
  };

  return (
    <div style={{margin: '10px'}}>
      <br />
      <div className="d-flex justify-content-between">
        <div className="w-25">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onSearch}
          />
        </div>

        <UserPermissionAddModal allPermission={allPermission} />
      </div>
      <br />
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Permission</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{userPermissionTable}</tbody>
        </Table>
      </div>
    </div>
  );
};
