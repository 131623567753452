import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { convertKeyNameToDisplayName } from "../../../field-tools/TimeReporting/TimeReportFunctions";
import { exampleHRShopTimesheet } from "../HRShopTimesheetConstants";
import { HRShopTimesheetFormCreator } from "./HRShopTimesheetFormCreator";
import { NewHRShopTimesheetHeader } from "./NewHRShopTimesheetHeader";
import { NewHRShopTimesheetJobs } from "./NewHRShopTimesheetJobs";

// Creates New HR ShopTimesheet
export const NewHRShopTimesheet = () => {
  const { email } = useAuth().currentUser;
  const [shopTimesheet, setShopTimesheet] = useState(exampleHRShopTimesheet);
  const [pageNumber, setPageNumber] = useState(0);
  // final page is the last page of the form
  const finalPage = 1;

  useEffect(() => {
    const newHRShopTimesheet = { ...shopTimesheet };
    const getUserName = email
      .split("@")[0]
      .split(".")
      .map((name) => convertKeyNameToDisplayName(name))
      .toString()
      .replaceAll(",", " ");
    newHRShopTimesheet.shopTimesheetName =
      getUserName + " - " + shopTimesheet.date;
    setShopTimesheet({ ...newHRShopTimesheet });
  }, [shopTimesheet.date]);

  // renders the component for the correct page
  const getCorrectPage = () => {
    switch (pageNumber) {
      case 0:
        return (
          <NewHRShopTimesheetHeader
            shopTimesheet={shopTimesheet}
            setShopTimesheet={setShopTimesheet}
          />
        );
      case 1:
        return (
          <NewHRShopTimesheetJobs
            shopTimesheet={shopTimesheet}
            setShopTimesheet={setShopTimesheet}
          />
        );
      default:
        return (
          <NewHRShopTimesheetHeader
            shopTimesheet={shopTimesheet}
            setShopTimesheet={setShopTimesheet}
          />
        );
    }
  };

  return (
    <HRShopTimesheetFormCreator
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      disabled={false}
      finalPage={finalPage}
      shopTimesheet={shopTimesheet}
    >
      {getCorrectPage()}
    </HRShopTimesheetFormCreator>
  );
};
