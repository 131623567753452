import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FormGroupCreator } from "../../FormGroupCreator";
import { expensesListExample } from "../../NewReportExample";
import { useEffect, useState } from "react";
import { getBooleanIcons } from "../../../../field-tools/TimeReporting/TimeReportFunctions";
import { DeleteModal } from "../DeleteModal";
import { useAuth } from "../../../../../contexts/AuthContext";
import { FieldTimeReportingEditAmount } from "../../FieldTimeReportingEditAmount";
import {
  deleteExpenseDocument,
  getAllExpense,
} from "../../../../../api/fieldExpenseReport";
export const ExistingFieldReportExpensesList = ({
  timeSheet,
  setTimeSheet,
  cancelChanges,
}) => {
  const { email } = useAuth().currentUser;
  const [editValue, setEditValue] = useState(false);
  const [show, setShow] = useState(false);
  const [expenseToBeDeleted, setExpenseToBeDeleted] = useState({});
  const [expenseList, setExpenseList] = useState([]);
  const [createNewExpense, setCreateNewExpense] = useState(false);
  const [newExpenseValue, setNewExpenseValue] = useState(null);

  useEffect(() => {
    setCreateNewExpense(false);
    setEditValue(false);
  }, [cancelChanges]);

  useEffect(() => {
    getAllExpense(email).then((result) => {
      const parsedExpenseArray = [];
      for (let expense of result) {
        let duplicate = false;
        for (let workExpense of timeSheet.workExpensesList) {
          if (expense._id == workExpense._id) duplicate = true;
        }
        if (!duplicate) parsedExpenseArray.push(expense);
      }
      if (parsedExpenseArray.length > 0)
        setNewExpenseValue(parsedExpenseArray[0]._id);
      setExpenseList(parsedExpenseArray);
    });
  }, [timeSheet]);

  const changeValue = (value, valueType) => {
    if (typeof expensesListExample[valueType.type] == "number")
      value = Number(value);
    let _timeSheet = { ...timeSheet };
    for (let val of _timeSheet.workExpensesList) {
      if (val._id == valueType.id) {
        val[valueType.type] = value;
      }
    }
    setTimeSheet(_timeSheet);
  };

  const findIsChecked = (expense, key) => {
    return expense[key] ? { checked: true } : {};
  };

  const deleteFunction = (id) => {
    const bodyVal = {
      _id: id,
      timesheetId: timeSheet._id,
      email,
    };
    deleteExpenseDocument(bodyVal).then((res) => {
      if (res.status == 200) {
        const _timeSheet = { ...timeSheet };
        _timeSheet.workExpensesList = _timeSheet.workExpensesList.filter(
          (expense) => expense._id != id
        );
        setTimeSheet(_timeSheet);
        alert("Expense report has been successfully deleted from timesheet");
      } else {
        alert("Expense report could not be deleted from timesheet");
      }
    });
  };

  const removeExpense = (expense) => {
    setExpenseToBeDeleted(expense);
    setShow(true);
  };

  return (
    <Card>
      <Card.Body className="w-100">
        <Card.Title className=" w-100 text-center border-bottom p-3 ">
          Materials, Subcontractors, and Expenses
        </Card.Title>

        <Row  className="border-bottom pb-3">
          <Col>
          <div
          className="text-center border rounded p-2"
          style={{ background: "rgba(0, 0, 0, 0.03)" }}
        >
          Please submit expense reports for any subcontractors, materials, and expenses and link them to this timesheet. You can do this on the <a style={{fontWeight: 'bold'}}>Expense Report</a> tab at the top of this page.
        </div></Col>
        </Row>

        <Row style={{ width: "98%" }} className="border-bottom pb-2">
          <Col md={4}>Expense Name</Col>
          <Col md={1}>CC</Col>
          <Col md={1}>Visa</Col>
          <Col md={1}>Cash</Col>
          <Col md={2}>Quantity</Col>
          <Col md={3}>Amount</Col>
        </Row>
        {timeSheet.workExpensesList.length > 0 ? (
          timeSheet.workExpensesList.map((expense) =>
            Object.keys(expense).length > 1 ? (
              <div className="d-flex flex-row pt-2 border-bottom">
                <div style={{ width: "98%" }}>
                  {!editValue ? (
                    <Row>
                      <Col md={4}>{expense.expenseName}</Col>
                      <Col md={1}>
                        {getBooleanIcons(expense.paidUsingCompanyCard)}
                      </Col>
                      <Col md={1}>{getBooleanIcons(expense.visa)} </Col>
                      <Col md={1}>{getBooleanIcons(expense.cash)}</Col>
                      <Col md={2}>{expense.quantity}</Col>
                      <Col md={3}>
                        {`${expense.amount} ${expense.currencyType}`}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md={4}>
                        <FormGroupCreator
                          type="string"
                          valueDict={expense}
                          valueType={{ type: "expenseName", id: expense._id }}
                          changeValue={changeValue}
                        />
                      </Col>

                      <Col
                        md={1}
                        className="pt-2 ps-4 d-flex justify-content-center"
                      >
                        <Form.Check
                          {...findIsChecked(expense, "paidUsingCompanyCard")}
                          inline
                          onChange={() =>
                            changeValue(!expense.paidUsingCompanyCard, {
                              type: "paidUsingCompanyCard",
                              id: expense._id,
                            })
                          }
                        />
                      </Col>
                      <Col
                        md={1}
                        className="pt-2 ps-4 d-flex justify-content-center"
                      >
                        <Form.Check
                          {...findIsChecked(expense, "visa")}
                          inline
                          onChange={() =>
                            changeValue(!expense.visa, {
                              type: "visa",
                              id: expense._id,
                            })
                          }
                        />
                      </Col>
                      <Col
                        md={1}
                        className="pt-2 ps-4 d-flex justify-content-center"
                      >
                        <Form.Check
                          {...findIsChecked(expense, "cash")}
                          inline
                          onChange={() =>
                            changeValue(!expense.cash, {
                              type: "cash",
                              id: expense._id,
                            })
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <FormGroupCreator
                          type="number"
                          valueDict={expense}
                          valueType={{ type: "quantity", id: expense._id }}
                          changeValue={changeValue}
                        />
                      </Col>
                      <FieldTimeReportingEditAmount
                        changeValue={changeValue}
                        id={expense._id}
                        valueDict={expense}
                      />
                    </Row>
                  )}
                </div>
                <DeleteModal
                  show={show}
                  setShow={setShow}
                  titleName={expenseToBeDeleted?.expenseName || "n/a"}
                  deleteFunction={deleteFunction}
                  id={expenseToBeDeleted._id}
                />
              </div>
            ) : null
          )
        ) : (
          <Row className="border-bottom d-flex justify-content-center">
            <div className="py-2">n/a</div>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};
