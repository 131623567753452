/**
 * api/carbonModelling.js
 * ----------------------
 * Module encapsulating functions related to carbon modelling.
 * Interfaces w/ LWR API
 * 
 * Author: Oscar Jaimes
 */

import http from "./http";

const runModel = async (modelParams) => {
  try {
    const res = await http.post('carbon-model/run', modelParams);
    return res.data;
  } catch (error) {
    return error;
  }
};

export {
  runModel
}