import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { DisplayImagesFromDocs } from "./DisplayImagesFromDocs";
import { UploadNewFiles } from "../UploadNewFiles";
import { useAuth } from "../../../../contexts/AuthContext";
import { getProperTime } from "../../fieldToolsFunctions";
import { useState } from "react";
import { useEffect } from "react";
import { ExistingFieldReceiptValue } from "./ExistingFieldReceiptValue";
import { DisplayFieldExpenseTimesheetValue } from "./DisplayFieldExpenseTimesheetValue";
import { DisplayFieldExpensesAmount } from "./DisplayFieldExpensesAmount";
import { getReportData } from "../../../../api/fieldTimeReport";
import {
  updateExpense,
  uploadExpenseImage,
} from "../../../../api/fieldExpenseReport";

export const DisplayFieldExpenseReceiptComponent = ({
  setIsReportList,
  expenseReceipt,
  setExpenseReceipt,
  images,
  setImages,
  loading,
  uploadedImages,
  setUploadedImages,
  setCancelButtonClicked,
  cancelButtonClicked,
}) => {
  const { email } = useAuth().currentUser;
  const [timesheetList, setTimesheetList] = useState([]);
  useEffect(() => {
    getReportData(email).then((result) => setTimesheetList(() => result));
  }, []);

  // onChange of image, make sure that the fileList is appended to expenseReceipt and the file data is updted in images
  const onImageChange = (fileList) => {
    // add a uuid to image url per new image added, and use that to create a new image in images
    fileList.map((file) => {
      let newUrl = `gs://lwr_web_portal_bucket/field_report/expenses/${email.split("@")[0]
        }/${expenseReceipt.reportName}/${file.name}`;
      let _expenseReceipt = { ...expenseReceipt };
      if (!_expenseReceipt.imageUrlList.includes(newUrl)) {
        _expenseReceipt.imageUrlList.push(newUrl);
        setExpenseReceipt(_expenseReceipt);
        let newImageData = {
          fileBlob: [file],
          fileName: file.name,
          imgUrl: newUrl,
        };
        let _images = [...images];
        _images.push(newImageData);
        setImages(_images);
        let _uploadedImages = [...uploadedImages];
        _uploadedImages.push(newImageData);
        setUploadedImages(_uploadedImages);
      } else {
        alert("Cannot upload multiple files with the same name.");
      }
    });
  };

  // update report by sending the new images to backend to store in google storage, then storing the updated report into docs
  const updateReport = async () => {
    const formData = new FormData();
    for (let i = 0; i < uploadedImages.length; i++) {
      formData.append("imgDatas", ...uploadedImages[i].fileBlob);
    }
    formData.append("title", expenseReceipt.reportName);

    formData.append("email", email);
    // adds expense images to google storage
    uploadExpenseImage(formData).then((result) => {
      if (result.status == 200) {
        let bodyVal = {
          updatedExpenseReport: { ...expenseReceipt },
          email,
        };
        // updates expenes report
        updateExpense(bodyVal).then((res) =>
          res.status == 200
            ? alert("Expense Report has been updated")
            : alert("Could not update expense report, please try again.")
        );
      } else {
        alert("Image could not be saved, please try again later");
      }
    });
  };

  const changeValue = (value, valueType) => {
    const _expenseReceipt = { ...expenseReceipt };
    _expenseReceipt[valueType] =
      typeof _expenseReceipt[valueType] == "number" ? Number(value) : value;
    setExpenseReceipt(_expenseReceipt);
  };

  return (
    <>
      <Button variant="outline-dark" onClick={() => setIsReportList((isReportList) => !isReportList)}>
        Close
      </Button>
      <Card className="mt-2">
        <Card.Header>
          <div className="d-flex w-100 align-items-left flex-column pb-2">
            <h3>{expenseReceipt.expenseName}</h3>
            <div>Created At: {getProperTime(expenseReceipt.createdAt)}</div>
            <div>Last Updated At: {getProperTime(expenseReceipt.updatedAt)}</div>
          </div>
        </Card.Header>
        <Card.Body>

          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <DisplayFieldExpenseTimesheetValue
                    timesheetList={timesheetList}
                    setExpenseReceipt={setExpenseReceipt}
                    expenseReceipt={expenseReceipt}
                    cancelButtonClicked={cancelButtonClicked}
                  />

                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="description"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="expenseType"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="paidUsingCompanyCard"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="visa"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="cash"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="inventory"
                    changeValue={changeValue}
                  />
                  <ExistingFieldReceiptValue
                    expenseReceipt={expenseReceipt}
                    cancelChanges={cancelButtonClicked}
                    keyName="quantity"
                    changeValue={changeValue}
                  />
                  <DisplayFieldExpensesAmount
                    expenseReceipt={expenseReceipt}
                    setExpenseReceipt={setExpenseReceipt}
                    cancelButtonClicked={cancelButtonClicked}
                  />
                </Col>
                <Col md={4}>
                  <DisplayImagesFromDocs
                    expenseReceipt={expenseReceipt}
                    setExpenseReceipt={setExpenseReceipt}
                    images={images}
                    setImages={setImages}
                    loading={loading}
                    uploadedImages={uploadedImages}
                    setUploadedImages={setUploadedImages}
                  />
                  {/* <UploadNewFiles onChange={onImageChange} /> */}

                </Col>
              </Row>

            </Card.Body>
          </Card>

          <div className="d-flex align-items-center flex-row mt-2">
            <Button
              variant="outline-secondary"
              onClick={() => {
                setCancelButtonClicked(!cancelButtonClicked);
              }}>
              Cancel Changes
            </Button>
            <Button
              onClick={updateReport}
              variant="outline-success"
              style={{ marginLeft: 'auto' }}>
              Save Changes
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
