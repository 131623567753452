import React from 'react'
import InputChart from './InputChart';
import { massFlowUnits } from '../../../utility/UnitConversions';
import { Table } from 'react-bootstrap';

export const InformationAndInputChart = (props) => {
  let formVal = {
    "ts": parseInt(props.data["Total Solids (ppm)"]),
    "ss": parseInt(props.data["TSS (ppm)"]),
    "aNitrogen": parseInt(props.data["Ammonium Nitrogen (ppm)"]),
    "tkn": parseInt(props.data["TKN (ppm)"]),
    "organic_nitrogen": parseInt(props.data["Organic Nitrogen (ppm)"]),
    "potassium": parseInt(props.data["Potassium (ppm)"]),
    "phosphorus": parseInt(props.data["Phosphorus (ppm)"]),
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      <h5 style={{ textAlign: 'left', color: '#005E7D', fontWeight: 'bold' }}>{props.language?.inputs || 'Input Data'}</h5>
      <br />
      <br />
      <div className='row'>
        <div className='col-md-12'>
          <Table className="striped-table" style={{ textAlign: 'right' }}>
            <tbody>
              <tr >
                <td style={{ textAlign: 'left' }} className="header">{props.language?.annual_flow || 'Annual Flow'}</td>
                <td id="annual-flow">{numberWithCommas(props.data["Annual Flow"])} ({massFlowUnits['Annual Flow'][props.units]})</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'left' }} className="header">{props.language?.total_solids_ppm || 'Total Solids'}</td>
                <td id="annual-flow">{numberWithCommas(props.data["Total Solids (ppm)"])} (ppm)</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'left' }} className="header">{props.language?.total_disolved_solids_ppm || 'Total Suspended Solids'}</td>
                <td id="annual-flow">{numberWithCommas(formVal.ss)} (ppm)</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'left' }} className="header">{props.language?.total_disolved_solids_ppm || 'Total Disolved Solids'}</td>
                <td id="annual-flow">{numberWithCommas(formVal.ts - formVal.ss)} (ppm)</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <InputChart data={props.data} formVal={formVal} other={props.other} language={props.language} units={props.units}/>
    </>
  )
}
