import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import "./ExistingHRLearnMore.css";

export const ExistingHRCSVTimesheet = ({
  blobData,
  timesheetName,
  payPeriod,
}) => {
  const [timeSheetFile, setTimesheetFile] = useState(null);

  useEffect(() => {
    const blobUrl = window.URL.createObjectURL(blobData);
    const timesheetDownloadButton = document.getElementById(
      "timesheetDownloadButton"
    );

    timesheetDownloadButton.innerHTML = "";

    let link = document.createElement("a");
    link.style.color = "white";
    link.style.textDecoration = "none";
    link.innerText = "Download File";
    link.id = "downloadLink";
    link.href = blobUrl;
    link.download = `${timesheetName}.xlsx`;
    link.addEventListener("click", () => ({ view: window }));
    timesheetDownloadButton.appendChild(link);
    getExcelFile(blobData);
  }, [blobData]);

  // parse date on the excel file from int to date
  const getJsDateFromExcel = (val) => {
    const newDate = new Date((val - (25567 + 1)) * 86400 * 1000);

    if (newDate.getFullYear() < 2000) {
      return val.toFixed(1);
    } else {
      const newDateMonth = newDate
        .toLocaleString("default", { month: "long" })
        .substring(0, 3);
      return `${newDateMonth} ${newDate.getDate()} ${newDate.getFullYear()}`;
    }
  };

  // for each row, check if the column is date or other values and parse them accordingly
  const parseEachExcelRow = (rows) => {
    rows = rows.map((row) =>
      row.map((val, index) => {
        if (typeof val == "string") {
          if (val.replace(" ", "").includes("DOC:")) {
            row[index + 1] = `PP#${payPeriod || "n/a"}`;
          } else if (val.replace(" ", "").includes("EMP#")) {
            row[index + 1] = `${row[index + 1]}`;
          }

          return val;
        } else {
          if (typeof val == "number") {
            return getJsDateFromExcel(val);
          } else return val;
        }
      })
    );
    return rows;
  };

  // render the excel file
  const getExcelFile = (blobData) => {
    ExcelRenderer(blobData, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let rows = resp.rows;
        rows = parseEachExcelRow(resp.rows);
        setTimesheetFile({
          cols: resp.cols,
          rows: rows,
        });
      }
    });
  };

  return (
    <div className="">
      {timeSheetFile && (
        <div className="w-100">
          <OutTable
            data={timeSheetFile.rows}
            columns={timeSheetFile.cols}
            tableClassName="ExcelTable2007"
            tableHeaderRowClass="heading"
          />
        </div>
      )}
    </div>
  );
};
