import { React, useState, useEffect } from "react";
import { FormGroupCreator } from "../../FormGroupCreator";
import { FieldTimeReportingForm } from "../FieldTimeReportingFormCreator";
import { FieldTimeSheetDescriptionForm } from "./FieldTimeSheetDescriptionForm";
import { useAuth } from "../../../../../contexts/AuthContext";

// FieldTimeSheetDescription shows all the input for field time sheet description

export const FieldTimeSheetDescription = ({
  formProps: { setNewReport, pageNumber, setPageNumber, newReport, setUpdate },
  editMode
}) => {
  const { currentUser } = useAuth();

  const [newReportDescription, setNewReportDescription] = useState(
    newReport.reportDescription
  );

  // effect here to listen for changes in newReport prop
  useEffect(() => {
    setNewReportDescription(newReport.reportDescription);
  }, [newReport]);

  const setNewReportValues = (valueType, value) => {
    const _newReportDescription = newReportDescription;
    _newReportDescription[valueType] = value;
    setNewReportDescription(_newReportDescription);
  };

  const changeValue = (value, valueType) => {
    setNewReportValues(valueType, value);
    setUpdate((update) => !update);
  };

  useEffect(() => {
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const userName = currentUser.email.split('@')[0]
      .split('.')
      .map(capitalize)
      .join(' ');

    setNewReport((currNewReport) => ({
      ...currNewReport,
      reportName: `${userName} Field Timesheet - ${newReportDescription.date}`,
      createdAt: newReportDescription.date
    }));

    changeValue(newReportDescription.date, 'createdOn');
  }, [newReportDescription.date]);

  return (
    <div>
      <h4 className="text-center py-3 border-bottom">Report Description</h4>
      {/* Create the form for routing */}
      <FieldTimeReportingForm
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        editMode={editMode}
      >
        {/* Creates the title field group as it is a different entity than description  */}
        <FormGroupCreator
          label="Title"
          type="text"
          changeValue={(value, valueType) => {
            setNewReport((currNewReport) => ({
              ...currNewReport,
              reportName: value,
            }));
          }}
          valueType="reportName"
          valueDict={newReport}
        />
        {/* Creates field group for each of the description values */}
        <FieldTimeSheetDescriptionForm
          changeValue={changeValue}
          newReportDescription={newReportDescription}
        />
      </FieldTimeReportingForm>
    </div>
  );
};
