import React from "react";
import { Button, Modal } from "react-bootstrap";

export const DeleteModal = ({
  show,
  setShow,
  titleName,
  report = true,
  policy = false,
  deleteFunction,
  id,
}) => {
  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <div style={{fontSize: '18px'}}>
            Do you want to delete {report ? " report " : policy ? " policy " : " task "} "{titleName}"
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleClose();
            deleteFunction(id);
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
