/**
 * api/sensordata.js
 * -----------------
 * Module encapsulating functions related to sensor data.
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 8 2023
 */

import http from "./http";
import { shouldReturnCachedData } from "./cache";

let sensorDocCount = null;
let lastPolled     = null;

const getSensorDataDocumentCount = () => {
  // Return cached sensorDocCount
  if(shouldReturnCachedData(sensorDocCount, lastPolled)) {
    return sensorDocCount;
  }

  return http.get('sensorData/count/')
    .then((res) => {
      sensorDocCount = res.data;
      lastPolled = Date.now();
      return sensorDocCount;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const getMostRecentData = (systemId) => {
  return http.get(`/sensorData/mostRecentData/?linkingId=${systemId}`)
  .then((res) => {
    return res.data;
  }).catch((error) => {
    console.log(error);
    return error;
  });
}

const deleteByDateAndLinkingId = (date, linkingId) => {
  return http.delete(`sensorData/deleteByDateAndLinkingID/?date=${date}&linkingId=${linkingId}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

const insertSensorData = (doc) => {
  return http.post(`sensorData/insert/?doc=${JSON.stringify(doc)}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
};

/**
 * Gets CSV data 
 * @param {String} linkingId 
 * @param {String} startDate 
 * @param {String} endDate 
 * @param {Array<String>} selectedFields 
 * @returns 
 */
const csvDataByDate = (linkingId, startDate, endDate, selectedFields) => {
  return http.get(`sensorData/dataByDate/?linkingId=${linkingId}&startDate=${startDate}&endDate=${endDate}&selectedFields=${selectedFields}`)
    .then((res) => {
      return res;
    }).catch((error) => {
      console.log(error);
      return error;
    })
}

const EXTRA_FIELDS = ['all', 'temp', 'humidity', 'min_temp', 'max_temp'];
const getAvailableFields = (linkingId) => {
  return http.post(`sensorData/available-fields`, {linkingId})
    .then((res) => {
      let fields = res.data.fields;
      fields.push(...EXTRA_FIELDS);

      return fields.sort();
    }).catch((error) => {
      return error;
    })
};

export {
  getSensorDataDocumentCount,
  deleteByDateAndLinkingId,
  insertSensorData,
  csvDataByDate,
  getAvailableFields,
  getMostRecentData
};