/**
 * api/labdata.js
 * ------------------
 * Module encapsulating functions related to the labdata collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 */

import http from "./http";

const getAllLabData = (page) => {
  return http.get(`/lab-data?page=${page}`)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getFilteredLabData = (startDate, endDate, testingType) => {
  return http.get(`/lab-data/filtered?startDate=${startDate}&endDate=${endDate}&testingType=${testingType}`)
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getAllFields = () => {
  return http.get('/lab-data/fields')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const getMappings = () => {
  return http.get('/lab-data/mappings')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const insertLabData = (labData) => {
  return http.post('/lab-data', { labData })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const updateLabData = (labData) => {
  return http.put('/lab-data', { labData })
  .then((res) => {
    return res;
  }).catch((err) => {
    console.log(err);
    return err;
  })
};

export {
  getAllLabData,
  getFilteredLabData,
  getAllFields,
  getMappings,
  insertLabData,
  updateLabData,
};