/**
 * SystemsTab.jsx
 * --------------
 * Component that contains all CRUD functionality for client Systems in the LWR database.
 * Renders a table with client Client System entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { json2csv } from 'json-2-csv';
// CRUD components
import SystemsInsertModal from "./SystemsInsertModal";
import SystemsDeleteModal from "./SystemsDeleteModal";
import SystemsEditModal from "./SystemsEditModal";

// api
import { getAllSystemSites } from "../../../api/systemSites";
import '../organization-components/OrganizationsTab.css';
import { getAllJobs } from "../../../api/jobs";


const SystemsTab = (props) => {
  const [systemTableElements, setSystemTableElements] = useState([]);
  const [systems, setSystems] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [systemToEdit, setSystemToEdit] = useState({});

  const searchRef = useRef();

  useEffect(async () => {
    document.title = "Systems - Admin Tools";
    fetchAndCreateSystemElements();
  }, []);

  const fetchAndCreateSystemElements = async () => {
    searchRef.current.value = "";
    const allSystems = await getAllSystemSites(true);
    let sysElements = createSystemTableElements(allSystems);
    setSystemTableElements(sysElements);
    setSystems(allSystems);
  };

  const createSystemTableElements = (systems) => {
    if (systems) {
      return systems.map((sys) => (
        <tr onClick={() => {setSystemToEdit(sys); setShowEditModal(true);}}>
          <td>{sys.systemName}</td>
          <td>{sys.farmId}</td>
          <td>{sys.manureType}</td>
          <td>{sys.systemType}</td>
          <td>{sys.jobNumber}</td>
          <td>{sys.linking_id}</td>
        </tr>
      ));
    }
  };

  const onChangeSearch = () => {
    let validSystems = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();
    systems.forEach((sys) => {
      if (sys.systemName.toLowerCase().includes(currentSearchVal)) {
        validSystems.push(sys);
      }
    });
    let newTableElements = createSystemTableElements(validSystems);
    setSystemTableElements(newTableElements);
  };

  const convertToCsv = async () => {
    try {
      const jobList = await getAllJobs();
      const csv = await json2csv(jobList);
      const blob = new Blob([csv], { type: 'text/csv' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'jobList.csv';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    } catch (err) {
      return ("Unable to convert json2csv: ", err);
    }
  }

  return (
    <div style={{margin: '10px'}}>
      <SystemsEditModal show={showEditModal} system={systemToEdit} onEnd={() => {fetchAndCreateSystemElements(); setShowEditModal(false); setSystemToEdit({})}}/>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4">       
          <Button onClick={convertToCsv} style={{ backgroundColor: '#005E7D' }}>Download CSV</Button>
        </div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          <SystemsInsertModal onEnd={() => {fetchAndCreateSystemElements(); }} />
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }} className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>System Name</th>
              <th>Parent Farm</th>
              <th>Manure Type</th>
              <th>System Type</th>
              <th>Job Number</th>
              <th>Linking ID</th>
              {/* <th>Edit</th>
              <th>Delete</th> */}
            </tr>
          </thead>
          <tbody>{systemTableElements}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default SystemsTab;
