import React, { useCallback, useEffect } from 'react';
import { toJpeg } from 'html-to-image';
import { FaFileDownload } from "react-icons/fa";
import { jsPDF } from "jspdf";
import ReactFlow, {
  addEdge,
  Controls,
  ControlButton,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds
} from 'reactflow';

import {
  createNodes,
  edges as initialEdges
} from './FirstWaveNodes';

import 'reactflow/dist/style.css';
import '../flowDiagrams.css';

function downloadPDF(dataUrl, title) {
  const img = new Image();
  img.src = dataUrl;

  img.onload = () => {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [img.width, img.height]
    });

    pdf.addImage(img, 'JPEG', 0, 0, img.width, img.height);
    pdf.save(`${title}.pdf`);
  };
}

const imageWidth = 6240;
const imageHeight = 3080;

function DownloadButton({ title }) {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toJpeg(document.querySelector('.react-flow__viewport'), {
      backgroundColor: 'white',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then((data) => downloadPDF(data, title));
  };


  return (
    <ControlButton onClick={onClick} title='Save Diagram'>
      <FaFileDownload />
    </ControlButton>
  );
}


const FirstWaveDiagram = ({ title, inputData, inletFlows, afterPolymerAddition, microscreenInlet, msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, sysParams }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(createNodes(sysParams, inputData, inletFlows, afterPolymerAddition, microscreenInlet, msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, title));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges(sysParams));
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  useEffect(() => {
    setNodes(createNodes(sysParams, inputData, inletFlows, afterPolymerAddition, microscreenInlet, msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, title))
    setEdges(initialEdges(sysParams));
  }, [sysParams, inputData, inletFlows, afterPolymerAddition, microscreenInlet, msSolids, msFiltrate, spSolids, spFiltrate, polymerSystem, title]);

  return (
    <ReactFlow
      className="fw-flow"
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      attributionPosition="top-right"
    >
      <Controls showFitView={false} showInteractive={false}>
        <DownloadButton title={title} />
      </Controls>
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
};

export default FirstWaveDiagram;
