import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { HRTimeSheetNav } from "./HRTimeSheetNav";

export const HRTimeSheet = () => {
  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <Row style={{ paddingTop: "2%" }}>
        <Col md={12}>
          <Card>
            <Card.Header style={{backgroundColor: '#005E7D', color: 'white'}}>HR Timesheet</Card.Header>
            <Card.Body className="p-0 m-2">
              <HRTimeSheetNav />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
