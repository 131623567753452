/**
 * LabData.jsx
 * -----------
 * Main lab data dashboard.
 * 
 * Author: Oscar Jaimes
 */

import { useState, useEffect, useRef } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Row, Col, Button, Alert} from "react-bootstrap";
import { getAllFields, getAllLabData, getMappings, insertLabData, updateLabData } from "../../api/labdata";
import { getColumns, getRows, applyChangesToLabData } from "./spreadSheetUtils";
import { MdOutlineFirstPage, MdOutlineLastPage } from "react-icons/md";
import UtilitySidebar from './UtilitySidebar';
import "./style.css";

const LabData = () => {
  // data states
  const [labData, setLabData] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [added, setAdded] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [workSpaceMode, setWorkSpaceMode] = useState(false);
  const [exploreMode, setExploreMode] = useState(true);

  // filter states
  const startDateRef = useRef();
  const endDateRef = useRef();
  const [testingType, setTestingType] = useState('All');

  const rows = getRows(labData, fieldMappings);
  const columns = getColumns(labData);

  useEffect(() => {
    const fetchData = async () => {
      const ld = await getAllLabData(page);
      const fields = await getAllFields();
      const mappings = await getMappings();

      setTotalPages(ld.totalPages);
      ld.labData.forEach((r) => {
        r.isOpen = Object.keys(r).reduce((acc, key) => {
          acc[key] = { isOpen: false };
          return acc;
        }, {})
      })

      setFieldMappings(mappings.mappings);
      setAvailableFields(fields.fields);
      setLabData(ld.labData);
    };

    fetchData();
  }, []);

  const addRow = () => {
    const newRowData = {};
    availableFields.forEach((f) => {
      newRowData[f] = '';
    });
    newRowData._id = added;
    setAdded(added + 1);
    newRowData['isOpen'] = Object.keys(newRowData).reduce((acc, key) => {
      acc[key] = { isOpen: false };
      return acc;
    }, {});

    setLabData(prevLabData => [...prevLabData, newRowData]);
  };


  const handleChanges = (changes) => {
    setLabData((prevLabData) => applyChangesToLabData(changes, prevLabData));

    // update data as user changes it in explore mode
    if(exploreMode) {
      const updatedDocs = changes
        .map((c) => c.rowId)
        .map((id) => labData.find((d) => d._id == id));
      
      // not async since we don't have to wait for this
      updateLabData(updatedDocs);
    }
  };

  const nextPage = async () => {
    const ld = await getAllLabData(page + 1);
    ld.labData.forEach((r) => {
      r.isOpen = Object.keys(r).reduce((acc, key) => {
        acc[key] = { isOpen: false };
        return acc;
      }, {})
    })
    setPage(page + 1);
    setLabData(ld.labData);
  };

  const prevPage = async () => {
    const ld = await getAllLabData(page - 1);
    ld.labData.forEach((r) => {
      r.isOpen = Object.keys(r).reduce((acc, key) => {
        acc[key] = { isOpen: false };
        return acc;
      }, {})
    })
    setPage(page - 1);
    setLabData(ld.labData);
  };

  const saveChanges = async () => {
    console.log(labData);
    const data = labData;
    data.forEach((d) => delete d._id);
    const res = await insertLabData(labData);
    if(res.status == 200) {
      alert('Succesfully saved data');
      setWorkSpaceMode(false);
      setExploreMode(true);

      const ld = await getAllLabData(1);
      ld.labData.forEach((r) => {
        r.isOpen = Object.keys(r).reduce((acc, key) => {
          acc[key] = { isOpen: false };
          return acc;
        }, {})
      });
      setLabData(ld.labData);
      setPage(1);
    } else {
      alert('Could not save lab data');
    }
  };

  return (
    <div>
      <Row style={{ margin: '10px' }}>
        <Col md={12} style={{ margin: 'auto', marginTop: '.5%', height: "50%" }}>
          <Row>
            <Col md={3}>
              <UtilitySidebar
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                exploreMode={exploreMode}
                workSpaceMode={workSpaceMode}
                setExploreMode={setExploreMode}
                setWorkSpaceMode={setWorkSpaceMode}
                testingType={testingType}
                setTestingType={setTestingType}
                setLabData={setLabData}
                labData={labData}
                setPage={setPage}
                added={added}
                setAdded={setAdded}
                availableFields={availableFields}
              />
            </Col>
            <Col md={9}>
              {labData.length >= 1 &&
                <div>
                  <div className="grid-container">
                    <ReactGrid
                      rows={rows}
                      columns={columns}
                      onCellsChanged={handleChanges}
                      stickyTopRows={1}
                    />
                  </div>
                  <Row style={{ marginTop: '10px' }}>
                    <Col style={{ textAlign: 'left' }} md={6}>
                      {exploreMode ?
                        <div>
                          <Button variant="outline-dark" disabled={page === 1} onClick={prevPage}>
                            <MdOutlineFirstPage size={20} />
                          </Button>
                          {`     Page ${page} of ${totalPages}     `}
                          <Button variant="outline-dark" disabled={page === totalPages} onClick={nextPage}>
                            <MdOutlineLastPage size={20} />
                          </Button>
                        </div> : 
                        <div>
                          <Button onClick={addRow} variant="outline-dark" style={{ marginRight: '20px' }}>
                        Add Entry
                      </Button>
                      <Button onClick={saveChanges} variant="light">
                        Save Changes
                      </Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LabData;
