/**
 * api/admin.js
 * ------------------
 * Module encapsulating functions related to the loisControl collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 12 2023
 */

import http from "./http";

const getAllLoisControls = async () => {
  return http.get('loisControl/all')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const deleteLoisControl = async (linkingId) => {
  return http.delete(`loisControl/delete/?linkingId=${linkingId}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const createLoisControl = async (linkingId, loisActivated) => {
  return http.post(`loisControl/insert/?linkingId=${linkingId}&loisActive=${loisActivated}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

export {
  getAllLoisControls,
  deleteLoisControl,
  createLoisControl
};