import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getUserPermissionFromCache,
  isPermissionTypeHRAdmin,
} from "../../../utility/permissionFunctions";
import { ExistingHRPolicies } from "./ExistingHRPolicies/ExistingHRPolicies";
import { HRLearnMorePolicy } from "./ExistingHRPolicies/HRLearnMorePolicy";
import { NewHRPolicy } from "./NewHRPolicies/NewHRPolicy";

export const HRPoliciesNav = () => {
  const { email } = useAuth().currentUser;
  const [userType, setUserType] = useState([]);
  useEffect(async () => {
    setUserType(await getUserPermissionFromCache(email));
  }, []);
  return (
    <>
      <Router>
        <Nav justify variant="tabs" sticky="top" className=" border-bottom">
          {isPermissionTypeHRAdmin(userType) ? (
            <Nav.Item>
              <Nav.Link
                as={Link}
                style={{ color: "#3c506b" }}
                to="/hr-tools/hr-policies/newHRPolicy"
              >
                New HR Policy
              </Nav.Link>
            </Nav.Item>
          ) : null}

          <Nav.Item>
            <Nav.Link
              as={Link}
              style={{ color: "#3c506b" }}
              to="/hr-tools/hr-policies/existingHRPolicies"
            >
              Existing HR Policy
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <br />
        <Switch>
          {isPermissionTypeHRAdmin(userType) ? (
            <Route
              path="/hr-tools/hr-policies/newHRPolicy"
              component={NewHRPolicy}
            />
          ) : null}

          <Route
            path="/hr-tools/hr-policies/existingHRPolicies"
            exact
            component={ExistingHRPolicies}
          />
          <Route
            path="/hr-tools/hr-policies/existingHRPolicies/:id"
            component={HRLearnMorePolicy}
          />
          <Redirect to="/hr-tools/hr-policies/existingHRPolicies" />
        </Switch>
      </Router>
    </>
  );
};
