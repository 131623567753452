/**
 * api/systemtypes.js
 * --------------------
 * Module encapsulating functions related to the systemtypes collection in mongodb
 * Interfaces with the LWR API.
 * 
 * Author: Oscar Jaimes
 * Date: February 10 2023
 */

import http from "./http";

const deleteSysType = (systemName) => {
  return http.delete(`systemList/delete/?systemName=${systemName}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    })
};

const createSysType = (systemName) => {
  return http.post(`systemList/insert/?systemName=${systemName}`)
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

const getAllSysTypes = () => {
  return http.get('systemList/all')
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    })
}

export {
  deleteSysType,
  createSysType,
  getAllSysTypes
}