import React, { useEffect } from "react";
import { ListGroup, Row, Col, Card } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import CsvToolPage from "./CsvToolPage";
import CsvExport from "./CsvExport";
import { getUserPermissionFromCache } from "../../utility/permissionFunctions";
import { useAuth } from "../../contexts/AuthContext";
import { BiExport, BiImport } from "react-icons/bi";

const CsvNav = () => {
  const { email } = useAuth().currentUser;

  useEffect(() => {
    getUserPermissionFromCache(email);
  }, []);

  return (
    <div style={{ zoom: '85%' }}>
      <Router>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{
              width: '13.666667%',
              overflowY: 'auto',
              backgroundColor: 'white',
              position: 'fixed',
              height: '100%'
            }}>
              <Navigation />
            </div>
            <div style={{
              marginLeft: '13.666667%',
              width: '100%',
            }}>
              <Switch>
                <Route path="/csv-tools/export" component={CsvExport} />
                <Route path="/csv-tools/insert" component={CsvToolPage} />
                <Redirect to="/csv-tools/export" />
              </Switch>
            </div>
          </div>
      </Router>
    </div>
  );
};

const Navigation = () => {
  const location = useLocation();

  return (
    <ListGroup variant="flush" style={{ width: '120%' }}>
      <ListGroup.Item
        action
        as={Link}
        to="/csv-tools/export"
        style={{ backgroundColor: "transparent", color: location.pathname === "/csv-tools/export" ? '#005E7D' : 'grey', border: 'none', fontSize: '18px' }}
      >
        <BiExport size={23} />
        {' Export'}
      </ListGroup.Item>
      <ListGroup.Item
        action
        as={Link}
        to="/csv-tools/insert"
        style={{ backgroundColor: "transparent", color: location.pathname === "/csv-tools/insert" ? '#005E7D' : 'grey', border: 'none', fontSize: '18px' }}
      >
        <BiImport size={24} />
        {' Import'}
      </ListGroup.Item>
    </ListGroup>
  );
};

export default CsvNav;
