/**
 * FarmsTab.jsx
 * ------------
 * Component that contains all CRUD functionality for Farms in the LWR database.
 * Renders a table with Farm entries from the database.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useEffect, useState, useRef } from "react";
import { Table, Form } from "react-bootstrap";

// CRUD components
import FarmsInsertModal from "./FarmsInsertModal";
import FarmsDeleteModal from "./FarmsDeleteModal";
import FarmsEditModal from "./FarmsEditModal";

// farm api
import { getAllFarms } from "../../../api/farms";

import '../organization-components/OrganizationsTab.css';


const FarmsTab = (props) => {
  const [farmTableElements, setFarnElements] = useState([]);
  const [farms, setFarms] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [farmToEdit, setFarmToEdit] = useState({});

  const searchRef = useRef();

  useEffect(async () => {
    document.title = "Farms - Admin Tool";
    fetchAndCreateFarmElements();
  }, []);

  const fetchAndCreateFarmElements = async () => {
    searchRef.current.value = "";
    const allFarmsResult = await getAllFarms();
    let farmElements = createFarmTableElements(allFarmsResult);
    setFarnElements(farmElements);
    setFarms(allFarmsResult);
  };

  const createFarmTableElements = (farms) => {
    return farms.map((farm) => (
      <tr onClick={() => {setShowEditModal(true); setFarmToEdit(farm)}}>
        <td>{farm.name}</td>
        <td>{farm.organizationId}</td>
        <td>{farm.owner}</td>
        <td>{farm.contactNumber}</td>
        <td>{farm.contactEmail}</td>
        <td>{farm.address}</td>
        {/* <td>{farm.lat}</td>
        <td>{farm.long}</td> */}
        <td>{farm.numSystems}</td>
        {/* <td>
          <FarmsEditModal farm={farm} onEnd={fetchAndCreateFarmElements} />
        </td>
        <td>
          <FarmsDeleteModal farm={farm} onEnd={fetchAndCreateFarmElements} />
        </td> */}
      </tr>
    ));
  };

  /**
   * Search functionality. Edits table contents based on input text in search box.
   */
  const onChangeSearch = () => {
    let validFarms = [];
    let currentSearchVal = searchRef.current.value.toLowerCase();

    farms.forEach((farm) => {
      if (farm.name.toLowerCase().includes(currentSearchVal)) {
        validFarms.push(farm);
      }
    });

    let newTableElements = createFarmTableElements(validFarms);
    setFarnElements(newTableElements);
  };

  return (
    <div style={{ margin: '10px' }}>
      <FarmsEditModal show={showEditModal} farm={farmToEdit} onEnd={() => {fetchAndCreateFarmElements(); setShowEditModal(false); setFarmToEdit({})}}/>
      <br />
      <div className="row">
        <div className="col-md-4">
          <Form.Control
            size="md"
            type="text"
            placeholder="Search..."
            ref={searchRef}
            onChange={onChangeSearch}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4" style={{ textAlign: "right" }}>
          <FarmsInsertModal onEnd={fetchAndCreateFarmElements} />
        </div>
      </div>
      <br />
      <div style={{ overflow: "scroll" }} className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Farm Name</th>
              <th>Parent Org.</th>
              <th>Contact</th>
              <th>Number</th>
              <th>Contact Email</th>
              <th>Address</th>
              <th>Systems</th>
              {/* <th>Edit</th>
              {isPermissionTypeAdmin(userType) && <th>Delete</th>} */}
            </tr>
          </thead>
          <tbody>{farmTableElements}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default FarmsTab;
