

const massFlowUnits = {
    "Annual Flow": {
        "Metric": "m^3",
        "Imperial": "US gallons",
    },
    "Other Flow": {
        "Metric": "US gallon/min",
        "Imperial": "Liters/min"
    },
    "Section units": {
        "Metric": "kg/min",
        "Imperial": "lb/min"
    },
    "Nutrient Concentrations":{
        "Metric": {
            "Flush Manure": "kg / m^3",
            "Liquid Nutrient": "kg / m^3",
            "Nutrient Solids": "kg / tonne",
            "Clean Water": "ppm",
        },
        "Imperial": {
            "Flush Manure": "lbs / 1000g",
            "Liquid Nutrient": "lbs / 1000g",
            "Nutrient Solids": "lbs / ton",
            "Clean Water": "ppm",
        }        
    },
    
}

module.exports ={
    massFlowUnits
}