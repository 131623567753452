import http from "./http";

export const getAllOfficeTimesheets = async (email) => {
  return http.get(`office-timesheets/all/?email=${email}`)
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getOfficeTimesheet = async (_id, email) => {
  return http.get(`office-timesheets/find/?_id=${_id}&email=${email}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getPayPeriods = async () => {
  return http.get(`office-timesheets/pay-periods`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getUserTasks = async (email) => {
  return http.post(`office-timesheets/user-tasks`, { email })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const createNewOfficeTimesheet = async (body) => {
  return http.post(`office-timesheets/create/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateOfficeTimesheet = async (body) => {
  return http.put(`office-timesheets/update/`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteOfficeTimesheet = async (_id, email) => {
  return http.delete(`office-timesheets/delete/?_id=${_id}&email=${email}`,)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const singleDownload = async (_id) => {
  return http({
    method: 'post',
    url: '/office-timesheets/single-download',
    data: { _id },
    responseType: 'blob'

  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `shopTimesheet.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch((error) => {
    console.log(error);
    return error;
  });
};

export const approveTimesheet = async (_id, approvedBy) => {
  return http.put('/office-timesheets/approve', {
    _id,
    approvedBy
  })
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};