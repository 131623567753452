import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { convertKeyNameToDisplayName } from "../../../field-tools/TimeReporting/TimeReportFunctions";
import { parseDate } from "../../HRFunctions";

// Renders the first page of the form
export const NewHRShopTimesheetHeader = ({
  shopTimesheet,
  setShopTimesheet,
}) => {
  // change the timesheet value
  const changeTimesheetValue = (value, valueType) => {
    switch (true) {
      case typeof shopTimesheet[valueType] == "number":
        value = Number(value);
        break;
      case valueType == "date":
        const getProperDate = new Date(value);
        getProperDate.setDate(getProperDate.getDate() + 1);
        value = parseDate(getProperDate);
        break;
      default:
        break;
    }

    shopTimesheet[valueType] = value;
    setShopTimesheet({ ...shopTimesheet });
  };

  return (
    <div>
      <h4 className="text-center">New Shop Timesheet</h4>

      {Object.keys(shopTimesheet).map((key) => {
        const typeOfKey = typeof shopTimesheet[key];
        return key != "jobList" && key != "date" ? (
          key == "shopTimesheetName" ? (
            <Row className="pt-2">
              <Col className="col-md-2 col-12 text-center py-2">
                Timesheet Name
              </Col>
              <Col
                className="text-center text-sm-start border rounded py-2 ms-2 me-3 "
                style={{ background: "rgba(0, 0, 0, 0.03)" }}
              >
                {shopTimesheet.shopTimesheetName}
              </Col>
            </Row>
          ) : (
            <FormGroupCreator
              label={convertKeyNameToDisplayName(key)}
              type={typeOfKey}
              valueDict={shopTimesheet}
              valueType={key}
              changeValue={changeTimesheetValue}
            />
          )
        ) : key == "date" ? (
          // renders the date values
          <FormGroupCreator
            label={convertKeyNameToDisplayName(key)}
            valueDict={shopTimesheet}
            valueType={key}
            type="date"
            changeValue={changeTimesheetValue}
          />
        ) : null;
      })}
    </div>
  );
};
