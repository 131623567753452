/**
 * WeeklyReport.jsx
 * ----------------
 * Functional component for weekly report.
 *
 * Author: Oscar Jaimes
 * Last Updated: 01/06/2021
 */

// Library Imports
import React, { useState, useEffect, useRef } from "react";
import { Alert, Col, Row, Container } from "react-bootstrap";
import ReactToPdf from "react-to-pdf";
import { useParams } from 'react-router-dom';

// Graph Components
import PolymerGraph from "./weekly-graphs/PolymerGraph";
import InletTSSAndPhGraph from "./weekly-graphs/InletTSSAndPhGraph";
import FiltrateGraph from "./weekly-graphs/FiltrateGraph";
import FlowRateGraph from "./weekly-graphs/FlowRateGraph";
import RecoveryGraph from "./weekly-graphs/RecoveryGraph";
import OperatingHoursGraph from "./weekly-graphs/OperatingHoursGraph";
import TSRemovalGraph from "./weekly-graphs/TSRemovalGraph";

// Icons
import Logo from "../../img/myPLANT.png";
import VolumeTreatedIcon from "../../img/volume_treated.png";
import InletTSSIcon from "../../img/inlet_tss.png";
import FlowRateIcon from "../../img/average_flow_rate.png";
import FiltrateTSSIcon from "../../img/filtrate_volume.png";
import OperatingHoursIcon from "../../img/operating_hours.png";
import PhosphorusRemovalIcon from "../../img/phosphorus_removal_rate.png";
import PolymerConsumptionIcon from "../../img/polymer_consumption.png";
import PolymerCostIcon from "../../img/polymer_cost.png";
import SolidsIcon from "../../img/lwr_solids.png";
import ScreenRecoveryIcon from "../../img/screen_recovery.png";
import NutrientLiquidsIcon from "../../img/clean_water_production.png";

// Loading Modals
import LoadingModal from "./LoadingModal";
import LoadingIndicatorModal from "./LoadingIndicatorModal";

// api
import { getWeeklyReport } from "../../api/reports";

export default function WeeklyReport(props) {
  // PDF Functionality
  const weeklyReportRef = useRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [25, 15],
    compress: true,
  };

  // Provides access to query parameters
  const params = useParams();

  // report date info
  const startDate = params.startdate.split("T")[0].replaceAll("-", "/");
  const endDate = params.enddate.split("T")[0].replaceAll("-", "/");

  // Loading state
  const [loading, setLoading] = useState(true);
  const [creatingPdf, setCreatingPdf] = useState(false);
  const [usedAssumptions, setUsedAssumptions] = useState(false);
  const [isFirstWave, setIsFirstWave] = useState(true);

  // Analytics Totals (Singular value)
  const [volumeTreated, setVolumeTreated] = useState(0);
  const [inletTSS, setInletTSS] = useState(0);
  const [inletFlow, setInletFlow] = useState(0);
  const [filtrateTSS, setFiltrateTSS] = useState(0);
  const [opHours, setOpHours] = useState(0);
  const [runtimeEfficiency, setRuntimeEfficiency] = useState(0);
  const [phosRemoval, setPhosRemoval] = useState(0);
  const [groDose, setGroDose] = useState(0);
  const [polyCost, setPolyCost] = useState(0);
  const [totalPoly, setTotalPoly] = useState(0);
  const [lwrSolids, setLwrSolids] = useState(0);
  const [screenRec, setScreenRec] = useState(0);
  const [liquids, setLiquids] = useState(0);
  const [waterProduction, setWaterProduction] = useState(0);

  // Advanced Analytics (Graph Data)
  const [polymerDoseData, setPolymerDoseData] = useState([
    ["", ""],
    [0, 0],
  ]);
  const [inletTSSAndPhData, setinletTSSAndPhData] = useState([
    ["", ""],
    [0, 0],
  ]);
  const [filtrateGraphData, setFiltrateGraphData] = useState([
    ["", ""],
    [0, 0],
  ]);
  const [flowRateGraphData, setFlowRateGraphData] = useState([
    ["", ""],
    [0, 0],
  ]);
  const [recoveryGraphData, setRecoveryGraphData] = useState([
    ["", ""],
    [0, 0],
  ]);
  const [operHoursGraphData, setOperHoursGraphData] = useState([
    ["", ""],
    [0, 0],
  ]);

  const [totalSolidsRemoval, setTotalSolidsRemoval] = useState([
    ["", ""],
    [0, 0],
  ]);

  // Units
  const [flowUnits, setFlowUnits] = useState("");
  const [massUnits, setMassUnits] = useState("");
  const [volumeUnits, setVolumeUnits] = useState("");

  // Error
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    document.title = `Weekly Analytics Dashboard`;
    loadData();
  }, []);

  // Function load API report data into state variables.
  const loadData = async () => {
    try{
    const weeklyReport = await getWeeklyReport(params.systemid, startDate, endDate, params.units);

    setIsFirstWave(weeklyReport.isFirstWave);
    setVolumeTreated(weeklyReport["Totals"]["Volume Treated"]["Total"]["value"]);
    setInletTSS(weeklyReport["Inlet Manure"]["Inlet Manure TSS"]["Average"]["value"]);
    setInletFlow(weeklyReport["Inlet Flow"]["Flow Rate"]["Average"]["value"]);
    setFiltrateTSS(weeklyReport["Filtrate"]["Actual Filtrate"]["Average"]["value"]);
    setOpHours(weeklyReport["Totals"]["Operating Hours"]["Total"]["value"]);
    setRuntimeEfficiency(weeklyReport["Totals"]["Runtime Efficiency"]["Total"]["value"]);
    setPhosRemoval(weeklyReport["Recovery"]["Phosphorous Removal Rate"]["Average"]["value"]);
    setGroDose(weeklyReport["Polymer Dosage"]["Actual GRO 17 Dosage"]["Average"]["value"]);
    setPolyCost(weeklyReport["Totals"]["Polymer Cost"]["Total"]["value"]);
    setTotalPoly(weeklyReport["Totals"]["Polymer Consumption"]["Total"]["value"]);
    setLwrSolids(weeklyReport["Totals"]["LWR Solids"]["Total"]["value"]);
    setScreenRec(weeklyReport["Recovery"]["Screen Recovery"]["Average"]["value"]);
    setLiquids(weeklyReport["Totals"]["Nutrient Liquids"]["Total"]["value"]);
    setWaterProduction(weeklyReport["Totals"]["Clean Water Production"]["Total"]["value"])

    // Units
    setFlowUnits(weeklyReport["Inlet Flow"]["Flow Rate"]["Average"]["units"]);
    setMassUnits(weeklyReport["Totals"]["LWR Solids"]["Total"]["units"]);
    setVolumeUnits(weeklyReport["Totals"]["Volume Treated"]["Total"]["units"]);

    // Graph Data
    loadPolymerDoseGraphData(weeklyReport);
    loadInletTSSAndPhData(weeklyReport);
    loadFiltrateData(weeklyReport);
    loadFlowRateData(weeklyReport);
    loadTSVSRemovalData(weeklyReport);
    loadRecoveryData(weeklyReport);
    loadOperHoursData(weeklyReport);
    setLoading(false);
    
    // If nutrient liquids is exactly 90% of total volume treated, it indicates that we used the 90% assumption in the API
    if((weeklyReport['Totals']['Nutrient Liquids']['Total']['value']) == Math.ceil(weeklyReport['Totals']['Volume Treated']['Total']['value'] * 0.90)) {
      setUsedAssumptions(true);
    }

    } catch(e) {
      console.error(e);
      setErrMessage("Error Fetching Weekly Report");
      setLoading(false);
    }
  };

  const loadPolymerDoseGraphData = (report) => {
    let operator = report["Polymer Dosage"]["Actual GRO 17 Dosage"]["Daily"];
    let predicted =
      report["Polymer Dosage"]["Predicted GRO 17 Dosage"]["Daily"];
    let data;
    if (report["Gro Control"]["Active"] == true) {
      predicted = operator;
      data = loadGraphData(predicted, operator, "", "grōControl grō17 Dose");
      setPolymerDoseData(data);
    } else {
      data = loadGraphData(
        operator,
        predicted,
        "Actual grō17 Dose",
        "Predicted grō17 Dose"
      );
      setPolymerDoseData(data);
    }
  };

  const loadInletTSSAndPhData = (report) => {
    let pH = report["Inlet Manure"]["Inlet Manure pH"]["Daily"];
    let tss = report["Inlet Manure"]["Inlet Manure TSS"]["Daily"];
    let data = loadGraphData(tss, pH, "Inlet TSS", "Inlet pH");
    setinletTSSAndPhData(data);
  };

  const loadTSVSRemovalData = (report) => {
    let tsRemoval = report["Recovery"]["TS Removal"]["Daily"];
    let vsRemoval = report["Recovery"]["VS Removal"]["Daily"];

    let data = loadGraphData(tsRemoval, vsRemoval, "TS Removal", "VS Removal");
    setTotalSolidsRemoval(data);
  };

  const loadFiltrateData = (report) => {
    let predictedDose = report["Filtrate"]["Actual Filtrate"]["Daily"];
    let predictedFiltrate = report["Filtrate"]["Predicted Filtrate"]["Daily"];
    let tss = report["Inlet Manure"]["Inlet Manure TSS"]["Daily"];
    let groDoseVals = [];

    let table = [];
    for (let i = 0; i < predictedDose.length; i++) {
      let currentDose = predictedDose[i];
      let currentFiltrate = predictedFiltrate[i];
      let currentTSS = tss[i];

      for (let j = 0; j < currentDose["Hourly"].length; j++) {
        let doseHours = currentDose["Hourly"][j];
        groDoseVals.push(doseHours["Value"]);
        let filtrateHours = currentFiltrate["Hourly"][j];
        let tssHours = currentTSS["Hourly"][j];

        let date = currentDose["Date"].split("/");
        let time = doseHours["Time"].split(":");

        let year = date[0];
        let month = date[1] - 1;
        let day = date[2];
        let hour = time[0];
        let min = time[1];
        let sec = time[2];

        let row = [
          new Date(year, month, day, hour, min, sec),
          doseHours["Value"],
          filtrateHours["Value"],
          tssHours["Value"],
        ];
        table.push(row);
      }
    }

    table.reverse();
    table.unshift([
      "Date & Time",
      "Actual Filtrate",
      "Predicted Filtrate",
      "Inlet TSS",
    ]);

    setFiltrateGraphData(table);
  };

  const loadFlowRateData = (report) => {
    let flow_rate = report["Inlet Flow"]["Flow Rate"]["Daily"];
    let flowUnit = flowUnits;

    let table = [];
    for (let i = 0; i < flow_rate.length; i++) {
      let flow_r = flow_rate[i];
      for (let j = 0; j < flow_r["Hourly"].length; j++) {
        let flow_hours = flow_r["Hourly"][j];

        let date = flow_r["Date"].split("/");
        let time = flow_hours["Time"].split(":");

        let year = date[0];
        let month = date[1] - 1;
        let day = date[2];
        let hour = time[0];
        let min = time[1];
        let sec = time[2];

        let row = [
          new Date(year, month, day, hour, min, sec),
          flow_hours["Value"],
        ];
        table.unshift(row);
      }
    }
    table.unshift(["Date", `Flow Rate`]);
    setFlowRateGraphData(table);
  };

  const loadRecoveryData = (report) => {
    let screen_recovery = report["Recovery"]["Screen Recovery"]["Daily"];
    let phosphorous_removal_rate =
      report["Recovery"]["Phosphorous Removal Rate"]["Daily"];

    let data = loadGraphData(
      screen_recovery,
      phosphorous_removal_rate,
      "Screen Recovery",
      "Phosphorous Removal"
    );
    setRecoveryGraphData(data);
  };

  const loadOperHoursData = (report) => {
    let operating_hours = report["Totals"]["Operating Hours"]["Daily"];
    let hours_per_day = [];
    let i = 0;
    operating_hours.forEach((day) => {
      let total_hours = 0;
      total_hours += day["Value"];
      hours_per_day.unshift([new Date(day["Date"]), total_hours]);
      i++;
    });

    hours_per_day.unshift(["Date", "Operating Hours"]);
    setOperHoursGraphData(hours_per_day);
  };

  /**
   * Loads data from two different data sets, and aggregates them to form
   * parsable data for google graphs.
   *
   * @param {Array} first
   * @param {Array} second
   * @param {String} firstName
   * @param {String} secondName
   * @returns {Array}
   */
  const loadGraphData = (first, second, firstName, secondName) => {
    let table = [];
    for (let i = 0; i < first.length; i++) {
      let current1 = first[i];
      let current2 = second[i];

      for (let j = 0; j < current1["Hourly"].length; j++) {
        let current1Hours = current1["Hourly"][j];
        let current2Hours = current2["Hourly"][j];

        let date = current1["Date"].split("/");
        let time = current1Hours["Time"].split(":");
        let year = date[0];
        let month = date[1] - 1;
        let day = date[2];
        let hour = time[0];
        let min = time[1];
        let sec = time[2];

        let row = [
          new Date(year, month, day, hour, min, sec),
          current1Hours["Value"],
          current2Hours["Value"],
        ];
        table.push(row);
      }
    }
    table.unshift([
      { label: "Date & Time", type: "date" },
      firstName,
      secondName,
    ]);
    return table;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Transforms linking Id to human readable system name
   * @param {String} siteName
   * @returns
   */
  function parseSiteName(siteName) {
    let name = siteName.split("_");
    let parsedName = "";
    name.forEach((word) => {
      let parsedWord = word[0].toUpperCase() + word.slice(1, word.length);
      parsedName += parsedWord + " ";
    });

    //return up to (but not including) the last space character (always last character)
    return parsedName.split(0, parsedName.length - 1);
  }

  return (
    <div style={{zoom: '90%'}}>
      <br />
      <div
        className="row"
        style={{
          marginLeft: '25px'          
        }}
      >
        <div className="col-md-4">
          <ReactToPdf
            targetRef={weeklyReportRef}
            filename={`${params.systemid}_${startDate.replaceAll(
              "/",
              "-"
            )}_${endDate.replaceAll("/", "-")}.pdf`}
            onComplete={() => {
              setCreatingPdf(false);
            }}
            options={options}
            x={0}
            y={0}
            scale={0.90}
          >
            {({ toPdf }) => (
              <LoadingModal
                onClick={toPdf}
                show={creatingPdf}
                setShow={setCreatingPdf}
              />
            )}
          </ReactToPdf>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          {errMessage && <Alert variant="danger">{errMessage}</Alert>}
        </div>
        <div className="col-md-3"></div>
      </div>

      <Container
        fluid="md"
        style={{
          maxWidth: "1600px",
          minWidth: "1000x",
          margin: "auto",
          marginTop: "15px",
          padding: "30px",
          border: "1px solid #eee",
          boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
          lineHeight: "24px",
        }}
        ref={weeklyReportRef}
      >
        {loading && (
          <Row>
            <Col md={12} style={{ textAlign: "left" }}>
              <LoadingIndicatorModal show={loading} />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4} style={{ textAlign: "Left" }}>
            <h5>{params.systemid == 'skyridge_farms' ? 'Western Valley Farms' : parseSiteName(params.systemid)} Data Dashboard</h5>
            <h5>
              {startDate} - {endDate}
            </h5>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}>
            <img src={Logo} style={{ width: "230px" }} />
          </Col>
        </Row>

        <br />
        <br />
        <Row style={{ textAlign: "center" }}>
          <h5>Analytics Overview</h5>
          <br />
          <br />
          <Col md={isFirstWave ? 12 : 10}>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "center" }}>
                  <img src={VolumeTreatedIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(volumeTreated)}
                  </h5>
                  {volumeUnits} Treated
                </div>
                <br />
                <br />
                <div>
                  <img src={PolymerConsumptionIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(groDose)} ppm
                  </h5>
                  Avg. Grō17 Dose
                </div>
              </Col>

              <Col md={2}>
                <div>
                  <img src={InletTSSIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(inletTSS)} ppm
                  </h5>
                  Avg. Inlet TSS
                </div>
                <br />
                <br />
                <div>
                  <img src={PolymerCostIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    $ {numberWithCommas(polyCost)}
                  </h5>
                  Total Grō17 Cost
                </div>
              </Col>

              <Col md={2}>
                <div>
                  <img src={FlowRateIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(inletFlow)} {flowUnits}
                  </h5>
                  Avg. Inlet Flow
                </div>
                <br />
                <br />
                <div>
                  <img src={PolymerConsumptionIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(totalPoly)} {massUnits}
                  </h5>
                  Total Polymer Used
                </div>
              </Col>

              <Col md={2}>
                <div>
                  <img src={FiltrateTSSIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(filtrateTSS)} ppm
                  </h5>
                  Avg. Filtrate TSS
                </div>
                <br />
                <br />
                <div>
                  <img src={SolidsIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(lwrSolids)} {massUnits}
                  </h5>
                  LWR Solids
                </div>
              </Col>

              <Col md={2}>
                <div>
                  <img src={OperatingHoursIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>{numberWithCommas(opHours)} Hrs / {runtimeEfficiency}% Runtime</h5>
                 Hours & Runtime
                </div>
                <br />
                <br />
                <div>
                  <img src={ScreenRecoveryIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(screenRec)}%
                  </h5>
                  Screen Recovery
                </div>
              </Col>

              <Col md={2}>
                <div>
                  <img src={PhosphorusRemovalIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(phosRemoval)}%
                  </h5>
                  Phosphorus Removal
                </div>
                <br />
                <br />
                <div>
                  <img src={NutrientLiquidsIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(liquids)} {volumeUnits}
                  </h5>
                  Nutrient Liquids
                </div>
              </Col>
            </Row>
          </Col>
          {!isFirstWave && <Col md={2}>
            <Row>
              <Col md={10}>
                <div style={{ textAlign: "center" }}>
                  <img src={NutrientLiquidsIcon} style={{ width: "35%" }} />
                  <h5 style={{ paddingTop: "5px" }}>
                    {numberWithCommas(waterProduction)} {volumeUnits}
                  </h5>
                  Clean Water
                </div>
              </Col>
            </Row>
          </Col>}
        </Row>

        <div className="row" style={{ textAlign: "center", paddingTop: "4%" }}>
          <h5>Advanced Analytics</h5>
          <div className="col-md-6">
            <PolymerGraph polymerdosedata={polymerDoseData} />
            <br />
            <FiltrateGraph filtrategraphdata={filtrateGraphData} />
            <br />
            <RecoveryGraph recoverydata={recoveryGraphData} />
          </div>
          <div className="col-md-6">
            <InletTSSAndPhGraph inlettssandphdata={inletTSSAndPhData} />
            <br />
            <FlowRateGraph flowratedata={flowRateGraphData} units={flowUnits} />
            <br />
            <OperatingHoursGraph operatinghoursdata={operHoursGraphData} />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <TSRemovalGraph recoverydata={totalSolidsRemoval} /></div>
            {isFirstWave && (
              <div>
                <p style={{ textAlign: "center", color: "grey" }}>
                  Approximately 41% of Ammonium Nitrogen Removed
                </p>
                <p style={{ textAlign: "center", color: "grey" }}>
                  Approximately 34% of Organic Nitrogen Removed
                </p>
                { usedAssumptions && <p style={{ textAlign: "center", color: "grey" }}>
                 Analytics assume that 90% of total volume treated goes to filtrate liquids
                </p>}
              </div>
            )}
            {!isFirstWave && (
              <div>
                <p style={{ textAlign: "center", color: "grey" }}>
                  Approximately 76% of Ammonium Nitrogen Removed
                </p>
                <p style={{ textAlign: "center", color: "grey" }}>
                  Approximately 88% of Organic Nitrogen Removed
                </p>
                { usedAssumptions && <p style={{ textAlign: "center", color: "grey" }}>
                  Analytics assume that 90% of total volume treated goes to filtrate liquids
                </p>}
              </div>
            )}
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col-md-12">
          <br />
            © 2023 Livestock Water Recycling
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
}
