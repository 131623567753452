import React from 'react'
import { Chart } from "react-google-charts";

export default function FlowRateGraph(props) {
    return (
        <Chart
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={props.flowratedata}
            options={{
                title: 'Flow Rate vs. Date',
                vAxis: { minValue: 0, title: props.units },
                hAxis: { format: 'dd/MM/yy' },
                titleTextStyle: {
                    fontFamily: 'Arial',
                    fontSize: 15, // 12, 18 whatever you want (don't specify px)
                    bold: true,    // true or false
                    italic: false   // true of false
                },
                backgroundColor: { fill: 'transparent' },
                height: 450,
                chartArea: {
                    width: '75%',
                    height: '75%'
                },
                legend: { position: 'top', alignment: 'start' }
            }}
        />
    )
}
