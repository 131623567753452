import React from "react";
import { FormCheckCreator } from "../../FormCheckCreator";
import { FormGroupCreator } from "../../FormGroupCreator";

export const EditExistingTimeReportField = ({
  valueDict,
  valueType,
  type,
  setTimeSheet,
  timeSheet,
  subDictName,
  multipleDict,
}) => {
  const changeValue = (value, dictValueType) => {
    if (typeof valueDict[valueType] == "number") value = Number(value);
    valueDict[dictValueType] = value;
    let _timeSheet = { ...timeSheet };
    if (multipleDict) {
      _timeSheet[subDictName] = _timeSheet[subDictName].map((value) =>
        value.newId == valueDict.newId ? valueDict : value
      );
    } else {
      _timeSheet[subDictName][dictValueType] = value;
    }
    setTimeSheet(_timeSheet);
  };

  type = valueType == "date" ? "date" : valueType;

  return typeof valueDict[valueType] == "boolean" ? (
    <FormCheckCreator
      valueDict={valueDict}
      valueType={valueType}
      type={type}
      changeValue={changeValue}
    />
  ) : (
    <FormGroupCreator
      valueDict={valueDict}
      valueType={valueType}
      type={type}
      changeValue={changeValue}
    />
  );
};
