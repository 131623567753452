import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { FieldTimeReportingEditAmount } from "../../TimeReporting/FieldTimeReportingEditAmount";

export const DisplayFieldExpensesAmount = ({
  expenseReceipt,
  setExpenseReceipt,
  cancelButtonClicked,
}) => {
  const [editAmount, setEditAmount] = useState(false);

  useEffect(() => {
    setEditAmount(false);
  }, [cancelButtonClicked]);

  const amountChangeValue = (value, valueType) => {
    const _expenseReceipt = { ...expenseReceipt };
    _expenseReceipt[valueType.type] =
      typeof _expenseReceipt[valueType.type] == "number"
        ? Number(value)
        : value;
    setExpenseReceipt(_expenseReceipt);
  };
  return !editAmount ? (
    <Row>
      <Col md={4}> Amount </Col>
      <Col md={1}>
        {expenseReceipt["amount"] + " " + expenseReceipt["currencyType"]}
      </Col>
      <Col md={6} />
      <Col md={1}>
        <Col md={1}>
          <AiOutlineEdit onClick={() => setEditAmount(true)} />
        </Col>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col md={4}> Amount </Col>

      <FieldTimeReportingEditAmount
        changeValue={amountChangeValue}
        id={0}
        valueDict={expenseReceipt}
      />
    </Row>
  );
};
