import React from "react";
import { Button, Modal } from "react-bootstrap";

// Field Expense Modal that will delete the report based on the id given
export const FieldExpensesModal = ({ handleClose, show, showFile }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="w-100 h-100"
      centered
      size="lg"
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title>{showFile.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={showFile.url} style={{ maxWidth: "100%", height: "80%" }} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
