import http from "./http";

export const getAllPermissions = async () => {
    return http.get(`adminToolSignup/allPermissions`)
        .then((res) => {
            if (res.status === 200) return res.data
        })
        .catch((error) => {
            console.log(error);
            return -1;
        });
}

