/**
 * AssessmentReportingPage.js
 * ---------------------------
 *
 * Main page for Assessment Reporting. Holds the form for Single Assessment Client & Engineering.
 *
 * Author: Oscar Jaimes
 * Last Updated: 18/11/2021
 */

import React, { useRef, useState, useEffect } from "react";
import { Card, Form, Button, Row, Col, Modal, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { withRouter } from "react-router-dom";
import { massFlowUnits } from "../../utility/UnitConversions";
import { AssessmentReportingInputForm } from "./AssessmentReportingInput";
import { useAuth } from "../../contexts/AuthContext";
import Switch from 'react-switch';

import { FaEdit } from "react-icons/fa";
import { MdAddCircleOutline, MdDeleteOutline } from "react-icons/md";

import {
  getUserPermissionFromCache,
  isPermissionTypeDistributor,
} from "../../utility/permissionFunctions";
import CarbonModellingForm from "./CarbonModellingForm";
import { validateAssessmentInputs } from "./assessmentValidation";

const AssessmentReportingPage = (props) => {
  const { currentUser } = useAuth();

  const exampleFormVal = {
    ts: 0,
    tflow: 0,
    ss: 0,
    aNitrogen: 0,
    tkn: 0,
    potassium: 0,
    phosphorus: 0,
    volatile: 0,
    calcium: 0,
    magnesium: 0,
    sodium: 0,
    iron: 0,
    manganese: 0,
    copper: 0,
    zinc: 0,
    sulfur: 0,
  };

  const removalTestValue = {
    primary: "84",
    secondary: "98.5",
  };

  const [systemType, setSystemType] = useState("First Wave");
  const [formVal, setFormVal] = useState(exampleFormVal);
  const [removalValue, setRemovalValue] = useState(removalTestValue);
  const [currentValue, setCurrentValue] = useState("none");
  const [removalDisabled, setRemovalDisabled] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [includeCarbonModel, setIncludeCarbonModel] = useState(false);
  const [includeMicroNutrients, setIncludeMicroNutrients] = useState(false);
  const [hours, setHours] = useState(24);
  const [secondRO, setSecondRO] = useState(false);
  const [spOutput20Percent, setSpOutput20Percent] = useState(false);
  const [showAddConstituentModal, setShowAddConstituentModal] = useState(false);
  const [forcePolymer, setForcePolymer] = useState(false);

  function disabledStyle(disabled) {
    return disabled ? "" : "white";
  }

  function changeRemovalValue(input) {
    let value = "";
    // current value is the value of the removal % dropdown
    switch (input) {
      case "First Wave":
        value = "84";
        setCurrentValue("none");
        setRemovalDisabled(false);
        break;
      case "Full System":
        value = "98.5";
        setCurrentValue("none");
        setRemovalDisabled(true);
        break;
      case "none":
        value = systemType === "First Wave" ? "84" : "98.5";
        setCurrentValue("none");
        break;
      default:
        value = input;
        setCurrentValue(value);
    }
    if (value != removalValue.primary && value != "none") {
      const primary = removalValue.primary;
      setRemovalValue({ primary: value, secondary: primary });
    }
  }

  useEffect(async () => {
    document.title = "Assessment Reporting";
    setAccountType(await getUserPermissionFromCache(currentUser.email));
  }, [removalDisabled, currentValue, removalValue]);

  function convertToPpm(constituentValue, unit) {
    let conversionNumber = 0;
    switch (unit) {
      case "%":
        conversionNumber = 10000;
        break;
      case "lbs/1000 gal":
        conversionNumber = 119.670188959;
        break;
      default:
        conversionNumber = 1;
    }
    return conversionNumber * constituentValue;
  }

  function changeFormVal(key, value, unit) {
    let obj = {};
    obj[key] = value >= 0 ? convertToPpm(value, unit) : 0;
    setFormVal({ ...formVal, ...obj });
  }

  // Refs
  const nameRef = useRef();
  const opDays = useRef();
  const opHours = useRef();
  const annualFlow = useRef();
  const totalSolids = useRef();
  const suspendedSolids = useRef();
  const ammoniumNitrogen = useRef();
  const tkn = useRef();
  const potassium = useRef();
  const phosphorus = useRef();
  const volatileSolids = useRef();
  const calcium = useRef();
  const magnesium = useRef();
  const sodium = useRef();
  const iron = useRef();
  const manganese = useRef();
  const copper = useRef();
  const zinc = useRef();
  const sulfur = useRef();
  const roRecovery = useRef();
  const ro2Recovery = useRef();
  const manure = useRef();
  const forcedPolymerDose = useRef();

  // carbon model refs
  const regionRef = useRef();
  const livestockTypeRef = useRef();
  const numLivestockRef = useRef();
  const dmiRef = useRef();
  const manureSeparationRef = useRef();
  const anerobicTreatmentRef = useRef();
  const baselineTreatmentRef = useRef();
  const sysPowerRef = useRef();
  const electricityRegionRef = useRef();
  const digestateKgRef = useRef();

  const [screwPressRecycling, setScrewPressRecycling] = useState(true);
  const [screwPressRemoval, setScrewPressRemoval] = useState(false);
  const [preferredUnits, setPreferredUnits] = useState("Imperial");
  const [reportType, setReportType] = useState("");

  function getCurrentFormValues() {

    const additional_constituents = {};
    Object.keys(additionalConstituents).forEach((c) => {
      additional_constituents[c] = Number(additionalConstituents[c].concentration)
    });

    const additional_constituents_ro_rejections = {};
    Object.keys(additionalConstituents).forEach((c) => {
      additional_constituents_ro_rejections[c] = Number(additionalConstituents[c].roRejection)
    });

    return {
      sysType: systemType,
      name: encodeURIComponent(nameRef.current.value),
      days: opDays.current.value,
      hours: opHours.current.value,
      flow: annualFlow.current.value,
      ts: totalSolids.current.value,
      ss: suspendedSolids.current.value,
      ammNit: ammoniumNitrogen.current.value,
      tkNit: tkn.current.value,
      pot: potassium.current.value,
      phos: phosphorus.current.value,
      vs: volatileSolids.current.value,
      sp: screwPressRecycling,
      spRemoved: screwPressRemoval,
      report_units: preferredUnits,
      roRecovery: roRecovery.current.value,
      secondRO: secondRO,
      spOutput20Percent: spOutput20Percent,
      ro2Recovery: ro2Recovery.current.value,
      manure: manure.current.value,
      additional_constituents,
      additional_constituents_ro_rejections,
      override_polymer_dose: forcePolymer,
      polymer_dose: forcedPolymerDose?.current?.value || 0
    };
  }

  const onEngAssessmentReportGenerate = () => {
    const formValues = getCurrentFormValues();

    const TDS = formValues.ts - formValues.ss;
    if(TDS > 20_000) {
      alert('The current TDS concentration is on the high end for this model. An RO projection should be run to verify the design and recovery. The TDS and constituent values need to be determined by Engineering.')
    }

    if(TDS < 5_000) {
      alert('The current TDS concentration is on the low end for this model. An RO projection should be run to verify the design and recovery. The TDS and constituent values need to be determined by Engineering.')
    }

    const carbonValues = {
      runCarbonModel: includeCarbonModel,
      region: regionRef?.current?.value || null,
      livestockType: livestockTypeRef?.current?.value || null,
      numLivestock: numLivestockRef?.current?.value || null,
      dryMatterIntake: dmiRef?.current?.value || null,
      manureSeparation: manureSeparationRef?.current?.value || null,
      separationEfficiency: removalValue?.primary || null,
      anerobicTreatment: anerobicTreatmentRef?.current?.value || null,
      baselineTreatment: baselineTreatmentRef?.current?.value || null,
      systemPower: sysPowerRef?.current?.value || null,
      operationTime: hours || null,
      electricityRegion: electricityRegionRef?.current?.value || null,
      digestateKg: digestateKgRef?.current?.value || null
    };

    // Redirect to engineering assesment report with given form values.
    const url = `/eng-assessment-h/${formValues.sysType}/${removalValue.primary}/${formValues.name}/${formValues.days}/${formValues.hours}/${formVal.tflow}/${formVal.ts}/${formVal.ss}/${formVal.aNitrogen}/${formVal.tkn}/${formVal.potassium}/${formVal.phosphorus}/${formVal.volatile}/${formValues.sp}/${formValues.roRecovery}/${formValues.spRemoved}/${formValues.report_units}/${formValues.manure}/${isProject}/${carbonValues.runCarbonModel}/${carbonValues.region}/${carbonValues.livestockType}/${carbonValues.numLivestock}/${carbonValues.dryMatterIntake}/${carbonValues.manureSeparation}/${carbonValues.separationEfficiency}/${carbonValues.anerobicTreatment}/${carbonValues.baselineTreatment}/${carbonValues.systemPower}/${carbonValues.operationTime}/${carbonValues.electricityRegion}/${formValues.secondRO}/${formValues.ro2Recovery}/${formValues.spOutput20Percent}/${JSON.stringify(formValues.additional_constituents)}/${JSON.stringify(formValues.additional_constituents_ro_rejections)}/${formValues.override_polymer_dose}/${formValues.polymer_dose}/${carbonValues.digestateKg}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onClientAssessmentReportGenerate = (event) => {
    const formValues = getCurrentFormValues();

    const carbonValues = {
      runCarbonModel: includeCarbonModel,
      region: regionRef?.current?.value || null,
      livestockType: livestockTypeRef?.current?.value || null,
      numLivestock: numLivestockRef?.current?.value || null,
      dryMatterIntake: dmiRef?.current?.value || null,
      manureSeparation: manureSeparationRef?.current?.value || null,
      separationEfficiency: removalValue?.primary || null,
      anerobicTreatment: anerobicTreatmentRef?.current?.value || null,
      baselineTreatment: baselineTreatmentRef?.current?.value || null,
      systemPower: sysPowerRef?.current?.value || null,
      operationTime: hours || null,
      electricityRegion: electricityRegionRef?.current?.value || null,
      digestateKg: digestateKgRef?.current?.value || null
    };

    // Redirect to client assesment report with given form values.
    const url = `/client-assessment-report/${formValues.sysType}/${removalValue.primary}/${formValues.name}/${formValues.days}/${formValues.hours}/${formVal.tflow}/${formVal.ts}/${formVal.ss}/${formVal.aNitrogen}/${formVal.tkn}/${formVal.potassium}/${formVal.phosphorus}/${formVal.volatile}/${formValues.sp}/${formValues.roRecovery}/${formValues.spRemoved}/${formValues.report_units}/${formValues.manure}/${isProject}/${carbonValues.runCarbonModel}/${carbonValues.region}/${carbonValues.livestockType}/${carbonValues.numLivestock}/${carbonValues.dryMatterIntake}/${carbonValues.manureSeparation}/${carbonValues.separationEfficiency}/${carbonValues.anerobicTreatment}/${carbonValues.baselineTreatment}/${carbonValues.systemPower}/${carbonValues.operationTime}/${carbonValues.electricityRegion}/${formValues.secondRO}/${formValues.ro2Recovery}/${formValues.spOutput20Percent}/${JSON.stringify(formValues.additional_constituents)}/${JSON.stringify(formValues.additional_constituents_ro_rejections)}/${formValues.override_polymer_dose}/${formValues.polymer_dose}/${carbonValues.digestateKg}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Validate form data
    const formValues = getCurrentFormValues();
    const isValid = validateAssessmentInputs({...formVal, ...formValues});
    if (!isValid) {
      return;
    }

    if (reportType == "eng") {
      onEngAssessmentReportGenerate();
    } else if (reportType == "client") {
      onClientAssessmentReportGenerate(e);
    }
  };

  const blueBorderStyle = {
    border: '0.5px dotted #005E7D',
  };

  const [additionalConstituents, setAdditionalConstituents] = useState({});
  const [showEditConstituents, setShowEditConstituents] = useState(false);

  const newConsituentName = useRef();
  const newConstituentConcentration = useRef();
  const newConstituentRORejection = useRef();

  const addConstituent = (e) => {
    e.preventDefault();
    const name = newConsituentName.current.value;
    const concentration = newConstituentConcentration.current.value;
    const roRejection = newConstituentRORejection.current.value;

    setAdditionalConstituents({
      ...additionalConstituents,
      [name]: {
        concentration,
        roRejection
      }
    });

    newConsituentName.current.value = null;
    newConstituentConcentration.current.value = null;
    newConstituentRORejection.current.value = null;

    setShowAddConstituentModal(false);
  };


  return (
    <>
      <Modal show={showEditConstituents} size="xl" style={{marginTop: '6%'}}>
        <Modal.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Edit Constituents</Modal.Header>
        <Modal.Body>
          <Row>
            {
              Object.keys(additionalConstituents).map((c) => {
                return <Col md={4}><Card style={{ marginBottom: '2%' }}>
                  <Card.Header style={{ backgroundColor: '#005E7D', color: 'white', fontWeight: 'bold' }}>
                    <Row>
                      <Col>
                        {c}
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <label>Concentration (ppm)</label>
                    <Form.Control type="number" disabled value={additionalConstituents[c].concentration} />
                    <br />
                    <label>RO Rejection (%)</label>
                    <Form.Control type="number" disabled value={additionalConstituents[c].roRejection} />
                    <br />
                    <Button
                          variant="outline-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            const copy = additionalConstituents;
                            delete additionalConstituents[c];
                            setAdditionalConstituents(copy);
                            setShowEditConstituents(false);
                          }}
                        ><MdDeleteOutline size={22} /></Button>
                  </Card.Body>
                </Card>
                </Col>
              })
            }
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowEditConstituents(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAddConstituentModal} size="lg" style={{marginTop: '6%'}} >
        <Modal.Header style={{backgroundColor: '#005E7D', color: 'white'}}>Add Constituent</Modal.Header>
        <Modal.Body>
          <Form onSubmit={addConstituent}>
            <label>Constituent Name</label>
            <Form.Control type="text" required ref={newConsituentName}></Form.Control>
            <label style={{ marginTop: '2%' }}>Concentration (ppm)</label>
            <Form.Control type="number" required min={0} max={25000} ref={newConstituentConcentration}></Form.Control>
            <label style={{ marginTop: '2%' }}>RO Rejection (%)</label>
            <Form.Control type="number" required min={0} max={100} ref={newConstituentRORejection}></Form.Control>
            <Button type="submit" variant="outline-success" style={{ marginTop: '4%' }}>Add</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { setShowAddConstituentModal(false) }} variant='outline-secondary'>Close</Button>
        </Modal.Footer>
      </Modal>
      <div style={{ marginLeft: '1.2%', marginRight: '1.2%', marginTop: '0.7%', marginBottom: '1.2%' }}>
      <Row style={{ paddingTop: '0.5%', color: '#005E7D', marginBottom: '1%' }}>
          <Col>
            <h4>New Assessment Report</h4>
          </Col>
        </Row>
          <Tabs>
            <Tab title="General Assessment" eventKey="General Assessment" style={{color: '#005E7D !important'}}>
              <Form onSubmit={onSubmit}>
                <>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-12">
                      <label style={{ fontWeight: '550', color: '#005E7D', fontSize: '16px' }}>Report Name</label>
                      <Form.Control type="text" style={blueBorderStyle} ref={nameRef} required />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: '1.3%' }}>
                    <label style={{ fontWeight: '550' }}>
                      <p style={{ color: '#005E7D', fontSize: '18px' }}>System Operation Parameters</p>
                    </label>

                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>System Type</label>
                      <Form.Control
                        as="select"
                        defaultValue="First Wave"
                        style={blueBorderStyle}
                        required
                        onChange={(e) => {
                          setSystemType(e.target.value);
                          if(e.target.value == "First Wave") {
                            setSecondRO(false);
                          }
                          changeRemovalValue(e.target.value);
                        }}
                      >
                        <option>First Wave</option>
                        <option>Full System</option>
                      </Form.Control>
                    </div>

                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>Operating Days / Year</label>
                      <Form.Control
                        type="number"
                        style={blueBorderStyle}
                        defaultValue={Number(365)}
                        ref={opDays}
                        required
                      />
                    </div>

                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        Operating Hours / Day (
                        {systemType == "First Wave" ? "1-24" : "1-22"})
                      </label>
                      <Form.Control
                        type="number"
                        style={blueBorderStyle}
                        defaultValue={Number(24)}
                        onChange={(e) => { setHours(e.target.value) }}
                        ref={opHours}
                        required
                      />
                    </div>
                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        Annual Manure Flow (
                        {massFlowUnits["Annual Flow"][preferredUnits]})
                      </label>
                      <Form.Control
                        type="number"
                        style={blueBorderStyle}
                        required
                        ref={annualFlow}
                        onChange={(e) =>
                          changeFormVal("tflow", e.target.value)
                        }
                      />
                    </div>

                  </div>
                  <div className="row">


                    <div className="col-md-3    ">
                      <br />
                      <label style={{ fontWeight: '550' }}>Screw Press Handling</label>
                      <Form.Control
                        style={blueBorderStyle}
                        as="select"
                        defaultValue="Recycle to front"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val == "Recycle to front") {
                            setScrewPressRecycling(true);
                            setScrewPressRemoval(false);
                          } else if (val == "Add to MS. Filtrate") {
                            setScrewPressRecycling(false);
                            setScrewPressRemoval(false);
                          } else if (val == "Remove Screw Press") {
                            setScrewPressRecycling(false);
                            setScrewPressRemoval(true);
                          }
                        }}
                        required
                      >
                        <option>Recycle to front</option>
                        <option>Add to MS. Filtrate</option>
                        <option>Remove Screw Press</option>
                      </Form.Control>
                    </div>

                    <div className="col-md-3    ">
                      <br />
                      <label style={{ fontWeight: '550' }}>Preferred Units </label>
                      <Form.Control
                        style={blueBorderStyle}
                        as="select"
                        defaultValue="Imperial"
                        onChange={(e) => {
                          setPreferredUnits(e.target.value);
                        }}
                        required
                      >
                        <option>Imperial</option>
                        <option>Metric</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-3">
                      <br />
                      <label style={{ fontWeight: '550' }}>Feed Type </label>
                      <Form.Control
                        style={blueBorderStyle}
                        as="select"
                        defaultValue="Manure"
                        ref={manure}
                        required
                      >
                        <option>Manure</option>
                        <option>Digestate</option>
                      </Form.Control>
                    </div>

                    <div className="col-md-3 ">
                      <br />
                      <label style={{ fontWeight: '550' }}>
                        Removal % (Default Value is{" "}
                        {systemType === "First Wave" ? "84" : "98.5"} )
                      </label>
                      <Form.Control
                        as="select"
                        disabled={removalDisabled || forcePolymer}
                        style={{
                          ...blueBorderStyle,
                        }}
                        onChange={(e) => changeRemovalValue(e.target.value)}
                        value={`${currentValue}`}
                      >
                        <option value="none"> </option>
                        <option value="35">35 </option>
                        <option value="50">50 </option>
                        <option value="65">65 </option>
                        <option value="73">73 </option>
                        <option value="78">78 </option>
                        <option value="84">84 </option>
                        <option value="98.5">98.5 </option>
                      </Form.Control>
                    </div>
                  </div>
                </>

                <>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        Force SP Solids Output to be 20% TSS
                      </label>
                      <Form.Control
                        as="select"
                        style={blueBorderStyle}
                        disabled={forcePolymer}
                        onChange={(e) => setSpOutput20Percent(e.target.value === 'true')}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        RO Recovery
                      </label>
                      <Form.Control
                        type="number"
                        defaultValue={73}
                        disabled={systemType === 'First Wave'}
                        style={blueBorderStyle}
                        required
                        ref={roRecovery}
                      />
                    </div>
                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        Add Second RO
                      </label>
                      <Form.Control
                        as="select"
                        style={blueBorderStyle}
                        disabled={systemType === 'First Wave'}
                        onChange={(e) => setSecondRO(e.target.value === "true")}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Form.Control>
                    </div>
                    <div className="col-md-3">
                      <label style={{ fontWeight: '550' }}>
                        RO #2 Recovery
                      </label>
                      <Form.Control
                        type="number"
                        defaultValue={73}
                        style={blueBorderStyle}
                        disabled={!secondRO}
                      required
                      ref={ro2Recovery}
                    />
                  </div>
                  
                  <div className="col-md-3 mt-4">
                    <label style={{ fontWeight: '550' }}>
                      Force Polymer Dose
                    </label>
                    <Form.Control
                      as="select"
                      style={blueBorderStyle}
                      disabled={spOutput20Percent}
                      onChange={(e) => {
                        setForcePolymer(e.target.value === 'true')
                      }}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Control>
                    {forcePolymer && <div
                      className="text-center border rounded p-2"
                      style={{ background: "rgba(0, 0, 0, 0.03)" }}
                    >
                      This option will make the simulation calculate
                      the appropriate solids removal % for your specified dose.
                    </div>}
                    {
                      spOutput20Percent &&  <div
                      className="text-center border rounded p-2"
                      style={{ background: "rgba(0, 0, 0, 0.03)" }}
                    >
                      This option can only be turned on if Force SP Output to 20% is turned off.
                    </div>
                    }
                  </div>

                  {forcePolymer && <div className="col-md-3 mt-4">
                    <label style={{ fontWeight: '550' }}>
                      Forced Polymer Dose (ppm)
                    </label>
                    <Form.Control
                      type="number"
                      min={0}
                      max={1000}
                      defaultValue={50}
                      style={blueBorderStyle}
                      required
                      ref={forcedPolymerDose}
                    />
                    <div
                      className="text-center border rounded p-2"
                      style={{ background: "rgba(0, 0, 0, 0.03)" }}
                    >
                      If the specified polymer dose yields over 100% removal, it will be scaled down by the simulation.
                    </div>
                  </div>}
                </div>
                </>

                <Row style={{ marginTop: '1.0%' }}>
                  <Col md={6}>
                    <label style={{ fontWeight: '550' }}>
                      {" "}
                      <p style={{ color: '#005E7D', fontSize: '18px' }}>Manure Constituent Values</p>
                    </label>
                  </Col>

                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Button variant="outline-success" onClick={() => { setShowAddConstituentModal(true) }} style={{ marginRight: '2%' }}>
                      <MdAddCircleOutline size={22} />
                    </Button>
                    <Button variant="outline-dark" onClick={() => { setShowEditConstituents(true) }} disabled={!Object.keys(additionalConstituents).length}>
                      <FaEdit size={22} />
                    </Button>
                  </Col>
                </Row>
                <>
                  <div className="row">
                    <AssessmentReportingInputForm
                      title={`Total Solids`}
                      changeFormVal={changeFormVal}
                      referenceTo={totalSolids}
                      formValName="ts"
                    />
                    <AssessmentReportingInputForm
                      title={`Suspended Solids`}
                      changeFormVal={changeFormVal}
                      referenceTo={suspendedSolids}
                      formValName="ss"
                    />
                    <div className="col-md-2">
                      <label style={{ fontWeight: '550' }}>Volatile Solids (%)</label>
                      <Form.Control
                        type="number"
                        required
                        ref={volatileSolids}
                        style={blueBorderStyle}
                        onChange={(e) =>
                          changeFormVal("volatile", e.target.value)
                        }
                      />
                    </div>
                    <AssessmentReportingInputForm
                      title={`Ammonium Nitrogen`}
                      changeFormVal={changeFormVal}
                      referenceTo={ammoniumNitrogen}
                      formValName="aNitrogen"
                    />
                    <AssessmentReportingInputForm
                      title={`TKN`}
                      changeFormVal={changeFormVal}
                      referenceTo={tkn}
                      formValName="tkn"
                    />
                    <AssessmentReportingInputForm
                      title={`Potassium`}
                      changeFormVal={changeFormVal}
                      referenceTo={potassium}
                      formValName="potassium"
                    />
                    <AssessmentReportingInputForm
                      title={`Phosphorus`}
                      changeFormVal={changeFormVal}
                      referenceTo={phosphorus}
                      formValName="phosphorus"
                    />
                    {additionalConstituents && Object.keys(additionalConstituents).map((c) => {
                      return <Col md={2}>
                        <label style={{ fontWeight: '550' }}>{c}</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="number"
                            value={additionalConstituents[c].concentration}
                            style={blueBorderStyle}
                            required
                            min={0}
                            onChange={(e) => {
                              setAdditionalConstituents({
                                ...additionalConstituents,
                                [c]: {
                                  ...additionalConstituents[c],
                                  concentration: e.target.value
                                }
                              })
                            }}
                          />
                          <DropdownButton
                            variant="outline-secondary"
                            title={'ppm'}
                            id="input-group-dropdown-2"
                            align="end"
                          >
                            <Dropdown.Item>ppm</Dropdown.Item>
                          </DropdownButton>
                        </InputGroup>
                      </Col>
                    })}
                  </div>
                  <br />
                </>
                <>
                  <br />
                  <div className="row">
                    <div className="col-md-6" style={{ textAlign: "left" }}>
                      <Button
                        variant="outline-dark"
                        type="submit"
                        onClick={() => {
                          setReportType("client");
                        }}
                      >
                        Generate Client Report
                      </Button>
                      </div>
                      <div className="col-md-6" style={{ textAlign: "right" }}>
                      {!isPermissionTypeDistributor(accountType) && (
                        <Button
                          variant="outline-success"
                          type="submit"
                          onClick={() => {
                            setReportType("eng");
                          }}
                        >
                          Generate Engineering Report
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              </Form>
            </Tab>
            <Tab title="Carbon Emissions Modelling" eventKey="Carbon Emissions Modelling" >
              <>
                <div className="row mt-3">
                  <div>
                    <Switch
                      onChange={() => { setIncludeCarbonModel(!includeCarbonModel) }}
                      checked={includeCarbonModel}
                      className="react-switch"
                      id="normal-switch"
                    />
                    <br />
                    <label style={{ fontWeight: '550', marginTop: '0.4%' }}>
                      {" "}
                      <p style={{ color: '#005E7D', fontSize: '22px' }}>Model Parameters</p>
                    </label>
                  </div>
                </div>
                <div>
                  <CarbonModellingForm removalValue={removalValue} operatingHours={hours} formRefs={{
                    regionRef,
                    livestockTypeRef,
                    numLivestockRef,
                    dmiRef,
                    manureSeparationRef,
                    anerobicTreatmentRef,
                    baselineTreatmentRef,
                    sysPowerRef,
                    electricityRegionRef,
                    digestateKgRef
                  }} />
                </div>
              </>
            </Tab>
          </Tabs>

      </div>
    </>
  );
};

export default withRouter(AssessmentReportingPage);
