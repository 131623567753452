const { v4: uuidv4 } = require('uuid');
const moment = require('moment');

export const reportDescriptionExample = {
    date: moment().format("YYYY-MM-DD"),
    name: "",
    jobNo: 0,
    jobType: "",
    client: "",
    contact: "",
    poBox: "",
    phoneNo: "",
    workDescription: "",
    siteAddress: "",
    completed: true,
}

export const workCarriedOutExample = { description: "" }

export const taskToBeCompletedExample = { description: "" }

export const expensesListExample = {
    expenseName: "",
    paidUsingCompanyCard: true,
    visa: true,
    cash: false,
    inventory: 0,
    description: "",
    quantity: 0,
    amount: 0,
    currencyType: "USD",
    expenseTimesheet: null
};

export const subConstractorsListExample = {
    visa: true,
    cash: false,
    PO: "",
    description: "",
    quantity: 0,
    amount: 0,
    currencyType: "USD",
};

export const fieldMaterialsListExample = {
    visa: true,
    cash: false,
    inventory: 0,
    description: "",
    quantity: 0,
    amount: 0,
    currencyType: "USD",
};

export const timeSpentExample = {
    timeStarted: moment().format("HH:mm:ss"),
    timeEnded: moment().format("HH:mm:ss"),
    totalHours: "0:00:00"
}
export const newReportExample = {
    reportName: "",
    reportDescription: reportDescriptionExample,
    workCarriedOut: [{ ...workCarriedOutExample, id: uuidv4() }],
    taskToBeCompleted: [{ ...taskToBeCompletedExample, id: uuidv4() }],
    workExpensesList: [],
    subContractorsList: [],
    fieldMaterialsList: [],
    timeSpent: { ...timeSpentExample },
    remarks: ""
}

export const newReportExampleCopy = {
    reportName: "",
    reportDescription: reportDescriptionExample,
    workCarriedOut: [{ ...workCarriedOutExample, id: uuidv4() }],
    taskToBeCompleted: [{ ...taskToBeCompletedExample, id: uuidv4() }],
    workExpensesList: [],
    subContractorsList: [],
    fieldMaterialsList: [],
    timeSpent: { ...timeSpentExample },
    remarks: ""
}