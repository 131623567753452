import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getProperTime } from "../../fieldToolsFunctions";
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect } from "react";

export const DisplayFieldExpenseTimesheetValue = ({
  timesheetList,
  setExpenseReceipt,
  expenseReceipt,
  cancelButtonClicked,
}) => {
  const [editTimesheet, setEditTimesheet] = useState(false);
  useEffect(() => {
    setEditTimesheet(false);
  }, [cancelButtonClicked]);
  return (
    <Row className="border-bottom py-1 d-flex align-items-center">
      <Col md={4}>Timesheet:</Col>
      {!editTimesheet ? (
        <>
          <Col md={7}>
            {timesheetList?.map((timesheet) =>
              timesheet._id == expenseReceipt.expenseTimesheet
                ? timesheet.reportName +
                  " created at " +
                  getProperTime(timesheet.createdAt)
                : null
            )}
          </Col>
          <Col md={1}>
            <div>
              <AiOutlineEdit onClick={() => setEditTimesheet(true)} />
            </div>
          </Col>
        </>
      ) : (
        <Col>
          <select
            class="form-select"
            value={expenseReceipt.expenseTimesheet}
            size="md"
            onChange={(e) =>
              setExpenseReceipt((newExpense) => ({
                ...newExpense,
                expenseTimesheet: e.target.value,
              }))
            }
            required
          >
            {timesheetList.map((timesheet) => (
              <option value={timesheet._id}>
                {timesheet.reportName}

                {timesheet.reportName
                  ? " created at " + getProperTime(timesheet.createdAt)
                  : null}
              </option>
            ))}
          </select>
        </Col>
      )}
    </Row>
  );
};
