import http from "./http";

const getAllJobs = () => {
  return http.get("/jobs")
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
      return err;
    });
};

export { getAllJobs };
