import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ResultModal = ({ title, content, titleColor, showErrorModal, handleClose }) => {
  return (
    <Modal show={showErrorModal} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title style={{color: titleColor}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResultModal;