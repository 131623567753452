/**
 * YearlyOutputs.jsx
 * ------------------
 * This component is responsible for rendering the yearly outputs section of the client and engineering assessment reports.
 *
 * Author: Oscar Jaimes
 * Last Updated: 25/11/2021
 */

import React from "react";

import { Table } from "react-bootstrap";

export default function YearlyOutputs(props) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div className={props.type == "eng" ? 'col-md-6' : 'col-md-12'} style={{ paddingTop: "2%" }}>
        <div className="row">
          <div className="col-md-12">
            <Table className="table">
              {props.units == "Imperial" ? (
                <tbody>
                  <th style={{ textAlign: "left", color: 'white', backgroundColor: '#005E7D' }}>{props.language?.yearly_outputs || 'Yearly Outputs'}</th>
                  <th style={{ textAlign: "left", color: 'white', backgroundColor: '#005E7D' }}>Value</th>
                  <tr>
                    <td className="header">{props.language?.annual_liquid_nutrient || 'Annual Liquid Nutrient'}</td>
                    <td id="annual-liquid-nutrient">
                      {numberWithCommas(props.yearlyTotals["Nutrient Liquids"])} Gallons
                    </td>
                  </tr>
                  <tr>
                    <td className="header">{props.language?.annual_nutrient_solids || 'Annual Nutrient Solids'}</td>
                    <td id="annual-nutrient-solids">
                      {numberWithCommas(
                        props.yearlyTotals["LWR Solids"]["tons"].toFixed()
                      )} Tons
                    </td>
                  </tr>
                  {!props.isFw ? (
                    <tr>
                      <td className="header">{props.language?.annual_clean_water || 'Annual Clean Water'}</td>
                      <td id="annual-clean-water">
                        {numberWithCommas(props.yearlyTotals["Clean Water"])} Gallons
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className="header">{props.language?.annual_liquid_nutrient || 'Annual Liquid Nutrient'}</td>
                    <td id="annual-liquid-nutrient">
                      {numberWithCommas(
                        props.yearlyTotals["Metric"]["Nutrient Liquids"]
                      )} m^3
                    </td>
                  </tr>
                  <tr>
                    <td className="header">{props.language?.annual_nutrient_solids || 'Annual Nutrient Solids'}</td>
                    <td id="annual-nutrient-solids">
                      {numberWithCommas(
                        props.yearlyTotals["Metric"]["LWR Solids"].toFixed()
                      )} Tonnes
                    </td>
                  </tr>
                  {!props.isFw ? (
                    <tr>
                      <td className="header">{props.language?.annual_clean_water || 'Annual Clean Water'}</td>
                      <td id="annual-clean-water">
                        {numberWithCommas(
                          props.yearlyTotals["Metric"]["Clean Water"]
                        )} m^3
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </div>

      {props.type == "eng" && (
      <div className="col-md-6" style={{ paddingTop: "2%" }}>
          <Table className="table">
            <th style={{ textAlign: "left", color: 'white', backgroundColor: '#005E7D' }}>Chemical Costs</th>
            <th style={{ textAlign: "left", color: 'white', backgroundColor: '#005E7D' }}>Value</th>
            <tbody>
              <tr>
                <td className="header">
                  gr&#333; 17 Cost (
                  {props.yearlyTotals["Chemical costs"]["LWR 17"]["Units"]})
                </td>
                <td>
                  {props.yearlyTotals["Chemical costs"]["LWR 17"][
                    "Cost"
                  ].toFixed(5)}
                </td>
              </tr>
              <tr>
                <td className="header">
                  Annual gr&#333; 17 Cost (
                  {
                    props.yearlyTotals["Chemical costs"]["Annual LWR 17"][
                    "Units"
                    ]
                  }
                  )
                </td>
                <td>
                  {numberWithCommas(
                    props.yearlyTotals["Chemical costs"]["Annual LWR 17"][
                      "Cost"
                    ].toFixed(0)
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
      </div>
        )}

    </>
  );
}
