import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Spinner, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";

// api
import { getAllSystemSites } from "../../api/systemSites";
import { csvDataByDate, getAvailableFields } from "../../api/sensordata";

const CsvExport = ({ linkingId }) => {
  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0].replaceAll("-", "/"));
  const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0].replaceAll("-", "/"));
  const [csvReady, setCsvReady] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState("");
  const [fieldSelectorMap, setFieldSelectorMap] = useState({});
  const [fieldSelectorOptions, setFieldSelectorOptions] = useState([]);
  const [systemData, setSystemData] = useState([]);
  const [fetching, setFetching] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Getting linking Ids
  useEffect(() => {
    document.title = "CSV Export";

    getAllSystemSites(linkingId).then((data) => {
      setSystemData(data);
    });
  }, []);

  // Showing the different fields in a system
  const handleSystemChange = (event) => {
    setLoading(true);
    const selectedSystemId = event.target.value;
    setSelectedSystem(selectedSystemId);

    getAvailableFields(selectedSystemId).then((fields) => {
      const defaultMap = fields.reduce(
        (map, field) => ({
          ...map,
          [field]: false,
        }),
        {}
      );
      setFieldSelectorMap(defaultMap);
      setFieldSelectorOptions(fields);
      setLoading(false);
    });
  };

  const fetchData = async () => {
    setCsvReady(false);
    setFetching(true);

    // Get selected fields
    const selectedFields = Object.keys(fieldSelectorMap).filter(
      (key) => fieldSelectorMap[key] == true
    );

    // Pull CSV data from
    const res = await csvDataByDate(
      selectedSystem,
      startDate.replaceAll("-", "/"),
      endDate.replaceAll("-", "/"),
      selectedFields
    );

    if (res.status != 200) {
      alert("Error fetching data");
      setFetching(false);
    } else {
      let data = await res.data;
      setCsvData(data);
      setCsvReady(true);
      setFetching(false);
    }
  };

  const ExportFieldSelectors = () => {
    if (!Array.isArray(fieldSelectorOptions)) {
      return null;
    }

    let optionComponents = fieldSelectorOptions.map((field) => {
      const handleClick = () => {
        // If selected field is 'all', turn all options to 1
        if (field === "all") {
          const allSelected = !fieldSelectorMap["all"];
          const newMap = fieldSelectorOptions.reduce(
            (map, field) => ({
              ...map,
              [field]: allSelected,
            }),
            {}
          );
          setFieldSelectorMap(newMap);
        } else {
          // Toggle select/unselect
          setFieldSelectorMap((prevMap) => {
            return {
              ...prevMap,
              [field]: !prevMap[field],
              all: false,
            };
          });
        }
      };

      return (
        <Form.Check
          key={field}
          type="checkbox"
          label={field}
          checked={fieldSelectorMap[field]}
          onChange={handleClick}   
        />
      );
    });

    const columnRatio = optionComponents.length / 3;
    return (
      <Row>
        <Col>{optionComponents.slice(0, columnRatio)}</Col>
        <Col>{optionComponents.slice(columnRatio, 2 * columnRatio)}</Col>
        <Col>{optionComponents.slice(2 * columnRatio, optionComponents.length)}</Col>
      </Row>
    );
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <Row style={{ paddingTop: "0%", margin: "10px" }}>
        <Col md={12}>
          <Card>
            <Card.Header style={{ backgroundColor: "#005E7D", color: "white" }}>
              CSV Data Export
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Export CSV sensor data from the Mongo Database
              </Card.Text>

              <Form.Group
                controlId="formSystemDropdown"
                style={{ margin: "15px" }}
              >
                <Form.Label>Select System</Form.Label>
                <Form.Control as="select" disabled={loading} onChange={handleSystemChange}>
                  {systemData.map((system) => (
                    <option key={system.linking_id} value={system.linking_id}>
                      {system.systemName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group
                controlId="formFieldOptions"
                style={{ margin: "15px" }}
              >
                <Form.Label>Select Target Fields</Form.Label>
                {!loading && <ExportFieldSelectors />}
                {loading && <Spinner style={{ display: "flex" }} />}
              </Form.Group>

              <Form style={{ display: "flex", gap: "20%" }}>
                <Form.Group
                  controlId="formStartDate"
                  style={{ width: "10em", margin: "15px", flex: "1" }}
                >
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    disabled={csvReady}
                    onChange={(event) => setStartDate(event.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group
                  controlId="fromEndDate"
                  style={{ width: "10em", margin: " 15px", flex: "1" }}
                >
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    disabled={csvReady}
                    onChange={(event) => setEndDate(event.target.value)}
                    required
                  />
                </Form.Group>
              </Form>

              <Button
                style={{ margin: "15px" }}
                variant="outline-dark"
                onClick={fetchData}
              >
                Fetch CSV Data
              </Button>

              {csvReady && (
                <Modal
                  show={csvReady}
                  onHide={() => setCsvReady(false)}
                  size="lg"
                  style={{ zoom: "85%" }}
                >
                  <Modal.Header>
                    <Modal.Title>Download CSV For {selectedSystem}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CSVLink
                      data={csvData}
                      filename={`${selectedSystem}_sensordata_${startDate}-${endDate}.csv`}
                    >
                      <Button variant="outline-dark">Download CSV</Button>
                    </CSVLink>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setCsvReady(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              <Col md={1}>{fetching && <Spinner animation="border" />}</Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CsvExport;