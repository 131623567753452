import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap/esm";

export const ExistingReportFilterByPerson = ({ allUsers = [], onChange }) => {
  return (
        <select
          onChange={(e) => onChange(e.target.value)}
          class="form-select"
          aria-label="Default select example"
        >
          <option value="all" selected>
            Everyone
          </option>
          {allUsers.map((individual) => (
            <option value={`${individual}`}>{individual}</option>
          ))}
        </select>
  );
};
