/**
 * client-report-translations/ja.js
 * ---------------------------------
 * 
 * Japanese strings for the client assessment report.
 * 
 * Author: Oscar Jaimes
 * Date: March 7, 2023
 */

const translations = {
    languageL :'ja',
    treatment_assessment_report: '処理評価レポート',
    client_assessment: 'クライアント評価',
    metric_units: 'メートル法',
    imperial_units: 'ヤード・ポンド法',
    annual_flow: '年間流量',
    total_solids_ppm: '固形物総量 (ppm)',
    input_data_ppm: '投入データ (ppm)',
    total_suspended_solids: '総浮遊物質量',
    ammonium_nitrogen: 'アンモニア性窒素',
    organic_nitrogen: '有機性窒素',
    potassium: 'カリウム',
    phosphorus: 'リン',
    calcium: 'カルシウム',
    magnesium: 'マグネシウム',
    sodium: 'ナトリウム',
    iron: '鉄',
    sulfur: '硫黄',
    manganese: 'マンガン',
    copper: '銅',
    zinc: '亜鉛',
    other_dissolved_solids: 'その他溶解固形物',
    other: 'その他',
    operating_information: '稼働情報',
    operating_days_per_year: '年間稼働日数',
    operating_hours_per_day: '1日当たり稼働時間',
    nutrient_value: '栄養価',
    process_flow: 'プロセスフロー',
    system_type: 'システムのタイプ',
    full_system: 'Full System',
    first_wave: 'First Wave',
    dollar_per_kg: '$/kg',
    kg_per_min: 'kg/min',
    lb_per_min: 'lb/min',
    nutrient_concentration: '栄養素濃度',
    inputs: '投入',
    system_outputs: 'システム生成量',
    nutrient: '栄養素',
    digestate: '発酵残渣',
    liquid_nutrient: '液体栄養素',
    nutrient_solids: '固形栄養素',
    clean_water: '清浄水',
    kg_per_m3: 'kg/m^3',
    lb_per_1000_gal: 'lb/1000ガロン',
    kg_per_ton: 'kg/英米トン',
    kg_per_tonne: 'kg/トン',
    ppm: 'ppm',
    output_data: '出力データ',
    yearly_outputs: '年間生成量',
    annual_liquid_nutrient: '年間液体栄養素',
    annual_nutrient_solids: '年間固形栄養素',
    annual_clean_water: '年間清浄水',
    volatile_solids: '揮発性固体'
  };
  
  export default translations;