import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";

const LogOutModal = (props) => {
  const { logout } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        style={{ borderRadius: "10px" }}
        variant={props.isDarkMode ? 'outline-light': 'outline-dark'}
        onClick={handleShow}
      >
        Log Out
      </Button>

      <Modal show={show} onHide={handleClose} size="md" style={{zoom: '90%'}}>
        <Modal.Header>
          <Modal.Title>Log Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={logout}>
            Log Out
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogOutModal;
