import React, { useEffect } from "react";
import { useState } from "react";
import { FieldTimeSheet } from "./FieldTimeSheet";
import { newReportExample } from "./NewReportExample";

import { getReport } from "../../../api/fieldTimeReport";
import { useAuth } from "../../../contexts/AuthContext";

// Create a new report component using the newReportExample, and sends it to FieldTimeSheet component
export const NewFieldReport = ({ editMode = false, reportId = null }) => {
  const [newReport, setNewReport] = useState(newReportExample);
  const { email } = useAuth().currentUser;

  useEffect(() => {
    const fetchReportToEdit = async () => {
      const report = await getReport(reportId, email);
      setNewReport(report);
    };
    
    if(editMode){
      fetchReportToEdit();
    }
  }, []);

  return (
    <div>
      <FieldTimeSheet newReport={newReport} setNewReport={setNewReport} editMode={editMode}/>
    </div>
  );
};
