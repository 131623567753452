import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Alert } from "react-bootstrap";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  taskToBeCompletedExample,
  workCarriedOutExample,
} from "../../NewReportExample";
import { ExistingReportInfoDictTableCreator } from "../ExistingTimeReportTableCreator/ExistingReportInfoDictTableCreator";
import { ExistingReportInfoTableCreator } from "../ExistingTimeReportTableCreator/ExistingReportInfoTableCreator";
import { useEffect } from "react";
import { ExistingFieldReportProjectRemarks } from "./ExistingFieldReportProjectRemarks";
import { useRef } from "react";
import "./CanvasStyles.css";
import { v4 as uuidv4 } from "uuid";
import { getProperTime } from "../../../fieldToolsFunctions";
import { ExistingFieldReportExpensesList } from "./ExistingFieldReportExpensesList";
import { approveTimesheet, getReport, singleFieldTimePDF, updateTimeReport } from "../../../../../api/fieldTimeReport";
import { getExpenseImages } from "../../../../../api/fieldExpenseReport";
import { getUserPermissionFromCache, isPermissionTypeFieldAdmin, isPermissionTypeHRAdmin } from "../../../../../utility/permissionFunctions";
export const ExistingFieldReportInfoComponent = ({
  report,
  isReportList,
  setIsReportList,
  timeSheet,
  setTimeSheet,
  cancelChanges,
  setCancelChanges,
  onFinish
}) => {
  
  const [downloadPageId, setdownloadPageId] = useState(["timeReport"]);
  const { currentUser } = useAuth();
  const { email } = currentUser;
  const reportRef = useRef();
  const [imageList, setImageList] = useState({});
  const [loading, setLoading] = useState(true);
  const [updateReport, setUpdateReport] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [userType, setUserType] = useState([]);

  useEffect(async () => {
    setLoading(true);
    let _imageList = {};

    const _downloadPageId = ["timeReport"];
    setdownloadPageId(_downloadPageId);
    let imageDataFinder = timeSheet.workExpensesList.map(
      (expense) =>
        new Promise((resolve, reject) => {
          const expenseName = expense.expenseName;
          getExpenseImages(expenseName)
            .then((result) => {
              _imageList[expenseName] = result.filter(
                (val) => val.imgUrl.split("/")[6] == expenseName
              );
              _imageList[expenseName].map((img) => {
                let newId = uuidv4();
                img.id = newId;
                _downloadPageId.push(newId);
              });
            })
            .then(resolve);
        })
    );

    Promise.all(imageDataFinder).then(() => {
      setImageList(_imageList);
      setdownloadPageId(_downloadPageId);
      setLoading(false);
    });
    setUserType(await getUserPermissionFromCache(email));
  }, [updateReport, timeSheet.workExpensesList]);

  useEffect(() => {}, [loading]);

  const downloadFile = async () => {
    await singleFieldTimePDF(timeSheet._id);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    let _id = timeSheet._id;
    updateTimeReport({ _id, email, updatedReport: timeSheet }).then(
      (result) => {
        setUpdateReport(!updateReport);
        alert("Time Report has been updated");
      }
    );
  };

  const imageBufferToSrc = (imageBufferData) => {
    const buffer = imageBufferData; // e.g., <Buffer 89 50 4e ... >
    const b64 = new Buffer.from(buffer).toString("base64");
    const mimeType = imageBufferData; // e.g., image/png
    return `data:${mimeType};base64,${b64}`;
  };

  const getIsSubmitDisabled = () => {
    return disableSave ? { disabled: true } : {};
  };

  const approve = async () => {
    const res = await approveTimesheet(timeSheet._id, email);
    if(res.status === 200) {
      alert('succesfully approved timesheet');
      const newTimesheet = await getReport(timeSheet._id, timeSheet.email);
      setTimeSheet(newTimesheet);
    }
  };

  return (
    <div>
      <Form onSubmit={(e) => saveChanges(e)}>
        <div className="d-flex flex-row justify-content-between px-2">
          <Button onClick={() => {setIsReportList(!isReportList); onFinish();}} variant="outline-dark">Close</Button>
          {!loading && (
            <Button variant="outline-success" onClick={() => downloadFile()}>
              Download Report
            </Button>
          )}
        </div>

        <div ref={reportRef} id="timeReport" className="mx-2">
          <div className="d-flex w-100 align-items-center flex-column pb-2">
            <h3>{timeSheet.reportName}</h3>
            <div>Created At: {getProperTime(timeSheet.createdAt)}</div>
            <div>Last Updated At: {getProperTime(timeSheet.updatedAt)}</div>
          </div>

          <div className="row m-1">
          <Alert variant={timeSheet.approved ? 'success' : 'warning'} className='mt-3'>
            <Row>
              <div>
                Timesheet Approved: {timeSheet.approved ? 'Yes' : 'No'}
              </div>
              <div>
                {(!timeSheet.approved && (isPermissionTypeHRAdmin(userType) || isPermissionTypeFieldAdmin(userType))) &&
                  <Button variant='outline-dark' onClick={approve}>
                    Approve Timesheet
                  </Button>
                }
                {
                  timeSheet.approved &&
                  <>
                    {`Approved By: ${timeSheet.approvedBy}`}
                  </>
                }
              </div>
            </Row>
          </Alert>
        </div>
          <Row>
            <Col className="text-center" md={6}>
              <ExistingReportInfoTableCreator
                title={"Timesheet Description"}
                subDictName={"reportDescription"}
                setTimeSheet={setTimeSheet}
                timeSheet={timeSheet}
                cancelChanges={cancelChanges}
              />
            </Col>

            <Col className="text-center" md={6}>
              <ExistingFieldReportExpensesList
                timeSheet={timeSheet}
                setTimeSheet={setTimeSheet}
                cancelChanges={cancelChanges}
              />
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="text-center" md={12}>
              <ExistingReportInfoDictTableCreator
                title="Work Carried Out"
                md={12}
                subDictName={"workCarriedOut"}
                setTimeSheet={setTimeSheet}
                timeSheet={timeSheet}
                exampleList={workCarriedOutExample}
                cancelChanges={cancelChanges}
              />
              <br />

              <ExistingReportInfoDictTableCreator
                title="Tasks Yet to be completed on Site & Reccomendations"
                md={12}
                subDictName={"taskToBeCompleted"}
                setTimeSheet={setTimeSheet}
                timeSheet={timeSheet}
                exampleList={taskToBeCompletedExample}
                cancelChanges={cancelChanges}
              />
            </Col>
          </Row>
          <br />
          <ExistingFieldReportProjectRemarks
            cancelChanges={cancelChanges}
            timeSheet={timeSheet}
            setTimeSheet={setTimeSheet}
            report={report}
          />
        </div>

        <br />
        <br />

        <div id="imagesContainer" className="pt-4" style={{ display: "none" }}>
          {Object.keys(imageList).map((imgData) => (
            <Row>
              {imageList[imgData].map((data) => (
                <Col id={data.id} md={12}>
                  <h4 className="d-flex justify-content-center">
                    report name: {imgData}
                  </h4>

                  <img
                    src={imageBufferToSrc(data.fileBlob[0])}
                    style={{
                      width: "100%",
                      height: "800px",
                      cursor: "pointer",
                    }}
                  />
                  <div className="d-flex justify-content-center">
                    {data.fileName}
                  </div>
                  <br />
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Form>
    </div>
  );
};
