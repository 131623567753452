import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";

// api
import { deleteAssessmentReport } from "../../api/reports";
import { MdDeleteOutline } from "react-icons/md";

const ReportDeleteModal = (props) => {
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  const handleClose = () => {
    setErr("");
    setSuccess("");
    props.onEnd();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const onDelete = async () => {
    const id = props.assessment._id;
    const deleteResult = await deleteAssessmentReport(id);
    if (deleteResult.status == 200) {
      setSuccess("Sucesfully deleted assessment from database.");
      setErr("");
    } else {
      setErr("Error deleting assessment from database");
      setSuccess("");
    }
  };

  return (
    <>
      <Button variant="outline-danger" onClick={handleShow}>
        <MdDeleteOutline size={25}/>
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Delete Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Delete Assessment: {props.assessment.name} -{" "}
          {props.assessment.dateCreated} ?
        </Modal.Body>
        {err && <Alert variant="danger">{err}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        <Modal.Footer>
          {!success && (
            <Button variant="outline-danger" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportDeleteModal;
