import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import "../styles/Login.css";

// api
import { resetFirebasePassword, generatePasswordResetToken } from "../api/auth";
import LoginScreenBoiler from "./LoginScreenBoiler";

const PasswordResetRequest = () => {
  const emailRef = useRef();
  const resetTokenRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const history = useHistory();
  const { getUser } = useAuth();

  // states
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [resetTokenSent, setResetTokenSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      let email = emailRef.current.value;

      const generatePasswordResetTokenResult = await generatePasswordResetToken(email);
      if (generatePasswordResetTokenResult == 1) {
        alert(
          "An email with a reset token to reset your password has been sent to your inbox, you should recieve it in the next few minutes. Make sure to check your junk folder."
        );
      } else {
        return;
      }
    } catch (eror) {
      console.log(error);
      setError("Failed to reset password");
    }

    emailRef.current.value = "";
    setLoading(false);
  };

  const resetPasswordApi = async (e) => {
    const strongPwRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    e.preventDefault();

    const email = emailRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const token = resetTokenRef.current.value;
    const newPasswordConfirm = confirmPasswordRef.current.value;

    if (!email) {
      setError("Please ensure you have entered your email in the form above");
      return;
    }

    const doPasswordsMatch = newPassword == newPasswordConfirm;
    if (!doPasswordsMatch) {
      setError("Passwords do not match");
      return;
    }

    const isPassValid = strongPwRegex.test(newPassword);
    if (!isPassValid) {
      setError(
        "Password must contain at least 8 characters, one special symbol, at least 1 lower case and upper case letter, and at least one number"
      );
      return;
    }

    const resetPswdResult = await resetFirebasePassword(
      email,
      newPassword,
      token
    );
    if (resetPswdResult == 1) {
      alert(
        "Your password has been succefully reset. You will now be redirected to the login page."
      );
      history.push("/");
    } else if (resetPswdResult === -2) {
      setError(
        "Invalid Reset Token. Please ensure you have entered the correct token."
      );
    } else {
      setError(
        "Error resetting password. Please contact the development administrator."
      );
    }

    setLoading(false);
  };

  const showResetSection = () => {
    setResetTokenSent(true);
  };

  const backTologin = () => {
    history.push("/");
  };

  return (
    <LoginScreenBoiler>
      <div>
        <Card
          className="border-0"
          style={{ padding: "15%", marginTop: '7%', zoom: '85%' }}
        >
          <Card.Body>
            <Alert variant="primary">
              Please enter your email below to receive a password reset token.
              Click the link in the email to reset your password.
            </Alert>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  required
                />
              </Form.Group>
              <br></br>
              <Button
                disabled={loading}
                className="w-100 mt-2"
                type="submit"
                style={{backgroundColor: '#005E7D'}}
              >
                Request Reset Token
              </Button>
              <Button
                className="w-100 mt-2"
                onClick={backTologin}
                variant="outline-dark"
              >
                Back to login
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </LoginScreenBoiler>
  );
};

export default PasswordResetRequest;
