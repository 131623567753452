import React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormGroupCreator } from "../../../field-tools/TimeReporting/FormGroupCreator";
import { getCurrentDate, getStartDate } from "../../HRFunctions";
import ResultModal from "../../../../components/ResultModal";

export const ExistingHRShopTimesheetDateFilter = ({
  allShopTimesheet,
  setAllFilteredShopTimesheet,
}) => {
  const [filterDateAssigned, setFilterDateAssigned] = useState({
    startDate: getStartDate(getCurrentDate()),
    endDate: getCurrentDate(),
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalTitleColor, setModalTitleColor] = useState("orange");

  useEffect(() => {
    let _reportList = [...allShopTimesheet];
    _reportList = _reportList.filter((report) => {
      let reportDate = new Date(report.date);
      let filterReportStartDate = new Date(filterDateAssigned.startDate);
      filterReportStartDate.setDate(filterReportStartDate.getDate() + 1);
      let filterReportEndDate = new Date(filterDateAssigned.endDate);
      filterReportEndDate.setDate(filterReportEndDate.getDate() + 1);
      return (
        reportDate >= filterReportStartDate && reportDate <= filterReportEndDate
      );
    });
    setAllFilteredShopTimesheet(_reportList);
  }, [filterDateAssigned]);

  const onDateChange = (value, valueType) => {
    const _filterDate = { ...filterDateAssigned };
    if (
      valueType == "startDate" &&
      new Date(_filterDate.endDate) < new Date(value)
    ) {
      setModalTitle("Error");
      setModalContent("Start date cannot be after end date");
      setShowModal(true);
    } else {
      _filterDate[valueType] = value;
      setFilterDateAssigned(_filterDate);
    }
  };

  return (
    <>
    <ResultModal title={modalTitle} content={modalContent} titleColor={modalTitleColor} showErrorModal={showModal} handleClose={() => setShowModal(false)}/>
      <Form>
        <Row className="mx-2 d-flex align-items-center">
          <Col md={2} className="text-center">
            Date Assigned Between
          </Col>
          <Col md={4}>
            <FormGroupCreator
              type="date"
              valueDict={filterDateAssigned}
              valueType="startDate"
              changeValue={onDateChange}
            />
          </Col>
          <Col md={4}>
            <FormGroupCreator
              type="date"
              valueDict={filterDateAssigned}
              valueType="endDate"
              changeValue={onDateChange}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};
