import React from "react";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { exampleJobCode } from "../HRShopTimesheetConstants";

// Creates the job number values
export const HRShopTimesheetCreateJobNumber = ({
  id,
  jobNumber,
  setShopTimesheet,
  shopTimesheet,
  employeeInitial,
}) => {
  // adds new job code to the form
  const addJobCode = () => {
    const newJobCode = {
      id: uuidv4(),
      new: true,
      ...exampleJobCode,
      employeeInitial,
    };
    for (let job of shopTimesheet.jobList) {
      if (id == job.id) {
        job.jobCodeList.push(newJobCode);
      }
    }
    setShopTimesheet({ ...shopTimesheet });
  };

  // changes value of the job code and number
  const changeValue = (value) => {
    shopTimesheet.jobList = shopTimesheet.jobList.map((job) =>
      job.id == id ? { ...job, jobNumber: value } : job
    );
    setShopTimesheet({ ...shopTimesheet });
  };

  // removes job number
  const removeJobNumber = () => {
    shopTimesheet.jobList = shopTimesheet.jobList.filter((job) => job.id != id);
    setShopTimesheet({ ...shopTimesheet });
  };

  return (
    <div className="d-flex justify-content-end w-100 mb-3">
      <div className="w-75 d-flex flex-row align-items-center justify-content-start">
        <div className=" pe-4">Job number: </div>
        <div className="w-75">
          <Form.Group>
            <Form.Control
              type="number"
              placeholder="Enter Job Number"
              value={jobNumber}
              onChange={(e) => changeValue(e.target.value)}
              required
            />
          </Form.Group>
        </div>
      </div>
      <div className="w-25 d-flex justify-content-end">
        <div className="px-2 me-2 ">
          <IoIosAddCircleOutline onClick={addJobCode} />
        </div>
        <div className="pe-4">
          <AiOutlineDelete onClick={removeJobNumber} />
        </div>
      </div>
    </div>
  );
};
