// libs
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaEye, FaTrashAlt } from "react-icons/fa";

// util
import {
  isPermissionTypeHRAdmin,
  isPermissionTypeFieldAdmin
} from "../../../utility/permissionFunctions";

// custom hooks
import usePermission from "../../../hooks/permissions";
import useQuery from "../../../hooks/useQuery";
import { useAuth } from "../../../contexts/AuthContext";

// custon components
import CreateFieldExpenseModal from "../CreateFieldExpenseModal";
import { DisplayFieldExpenseReceipt } from "./ExistingFieldExpenses/DisplayFieldExpenseReceipt";

// api
import { getAllExpense, getExpenseImages } from "../../../api/fieldExpenseReport";

export const FieldExpenses = () => {
  // state
  const [report, setReport] = useState(null);
  const [isReportList, setIsReportList] = useState(true);
  const [images, setImages] = useState([]);

  // custom hooks
  const { email } = useAuth().currentUser;
  const { permission } = usePermission(email);

  // expense data
  const { data: expenses, error, isLoading, refetch: refetchExpenses } = useQuery(
    'field-expenses',
    () => getAllExpense(email),
    null,
  );

  // effects
  useEffect(() => {
    if(!isReportList) {
      const { expenseName } = report;
      getExpenseImages(expenseName).then((result) => {
        setImages(result);
      });

    }
  }, [isReportList]);
  
  // individual report
  if (!isReportList) {
    return <div style={{ margin: '0% 1% 1% 1%' }}>
      <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <DisplayFieldExpenseReceipt
          showReport={report}
          isReportList={isReportList}
          setIsReportList={setIsReportList}
          onFinish={() => refetchExpenses()}
          images={images}
          setImages={() => {}}
          loading={false}
          uploadedImages={[]}
          setUploadedImages={() => {}}
          setCancelButtonClicked={() => {}}
          cancelButtonClicked={false}
        />
      </div>
    </div>
  }

  // report list
  return (
    <div style={{ margin: '0% 1% 1% 1%' }}>
      <Row style={{ marginBottom: '0.5%' }}>
        <Col md={6} style={{ textAlign: 'left' }}>
          <h5 style={{ color: '#005E7D', fontWeight: 'bold' }}>Field Expense Reports
            {(isPermissionTypeFieldAdmin(permission) || isPermissionTypeHRAdmin(permission)) && " - HR/Admin View"}
          </h5>
        </Col>
        <Col md={6} style={{ textAlign: 'Right' }}>
          <CreateFieldExpenseModal onFinish={() => refetchExpenses()}/>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Table striped hover>
              <thead style={{ position: 'sticky', top: 0, zIndex: '30' }}>
                <tr>
                  <th style={{ width: '25%' }}>🧾 Expense Name</th>
                  <th>Created By</th>
                  <th>Expense Type</th>
                  <th>Currency</th>
                  <th>Amount ($)</th>
                  <th>View</th>
                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {isLoading && <td colSpan={6}> Loading ...</td>}
                {error && <td colSpan={6}>Error fetching expenses.</td>}
                {(!isLoading && expenses) && expenses.map((expense) => {
                  return (<tr>
                    <td>{expense.expenseName}</td>
                    <td>{expense.email}</td>
                    <td>{expense.expenseType}</td>
                    <td>{expense.currencyType}</td>
                    <td>${Number(expense.amount).toFixed(2)}</td>
                    <td><FaEye size={22} onClick={() => { setReport(expense); setIsReportList(false); }} /></td>
                    {/* <td><FaTrashAlt size={22} /></td> */}
                  </tr>);
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};
