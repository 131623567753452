import React from "react";
import { Col, Row } from "react-bootstrap";

// Displays the field images based on the img Data
export const FieldImagesDisplay = ({ imgDatas, handleShow, style }) => {
  const findImageSize = () => {
    return imgDatas.length == 1 ? { md: 12 } : { md: 6 };
  };
  return (
    <Row>
      {imgDatas.map((imgData) => (
        <Col
          {...findImageSize()}
          className="d-flex flex-column text-center align-items-center"
        >
          <img
            src={URL.createObjectURL(imgData)}
            style={style || {
              maxWidth: "80%",
              height: "400px",
              cursor: "pointer",
            }}
            onClick={() =>
              handleShow(URL.createObjectURL(imgData), imgData.name)
            }
          />
          <div>{imgData.name} </div>
        </Col>
      ))}
    </Row>
  );
};
