/**
 * SystemTypesInsertModal.jsx
 * ----------------------------
 * Modal component that contains functionality for inserting System Types into the LWR database.
 * This data insertion is done via a POST request to the LWR API.
 *
 * Contains insert form and assosciated validation.
 *
 * Author: Oscar Jaimes
 * Last Updated: 15/06/2021
 */

import React, { useState, useRef } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import { createSysType } from "../../../api/systemtypes";

export default function SystemTypesInsertModal(props) {
  // States
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // Refs
  const systemNameRef = useRef();

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let systemName = systemNameRef.current.value;
    const createResult = await createSysType(systemName);

    if (createResult.status != 200) {
        setSuccessMsg("");
        setErrMsg("Error inserting new system type into the database");
      } else {
        setErrMsg("");
        setSuccessMsg(
          `Succesfully inserted new System Type: ${systemName} into the Database`
        );
      }
  };

  // Shows
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setSuccessMsg("");
    props.onEnd();
  };

  return (
    <>
      <Button style={{ backgroundColor: '#005E7D' }} onClick={handleShow}>
        Add New System Type
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{zoom: '85%'}}>
        <Modal.Header>
          <Modal.Title>Add New System Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Fill out all fields below to insert a new client System Type into
            the database.
          </div>
          <br />
          <div>
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            {successMsg && <Alert variant="success">{successMsg}</Alert>}
            <Form onSubmit={onFormSubmit}>
              <Form.Group>
                <Form.Label>System Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter System Type name"
                  ref={systemNameRef}
                  required
                />
              </Form.Group>
              <br />
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
