import React from "react";
import { FieldTimeReportingForm } from "./FieldTimeReportingFormCreator";

// contains the inputs, styles and formgroup for project remarks

export const FieldTimeSheetProjectRemarks = ({
  formProps: { setNewReport, pageNumber, setPageNumber, newReport, setUpdate },
  editMode
}) => {
  return (
    <div>
      <h4 className="text-center py-3 border-bottom">
        Project Manager Remarks, coding & Authroization (For Internal Use Only)
      </h4>
      <FieldTimeReportingForm
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        newReport={newReport}
        editMode={editMode}
      >
        {/* text area to get bigger text fields for remarks */}
        <textarea
          className="w-100 border p-2"
          value={newReport.remarks}
          placeholder="Enter Project Remarks here"
          rows="5"
          onChange={(e) =>
            setNewReport((newReport) => ({
              ...newReport,
              remarks: e.target.value,
            }))
          }
        ></textarea>
      </FieldTimeReportingForm>
    </div>
  );
};
