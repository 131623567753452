import { ListGroup } from "react-bootstrap";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

// Work Order Components
import WorkOrderForm from "./WorkOrderForm";
import WorkOrderListPage from "./WorkOrderListPage";

import { IoCreate, IoReader } from "react-icons/io5";

const TabNav = () => {

  const [listViewSelected, setListViewSelected] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  return (
    <div style={{ zoom: '100%' }}>
      <Router>
          <Switch>
            <Route
              path="/work-orders/create"
              component={WorkOrderForm}
            />
            <Route path="/work-orders/:workOrderId" component={() => {
              return <WorkOrderForm mode="view" workOrder={selectedWorkOrder} setViewMode={setSelectedWorkOrder} />;
            }} />
            <Route
              path="/work-orders/"
              component={() => <WorkOrderListPage listViewSelected={listViewSelected} setListViewSelected={setListViewSelected} selectedWorkOrder={selectedWorkOrder} setSelectedWorkOrder={setSelectedWorkOrder} />}
            />
            <Redirect to="/work-orders/" />
          </Switch>
      </Router>
    </div>
  );
};

const Navigation = ({ setListViewSelected, setSelectedWorkOrder }) => {
  const location = useLocation();

  const handleViewWorkOrdersClick = () => {
    setListViewSelected(true);
    setSelectedWorkOrder(null);
  }

  return (
    <ListGroup defaultActiveKey="/work-orders/create" variant="" style={{ width: '120%' }}>
      <ListGroup.Item
        action
        href="/work-orders/create"
        to="/work-orders/create"
        as={Link}
        style={{ backgroundColor: "transparent", color: location.pathname === "/work-orders/create" ? '#005E7D' : 'grey', border: 'none', fontSize: '18px' }}
      >
        {<IoCreate size={23} />}
        {' Create Work Order'}
      </ListGroup.Item>

      <ListGroup.Item
        action
        href="/work-orders/view"
        to="/work-orders/view"
        as={Link}
        onClick={handleViewWorkOrdersClick}
        style={{ backgroundColor: "transparent", color: location.pathname === "/work-orders/view" ? '#005E7D' : 'grey', border: 'none', fontSize: '18px' }}
      >
        {<IoReader size={23} />}
        {' View Work Orders'}
      </ListGroup.Item>
    </ListGroup>
  );
}

export default TabNav;
