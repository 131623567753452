import { isPermissionTypeDistributor } from "../../utility/permissionFunctions";

// api
import { findSimilarDocumentName, saveAssessmentReport } from "../../api/reports";

const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return (yyyy + '/' + mm + '/' + dd);
}

const getTime = () => {
    const date = new Date();
    return date
}

export const getSimilarFileName = async (name) => {
    name = name.replace(/%/g, '%25');
    let res = await findSimilarDocumentName(name);
    return res;
}

export const saveEngineeringReportToDatabase = async (params, sysParams, currentUser, accountType, carbonModel) => {
    let date = getDate();
    params.name = params.name.replace(/%25/g, '%')
    params.name = params.name.split(' - Engineering Assessment')[0]
    let assessmentNameWithoutTime = `${params.name} - Engineering Assessment`

    let numberOfSimilarFileName = await getSimilarFileName(assessmentNameWithoutTime)

    let versionName = numberOfSimilarFileName + 1
    let assessmentName = `${assessmentNameWithoutTime} v${versionName} --time-- ${getTime()}`;
    let createdBy = `${currentUser.email}`
    let isProject = `${params.isProject}`

    console.log('CARBON MODEL HERE!!@!!!');
    console.log(carbonModel);

    const saveAssessmentResult = await saveAssessmentReport(assessmentName, date, createdBy, isProject, sysParams, 'eng', carbonModel);

    if (!isPermissionTypeDistributor(accountType)) {
        if (saveAssessmentResult.status == 200) {
            alert("Successfully saved assessment in database");
        } else {
            let fin = saveAssessmentResult.data;
            alert(fin);
        }
    } else {
        if (saveAssessmentResult.status !== 200) {
            let fin = saveAssessmentResult.data;
            console.log(fin)
        }
    }

};

export const saveClientReportToDatabase = async (params, currentUser, microNutrients, accountType, carbonModel) => {
    let assessmentData = {
        operatingDays: Number(params.days),
        operatingHours: Number(params.hours),
        annualFlow: Number(params.flow),
        totalSolids: Number(params.ts),
        suspendedSolids: Number(params.ss),
        ammoniumNitrogen: Number(params.ammNit),
        tkn: Number(params.tkNit),
        potassium: Number(params.pot),
        phosphorus: Number(params.phos),
        volatileSolids: Number(params.vs),
        isFirstWave: (params.sysType === "First Wave"),
        screwPressLoop: true,
        units: params.units,
        roRecovery: Number(params.roRecovery),
        manure: params.manure,
        secondRO: params.secondRO == "true",
        ro2Recovery: Number(params.ro2Recovery),
        spOutput20Percent: params.spOutput20Percent  == "true"
    }

    let date = getDate();
    params.name = params.name.split(' - Client Assessment')[0]

    let assessmentNameWithoutTime = `${params.name} - Client Assessment`

    let createdBy = currentUser.email
    let numberOfSimilarFileName = await getSimilarFileName(assessmentNameWithoutTime)

    let versionName = numberOfSimilarFileName + 1
    let assessmentName = `${assessmentNameWithoutTime} v${versionName} --time-- ${getTime()}`;
    let isProject = `${params.isProject}`;

    const savedAssesmentResult = await saveAssessmentReport(assessmentName, date, createdBy, isProject, assessmentData, "client", carbonModel);

    if (!isPermissionTypeDistributor(accountType)) {
        if (savedAssesmentResult.status == 200) {
            alert("Successfully saved assessment in database");
        } else {
            let fin = await savedAssesmentResult.data;
            alert(fin);
        }
    } else {
        if (savedAssesmentResult.status !== 200) {
            let fin = await savedAssesmentResult.data;
            console.log(fin)
        }
    }

};