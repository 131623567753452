import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import "./organization-components/OrganizationsTab.css"

// Custom Components (Tabs)
import OrganizationTab from "./organization-components/OrganizationsTab";
import FarmsTab from "./farms-components/FarmsTab";
import SystemsTab from "./systems-components/SystemsTab";
import SystemTypesTab from "./system-type-components/SystemTypesTab";
import IotTab from "./iot-components/IotTab";
import ChemicalTab from "./chemical-components/ChemicalTab";
import ApiKeyTab from "./api-key-components/ApiKeyTab";
import LoisControlTab from "./lois-control-components/loisControlTab";
import { UserPermissionControlTab } from "./user-permission-components/UserPermissionControlTab";
import { isPermissionTypePemissionAdmin } from "../../utility/permissionFunctions";
import { CgOrganisation, CgViewList } from 'react-icons/cg';
import { GiBarn, GiMechanicalArm, GiProcessor, GiChemicalDrop, GiKeyCard, GiControlTower, GiBookPile  } from 'react-icons/gi';
import { MdOutlinePermIdentity } from 'react-icons/md';
import AssetsTab from "./assets/AssetsTab";

const Navigation = ({ accountType }) => {
  const location = useLocation();

  const navItems = [
    { path: "/admin-tools/assets", name: "Assets", icon: <GiBookPile size={23} /> },
    { path: "/admin-tools/organizations", name: "Organizations", icon: <CgOrganisation size={23} /> },
    { path: "/admin-tools/farms", name: "Farms", icon: <GiBarn size={23} /> },
    { path: "/admin-tools/systems", name: "Systems", icon: <GiMechanicalArm size={23}/> },
    { path: "/admin-tools/system-types", name: "System Types", icon: <CgViewList size={23}/> },
    { path: "/admin-tools/iot-devices", name: "IoT Devices", icon: <GiProcessor size={23}/> },
    { path: "/admin-tools/chemicals", name: "Chemicals", icon: <GiChemicalDrop size={23} /> },
    { path: "/admin-tools/api-keys", name: "API Keys", icon: <GiKeyCard size={23} /> },
    { path: "/admin-tools/lois-control", name: "LOIS Control", icon: <GiControlTower size={23} /> },
    isPermissionTypePemissionAdmin(accountType)
      ? { path: "/admin-tools/permission-control", name: "User Permissions", icon: <MdOutlinePermIdentity size={23}/> }
      : null,
  ].filter(Boolean);

  return (
    <ListGroup defaultActiveKey="/admin-tools/organizations" style={{ width: '100%' }}>
      {navItems.map(({ path, name, icon }, index) => (
        <ListGroup.Item
          key={index}
          action
          href={path}
          to={path}
          as={Link}
          style={{
            backgroundColor: location.pathname === path ? "#005E7D" : 'transparent',
            color: location.pathname === path ? 'white' : '#005E7D',
            width: '100%',
            fontSize: '17px',
            marginTop: '10px',
          }}
        >
          {icon}
          {'  ' + `${name}`}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default function AdminNav(props) {
  return (
    <div style={{ zoom: '85%' }}>
      <Router>
        <div style={{ display: 'flex', height: '100vh' }}>
          <div style={{
            width: '13.666667%',
            overflowY: 'auto',
            backgroundColor: 'white',
            position: 'fixed',
            height: '100%',
            marginLeft: '0.5%',
            marginTop: '0.3%'
          }}>
            <Navigation accountType={props.accountType} />
          </div>
          <div style={{
            marginLeft: '14.666667%',
            marginRight: '0.5%',
            width: '100%',
            marginTop: '-15px'
          }}>
            <Switch>
              <Route
                path="/admin-tools/assets"
                component={() => {
                  return (
                    <AssetsTab />
                  );
                }}
              />
              <Route
                path="/admin-tools/organizations"
                component={() => {
                  return (
                    <OrganizationTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/farms"
                component={() => {
                  return (
                    <FarmsTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/systems"
                component={() => {
                  return (
                    <SystemsTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/system-types"
                component={() => {
                  return (
                    <SystemTypesTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/iot-devices"
                component={() => {
                  return (
                    <IotTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/chemicals"
                component={() => {
                  return (
                    <ChemicalTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/api-keys"
                component={() => {
                  return (
                    <ApiKeyTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              <Route
                path="/admin-tools/lois-control"
                component={() => {
                  return (
                    <LoisControlTab
                      account={props.account}
                      accountType={props.accountType}
                    />
                  );
                }}
              />
              {isPermissionTypePemissionAdmin(props.accountType) ? (
                <Route
                  path="/admin-tools/permission-control"
                  component={() => {
                    return (
                      <UserPermissionControlTab
                        account={props.account}
                        accountType={props.accountType}
                      />
                    );
                  }}
                />
              ) : null}
              <Redirect to="/admin-tools/organizations" />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}
