import React from "react";
import { ExistingTimeReportSubDictValues } from "../ExistingTimeReportSubDictValues";
import { ExistingTimeReportValue } from "../ExistingTimeReportValue";

// Displays Existing Report Values
export const DisplayExistingReportValue = ({
  reportSubDict,
  multipleDict = false,
  md = 2,
  returnMD = () => {},
  setTimeSheet,
  subDictName,
  timeSheet,
  editIdList,
  cancelChanges,
}) => {
  return Object.keys(reportSubDict).length > 0 ? (
    Object.keys(reportSubDict).map((key) =>
      // ignore any keys with _id, newId, expense currencyType and totalHours
      key != "_id" &&
      key != "newId" &&
      key != "expenses" &&
      key != "totalHours" &&
      key != "currencyType" ? (
        !multipleDict ? (
          // if the value is not a dict within a dict
          <ExistingTimeReportValue
            reportSubDict={reportSubDict}
            keyName={key}
            setTimeSheet={setTimeSheet}
            subDictName={subDictName}
            timeSheet={timeSheet}
            multipleDict={multipleDict}
            editIdList={editIdList}
            cancelChanges={cancelChanges}
          />
        ) : (
          // if the value is a dict within a dict
          <ExistingTimeReportSubDictValues
            returnMD={returnMD}
            reportSubDict={reportSubDict}
            setTimeSheet={setTimeSheet}
            timeSheet={timeSheet}
            keyName={key}
            subDictName={subDictName}
            editIdList={editIdList}
            md={md}
            multipleDict={multipleDict}
            cancelChanges={cancelChanges}
          />
        )
      ) : null
    )
  ) : (
    <div className="d-flex justify-content-center align-items-center">n/a</div>
  );
};
