import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { getWorkOrderPDF } from '../../api/workOrders';

const WorkOrderPDF = ({ workOrderId }) => {
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await getWorkOrderPDF(workOrderId);
        if (response && response.data) {
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          setPdfData(pdfBlob);
        } else {
          console.error('Failed to fetch PDF data');
        }
      } catch (error) {
        console.error('Error fetching the PDF', error);
      }
    };

    fetchPdf();
  }, [workOrderId]);

  return (
    <div>
      {pdfData ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
          <Viewer fileUrl={URL.createObjectURL(pdfData)} />
        </Worker>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default WorkOrderPDF;
