import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { acknowledgePolicy } from "../../../../api/hrPolicy";
import { useAuth } from "../../../../contexts/AuthContext";
export const ExistingHRPolicyAcknowledgeModal = ({
  show,
  setShow,
  _id,
  setLearnMorePolicy,
  learnMorePolicy,
}) => {
  const handleClose = () => setShow(false);
  const { email } = useAuth().currentUser;

  const submitForm = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    const bodyVal = {
      email,
      _id,
      acknowledgedDate: currentDate,
      policyName: learnMorePolicy.policyName,
    };
    handleClose();

    acknowledgePolicy(bodyVal).then((res) => {
      if (res.status == 200) {
        const _learnMorePolicy = { ...learnMorePolicy };
        _learnMorePolicy.acknowledgedBy.push({
          acknowledgedDate: currentDate,
          acknowledgedEmail: email,
        });
        setLearnMorePolicy(_learnMorePolicy);
      }
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={submitForm}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Policy Acknowledgement</Modal.Title>
          <div className="">
            <AiOutlineClose
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          By clicking the button below, you indicate that you have read the
          policy and understood all the sub heading and its contents.
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" type="submit">
            I Acknowledge
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
