import React from "react";
import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { FormGroupCreator } from "../../FormGroupCreator";
import "./CanvasStyles.css";
export const ExistingFieldReportProjectRemarks = ({
  cancelChanges,
  timeSheet,
  setTimeSheet,
  report,
}) => {
  const [editValue, setEditValue] = useState(false);

  useEffect(() => {
    cancelChange();
  }, [cancelChanges]);
  const cancelChange = () => {
    setEditValue(false);
    timeSheet["remarks"] = report["remarks"];
    setTimeSheet(timeSheet);
  };
  const changeValue = (value, dictValueType) => {
    const _timeSheet = { ...timeSheet };
    _timeSheet.remarks = value;
    setTimeSheet(_timeSheet);
  };

  return (
    <div>
      <Row className="px-3">
        <Card>
          <Card.Body>
            <Card.Title className="text-center py-2 border-bottom d-flex flex-row justify-content-end pe-5 me-5">
              <div
                className=" d-flex justify-content-start"
                style={{ width: "65%" }}
              >
                Project Manager Remarks, coding & Authorization (For Internal
                Use Only)
              </div>

            </Card.Title>
            {!editValue ? (
              <Row>
                <Col>{timeSheet.remarks}</Col>
              </Row>
            ) : (
              <Row>
                <FormGroupCreator
                  valueDict={timeSheet}
                  valueType={"remarks"}
                  type="text"
                  changeValue={changeValue}
                />
              </Row>
            )}
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
};
