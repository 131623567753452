/**
 * ExistingHRShopTimesheet.jsx
 * ---------------------------
 * Component that provides the single-view for an existing shop timesheet.
 * 
 * Last Updated: 11/05/2023
 */

// Library dependencies
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import ReactToPdf from "react-to-pdf";

// Custom components and functions
import { ExistingHRShopTimesheetJob } from "./ExistingHRShopTimesheetJob";
import { useAuth } from "../../../../contexts/AuthContext";
import { getProperTime } from "../../../field-tools/fieldToolsFunctions";
import { ExistingHRShopTimesheetValue } from "./ExistingHRShopTimesheetValue";
import { parseDate } from "../../HRFunctions";
import { examplejobNumber } from "../HRShopTimesheetConstants";
import LoadingModal from "../../../analytics-reporting/LoadingModal";
import ResultModal from "../../../../components/ResultModal";

// api
import { getShopTimesheet, updateShopTimesheet } from "../../../../api/hrShopTimeSheet";


// Displays the existing timesheet
export const ExistingHRShopTimesheet = (props) => {
  // gets the id from the url
  let {
    params: { id },
  } = props.match;
  const { email } = useAuth().currentUser;
  const [shopTimesheet, setShopTimesheet] = useState({});
  const [cancelChanges, setCancelChanges] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalTitleColor, setModalTitleColor] = useState("green");
  const [creatingPdf, setCreatingPdf] = useState(false);

  const timesheetRef = useRef();
  const history = useHistory();

  const handleModalClose = () => setShowModal(false);

  // gets the proper timesheet from database
  useEffect(async () => {
    const timesheet = await getShopTimesheet(id, email);
    const getProperDate = new Date(timesheet.date);
    timesheet.date = parseDate(getProperDate);
    timesheet.jobList = timesheet.jobList.map((job) => ({
      ...job,
      jobCodeList: job.jobCodeList.map((jobCode) => ({
        ...jobCode,
        id: uuidv4(),
      })),
    }));
    setShopTimesheet(timesheet);
  }, [cancelChanges]);

  // changes the timesheet value
  const changeTimesheetValue = (value, valueType) => {
    shopTimesheet[valueType] = typeof shopTimesheet[valueType] == "number" ? Number(value) : value;
    setShopTimesheet({ ...shopTimesheet });
  };

  // changes the job number values
  const changeJobNumber = (value, valueType) => {
    shopTimesheet.jobList = shopTimesheet.jobList.map((job) =>
      job._id == valueType.id ? { ...job, jobNumber: Number(value) } : job
    );
    setShopTimesheet({ ...shopTimesheet });
  };

  // creates new job nubmer
  const addJobNumber = () => {
    const newJobNumber = { ...examplejobNumber };
    newJobNumber.id = uuidv4();
    shopTimesheet.jobList.push(newJobNumber);
    setShopTimesheet({ ...shopTimesheet });
  };

  // removes existing job number
  const removeJobNumber = (valueType) => {
    shopTimesheet.jobList = shopTimesheet.jobList.filter(
      (job) => job._id != valueType.id
    );
    setShopTimesheet({ ...shopTimesheet });
  };

  function validateJobHours() {
    const jobs = shopTimesheet.jobList;
    let totalJobHours = 0;

    jobs.forEach((j) => {
      const jobCodes = j.jobCodeList;
      jobCodes.forEach((jc) => {
        totalJobHours += jc.hoursWorked;
      })
    });

    if (totalJobHours != shopTimesheet.totalHours) {
      setModalTitle('Validation Error')
      setModalContent(`Individual job hours must add up to total day hours. Total must add up to ${shopTimesheet.totalHours} Hours`);
      setShowModal(true);
      setModalTitleColor("orange");
      return false;
    }

    return true;
  }

  // submits form
  const submitForm = (e) => {
    e.preventDefault();

    // Validate timesheet hours before submitting
    if (!validateJobHours()) { return; }

    shopTimesheet.date = new Date(shopTimesheet.date);
    shopTimesheet.date.setDate(shopTimesheet.date.getDate() + 1);
    const bodyVal = {
      updatedTimesheet: shopTimesheet,
      email,
    };
    updateShopTimesheet(bodyVal).then((res) => {

      const message = res.status == 200
        ? "Timesheet has been successfully updated!"
        : "Timesheet could not be updated, please try again later!";

      const titleColor = res.status == 200 ? 'green' : 'red';

      setModalTitle(res.status == 200 ? 'Success' : 'Error');
      setModalContent(message);
      setModalTitleColor(titleColor);
      setShowModal(true)
    });
  };

  const pdfExportOptions = {
    orientation: "portrait",
    unit: "in",
    format: "a2",
    compress: true,
  };

  return (
    <>
      <ResultModal title={modalTitle} content={modalContent} titleColor={modalTitleColor} showErrorModal={showModal} handleClose={handleModalClose} />
      <Row className="pt-2">
        <Col style={{ textAlign: 'left' }}>
          <Button variant="secondary" onClick={() => { history.push('/hr-tools/hr-timesheet/existingShopTimesheet/') }}>
            Back
          </Button>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <ReactToPdf
            targetRef={timesheetRef}
            filename={`${shopTimesheet.shopTimesheetName} - Shop Timesheet.pdf`}
            onComplete={() => {
              setCreatingPdf(false);
            }}
            options={pdfExportOptions}
            x={0}
            y={0}
            scale={0.99}
          >
            {({ toPdf }) => (
              <LoadingModal
                onClick={toPdf}
                show={creatingPdf}
                setShow={setCreatingPdf}
              />
            )}
          </ReactToPdf>
        </Col>
      </Row>
      <div ref={timesheetRef}>
        <Form onSubmit={submitForm}>
          <h4 className="text-center">{shopTimesheet.shopTimesheetName} </h4>
          <div className="text-center">
            Created At: {getProperTime(shopTimesheet.createdAt)}
          </div>
          <div className="text-center">
            Last Updated At: {getProperTime(shopTimesheet.updatedAt)}
          </div>
          <ListGroup>
            <ExistingHRShopTimesheetValue
              valueDict={shopTimesheet}
              valueType="createdAt"
              label="Timesheet Date"
              date={true}
              changeValue={changeTimesheetValue}
              cancelChanges={cancelChanges}
            />
            <ExistingHRShopTimesheetValue
              valueDict={shopTimesheet}
              valueType="empNumber"
              label="Employee Number"
              changeValue={changeTimesheetValue}
              cancelChanges={cancelChanges}
            />
            <ExistingHRShopTimesheetValue
              valueDict={shopTimesheet}
              valueType="totalHours"
              label="Hours Worked"
              changeValue={changeTimesheetValue}
              cancelChanges={cancelChanges}
            />
            <br />
            <h5 className="text-center">Job Numbers</h5>

            {shopTimesheet.jobList?.map((job) => (
              <ListGroupItem>
                <ExistingHRShopTimesheetValue
                  valueDict={job}
                  valueType={{ type: "jobNumber", id: job._id }}
                  label="Job Number"
                  changeValue={changeJobNumber}
                  deleteButton={true}
                  deleteFunction={removeJobNumber}
                  cancelChanges={cancelChanges}
                />
                <ExistingHRShopTimesheetJob
                  job={job}
                  cancelChanges={cancelChanges}
                  shopTimesheet={shopTimesheet}
                  setShopTimesheet={setShopTimesheet}
                />
              </ListGroupItem>
            ))}
            <div className="d-flex justify-content-center mt-2">
              <Button onClick={addJobNumber} variant="outline-primary">
                Add Job Number
              </Button>
            </div>
          </ListGroup>
        </Form>
      </div>
      <Row className="pt-2">
        <Col style={{ textAlign: 'center' }}>
          <Button
            variant="warning"
            onClick={() => setCancelChanges(!cancelChanges)}
          >
            Cancel Changes
          </Button>
        </Col>
        <Col style={{ textAlign: 'center' }}>
          <Button onClick={submitForm}> Save Changes </Button>
        </Col>
      </Row>
    </>
  );
};
