import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { HRShopTimesheetCreateJobNumber } from "./HRShopTimesheetCreateJobNumber";
import { v4 as uuidv4 } from "uuid";
import { HRShopTimesheetJobCodesList } from "./HRShopTimesheetJobCodesList";
import { IoIosAddCircleOutline } from "react-icons/io";

// renders the second page of the shop timesheet
export const NewHRShopTimesheetJobs = ({ shopTimesheet, setShopTimesheet }) => {
  // creates new job number
  const addJobNumber = () => {
    shopTimesheet.jobList.push({
      id: uuidv4(),
      jobNumber: 0,
      jobCodeList: [],
    });
    setShopTimesheet({ ...shopTimesheet });
  };
  const [employeeInitial, setEmployeeInitial] = useState("");

  return (
    <div>
      {shopTimesheet.jobList.length == 0 ? (
        <div className="d-flex justify-content-center">
          <Button onClick={addJobNumber}>Enter Hours</Button>
        </div>
      ) : (
        <>
          <div
            className="text-center border rounded ms-2 p-2"
            style={{ background: "rgba(0, 0, 0, 0.03)" }}
          >
            Please Enter a Job Number and click the "<IoIosAddCircleOutline />"{" "}
            icon to add Job Tasks.
          </div>
          <br />
          <Row>
            <Col
              className="col-10 col-md-11 text-center py-2"
              style={{ paddingLeft: "10%" }}
            >
              <h4>Job Numbers</h4>
            </Col>
            {shopTimesheet.jobList.length > 0 ? (
              <Col className="col-1">
                <Button onClick={addJobNumber}>+</Button>
              </Col>
            ) : null}
          </Row>

          {shopTimesheet.jobList.map((key) => (
            <div className="my-2">
              <Card>
                <Card.Title className="px-2"></Card.Title>
                <Card.Body>
                  <HRShopTimesheetCreateJobNumber
                    jobNumber={key.jobNumber}
                    id={key.id}
                    setShopTimesheet={setShopTimesheet}
                    shopTimesheet={shopTimesheet}
                    employeeInitial={employeeInitial}
                  />
                  {/* displays job codes */}
                  <HRShopTimesheetJobCodesList
                    job={key}
                    shopTimesheet={shopTimesheet}
                    setShopTimesheet={setShopTimesheet}
                    setEmployeeInitial={setEmployeeInitial}
                  />
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
